import axios from 'axios/index';

/**
 * @class ./services/api/Users/Users
 */
class Users
{

    /**
     * Returns full list of users.
     *
     * @param {Object} params
     *
     * @example Response:
     * {
     *      "results": [
     *          {
     *          "username": "string",
     *          "lastName": "string",
     *          "firstName": "string",
     *          "language": 0,
     *          "isAdmin": true,
     *          "id": "string"
     *          }
     *      ],
     *      "resultCount": 0,
     *      "totalResultCount": 0,
     *      "pageSize": 0,
     *      "currentPage": 0,
     *      "totalPageCount": 0
     *  }
     *
     */
    getAllUsers = params =>
    {
        return axios.get('/users', {
            params
        })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Create a new user.
     *
     * @param {object} params 
     * {
     *      "username": "string",
     *      "lastName": "string",
     *      "firstName": "string",
     *      "language": 0,
     *      "isAdmin": true,
     *      "password": "string"
     *  }
     */
    createUser = params => {
        return axios.post('/users', {
            ...params
        })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    }
    
    /**
     * @example Response:
     *
     *  {
     *      "username": "string",
     *      "lastName": "string",
     *      "firstName": "string",
     *      "language": 0,
     *      "isAdmin": true,
     *      "id": "string"
     *  }
     */
    getUser = (id) =>
    {
        return axios.get('/users/' + encodeURIComponent(id))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * 
     * @param {string} id 
     * 
     */
    deleteUser = (id) =>
    {
        return axios.delete('/users/' + encodeURIComponent(id))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };
    
    /**
     * 
     * @param {string} id 
     * @param {object} params 
     * 
     * @params 
     * {
     *      "username": "string",
     *      "lastName": "string",
     *      "firstName": "string",
     *      "language": 0,
     *      "isAdmin": true
     * }
     */
     updateUser = (id, params) =>
     {
        return axios.put('/users/' + encodeURIComponent(id), {
            ...params
        })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
      };
      /**
       * 
       * @param {string} id 
       * @param {object} params 
       * 
       * @params 
       * {
       *    "password": "string"
       * }
       */
       updatePassword = (id, params) =>
      {
        return axios.put('/users/' + encodeURIComponent(id) + '/updatepassword', {
            password : params
        })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
       };
};

export default Users;