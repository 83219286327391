import React         from 'react';
import PropTypes     from 'prop-types';
import { Link }      from 'react-router-dom';
import { UsersIcon } from '../../../../../../../components/Images';
import { UserIcon }  from '../../../../../../../components/Images';
import { Logout  }   from '../../../../../../../components/Images';

import"./style.css";

const UserMenu = ({closeMenu , loggedUser, show, logout}) =>
(
    <div id="user-management-menu" className={show ? "openUserMenu" : "" }>
        <nav>
            <p className="user-details">
                {loggedUser?.firstName} {loggedUser?.lastName}
            </p>
            
            <div className="underline">
                <hr />
            </div>

            <ul>
                <li>
                    <Link to={`/user/${loggedUser?.id}`} onClick={closeMenu}>
                        <UserIcon /> My Account
                    </Link>
                </li>
                {loggedUser?.isAdmin &&
                    <li>
                        <Link to="/usersmanagement" onClick={closeMenu}>
                            <UsersIcon /> Users
                        </Link>
                    </li>
                }
                <li onClick={logout}>
                    <Logout /> Logout
                </li>
            </ul>
        </nav>
    </div>
);

UserMenu.propTypes = {
    show : PropTypes.bool.isRequired,
    logout : PropTypes.func.isRequired,
    closeMenu : PropTypes.func.isRequired,
    loggedUser : PropTypes.object.isRequired
};

export default UserMenu;