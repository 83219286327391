/**
 * /services/utils/Response/Response
 */
class Response {
    /**
     * Parses response and returns array of error messages.
     *
     * @param {String|Object} response
     *
     * @returns {Array}
     */
    getErrorMessages = (response) =>
    {
        let errors = [];

        if (typeof response === 'object') {
            if (response.validationErrors) {
                response.validationErrors.map(error => {
                    errors.push(error.message);

                    return true;
                });
            } else {
                errors.push(response.error);
            }
        } else {
            errors.push(response);
        }

        return errors;
    };
}

export default Response;