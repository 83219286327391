import React, { useRef } from 'react';
import PropTypes         from 'prop-types';

import './style.css';

export default function Radio (props)
{
    const inputRef = useRef(null);

    return (
        <label className={[
            'Radio',
            props.className,
            props.required && 'required',
            props.disabled && 'disabled'
        ].join(' ')}>
            <span className={['label-text', props.checkedValue === props.value ? 'checked' : ''].join(' ')}
                onClick={() => !props.disabled && props.changeSelectedHandler && props.changeSelectedHandler(inputRef.current.value)}
        >   
                {
                    props.split
                        ?   <React.Fragment>
                                <strong className="d-block">
                                    {props.label.split(",")[0]}
                                </strong>
                                {props.label.split(",")[1]}
                            </React.Fragment>
                        :   props.label
                }
            </span>
            <input
                type={ props.type }
                name={ props.name }
                value={props.value}
                className={ props.className }
                disabled={ props.disabled }
                readOnly={ props.readOnly }
                placeholder={ props.placeholder }
                required={ !props.disabled && props.required }
                checked={ props.defaultValue === props.value }
                onChange={ (e) => !props.disabled && props.changeSelectedHandler &&
                    props.changeSelectedHandler(e.target.value)
                }
                ref={inputRef}
            />
            <span className="checkmark" />
        </label>
    );
}

Radio.defaultProps = {
    type: 'radio',
    required : true,
    readOnly : false,
    disabled : false,
    className : '',
};

Radio.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    required : PropTypes.bool,
    readOnly : PropTypes.bool,
    disabled : PropTypes.bool,
    className : PropTypes.string,
    onClickHandler : PropTypes.func,
    changeSelectedHandler : PropTypes.func,
};