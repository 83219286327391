/**
 * @class scenes/VenueManagement/scenes/ZonesAndCheckpoints/services/Zone
 */
class Zone
{
    /**
     * Returns adapted and modified zones data.
     *
     * @param {Object} responseData
     *
     * @returns {Object}
     */
    getZonesData = (responseData) =>
    {
        let zonesData = {};

        responseData !== null && responseData.map(zone => {
            let filters = '';

            zone.rules.map((andRules,index) => {
                andRules.map((orRule,index) => {
                    let operator = '';
                    switch (orRule.operator) {
                        case 'equal':
                            operator = '=';
                            break;
                        case 'notEqual':
                            operator = '!=';
                            break;
                        case 'less':
                            operator = '<';
                            break;
                        case 'greater':
                            operator = '>';
                            break;
                        case 'lessEqual':
                            operator = '<=';
                            break;
                        case 'greaterEqual':
                            operator = '>=';
                            break;
                        case 'contains':
                            operator = '@=';
                            break;
                        case 'notContains':
                            operator = '!@=';
                            break;
                        default:
                            operator = '=';
                    }
                    if ((andRules.length - 1) === index) {
                        filters += orRule.field + ' ' + operator + ' ' + orRule.operand;
                    } else {
                        filters += orRule.field + ' ' + operator + ' ' + orRule.operand + ' && ';
                    }

                    return true;
                });

                if ((zone.rules.length - 1) > index) {
                    filters += ' || ';
                }

                return true;
            });

            zonesData[zone.name] = {
                'filters' : filters
            };

            return true;
        });

        return zonesData;
    };
}

export default Zone;