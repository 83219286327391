import { useEffect, useState } from "react";
import { SortingArrowsIcon } from "../../../../../components/Images";

import "./style.css";

export type SelectChangeEvent = {
  uid: string;
  value: string;
};

export interface SelectMap {
  [key: string]: string | undefined;
}

type SelectProps<T> = {
  getValue?: (event: SelectChangeEvent) => void;
  uid: string;
  name?: string;
  disabled?: boolean;
  initialValue?: string | number;
  data?: SelectMap | T[];
  className?: string;
  keyValueMapper?: (item: T, index: number) => [key: string, value: string];
};

export default function Select<T>({
  getValue,
  uid,
  name = undefined,
  disabled,
  initialValue,
  data,
  className,
  keyValueMapper,
}: SelectProps<T>) {
  const [value, setValue] = useState<string | number | undefined>(initialValue);

  useEffect(() => {
    initialValue !== undefined &&
      initialValue !== null &&
      setValue(initialValue);
  }, [data, initialValue]);

  const updateValue = (value: string) => {
    setValue(value);
    getValue &&
      getValue({
        value: value,
        uid: uid,
      });
  };

  if (!data) {
    return null;
  }

  /**
   * @returns {JSX}
   */
  return (
    <div className="theme-select">
      <select
        name={name}
        disabled={disabled}
        className={className}
        onChange={(e) => updateValue(e.target.value)}
        value={value}
      >
        {!keyValueMapper &&
          Object.entries(data).map((item, index) => (
            <option key={index} value={item[0]}>
              {item[1]}
            </option>
          ))}
        {keyValueMapper &&
          Array.isArray(data) &&
          data.map((item: T, index: number) => {
            const [key, value] = keyValueMapper(item, index);
            return (
              <option key={index} value={key}>
                {value}
              </option>
            );
          })}
      </select>
      <SortingArrowsIcon fill="var(--primary-color)" />
    </div>
  );
}
