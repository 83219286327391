import React     from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Textarea = ({ label, name, className, required, value, disabled, readOnly, placeholder, onChangeHandler, maxLength }) => 
(
    <div className={'Textarea'}>
        { label ?
            <label htmlFor={ name } className={[className, required ? 'required' : ''].join(' ')}>
                { label }
            </label>:
            null
        }
        <textarea
            name={ name }
            className={ className }
            value={ value }
            disabled={ disabled }
            required={ required }
            readOnly={ readOnly }
            placeholder={ placeholder }
            minLength={10}
            onChange={ onChangeHandler ? (event) => onChangeHandler(event) : null }
            maxLength={ maxLength}
        >
        </textarea>
    </div>
);

Textarea.defaultProps = {
    label: '',
    name: 'textarea' + Math.floor(Math.random() * 10),
    required : false,
    readOnly : false,
    disabled : false,
    maxLength : '',
    placeholder : 'Enter text here',
    className : ''
};

Textarea.propTypes = {
    label : PropTypes.string,
    name : PropTypes.string.isRequired,
    required : PropTypes.bool,
    maxLength : PropTypes.string,
    readOnly : PropTypes.bool,
    disabled : PropTypes.bool,
    placeholder : PropTypes.string.isRequired,
    className : PropTypes.string
};

export default Textarea;