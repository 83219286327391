import React, { Component } from 'react';
import moment               from 'moment';
import PropTypes            from 'prop-types';
import { T }                from '@transifex/react';
import DatePickerInput      from './DatePickerInput';

export default class DatePeriodPicker extends Component {
    state = {
        beginDate: null,
        endDate: null
    };

    componentDidMount () {
        const { beginDate, endDate } = this.props;
        if ( beginDate && beginDate.initialDate ) {
            this.setState({
                beginDate : beginDate.initialDate
            })
        };
        if ( endDate && endDate.initialDate ) {
            this.setState({
                endDate : endDate.initialDate
            })
        };
    }

    /**
     * Return the Begin date to the parent and update the state accordingly
     */
    beginDateHandler = value => {
        this.props.beginDateCallBack(moment(value).format('DD-MM-YYYY HH:mm'));
        this.setState({ beginDate : value});
    };

    /**
     * Return the End date to the parent and update the state accordingly
     */
    endDateHandler = value => {
        this.props.endDateCallBack(moment(value).format('DD-MM-YYYY HH:mm'))
        this.setState({ endDate : value});
    };

    render() {
        return (
            <div className="row" style={{ marginBottom : '15px' }}>
                <div className="col-6">
                    <label><T _str="Begin"/></label>
                    <DatePickerInput
                        hasIcon
                        {...this.props}
                        {...this.props.beginDate}
                        callBack ={this.beginDateHandler}
                        className={this.props.beginDateErrors ? this.props.beginDateErrors.length > 0 ? 'error' : '' : ''}
                    />
                </div>

                <div className="col-6">

                    <label><T _str="End"/></label>
                    <DatePickerInput
                        hasIcon
                        {...this.props}
                        {...this.props.endDate}
                        callBack ={this.endDateHandler}
                        className={this.props.endDateErrors ? this.props.endDateErrors.length > 0 ? 'error' : '' : ''}
                    />
                </div>
                <div className="col-12">
                    <ErrorAlert DateError={this.props.beginDateErrors}/>
                    <ErrorAlert DateError={this.props.endDateErrors}/>
                </div>
            </div>
        )
    }
};

/**
 * Show the error message if any
 * 
 */
const ErrorAlert = ({ DateError }) =>
(
    DateError !== undefined &&
    DateError.length > 0 &&
    DateError.map( (error, key) => {
        return (<div key={ key } className={'error'}>
            - { error }
        </div>)
    })    
);

DatePeriodPicker.propTypes = {
    endDateCallBack   : PropTypes.func,
    beginDateCallBack : PropTypes.func,
    endDateErrors         : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    beginDateErrors       : PropTypes.oneOfType([ PropTypes.string, PropTypes.array])
};