import React, { Component, useRef } from "react";
import QRCode from "qrcode.react";
import { FileService } from "./services/File";
import { ProgressBar } from "react-bootstrap";
import { T, useT } from "@transifex/react";
import { ValidatorService, Type, MaxLength } from "pretty-validator";
import { List } from "./components/List";
import { ShowService } from "../../../../services/api/Show";
import { LoadingScreen } from "../../../../components/Loading";
import { TicketService } from "../../../../services/api/Ticket";
import { SortService } from "../../../../services/utils/Sort";
import { Alert, AlertTypeSuccess } from "../../../../themes/default/Alert";
import { Pagination } from "../../../../components/Pagination";
import { Dialog } from "../../../../themes/default/Dialog";
import { DialogError } from "../../../../themes/default/Dialog";
import { ResponseService } from "../../../../services/utils/Response";
import { Input } from "../../../../themes/default/Form/components/Input";
import { SceneTitle } from "../../../../themes/default/Title/components/Scene";
import { Search } from "../../../../themes/default/Form/components/Search";
import { Select } from "../../../../themes/default/Form/components/Select";
import {
  Button,
  ButtonSize,
} from "../../../../themes/default/Form/components/Button";
import { Panel } from "../../../../themes/default/Layout/components/Panel";
import { RadioGroup } from "../../../../themes/default/Form/components/RadioGroup";

import IconLoading from "./images/ico-loading.svg";
import IconUpload from "./images/upload.svg";

import "./style.css";

const NUMBER_OF_TICKETS_PER_REQUEST = 1000;

/**
 *  Stores latest timeout id.
 */
let timeoutId;

/**
 * @class /src/scenes/ShowManagement/scenes/Tickets/Tickets
 */
class Tickets extends Component {
  /**
   * @type {Object}
   */
  fileReader;

  /**
   * @type {Object}
   */
  validatorService;

  /**
   * @type {Object}
   */
  showService;

  /**
   * @type {Object}
   */
  sortService;

  /**
   * @type {Object}
   */
  ticketService;

  /**
   * @type {Object}
   */
  responseService;

  state = {
    tickets: {},
    show: {},
    openUploadTicketsDialog: false,
    openTicketDetailsDialog: false,
    searchBarcodeValue: "",
    ticketBarcode: "",
    ticketValidity: "valid",
    ticketSalutation: "",
    ticketFirstName: "",
    ticketLastName: "",
    ticketCustomProperties: "",
    ticketSalutationErrors: "",
    ticketFirstNameErrors: "",
    ticketLastNameErrors: "",
    ticketUsageLimit: "",
    pageSize: 50,
    currentPage: 1,
    totalPageCount: 1,
    sort: "",
    showLoadingScreen: false,
    showErrorDialog: false,
    errorMessages: [],
    showSuccessMessage: false,
    ticketUploadFormat: "starticket",
    fileName: "No file selected",
    progressBarPercentage: 0,
    currentNumberOfChunk: 0,
  };

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.showId = this.props.match.params.id;

    this.sortService = new SortService();
    this.ticketService = new TicketService();
    this.validatorService = new ValidatorService();
    this.responseService = new ResponseService();
    this.fileService = new FileService();
    this.showService = new ShowService();
  }

  /**
   * @override
   */
  componentDidMount() {
    this.handleTicketList(1, this.state.pageSize, this.state.sort);

    this.showService.getOne(this.showId).then((response) => {
      if (JSON.stringify(this.state.show) !== JSON.stringify(response)) {
        this.setState({
          show: response.data,
          isPromiseFinished: true,
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  }

  /**
   * Checks if tickets object is empty.
   *
   * @param {Object} tickets
   *
   * @returns {Boolean}
   */
  isEmpty = (tickets) => {
    for (let i in tickets) {
      if (tickets.hasOwnProperty(i)) {
        return false;
      }
    }
    return true;
  };

  /**
   * Retrieves list of tickets and sets state accordingly.
   *
   * @param {Number} pageSize
   * @param {Number} page
   * @param {String} sort
   */
  handleTicketList = (page, pageSize, sort) => {
    let parameterObject = {
      ...(sort !== "" ? { Sort: sort } : {}),
      ...(this.state.searchBarcodeValue !== ""
        ? { Filter: "barcode@=*" + this.state.searchBarcodeValue }
        : {}),
      Page: page,
      PageSize: pageSize,
    };

    if (!this.state.showLoadingScreen) {
      this.setState({
        showLoadingScreen: true,
      });
    }
    this.ticketService
      .getAllForShow(this.showId, parameterObject)
      .then((response) => {
        if (response.status === 200) {
          if (
            JSON.stringify(response.data) !== JSON.stringify(this.state.tickets)
          ) {
            this.setState({
              tickets: response.data,
              currentPage:
                response.data.currentPage === null
                  ? 1
                  : response.data.currentPage,
              totalPageCount: response.data.totalPageCount,
              pageSize: response.data.pageSize,
            });
          }
        } else {
          this.showErrorMessage(response.data);
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
  };

  /**
   * Returns searched value.
   *
   * @param {String} searchValue
   */
  getSearchValue = (searchValue) => {
    let value = encodeURIComponent(searchValue);
    let queryParameter = "barcode@=*" + value;

    let parameterObject = {
      Filter: queryParameter,
      PageSize: this.state.pageSize,
      Page: 1,
    };

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      if (value.length > 2 || value.length === 0) {
        this.setState({
          showLoadingScreen: true,
        });
        this.ticketService
          .getAllForShow(this.showId, parameterObject)
          .then((response) => {
            if (response.status === 200) {
              if (
                JSON.stringify(response.data) !==
                JSON.stringify(this.state.tickets)
              ) {
                this.setState({
                  tickets: response.data,
                  searchBarcodeValue: value,
                  currentPage:
                    response.data.currentPage === null
                      ? 1
                      : response.data.currentPage,
                  totalPageCount: response.data.totalPageCount,
                  pageSize: response.data.pageSize,
                });
              }
            } else {
              this.showErrorMessage(response.data);
            }
            this.setState({
              showLoadingScreen: false,
            });
          });
      }
    }, 300);
  };

  /**
   * Returns selected tickets per page value.
   *
   * @param {Object} valueObject
   */
  getTicketsPerPageValue = (valueObject) => {
    if (this.state.pageSize !== parseInt(valueObject.value, 10)) {
      this.handleTicketList(
        this.state.currentPage,
        valueObject.value,
        this.state.sort
      );
    }
  };

  /**
   * Updates show's state value according to selected page.
   *
   * @param {Number} page
   */
  changePageHandler = (page) => {
    if (this.state.currentPage !== page) {
      this.handleTicketList(page, this.state.pageSize, this.state.sort);
    }
  };

  /**
   * Returns upload tickets type radio value.
   *
   * @param {Object} event
   */
  getUploadTicketsTypeValue = (event) => {
    let ticketFormat = event.target.value;
    this.setState({
      ticketUploadFormat: ticketFormat,
    });
  };

  /**
   * Returns selected ticket details validity value.
   *
   * @param {Object} valueObject
   */
  getTicketDetailsValidityValue = (valueObject) => {
    if (
      this.state.ticketValidity !== valueObject.value &&
      this.state.openTicketDetailsDialog
    ) {
      this.setState({
        ticketValidity: valueObject.value,
      });
    }
  };

  /**
   * Returns ticket salutation value.
   *
   * @param {String} value
   */
  getTicketSalutationValue = (value) => {
    this.setState(
      {
        ticketSalutation: value,
      },
      function () {
        if (this.isValidSalutation()) {
          this.setState({
            ticketSalutationErrors: "",
          });
        }
      }
    );
  };

  /**
   * Returns ticket first name value.
   *
   * @param {String} value
   */
  getTicketFirstNameValue = (value) => {
    this.setState(
      {
        ticketFirstName: value,
      },
      function () {
        if (this.isValidFirstName()) {
          this.setState({
            ticketFirstNameErrors: "",
          });
        }
      }
    );
  };

  /**
   * Returns ticket last name value.
   *
   * @param {String} value
   */
  getTicketLastNameValue = (value) => {
    this.setState(
      {
        ticketLastName: value,
      },
      function () {
        if (this.isValidLastName()) {
          this.setState({
            ticketLastNameErrors: "",
          });
        }
      }
    );
  };

  /**
   * Returns ticket usage limit value.
   *
   * @param {String} value
   */
  getTicketUsageLimitValue = (value) => {
    this.setState({
      ticketUsageLimit: value,
    });
  };

  /**
   * Uploads tickets from files in chunks if file contains more than 1000 tickets.
   *
   * @param {Number} chunkStart
   */
  uploadTickets = (chunkStart = 0) => {
    let ticketArray = this.fileService.uploadTickets(
        this.state.ticketUploadFormat,
        this.showId
      ),
      chunkedArray,
      numberOfChunks = this.calculateNumberOfChunks(ticketArray.length),
      progressBarIncrementPercentage = parseFloat(
        (100 / numberOfChunks).toFixed(2)
      ),
      progressBarCurrentPercentage = 0,
      currentNumberOfChunk = 0;
    let createTicketsForShow = (showId, chunkedArray) => {
      this.ticketService
        .createOneForShow(this.showId, chunkedArray)
        .then((response) => {
          if (response.status !== 200) {
            chunkStart += ticketArray.length;
            this.showErrorMessage(response.data);
          } else if (response.status === 200) {
            currentNumberOfChunk++;
            progressBarCurrentPercentage += progressBarIncrementPercentage;
            this.setState(
              {
                progressBarPercentage: parseFloat(
                  progressBarCurrentPercentage.toFixed(2)
                ),
                currentNumberOfChunk: currentNumberOfChunk,
              },
              () => {
                if (currentNumberOfChunk === numberOfChunks) {
                  this.showSuccessMessageAndReloadTickets();
                }
              }
            );
          }
        });
    };
    while (chunkStart < ticketArray.length) {
      chunkedArray = ticketArray.slice(
        chunkStart,
        chunkStart + NUMBER_OF_TICKETS_PER_REQUEST
      );
      chunkStart += NUMBER_OF_TICKETS_PER_REQUEST;
      createTicketsForShow(this.showId, chunkedArray);
    }
  };

  /**
   * Shows success message for uploading tickets, reloads ticket list and sets state accordingly.
   */
  showSuccessMessageAndReloadTickets = () => {
    this.setState(
      {
        progressBarPercentage: 100,
      },
      this.setState(
        {
          showSuccessMessage: true,
        },
        () => {
          this.closeUploadTicketsDialog();
          this.handleTicketList(
            this.state.currentPage,
            this.state.pageSize,
            this.state.sort
          );
        }
      )
    );
  };

  /**
   * Sets state for showing error dialog and its content.
   *
   * @param {String} responseData
   */
  showErrorMessage = (responseData) => {
    this.setState({
      showErrorDialog: true,
      errorMessages: this.responseService.getErrorMessages(responseData),
    });
  };

  /**
   * Calculates number of chunks based on allowed number of tickets per request.
   *
   * @param {Number} ticketArrayLength
   *
   * @returns {Number}
   */
  calculateNumberOfChunks = (ticketArrayLength) => {
    let numberOfChunks = Math.floor(
      ticketArrayLength / NUMBER_OF_TICKETS_PER_REQUEST
    );
    if (ticketArrayLength % NUMBER_OF_TICKETS_PER_REQUEST !== 0) {
      numberOfChunks += 1;
    }

    return numberOfChunks;
  };

  /**
   * Checks if salutation is valid and if not adds error to input field.
   *
   * @returns {Boolean}
   */
  isValidSalutation = () => {
    let errors = this.validatorService.validate(this.state.ticketSalutation, [
      new MaxLength(50),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        ticketSalutationErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Checks if first name is valid and if not adds error to input field.
   *
   * @returns {Boolean}
   */
  isValidFirstName = () => {
    let errors = this.validatorService.validate(this.state.ticketFirstName, [
      new MaxLength(50),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        ticketFirstNameErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Checks if last name is valid and if not adds error to input field.
   *
   * @returns {Boolean}
   */
  isValidLastName = () => {
    let errors = this.validatorService.validate(this.state.ticketLastName, [
      new MaxLength(50),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        ticketLastNameErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Edits tickets details.
   */
  editTicketDetails = () => {
    if (
      this.isValidSalutation() &&
      this.isValidFirstName() &&
      this.isValidLastName()
    ) {
      let ticket = {
        barcode: this.state.ticketBarcode,
        validity: this.state.ticketValidity,
        personalisedTicketSalutation: this.state.ticketSalutation,
        personalisedTicketFirstName: this.state.ticketFirstName,
        personalisedTicketLastName: this.state.ticketLastName,
        customProperties: this.state.ticketCustomProperties,
        ticketUsageLimit: this.state.ticketUsageLimit,
      };

      this.ticketService
        .updateOne(this.showId, this.state.ticketBarcode, ticket)
        .then((response) => {
          if (response.status === 204) {
            this.handleTicketList(
              this.state.currentPage,
              this.state.pageSize,
              this.state.sort
            );
            this.closeTicketDetailsDialog();
          } else {
            this.showErrorMessage(response.data);
          }
        });
    }
  };

  /**
   * Updates state and opens upload tickets dialog.
   */
  openUploadTicketsDialog = () => {
    this.setState({
      openUploadTicketsDialog: true,
    });
  };

  /**
   * Updates state and closes upload tickets dialog.
   */
  closeUploadTicketsDialog = () => {
    this.setState({
      fileName: "No file selected",
      openUploadTicketsDialog: false,
      ticketUploadFormat: "starticket",
      progressBarPercentage: 0,
    });
  };

  /**
   * Updates state and opens ticket details dialog.
   *
   * @param {String} ticket
   */
  openTicketDetailsDialog = (ticket) => {
    this.setState({
      openTicketDetailsDialog: true,
      ticketBarcode: ticket[0],
      ticketValidity: ticket[1][1],
      ticketFirstName: ticket[1][2],
      ticketLastName: ticket[1][3],
      ticketSalutation: ticket[1][4],
      ticketUsageLimit: ticket[1][5],
      ticketCustomProperties: ticket[1][6],
    });
  };

  /**
   * Updates state and closes ticket details dialog.
   */
  closeTicketDetailsDialog = () => {
    this.setState({
      ticketBarcode: "",
      ticketValidity: "",
      ticketSalutation: "",
      ticketSalutationErrors: "",
      ticketFirstName: "",
      ticketFirstNameErrors: "",
      ticketLastName: "",
      ticketLastNameErrors: "",
      ticketCustomProperties: "",
      ticketUsageLimit: "",
      openTicketDetailsDialog: false,
    });
  };

  /**
   * @param file
   */
  handleFileChosen = (file) => {
    this.fileReader = new FileReader();
    this.fileReader.onloadend = this.handleFileRead;
    this.fileReader.readAsText(file);
    this.fileService.setFileName(file.name);
    this.setState({
      fileName: file.name,
    });
  };

  /**
   * @param e
   */
  handleFileRead = (e) => {
    const content = this.fileReader.result;
    this.fileService.setFileContent(content);
  };

  /**
   * Sorts Barcode, First name, Last name and Salutation column ascending or descending or reset sort and
   * sets state accordingly.
   *
   * @param {String} column
   */
  sortColumn = (column) => {
    let sort = this.sortService.getSortValue(this.state.sort, column);

    this.setState({
      sort: sort,
    });

    this.handleTicketList(this.state.currentPage, this.state.pageSize, sort);
  };

  /**
   * Updates state and closes error dialog.
   */
  closeErrorDialog = () => {
    this.setState({
      showErrorDialog: false,
    });
  };

  /**
   * Returns adapted data for ticket list.
   *
   * @param {Object} responseData
   *
   * @returns {Object}
   */
  getTicketData = (responseData) => {
    let tableData = {};

    responseData["results"].map((ticket) => {
      let ticketData = [];

      ticketData.push(ticket.barcode);

      if (ticket.validity === null) {
        ticketData.push("");
      } else {
        ticketData.push(ticket.validity);
      }

      if (ticket.personalisedTicketFirstName === null) {
        ticketData.push("");
      } else {
        ticketData.push(ticket.personalisedTicketFirstName);
      }

      if (ticket.personalisedTicketLastName === null) {
        ticketData.push("");
      } else {
        ticketData.push(ticket.personalisedTicketLastName);
      }

      if (ticket.personalisedTicketSalutation === null) {
        ticketData.push("");
      } else {
        ticketData.push(ticket.personalisedTicketSalutation);
      }

      if (ticket.ticketUsageLimit === null) {
        ticketData.push("");
      } else {
        ticketData.push(ticket.ticketUsageLimit);
      }

      ticketData.push(ticket.customProperties);

      tableData[ticket.barcode] = ticketData;

      return true;
    });

    return tableData;
  };

  /**
   * @returns {XML}
   */
  render() {
    return (
      <div className="ShowTickets">
        <Tite name={this.props.venue?.name} />

        {this.state.showSuccessMessage && (
          <Alert type={AlertTypeSuccess}>
            <T _str="Tickets are successfully uploaded!" />
          </Alert>
        )}

        <p>
          <T _str="Inspect and manage a show's tickets. Some fields are inactive for shows created by integrated ticketing systems." />
        </p>

        <Panel title="" colorize>
          <div className="row p-0 show-tickets-options">
            <div className="search mt-1">
              <Search
                className="show-tickets-search"
                name="tickets-search"
                placeholder={<T _str="Search" />}
                getValue={this.getSearchValue}
              />
            </div>

            <div className="mt-1 total-tickets-col">
              <span className="total-tickets">
                <T _str="Total" />: {this.state.tickets.totalResultCount}{" "}
                {this.state.tickets.totalResultCount === 1 ? (
                  <T _str="ticket" />
                ) : (
                  <T _str="tickets" />
                )}
              </span>

              <TicketsPerPage getValue={this.getTicketsPerPageValue} />
            </div>

            <div className="mt-1 upload-tickets">
              <Button
                className="mr-1"
                onClickHandler={this.openUploadTicketsDialog}
              >
                <T
                  _str="Upload tickets"
                  _context="Button, please try to minimize the translation"
                />
                <img src={IconUpload} alt="Upload" />
              </Button>

              <Button
                className={ButtonSize.Tiny}
                onClickHandler={() =>
                  this.handleTicketList(
                    this.state.currentPage,
                    this.state.pageSize,
                    this.state.sort
                  )
                }
              >
                <img src={IconLoading} alt="Refresh" />
              </Button>
            </div>
          </div>

          <div className="helper">
            <T _str="Helper" />
          </div>
        </Panel>

        <div className="col-12 p-0 show-tickets-table">
          {!this.isEmpty(this.state.tickets) && (
            <List
              sort={this.sortColumn}
              ticketDetails={this.openTicketDetailsDialog}
              data={this.getTicketData(this.state.tickets)}
            />
          )}
        </div>

        {this.state.showLoadingScreen && <LoadingScreen />}

        <UploadTicketsDialog
          fileName={this.state.fileName}
          ticketUploadFormat={this.state.ticketUploadFormat}
          progressBarPercentage={this.state.progressBarPercentage}
          openUploadTicketsDialog={this.state.openUploadTicketsDialog}
          uploadTickets={this.uploadTickets}
          handleFileChosen={this.handleFileChosen}
          closeUploadTicketsDialog={this.closeUploadTicketsDialog}
          getUploadTicketsTypeValue={this.getUploadTicketsTypeValue}
        />

        <TicketDetailsDialog
          ticketBarcode={this.state.ticketBarcode}
          ticketLastName={this.state.ticketLastName}
          ticketValidity={this.state.ticketValidity}
          ticketFirstName={this.state.ticketFirstName}
          ticketSalutation={this.state.ticketSalutation}
          ticketUsageLimit={this.state.ticketUsageLimit}
          ticketLastNameErrors={this.state.ticketLastNameErrors}
          ticketFirstNameErrors={this.state.ticketFirstNameErrors}
          ticketCustomProperties={this.state.ticketCustomProperties}
          ticketSalutationErrors={this.state.ticketSalutationErrors}
          openTicketDetailsDialog={this.state.openTicketDetailsDialog}
          editTicketDetails={this.editTicketDetails}
          getTicketLastNameValue={this.getTicketLastNameValue}
          getTicketFirstNameValue={this.getTicketFirstNameValue}
          getTicketSalutationValue={this.getTicketSalutationValue}
          closeTicketDetailsDialog={this.closeTicketDetailsDialog}
          getTicketDetailsValidityValue={this.getTicketDetailsValidityValue}
          getTicketUsageLimitValue={this.getTicketUsageLimitValue}
        />

        <Pagination
          currentPage={this.state.currentPage}
          changePageHandler={this.changePageHandler}
          totalPageCount={this.state.totalPageCount}
        />

        <DialogError
          show={this.state.showErrorDialog}
          closeDialog={this.closeErrorDialog}
        >
          {this.state.errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>
      </div>
    );
  }
}

const Tite = ({ name }) => {
  const t = useT();
  return (
    <SceneTitle text={t("Show Tickets")}>
      <p>{name}</p>
    </SceneTitle>
  );
};

const TicketsPerPage = ({ getValue }) => {
  const t = useT();

  const ticketPerPage = t("ticket per page");
  const ticketsPerPage = t("tickets per page");

  const data = {
    1: `1 ${ticketPerPage}`,
    5: `5 ${ticketsPerPage}`,
    20: `20 ${ticketsPerPage}`,
    50: `50 ${ticketsPerPage}`,
    100: `100 ${ticketsPerPage}`,
    500: `500 ${ticketsPerPage}`,
  };

  return <Select data={data} initialValue={"50"} getValue={getValue} />;
};

const UploadTicketsDialog = ({
  openUploadTicketsDialog,
  fileName,
  ticketUploadFormat,
  progressBarPercentage,
  uploadTickets,
  closeUploadTicketsDialog,
  handleFileChosen,
  getUploadTicketsTypeValue,
}) => {
  const fileUpload = useRef();
  const ticketUploadFormatText = (format) => {
    switch (format) {
      case "starticket": {
        return { name: "Starticket Default", url: "/csv/starticket.csv" };
      }
      case "ticketcorner": {
        return { name: "Ticketcorner", url: "/csv/ticketcorner.csv" };
      }
      case "barcodesAndCustomProps": {
        return {
          name: "Barcodes & custom props",
          url: "/csv/barcodesAndCustomProps.csv",
        };
      }
      case "seeticketsDF": {
        return { name: "Seetickets DF", url: "/csv/seeticketsDF.csv" };
      }
      case "SeeChPersonalized": {
        return {
          name: "See CH personalized",
          url: "/csv/SeeChPersonalized.csv",
        };
      }
      default: {
        return { name: "Starticket Default", url: "/csv/starticket.csv" };
      }
    }
  };

  return (
    openUploadTicketsDialog && (
      <Dialog
        action={uploadTickets}
        mainButton={<T _str="Upload" />}
        title={<T _str="Upload tickets" />}
        showModal={openUploadTicketsDialog}
        closeModal={closeUploadTicketsDialog}
      >
        <div className="row p-0">
          <div className="col-9 p-0 fileName">{fileName}</div>
          <div className="col-3 upload-tickets">
            <Button onClickHandler={() => fileUpload.current.click()}>
              <T
                _str="Browse"
                _context="button, is it possible to minimize the translation"
              />
              ...
            </Button>
            <span>
              <input
                id="file"
                type="file"
                ref={fileUpload}
                accept=".csv,.txt"
                className="input-file"
                onChange={(e) => handleFileChosen(e.target.files[0])}
              />
            </span>
          </div>
        </div>
        <div className="col-12 p-0">
          <RadioGroup
            name="upload-tickets-type"
            getValue={getUploadTicketsTypeValue}
            defaultValue={ticketUploadFormat}
            labels={{
              starticket: "Starticket Default",
              ticketcorner: "Ticketcorner",
              barcodesAndCustomProps: "Barcodes & custom props",
              seeticketsDF: "Seetickets DF",
              SeeChPersonalized: "See CH personalized",
            }}
          />
        </div>
        <div className="col-12" style={{ padding: "25px 0 0 0 " }}>
          <a href={ticketUploadFormatText(ticketUploadFormat).url}>
            <T _str="Download sample" />
          </a>
          &nbsp;
          <T _str="for" /> {ticketUploadFormatText(ticketUploadFormat).name}
          <span className="error d-block mt-3">
            {(ticketUploadFormatText(ticketUploadFormat).name ===
              "Barcodes & custom props" ||
              ticketUploadFormatText(ticketUploadFormat).name ===
                "Ticketcorner") && (
              <React.Fragment>
                <T _str="Note: max of (10) columns" />
              </React.Fragment>
            )}
            &nbsp;
          </span>
        </div>
        <span className="helper">
          <T _str="Helper" />
        </span>

        {progressBarPercentage !== 0 && (
          <ProgressBar striped now={progressBarPercentage} />
        )}
      </Dialog>
    )
  );
};

/**
 * Renders ticket details dialog.
 *
 * @returns {XML}
 */
const TicketDetailsDialog = ({
  openTicketDetailsDialog,
  editTicketDetails,
  closeTicketDetailsDialog,
  ticketBarcode,
  ticketLastNameErrors,
  ticketLastName,
  ticketUsageLimit,
  getTicketLastNameValue,
  ticketCustomProperties,
  ticketValidity,
  getTicketDetailsValidityValue,
  ticketSalutation,
  ticketSalutationErrors,
  ticketFirstNameErrors,
  ticketFirstName,
  getTicketFirstNameValue,
  getTicketSalutationValue,
  getTicketUsageLimitValue,
}) => {
  const t = useT();

  return (
    openTicketDetailsDialog && (
      <Dialog
        mainButton={<T _str="save" />}
        action={editTicketDetails}
        closeModal={closeTicketDetailsDialog}
        showModal={openTicketDetailsDialog}
        title={
          <T
            _str="Ticket details - {ticketBarcode}"
            ticketBarcode={ticketBarcode}
          />
        }
      >
        <div className="qrcode">
          <QRCode size={64} value={ticketBarcode} />
        </div>

        <p>
          <strong>
            <T _str="Validity" />:
          </strong>
        </p>
        <Select
          initialValue={ticketValidity}
          getValue={getTicketDetailsValidityValue}
          data={{
            valid: t("valid"),
            invalid: t("invalid"),
            canceled: t("canceled"),
            refunded: t("refunded"),
          }}
        />

        <Input
          name="salutation"
          label={<T _str="Salutation" />}
          value={ticketSalutation}
          getValue={getTicketSalutationValue}
          className={ticketSalutationErrors.length > 0 ? "error" : ""}
        />
        <FieldError errors={ticketSalutationErrors} />

        <Input
          name="first-name"
          label={<T _str="First Name" />}
          value={ticketFirstName}
          getValue={getTicketFirstNameValue}
          className={ticketFirstNameErrors.length > 0 ? "error" : ""}
        />
        <FieldError errors={ticketFirstNameErrors} />

        <Input
          name="last-name"
          value={ticketLastName}
          label={<T _str="Last Name" />}
          getValue={getTicketLastNameValue}
          className={ticketLastNameErrors.length > 0 ? "error" : ""}
        />
        <FieldError errors={ticketLastNameErrors} />

        <Input
          name="ticket-usage-limit"
          value={ticketUsageLimit + ""}
          label={<T _str="Ticket usage limit" />}
          getValue={getTicketUsageLimitValue}
          readOnly={true}
        />

        {Object.keys(ticketCustomProperties).length !== 0 && (
          <div>
            <p>
              <T _str="Custom properties:" />
            </p>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>
                    <T _str="Key" />
                  </th>
                  <th>
                    <T _str="Value" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(ticketCustomProperties).map((key) => (
                  <tr key={key[0]}>
                    <td>{key[0]}</td>
                    <td>{key[1]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Dialog>
    )
  );
};

const FieldError = ({ errors }) =>
  errors.length > 0 &&
  errors.map((error, key) => (
    <div key={key} className="error">
      - {error}
    </div>
  ));

export default Tickets;
