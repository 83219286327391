import { useId } from "react";
import "./Toggle.css";

type ToggleProps = {
  callBack?: (value: boolean) => void;
  label: string;
  id?: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  isChecked: boolean;
};
const Toggle = ({
  callBack,
  label = "",
  id,
  className,
  inputClassName,
  labelClassName,
  isChecked = false,
}: ToggleProps) => {
  var elementId = useId();

  return (
    <div className={`toggle-container ${className}`}>
      <label
        className={`switch ${
          isChecked ? "checked" : "unchecked"
        } ${labelClassName}`}
      >
        <input
          id={id ?? elementId}
          type="checkbox"
          className={inputClassName}
          defaultChecked={isChecked}
          onChange={callBack && (() => callBack(!isChecked))}
        />
        <span className="slider"></span>
        {label && <span className="label">{label}</span>}
      </label>
    </div>
  );
};
export default Toggle;
