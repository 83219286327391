import React from 'react';

import './style.css';

export const LoadingScreen = () => 
(
    <div className="Loading">
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <rect x="18.5" y="26" width="13" height="48">
                <animate attributeName="y" repeatCount="indefinite" dur="1.1363636363636365s" calcMode="spline" keyTimes="0;0.5;1" values="6.799999999999997;26;26" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2272727272727273s"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1.1363636363636365s" calcMode="spline" keyTimes="0;0.5;1" values="86.4;48;48" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2272727272727273s"></animate>
            </rect>
            <rect x="43.5" y="26" width="13" height="48">
                <animate attributeName="y" repeatCount="indefinite" dur="1.1363636363636365s" calcMode="spline" keyTimes="0;0.5;1" values="11.599999999999994;26;26" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.11363636363636365s"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1.1363636363636365s" calcMode="spline" keyTimes="0;0.5;1" values="76.80000000000001;48;48" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.11363636363636365s"></animate>
            </rect>
            <rect x="68.5" y="26" width="13" height="48">
                <animate attributeName="y" repeatCount="indefinite" dur="1.1363636363636365s" calcMode="spline" keyTimes="0;0.5;1" values="11.599999999999994;26;26" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1.1363636363636365s" calcMode="spline" keyTimes="0;0.5;1" values="76.80000000000001;48;48" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
            </rect>
        </svg>
    </div>
);

export const LoadingForTables = () => 
(
    <div className='still-loading'>
        <div className="loading-for-tables wrap">
            <div className="loading">
                <div className="bounceball"></div>
                <div className="text">LOADING</div>
            </div>
        </div>
    </div>
);
