import React, { Component } from "react";
import PropTypes from "prop-types";
import { T } from "@transifex/react";
import {
  Button,
  ButtonStatus,
  ButtonSize,
} from "../../themes/default/Form/components/Button";

import "./style.css";

const PAGINATION_LENGTH = 5;

/**
 * @class src/components/Pagination/Pagination
 */
class Pagination extends Component {
  state = {
    paginationPages: [],
  };

  /**
   * @override
   */
  componentDidUpdate() {
    let paginationPages = [];

    paginationPages.push(this.addFirstPageButton());
    paginationPages.push(this.addPreviousPageButton());
    paginationPages.push(this.addPages(paginationPages));
    paginationPages.push(this.addNextPageButton());
    paginationPages.push(this.addLastPageButton());

    if (
      JSON.stringify(paginationPages) !==
      JSON.stringify(this.state.paginationPages)
    ) {
      this.setState({
        paginationPages: paginationPages,
      });
    }
  }

  /**
   * Adds first page button to pages array.
   *
   * @returns {XML}
   */
  addFirstPageButton = () => (
    <Button
      className="first"
      onClickHandler={() => this.props.changePageHandler(1)}
    >
      <T _str="First" />
    </Button>
  );

  /**
   * Adds previous page button to pages array.
   *
   * @returns {XML}
   */
  addPreviousPageButton = () => (
    <Button
      className="previous"
      status={this.props.currentPage === 1 ? ButtonStatus.Disabled : ""}
      onClickHandler={() =>
        this.props.changePageHandler(this.props.currentPage - 1)
      }
    >
      <T _str="Previous" />
    </Button>
  );

  /**
   * Adds next page button to pages array.
   *
   * @returns {XML}
   */
  addNextPageButton = () => (
    <Button
      className="next"
      status={
        this.props.currentPage === this.props.totalPageCount
          ? ButtonStatus.Disabled
          : ""
      }
      onClickHandler={() =>
        this.props.changePageHandler(this.props.currentPage + 1)
      }
    >
      <T _str="Next" />
    </Button>
  );

  /**
   * Adds last page button to pages array.
   *
   * @returns {XML}
   */
  addLastPageButton = () => (
    <Button
      className="last"
      onClickHandler={() =>
        this.props.changePageHandler(this.props.totalPageCount)
      }
    >
      <T _str="Last" />
    </Button>
  );

  /**
   * Adds pages to pages array.
   *
   * @param {Array} paginationPages
   */
  addPages = (paginationPages) => {
    for (let i = 1; i <= this.props.totalPageCount; i++) {
      if (paginationPages.length < PAGINATION_LENGTH + 2) {
        if (
          (i >= this.props.currentPage - PAGINATION_LENGTH / 2 &&
            i < this.props.currentPage) ||
          (i <= this.props.currentPage + PAGINATION_LENGTH / 2 &&
            i > this.props.currentPage) ||
          (this.props.currentPage >
            this.props.totalPageCount - PAGINATION_LENGTH / 2 &&
            i >=
              this.props.currentPage -
                (PAGINATION_LENGTH -
                  1 -
                  (this.props.totalPageCount - this.props.currentPage))) ||
          i === this.props.currentPage ||
          this.props.currentPage <
            PAGINATION_LENGTH / 2 + (PAGINATION_LENGTH % 2)
        ) {
          let page = (
            <Button
              key={i}
              className={this.props.currentPage === i ? "current-page" : ""}
              size={ButtonSize.Tiny}
              onClickHandler={() => this.props.changePageHandler(i)}
            >
              {i}
            </Button>
          );
          paginationPages.push(page);
        }
      }
    }
  };

  /**
   * @returns {XML}
   */
  render() {
    return (
      <div
        className={`col-12 p-0 pagination text-end ${
          this.props.totalPageCount === 1 ? "hidden" : ""
        }`}
      >
        {this.state.paginationPages.map(
          (page, index) =>
            this.props.totalPageCount > 1 && <span key={index}>{page}</span>
        )}
      </div>
    );
  }
}

Pagination.propTypes = {
  changePageHandler: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPageCount: PropTypes.number.isRequired,
};

export default Pagination;
