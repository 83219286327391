import React                from 'react';
import PropTypes            from 'prop-types';
import {LoadingForTables}   from '../Loading';
import { NavLink }          from 'react-router-dom';
import { T, useT }          from '@transifex/react';
import IconArrows           from './images/arrows.svg';
import IconArchive          from './images/archive.svg';
import IconUnarchive        from './images/unarchive.svg';
import IconDelete           from './images/ico-delete.svg';
import IconDevice           from './images/ico-payment.svg';
import IconArchiveGrayed    from './images/archive-grayed.svg';
import IconDeleteGrayed     from './images/ico-delete-grayed.svg';
import { VenuesHeadRow }    from '../../scenes/VenueManagement/VenueManagement';
import { UsersHeadRow }     from '../../scenes/UsersManagement/UsersManagement';
import { ProviderHeadRow }  from '../../scenes/ProviderManagement/ProviderManagement';

import './style.css';

/**
 * @class components/List
 */
const List = (props) =>
{
    const t = useT();
    /**
     * Returns an object with information for certain table cell.
     *
     * @param {Array | String | Array} columnData
     *
     * @returns {Object}
     */
    const getCellData = (columnData) =>
    {
        let noRecord = true;
        let cellData, title;

        if (typeof columnData === 'object') {
            cellData = columnData.cellData === '' ? t("No record") : columnData.cellData;
            noRecord = columnData.cellData === '';
            title = columnData.isImage ? columnData.className : columnData.cellData;
        }
        else if (columnData === '') {
            cellData = t("No record");
            title = t("No record");
        }
        else {
            cellData = <span>{columnData}</span>;
            noRecord = false;
            title = columnData
        }

        return {
            cellData,
            noRecord,
            title
        };
    };

    /**
     * Renders list head row.
     *
     * @param {Object} columns
     *
     * @returns {any[]}
     */
    const renderHeadRow = (columns) =>
    {
        return Object.entries(columns).map((column,index) => {
            return (
                <th className={column[1].className ? column[1].className : ''} key={index}>{column[0]}
                    {
                        column[1].subtitle !== null ? <span className="grayed">{column[1].subtitle}</span> : null
                    }
                    {
                        column[1].sortable ? <img className="pull-right" src={IconArrows} alt={t("Arrows")}
                                                  onClick={() => props.sort(column[1].fieldValue)}/> : null
                    }
                </th>
            );
        })
    };

    /**
     * Renders list's body cell.
     *
     * @param {*} cellData
     * @param {Number} rowIndex
     * @param {Number} cellIndex
     * @param {String} id
     *
     * @returns {XML}
     */
    const renderTableCell = (cellData, rowIndex, cellIndex, id, rowData) =>
    {
        const content = rowData[1][0].cellData;
        let deleteImageTitle = t("Delete");
        let deleteImageClass = t("delete");
        let archiveImageTitle = t("Archive");

        const slugifyContent = content.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');

        switch (props.columns) {
            case 'VenuesHeadRow':
                deleteImageTitle = t("Delete venue") + " " + content;
                deleteImageClass = "delete-venue-" + slugifyContent;
                archiveImageTitle = t("Archive venue") + " " +  content;
                break;
            case 'ProviderHeadRow':
                deleteImageTitle = t("Delete provider") + " " + content
                deleteImageClass = "delete-provider-" + slugifyContent
                archiveImageTitle = t("Archive provider") + " " + content;
                break;
            case 'UsersHeadRow':
                deleteImageTitle = t("Delete user") + " " + content
                deleteImageClass = "delete-user-"  + slugifyContent
                break;
            default:
                break;
        }

        return (
            <td key={rowIndex + '-' + cellIndex}
                onClick={() => {
                    cellData.onClick === "edit" &&
                    props.editHandler && props.editHandler(rowData)
                }}
                className={`${cellData.className ? cellData.className : ''} ${["",getCellData(cellData).noRecord && "grayed"].join(" ")}`}
                title={getCellData(cellData).title}>
                {
                    (cellData.href && !getCellData(cellData).noRecord)
                        ?   <NavLink exact to={cellData.href + '' + id}>
                                {getCellData(cellData).cellData}
                            </NavLink>
                        :   getCellData(cellData).cellData
                }
                {
                    cellData.options &&
                        <span className="pull-right options">
                            {
                            !cellData.options.deattached &&
                                <img
                                    src={IconDevice}
                                    alt={t("Handheld device")}
                                    title={t("Deattach devices")}
                                    onClick={() => props.deattachDevices(id)}
                                />
                            }
                            <img
                                src={cellData.options.deattached ? IconDelete : IconDeleteGrayed}
                                alt={deleteImageTitle}
                                title={deleteImageTitle}
                                className={`${deleteImageClass} ${!cellData.options.deattached ? 'not-allowed' : '' }`}
                                onClick={() => props.deleteHandler && props.deleteHandler(id) }
                            />
                            <img
                                src={
                                    !cellData.options.archived
                                        ?   cellData.options.deattached
                                            ?   IconArchive
                                            :   IconArchiveGrayed
                                        :   IconUnarchive
                                }
                                alt={archiveImageTitle}
                                title={
                                    !cellData.options.archived
                                        ?   cellData.options.deattached
                                            ?   archiveImageTitle
                                            :   t("Disabled")
                                        :   t("Unarchive")
                                }
                                onClick={() => !cellData.options.archived ? props.archiveVenue(id) : props.unarchiveVenue(id)}
                            />
                        </span>
                }
                {
                    cellData.cellOptions &&
                    <span className="pull-right options">
                        <img
                            src={cellData.cellOptions.delete ? IconDelete : IconDeleteGrayed}
                            alt={deleteImageTitle}
                            title={deleteImageTitle}
                            className={`${deleteImageClass} ${!cellData.cellOptions.delete ? 'not-allowed' : '' }`}
                            onClick={() => props.deleteHandler ? props.deleteHandler(rowData) : null }
                        />
                    </span>
                }
            </td>
        );
    };

    /**
     * Renders list body.
     *
     * @param {Object} data
     *
     * @returns {any[]}
     */
    const renderBody = (data) =>
    {
        if (Object.entries(data).length > 0) {
            return Object.entries(data).map((rowData, rowIndex) =>
                <tr key={rowData[0]}>
                    {
                        rowData[1].map((cellData, cellIndex) =>
                            renderTableCell(cellData, rowIndex, cellIndex, rowData[0], rowData)
                        )
                    }
                </tr>
             )
        }
    };

    return (
        <React.Fragment>
            <table className="List table-hover table table-striped">
                <thead>
                    {
                        props.columns === "ProviderHeadRow"
                            ?   <ProviderHeadRow sort={props.sort} currentSorting={props.currentSorting} />
                            :   props.columns === "VenuesHeadRow"
                                ?   <VenuesHeadRow sort={props.sort} currentSorting={props.currentSorting} />
                                :   props.columns === "UsersHeadRow"
                                    ?   <UsersHeadRow sort={props.sort} currentSorting={props.currentSorting} />    
                                    :   <tr>{renderHeadRow(props.columns)}</tr>                        
                    }
                </thead>
                <tbody>
                    { renderBody(props.data) }
                </tbody>
            </table>
            
            <IsLoadingOrNoResults data={props.data} showLoadingScreen={props.showLoadingScreen} />

        </React.Fragment>
    );
}

const IsLoadingOrNoResults = ({ showLoadingScreen, data }) => {
    if (showLoadingScreen) {
        return <LoadingForTables />
    }
    else if (!showLoadingScreen && Object.entries(data).length === 0) {
        return <div className='no-results'><T _str="NO RESULTS FOUND"/></div>
    }
    return null
};

List.propTypes = {
    columns : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    data : PropTypes.object,
    deattachDevices : PropTypes.func,
    deleteHandler: PropTypes.func,
    archiveVenue : PropTypes.func,
    unarchiveVenue : PropTypes.func,
    showLoadingScreen : PropTypes.bool.isRequired
};

export default List;