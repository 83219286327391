/**
 * @class components/List/services
 */
class ListService
{
    /**
     * Returning the sorting arrow class depending on whitch column and sort direction
     * @param {Object} column
     * @param {String} currentSorting can be one of these cases 'xxx', '-xxx', ''
     */
    sortingArrowClassController = (column, currentSorting) => {

        let isNegative = false;
        let sorting = currentSorting;
        let sortDirection = 'no-sort';
        
        if (currentSorting.length > 0 && currentSorting[0] === '-') {
            sorting = currentSorting.substring(1);
            isNegative = true;
        }

        if (sorting.toLowerCase() === column[1].fieldValue.toLowerCase()) {
            if (sorting !== '') {
                isNegative
                    ? sortDirection = 'ascending-sort'
                    : sortDirection = 'descending-sort';
            };
        }

        return sortDirection;
    };
}

export default ListService;