/**
 * @class scenes/VenueManagement/scenes/ZonesAndCheckpoints/services/Checkpoint
 */
class Checkpoint
{
    /**
     * Returns adapted and modified checkpoints data for certain zone by provided zone name.
     *
     * @param {Object} responseData
     * @param {String} zoneName
     *
     * @returns {Object}
     */
    getCheckpointsData = (responseData, zoneName) =>
    {
        let checkpointsData = {
            entry : {},
            exit : {}
        };

        if (responseData.results) {
            responseData.results.map(checkpoint => {
                if (checkpoint.from === zoneName || checkpoint.to === zoneName) {
                    if (checkpoint.from === null) {
                        checkpointsData.entry[checkpoint.name] = {
                            'permission' : checkpoint.permissionCheckOnly ? 'check-only' : 'check-and-check-in',
                            'protected' : false
                        };
                    } else if (checkpoint.to === null) {
                        checkpointsData.exit[checkpoint.name] = {
                            'permission' : checkpoint.permissionCheckOnly ? 'check-only' : 'check-and-check-in',
                            'protected' : false
                        };
                    }
                }

                return true;
            });
        }


        return checkpointsData;
    };
}

export default Checkpoint;