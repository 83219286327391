import React, { useRef, useEffect, Component } from 'react';
import { SingleFilter }                        from '../Filter';
import PropTypes                               from 'prop-types';
import { ValidatorService, NotBlank }          from 'pretty-validator';
import { T }                                   from '@transifex/react';
import { ZoneService }                         from '../../services/api/Zone';
import { Dialog }                              from '../../themes/default/Dialog';
import { Input }                               from '../../themes/default/Form/components/Input';
import { Button }                              from '../../themes/default/Form/components/Button';
import { Select }                              from '../../themes/default/Form/components/Select';

import IconArrow                               from './images/arrow.svg';
import IconMore                                from './images/ico-more.svg';

import './style.css';

/**
 * @class components/Rule
 */
class Rule extends Component
{
    /**
     * @type {Object}
     */
    validatorService;

    /**
     * @type {Object}
     */
    zoneService;

    state = {
        openCreateFilterItemDialog : false,
        openConfirmationDialog : false,
        valueOfKeyField : '',
        valueOfOperationField : '',
        valueOfValueField : '',
        removeFilterLevel : null,
        removeFilterIndex : null,
        filters : {},
        level : '1',
        isUpdated : false,
        width : 0,
        valueFieldErrors : [],
        isEdit: false,
        autoCompleteObj: null,
        autoCompletelist: []
    };

    /**
     * Constructor.
     *
     * @param {Object} props
     */
    constructor(props)
    {
        super(props);

        this.zoneService = new ZoneService();
        this.validatorService = new ValidatorService();
    };

    /**
     * @override
     */
    UNSAFE_componentWillMount()
    {
        var valueOfKeyField = Object.keys(this.props.config?.keys)[0];
        var valueOfOperationField = Object.keys(this.props.config?.operations)[0];       
        
        let width = window.innerWidth > 991 ? window.innerWidth- 500 : window.innerWidth - 42;
        this.setState({
            width: width,
            valueOfKeyField: valueOfKeyField ? valueOfKeyField : "",
            valueOfOperationField: valueOfOperationField ? valueOfOperationField : ""
        });

        if (this.props.config.value) {
            this.setValue(this.props.config.value);
        }
    };

    /**
     * @override
     */
    componentDidUpdate()
    {
        if (this.props.getValue && this.state.isUpdated) {
            this.props.getValue(this.getValue());

            this.setState({
                isUpdated : false
            });
        }
    };

    /**
     * Returns the value of a tag component.
     *
     * @returns {Object}
     */
    getValue = () =>
    {
        let value = '';

        Object.entries(this.state.filters).map(filterRow => {
            let rowLength = filterRow[1].length;

            filterRow[1].map((filter,index) => {
                value += (index < (rowLength-1)) ? (filter + ' && ') : filter;

                return true;
            });

            value += ' || ';

            return true;
        });

        value = value.replace(' ||  || ',' || ');
        value = value.substr(0,(value.length - 4));
        if (value.substr(-4,value.length) === ' || ') {
            value = value.substr(0,(value.length - 4));
        }
        if (value.substr(0,4) === ' || ') {
            value = value.substr(4, value.length);
        }

        return {
            'value' : value,
            'uid' : this.props.uid ? this.props.uid : null
        };
    };

    /**
     * Sets tag value retrieved from api.
     *
     * @param {String} value
     */
    setValue = (value) =>
    {
        if (value) {
            let filtersRows = value.split(' || ');
            let filters = {};

            filtersRows.map((filtersRow,index) => {
                let filtersLevel = parseInt(index, 10) + 1;
                filters[filtersLevel] = [];
                return (
                    filtersRow.split(' && ').map((filter) => {
                        if (filter) {
                            return filters[filtersLevel].push(filter);
                        } else {
                            return true;
                        }
                    })
                );
            });

            let level = this.getNumberOfLevelsInFiltersObject(filters);

            if (JSON.stringify(this.state.filters) !== JSON.stringify(filters)) {
                this.setState({
                    filters : filters,
                    level : level
                });
            }
        }
    };

    /**
     * Checks if filters object is empty.
     *
     * @param {Object} filters
     *
     * @returns {Boolean}
     */
    isEmpty = (filters) =>
    {
        for(let level in filters) {
            if(filters.hasOwnProperty(level)) {
                if (!!filters[level].length) {
                    return false;
                }
            }
        }
        return true;
    };

    /**
     * Checks if value is valid and if not adds error to input field.
     *
     * @param {String} value
     *
     * @returns {Boolean}
     */
    isValueFieldValid = (value = this.state.valueOfValueField) =>
    {
        let errors = this.validatorService.validate(value,[new NotBlank()]);

        let isValid = errors.length === 0;

        if (!isValid) {
            this.setState({
                valueFieldErrors : errors
            });
        }

        return isValid;
    };

    /**
     * Returns number of levels in current filters object.
     *
     * @param {Object} filtersObject
     *
     * @returns {number}
     */
    getNumberOfLevelsInFiltersObject = (filtersObject) =>
    {
        let levels = 0;
        for(let level in filtersObject) {
            if(filtersObject.hasOwnProperty(level))
                levels++;
            }

        return levels;
    };

    /**
     * Updates state's value responsible for showing (hiding) of dialog.
     */
    openCreateFilterItemDialog = () =>
    {
        this.setState({ openCreateFilterItemDialog : true });
    };

    /**
     * Updates state's value responsible for showing (hiding) of dialog.
     */
    closeCreateFilterItemDialog = () =>
    {
        this.setState({
            openCreateFilterItemDialog : false,
            level : '1',
            valueOfKeyField : 'categoryName',
            valueOfOperationField : '=',
            valueOfValueField : '',
            valueFieldErrors : [],
            isEdit : false
        });
    };

    /**
     * Returns selected key value.
     *
     * @param {Object} valueObject
     *
     * @returns {String}
     */
    getSelectedKeyValue = (valueObject) =>
    {
        if (this.state.valueOfKeyField !== valueObject.value) {
            this.setState({
                valueOfKeyField : valueObject.value
            });
        }
    };

    /**
     * Returns selected operation value.
     *
     * @param {Object} valueObject
     *
     * @returns {String}
     */
    getSelectedOperationValue = (valueObject) =>
    {
        if (this.state.valueOfOperationField !== valueObject.value) {
            this.setState({
                valueOfOperationField : valueObject.value
            });
        }
    };

    /**
     * Returns typed value.
     *
     * @param {String} typedValue
     *
     * @returns {String}
     */
    getTypedValue = (typedValue) =>
    {
        var autoCompleteObj = {
            key: this.state.valueOfKeyField,
            partialValue: typedValue,
            venueId: this.props.config.venueId
        };

        let valueFieldErrors = [...this.state.valueFieldErrors];
        this.setState({
            autoCompleteObj: autoCompleteObj,
            valueOfValueField : typedValue,
            valueFieldErrors : this.isValueFieldValid(typedValue) ? [] : valueFieldErrors
        });

        typedValue === ''
            ? this.setState({ autoCompletelist : []})
            :  this.autoCompletelistHandler(autoCompleteObj)
    };

    /**
     * Adds new filter item to the filters object.
     */
    createNewFilterItem = () =>
    {
        if (this.isValueFieldValid()) {
            let filter = '' + this.state.valueOfKeyField + ' ' + this.state.valueOfOperationField + ' '
                + this.state.valueOfValueField + '';

            let newFiltersObject = {};
            let filtersInnerArray = [];
            let filtersObject = {...this.state.filters};

            if (filtersObject[this.state.level]) {
                filtersInnerArray = filtersObject[this.state.level];
                filtersInnerArray = [...filtersInnerArray, filter];
                filtersObject[this.state.level] = filtersInnerArray;
                newFiltersObject = filtersObject;
            } else {
                filtersObject[this.state.level] = [filter];
                newFiltersObject = filtersObject;
            }

            this.setState({
                openCreateFilterItemDialog : false,
                filters : newFiltersObject,
                isUpdated : true,
                level : '1',
                valueOfKeyField : 'categoryName',
                valueOfOperationField : '=',
                valueOfValueField : '',
                valueFieldErrors : []
            });
        }
    };

    /**
     * Handles click on "or" filter button.
     */
    orButtonClickHandler = () =>
    {
        let currentLevel = this.getNumberOfLevelsInFiltersObject(this.state.filters);

        this.setState({
            level : currentLevel + 1
        });

        this.openCreateFilterItemDialog();
    };

    /**
     * Handles click on "and" filter button.
     *
     * @param {String} level
     */
    andButtonClickHandler = (level) =>
    {
        this.setState({
            level : level
        });

        this.openCreateFilterItemDialog();
    };

    /**
     * Handles click on "edit" filter image.
     *
     * @param {String} level
     */
    editButtonClickHandler = (level,index) =>
    {
        const editedFilter = this.state.filters[level][index];

        const valueOfKeyField = editedFilter.split(' ')[0];
        const valueOfOperationField = editedFilter.split(' ')[1];
        // valueOfValueField => Take the rest of the string after remove the Field name & the operation
        const valueOfValueField = editedFilter.split(`${editedFilter.split(' ')[0]} ${editedFilter.split(' ')[1]} `)[1];

        this.setState({
            isEdit: true,
            editedFilterObj: {
                key: valueOfKeyField,
                operation : valueOfOperationField,
                value : valueOfValueField,
                index
            },
            level
        });

        this.openCreateFilterItemDialog();
    };

    /**
     * Update the current filter informations
     * 
     * @param {string}  action
     * @param {string}  value
     */
    editFilter = (value, action) =>
    {
        const { level, filters, editedFilterObj} = this.state;

        if (editedFilterObj[action] !== value) {

            const index = editedFilterObj.index;
            const editedFilter = filters[level][index];
            const editedFilterSplit = editedFilter.split(' ');
        
            const returnExistingValue = arr => {
                let final = "";
                arr.map((str, idx) => {
                    idx > 1 && (final = final === "" ? str : `${final} ${str}`);
                    return null
                });
                return final;
            };

            const valueOfKeyField       = action === 'key'       ? value : editedFilterSplit[0];
            const valueOfOperationField = action === 'operation' ? value : editedFilterSplit[1];
            const valueOfValueField     = action === 'value'     ? value : returnExistingValue(editedFilterSplit)

            filters[level][index] = `${valueOfKeyField} ${valueOfOperationField} ${valueOfValueField}`;
            
            var autoCompleteObj = {
                key: editedFilterObj.key,
                partialValue: value,
                venueId: this.props.config.venueId
            };

            this.setState({
                autoCompleteObj,
                editedFilterObj : {
                    ...editedFilterObj,
                    [action] : value
                }
            });

            this.autoCompletelistHandler(autoCompleteObj);
        };
    };

    /**
     * Fire the componentDidUpdate and close the create Filter dialog
     */
    updateFilter = () => {
        this.setState({
            isUpdated : true,
            openCreateFilterItemDialog : false,
            isEdit : false
        });
    }

    /**
     * Removes filter from filters object.
     *
     * @param {String} filterLevel
     * @param {Number} filterIndex
     */
    removeFilter = (filterLevel, filterIndex) =>
    {
        let newFilters = {...this.state.filters};

        let innerFiltersArray = newFilters[filterLevel];
        innerFiltersArray.splice(filterIndex,1);
        const isLevelEmpty = !(!!innerFiltersArray.length);
        if (isLevelEmpty) {
            delete newFilters[filterLevel];
        } else {
            newFilters[filterLevel] = innerFiltersArray;
        }
        let newFiltersReindexed = this.reindexFilters(newFilters,filterLevel,isLevelEmpty);

        this.setState({
            filters : newFiltersReindexed,
            isUpdated : true
        });
    };

    /**
     * Returns reindexed filters object when certain rule is removed from filters.
     *
     * @param {Object} filters
     * @param {Number} filterLevel
     * @param {Boolean} isLevelEmpty
     *
     * @returns {Object}
     */
    reindexFilters = (filters,filterLevel,isLevelEmpty) =>
    {
        let reindexedFiltersArray = {};
        Object.entries(filters).map(filter => {
            if (filter[0] > filterLevel && isLevelEmpty) {
                reindexedFiltersArray[filter[0] - 1] = filter[1];
            } else {
                reindexedFiltersArray[filter[0]] = filter[1];
            }

            return true;
        });

        return reindexedFiltersArray;
    };

    /**
     * Handles click on the "remove" button.
     *
     * @param {String} filterLevel
     * @param {String} filterIndex
     */
    removeButtonClickHandler = (filterLevel, filterIndex) =>
    {
        this.setState({
            removeFilterLevel : filterLevel,
            removeFilterIndex : filterIndex,
            openConfirmationDialog : true
        });
    };

    /**
     * Handles click on confirm button in confirmation dialog.
     */
    removeFilterConfirmation = () =>
    {
        this.removeFilter(this.state.removeFilterLevel, this.state.removeFilterIndex);
        this.setState({
            removeFilterLevel : null,
            removeFilterIndex : null,
            openConfirmationDialog : false
        });
    };

    /**
     * Handles click on cancel button in confirmation dialog.
     */
    closeConfirmationDialog = () =>
    {
        this.setState({
            removeFilterLevel : null,
            removeFilterIndex : null,
            openConfirmationDialog : false
        });
    };

    autoCompletelistHandler = autoCompleteObj => {
        this.zoneService.getAutocompleteList(autoCompleteObj).then(response => {
            if (response.status === 200) {
                this.setState({
                    autoCompletelist: response.data
                });
            } else {
                this.setState({
                    showErrorDialog: true,
                    errorMessages: this.props.errorHandler(response)
                });
            };
        });
    };

    selectAutoCompleteValue = value => {
        this.setState({ autoCompletelist: [] });
        this.state.isEdit ? this.editFilter(value, 'value') : this.getTypedValue(value);
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <div className="Rule">
                {/* Renders create new filter item button. */}
                {this.isEmpty(this.state.filters) &&    
                    <div className="createNewTagBtn">
                        <Button onClickHandler={() => this.openCreateFilterItemDialog()}>
                            { this.props.config.buttonTitle }
                        </Button>
                    </div>
                }

                {this.state.openCreateFilterItemDialog && Object.entries(this.props.config.keys).length > 0 &&
                    <RenderDialog
                        props={this.props}
                        isEdit={this.state.isEdit}
                        editedFilterObj={this.state.editedFilterObj}
                        autoCompletelist={this.state.autoCompletelist}
                        valueFieldErrors={this.state.valueFieldErrors}
                        valueOfValueField={this.state.valueOfValueField}
                        openCreateFilterItemDialog={this.state.openCreateFilterItemDialog}
                        editFilter={this.editFilter}
                        updateFilter={this.updateFilter}
                        getTypedValue={this.getTypedValue}
                        createNewFilterItem={this.createNewFilterItem}
                        getSelectedKeyValue={this.getSelectedKeyValue}
                        selectAutoCompleteValue={this.selectAutoCompleteValue}
                        getSelectedOperationValue={this.getSelectedOperationValue}
                        closeCreateFilterItemDialog={this.closeCreateFilterItemDialog}
                        clearAutoCompletelist={() => this.setState({autoCompletelist: []})}
                    />
                }

                 
                {!this.isEmpty(this.state.filters) &&
                    <RenderFilters
                        width={this.state.width}
                        filters={this.state.filters}
                        isEmpty={this.isEmpty}
                        orButtonClickHandler={this.orButtonClickHandler}
                        andButtonClickHandler={this.andButtonClickHandler}
                        editButtonClickHandler={this.editButtonClickHandler}
                        removeButtonClickHandler={this.removeButtonClickHandler}
                        getNumberOfLevelsInFiltersObject={this.getNumberOfLevelsInFiltersObject}
                    />
                }

                {/* Render confirmation dialog. */}
                {this.state.openConfirmationDialog &&
                    <Dialog
                        mainButton={<T _str="Confirm"/>}
                        action={this.removeFilterConfirmation}
                        title={<T _str="Confirmation dialog"/>}
                        closeModal={this.closeConfirmationDialog}
                        showModal={this.state.openConfirmationDialog}
                    >
                        <h4 className="text-center"><T _str="Are you sure you want to delete filter?"/></h4>
                    </Dialog>
                }
            </div>
        );
    }
};

/**
 * Renders dialog for creating new filter item.
 */
 const RenderDialog = ({
    valueOfValueField, clearAutoCompletelist, selectAutoCompleteValue, autoCompletelist, openCreateFilterItemDialog, isEdit, editedFilterObj, valueFieldErrors,
    props, updateFilter, createNewFilterItem, closeCreateFilterItemDialog, editFilter, getSelectedKeyValue, getSelectedOperationValue, getTypedValue }) =>
(
    <Dialog
        showModal={openCreateFilterItemDialog}
        closeModal={closeCreateFilterItemDialog}
        action={isEdit ? updateFilter : createNewFilterItem}
        mainButton={isEdit ? <T _str="Update"/> : <T _str="Create"/>}
        title={isEdit ? <T _str="Edit"/> : props.config.dialogTitle}
        >
        <div className="col-12 tag-dialog">
            <div className="col-5 key p-0">
                <span className="tag-filter-labels"><T _str="Key"/></span>
                {isEdit
                    ?   <Select
                            data={props.config.keys}
                            initialValue ={editedFilterObj.key}
                            getValue={value => editFilter(value.value,'key')}/>
                    :   <Select
                            data={props.config.keys}
                            getValue={getSelectedKeyValue}
                            initialValue={Object.entries(props.config.keys)[0][0]}/>
            }
            </div>
            <div className="col-2 operation p-0">
                {isEdit
                    ?   <Select
                            data={props.config.operations}
                            initialValue={editedFilterObj.operation}
                            getValue={value => editFilter(value.value,'operation')}/>
                    :   <Select
                            data={props.config.operations}
                            getValue={getSelectedOperationValue}
                            initialValue={Object.entries(props.config.operations)[0][0]}/>
                }
            </div>
            <div className="col-5 value">
                <span className="tag-filter-labels"><T _str="Value"/></span>
                {isEdit
                    ?   <Input
                            name="filter-value"
                            value={editedFilterObj.value}
                            getValue={value => editFilter(value,'value')}
                            className={valueFieldErrors.length > 0 ? "error" : ""}/>
                    :   <Input 
                            name="filter-value"
                            value={valueOfValueField}
                            getValue={getTypedValue}
                            className={valueFieldErrors.length > 0 ? "error" : ""}/>
                }
                <AutoCompletelist
                    autoCompletelist={autoCompletelist}
                    clearAutoCompletelist={clearAutoCompletelist}
                    selectAutoCompleteValue={selectAutoCompleteValue}
                />
            </div>
            { valueFieldErrors.length > 0 && valueFieldErrors.map( (error, key) =>
                <div key={ key } className="col-12 p-0 error">
                    - { error }
                </div>
            )}
        </div>
        <span className="helper"><T _str="Helper"/></span>
    </Dialog>
);

/**
 * Rendering the AutoCompletelist when the user change the modal input value
 *
 * @returns {XML}
 */
const AutoCompletelist = ({ autoCompletelist, clearAutoCompletelist, selectAutoCompleteValue }) => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (wrapperRef?.current) {
            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    clearAutoCompletelist()
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [autoCompletelist, clearAutoCompletelist]);

    return autoCompletelist.length > 0 && <div id="auto-complete" ref={wrapperRef}>
            <ul>
                {autoCompletelist.map((i, idx) => (
                    <li onClick={() => selectAutoCompleteValue(i)} key={idx}>{i}</li>
                ))}
            </ul>
        </div>
};

/**
 * Renders filters section.
 *
 * @returns {XML}
 */
const RenderFilters = ({filters, width, getNumberOfLevelsInFiltersObject, editButtonClickHandler, removeButtonClickHandler, andButtonClickHandler, orButtonClickHandler}) =>
(
    <div className="filters" style={{'width':width + 'px'}}>
        {
            Object.entries(filters).map((filtersRow,rowIndex) => {
                let isRowEmpty = !(!!filtersRow[1].length);
                return (
                    !isRowEmpty &&
                    <div key={rowIndex}>
                        {(rowIndex > 0 && getNumberOfLevelsInFiltersObject(filters) > 1) &&
                            <span className="or-between">
                                <T _str="or"/>
                            </span>
                        }
                        <span className="filters-row">
                            {filtersRow[1].map((filter,index) =>
                                <span key={index}>
                                    {
                                        index > 0 && <span className="and-between"><T _str="and"/></span>
                                    }
                                    <SingleFilter
                                        filter             ={filter}
                                        editClickHandler   ={() => editButtonClickHandler(filtersRow[0],index)}
                                        deleteClickHandler ={() => removeButtonClickHandler(filtersRow[0],index)}
                                    />
                                </span>
                            )}
                            <span className="and text-uppercase" onClick={() => andButtonClickHandler(filtersRow[0])}>
                            <T _str="and"/>
                                <img src={IconMore} alt="And" title="And"/>
                            </span>
                        </span>
                    </div>
                )
            })
        }
        <span className="or text-uppercase" onClick={() => orButtonClickHandler()}>
            <T _str="or"/>
            <img src={IconArrow} alt="Or" title="Or"/>
        </span>
    </div>
);

Rule.propTypes = {
    config : PropTypes.object.isRequired,
    getValue : PropTypes.func,
    uid : PropTypes.string,
    errorHandler: PropTypes.func.isRequired
};

export default Rule;