import { useEffect, useState, useRef } from "react";
import { SpeechService } from "./services";
import { useT } from "@transifex/react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  PlayAudioIcon,
  PauseAudioIcon,
  DownloadIcon,
  InfoIcon,
} from "../../../../../components/Images";
import { Select } from "../../../../../themes/default/Form";
import { Toggle } from "../../../../../themes/default/Toggle";
import { Dialog } from "../../../../../themes/default/Dialog";
import { DialogError } from "../../../../../themes/default/Dialog";
import { ResponseService } from "../../../../../services/utils/Response";
import { Input } from "../../../../../themes/default/Form/components/Input";
import { Checkbox } from "../../../../../themes/default/Form/components/Checkbox";

import "./AudioPanel.css";
import { useActionDataSources } from "./ActionDataSourcesProvider";
import {
  SelectMap,
  SelectChangeEvent,
} from "../../../../../themes/default/Form/components/Select";
import { ActionMessage } from "../../../../../services/api/ActionMessage";
import { ActionFilter } from "../../../../../services/api/ActionFilter";
import moment from "moment";

type CustomMessageSelection = {
  selectedLocale: string;
  selectedGender: string;
  selectedVoice: string | undefined;
  selectedStyle: string | undefined;
};

type SpeechData = {
  src: string;
  date: Date;
};

type DialogData = {
  title: string;
  text: string;
  mainButton: string;
  action: () => void;
};

type AudioPanelProps = {
  actionFilter: ActionFilter;
  onActionFilterChanged: (actionFilter: ActionFilter, panelId: string) => void;
  panelId: string;
};

/**
 * AudioPanel component
 */
function AudioPanel({
  actionFilter,
  onActionFilterChanged,
  panelId,
}: AudioPanelProps) {
  const t = useT();
  const audioTrackRef = useRef<HTMLAudioElement>(null);
  const actionDataSourcesContext = useActionDataSources();
  const customMessage: ActionMessage = {
    id: "customMessage",
    text: t("Custom Message"),
    sound: "",
    sort: 0,
  };

  const selectedAction =
    actionFilter.actionId ?? actionDataSourcesContext.actions?.[0].id;
  const selectedColor =
    actionFilter.colorId ?? actionDataSourcesContext.colors?.[0].id;
  const [selectedActionMessage, setSelectedActionMessage] = useState(
    actionFilter.actionMessageId ??
      (actionFilter.customMessage
        ? customMessage.id
        : actionDataSourcesContext.actionMessages?.[0].id)
  );

  const [messageText, setMessageText] = useState(
    actionFilter.customMessage?.text ?? ""
  );
  const [disableSound, setDisableSound] = useState(
    !(actionFilter.customMessage?.mergeOkSound ?? true)
  );
  const [dialogData, setDialogData] = useState<DialogData | null>(null);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessages, setErrorMessages] = useState<any[] | null>(null);

  const [filteredData, setFilteredData] = useState<CustomMessageSelection>({
    selectedLocale: actionFilter.customMessage?.locale ?? "de-CH",
    selectedGender: actionFilter.customMessage?.gender ?? "Female",
    selectedVoice: actionFilter.customMessage?.voice,
    selectedStyle: actionFilter.customMessage?.style,
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingPreview, setIsPlayingPreview] = useState(false);
  const [stylesList, setStylesList] = useState<SelectMap | null>(null);
  const [voicesList, setVoicesList] = useState<SelectMap | null>(null);

  const [isCustomMessage, setIsCustomMessage] = useState(
    selectedActionMessage === customMessage.id
  );

  const currentSound =
    actionFilter.actionMessageId || !actionFilter.customMessage
      ? null
      : {
          src: actionFilter.customMessage.sound,
          date: actionFilter.customMessage.lastModifiedAt,
        };
  const [previewSound, setPreviewSound] = useState<SpeechData | null>(null);

  const currentActionMessage = actionDataSourcesContext.actionMessages?.find(
    (actionMessage) => actionMessage.id === actionFilter.actionMessageId
  );

  // Preselect voice if current selection is invalid
  useEffect(() => {
    if (
      voicesList &&
      (!filteredData.selectedVoice || !voicesList[filteredData.selectedVoice])
    ) {
      setFilteredData((prev) => ({
        ...prev,
        selectedVoice: Object.keys(voicesList)[0],
      }));
    }
  }, [voicesList, filteredData.selectedVoice]);

  // Preselect style if current selection is invalid
  useEffect(() => {
    if (
      stylesList &&
      (!filteredData.selectedStyle || !stylesList[filteredData.selectedStyle])
    ) {
      setFilteredData((prev) => ({
        ...prev,
        selectedStyle: Object.keys(stylesList)[0],
      }));
    }
  }, [stylesList, filteredData.selectedStyle]);

  // reload styles when the selected voice changes
  useEffect(() => {
    const getStyles = async () => {
      const speechService = new SpeechService();
      var styles = await speechService.getStyles(filteredData.selectedVoice);
      if (styles.length === 0) {
        setStylesList(null);
        return;
      }

      var stylesMapped: SelectMap = {};
      styles.map((style: any) => {
        stylesMapped[style] = t(style);
      });
      setStylesList(stylesMapped);
    };
    isCustomMessage && filteredData.selectedVoice && getStyles();
  }, [isCustomMessage, filteredData.selectedVoice, t]);

  // Plays the preview sound once it is generated
  useEffect(() => {
    previewSound && playAudio(previewSound.src, setIsPlayingPreview);
  }, [previewSound]);

  // reload voices list when the selected language / gender changes
  useEffect(() => {
    const getVoices = async () => {
      const speechService = new SpeechService();
      var voices = await speechService.getVoices(
        filteredData.selectedLocale,
        filteredData.selectedGender
      );
      if (voices.success) {
        let voicesMapped: SelectMap = {};
        voices.voices.map((voice: any) => {
          voicesMapped[voice.shortName] = voice.displayName;
        });
        setVoicesList(voicesMapped);
      }
    };

    isCustomMessage &&
      filteredData.selectedLocale &&
      filteredData.selectedGender &&
      getVoices();
  }, [
    isCustomMessage,
    filteredData.selectedLocale,
    filteredData.selectedGender,
  ]);

  const getSpeech = async () => {
    if (messageText.length == 0 || !filteredData.selectedVoice) {
      return;
    }

    const speechService = new SpeechService();

    var response = await speechService.getSpeech(
      messageText,
      filteredData.selectedVoice,
      filteredData.selectedStyle,
      !disableSound
    );
    if (response.status !== 200) {
      const responseService = new ResponseService();
      if (
        JSON.stringify(errorMessages) !==
        JSON.stringify(responseService.getErrorMessages(response.data))
      ) {
        setErrorMessages(responseService.getErrorMessages(response.data));
        setErrorDialog(true);
      }
      return;
    }
    setPreviewSound({
      src: response.data,
      date: new Date(),
    });
  };

  const disableOKTooltip = (props: any) => (
    <Tooltip {...props}>
      {t(
        'Disabling the "OK" sound may produce confusion during scanning. Be mindfull.'
      )}
    </Tooltip>
  );

  const handleFilteredDataChanged = (idx: SelectChangeEvent) => {
    setFilteredData((prev) => ({
      ...prev,
      [idx.uid]: idx.value,
    }));
  };

  const handleSaveAudioClicked = () => {
    let action, text, title, mainButton;

    action = saveAudio;
    (title = t("Confirm Audio Replacement")), (mainButton = t("Replace audio"));
    text = t(
      "You are about to save a new audio message. This action will overwrite the existing audio file. Once saved, the previous audio cannot be retrieved. Are you sure you want to proceed and replace the existing audio with the new one?"
    );
    setDialogData({ title, text, mainButton, action });
  };

  const saveAudio = () => {
    if (!filteredData.selectedVoice || !previewSound) {
      return;
    }

    setDialogData(null);
    const changedActionFilter: ActionFilter = {
      ...actionFilter,
      actionMessageId: undefined,
      customMessage: {
        text: messageText,
        locale: filteredData.selectedLocale,
        gender: filteredData.selectedGender,
        voice: filteredData.selectedVoice,
        style: filteredData.selectedStyle,
        mergeOkSound: !disableSound,
        sound: previewSound.src,
        lastModifiedAt: previewSound.date,
      },
    };
    onActionFilterChanged(changedActionFilter, panelId);
  };

  const previewAudio = async () => {
    await getSpeech();
  };

  const playAudio = (
    src: string | undefined,
    setPlaying: (value: boolean) => void
  ) => {
    if (!src || !audioTrackRef.current) {
      return;
    }
    audioTrackRef.current.onended?.(new Event("end"));
    setPlaying(true);
    audioTrackRef.current.pause();
    audioTrackRef.current.src = "data:audio/wav;base64," + src;
    var onStop = () => setPlaying(false);
    audioTrackRef.current.onended = onStop;
    audioTrackRef.current.load();
    audioTrackRef.current.play();
  };

  const pauseAudio = (setPlaying: (value: boolean) => void) => {
    setPlaying(false);
    if (!audioTrackRef.current) {
      return;
    }
    audioTrackRef.current.pause();
  };

  const handleActionFilterChanged = (e: SelectChangeEvent): void => {
    setSelectedActionMessage(e.value);
    if (e.uid === "actionMessageId" && e.value === customMessage.id) {
      setIsCustomMessage(true);
      return;
    }

    if (isCustomMessage && e.uid === "actionMessageId") {
      setIsCustomMessage(false);
    }

    const changedActionFilter: ActionFilter = {
      ...actionFilter,
      [e.uid]: e.value,
    };
    onActionFilterChanged(changedActionFilter, panelId);
  };

  const handleOnlyOnceChanged = (checked: boolean): void => {
    if (checked === actionFilter.onlyOnce) {
      return;
    }

    const changedActionFilter: ActionFilter = {
      ...actionFilter,
      onlyOnce: checked,
    };
    onActionFilterChanged(changedActionFilter, panelId);
  };

  return (
    <div className="audio-action">
      <audio className="audio-hidden" ref={audioTrackRef}>
        <source type="audio/wav" />
      </audio>
      <div className="row">
        <h6>{t("Configuration")}</h6>
        <div className="col-md-4 col-lg-2">
          <label className="action-label">
            {t("Action")}&nbsp;
            <i>{t("(deprecated - used for older devices)")}</i>
          </label>
          <Select
            data={actionDataSourcesContext.actions}
            uid={"actionId"}
            initialValue={selectedAction}
            getValue={handleActionFilterChanged}
            keyValueMapper={(action) => [action.id, action.name]}
          />
        </div>
      </div>
      <hr />
      <div className="row mt-3">
        <div className="col-md-4 col-lg-2">
          <label>{t("Color")}</label>
          <Select
            data={actionDataSourcesContext.colors}
            uid={"colorId"}
            initialValue={selectedColor}
            getValue={handleActionFilterChanged}
            keyValueMapper={(color) => [color.id, color.name]}
          />
        </div>
        <SelectTranslatorWrapper
          uid={"onlyOnce"}
          checked={actionFilter.onlyOnce}
          onChange={handleOnlyOnceChanged}
        />
      </div>
      {/* Message */}
      <div className="row mt-3">
        <h6>{t("Message")}</h6>
        <div className="col-md-4 col-lg-2 mb-3 mb-md-0">
          <label>{t("Message")}</label>
          <Select
            data={[
              ...(actionDataSourcesContext.actionMessages ?? []),
              customMessage,
            ]}
            uid={"actionMessageId"}
            initialValue={selectedActionMessage}
            getValue={handleActionFilterChanged}
            keyValueMapper={(actionMessage) => [
              actionMessage.id,
              actionMessage.text,
            ]}
          />
        </div>
        {isCustomMessage && (
          <div className="col-md-4 col-lg-4 mb-3 mb-md-0">
            <Input
              className="mb-0"
              label={t("Text")}
              name="messageText"
              value={messageText}
              onChange={(value) => setMessageText(value)}
              maxLength={50}
              placeholder={t("Enter a custom message")}
            />
            <span
              className={
                messageText.length > 50 ? "text-danger" : "text-secondary"
              }
            >
              {messageText.length}/50
            </span>
          </div>
        )}
      </div>
      {/* Language */}
      {isCustomMessage && (
        <div className="row language">
          <div className="col-md-4 col-lg-2 mb-3">
            <label>{t("Language")}</label>
            <Select
              data={actionDataSourcesContext.locales}
              uid={"selectedLocale"}
              initialValue={filteredData.selectedLocale}
              getValue={handleFilteredDataChanged}
            />
          </div>
        </div>
      )}

      {/* Gender */}
      {isCustomMessage && (
        <>
          <div className="row">
            <div className="col-md-4 col-lg-2 mb-3 mb-md-0">
              <label>{t("Gender")}</label>
              <Select
                data={actionDataSourcesContext.genders}
                uid={"selectedGender"}
                initialValue={filteredData.selectedGender}
                getValue={handleFilteredDataChanged}
              />
            </div>
            {voicesList && (
              <div className="col-md-4 col-lg-2 mb-3 mb-md-0">
                <label>{t("Voice")}</label>
                <Select
                  data={voicesList}
                  uid={"selectedVoice"}
                  initialValue={filteredData.selectedVoice}
                  getValue={handleFilteredDataChanged}
                />
              </div>
            )}
            {stylesList && (
              <div className="col-md-4 col-lg-2 mb-3 mb-md-0">
                <label>{t("Style")}</label>
                <Select
                  data={stylesList}
                  uid={"selectedStyle"}
                  initialValue={filteredData.selectedStyle}
                  getValue={handleFilteredDataChanged}
                />
              </div>
            )}
          </div>
          <div className="col-md-4 col-lg-12 d-flex gap-4 align-items-center">
            <Toggle
              isChecked={disableSound}
              label={t('Disable "OK" sound.')}
              callBack={(value) => setDisableSound(value)}
            />
            <OverlayTrigger
              placement="top"
              delay={{ show: 50, hide: 50 }}
              overlay={disableOKTooltip}
            >
              <span className="d-inline-block">
                <InfoIcon />
              </span>
            </OverlayTrigger>
          </div>
        </>
      )}

      {/* Has audio */}
      {(currentActionMessage?.sound ?? currentSound) && (
        <>
          <div className="has-audio col-md-12 col-lg-6 mt-2 d-flex justify-content-between">
            <div className="bg"></div>
            <span className="d-flex gap-3">
              <span
                className="play-audio"
                onClick={
                  isPlaying
                    ? () => pauseAudio(setIsPlaying)
                    : () =>
                        playAudio(
                          currentActionMessage?.sound ?? currentSound?.src,
                          setIsPlaying
                        )
                }
              >
                {isPlaying ? (
                  <PauseAudioIcon fill="var(--primary-color)" />
                ) : (
                  <PlayAudioIcon fill="var(--primary-color)" />
                )}
              </span>
              <span className="current-audio">
                {t("Current Audio")}
                {currentSound &&
                  " - " +
                    moment(currentSound.date).format("DD.MM.YYYY HH:mm:ss")}
                {currentActionMessage && " (" + currentActionMessage.text + ")"}
              </span>
            </span>
          </div>
        </>
      )}

      {isCustomMessage && (
        <div className="buttons-group col-md-12 mt-4 d-flex gap-3">
          <button
            disabled={messageText.length <= 0}
            className="preview px-4 py-2 d-flex gap-2 align-items-center"
            onClick={
              isPlayingPreview
                ? () => pauseAudio(setIsPlayingPreview)
                : previewAudio
            }
          >
            {isPlayingPreview ? (
              <PauseAudioIcon fill="#fff" />
            ) : (
              <PlayAudioIcon fill="#fff" />
            )}
            <span>{t("Preview")}</span>
          </button>
          <button
            disabled={!previewSound}
            className="download px-4 py-2 d-flex gap-2 align-items-center"
            onClick={handleSaveAudioClicked}
          >
            <DownloadIcon width="20" fill="var(--primary-color)" />
            <span>{t("Save Message / Sound")}</span>
          </button>
        </div>
      )}

      {dialogData && (
        <Dialog
          show={dialogData}
          title={dialogData.title}
          closeModal={() => setDialogData(null)}
          action={dialogData.action}
          mainButton={dialogData.mainButton}
        >
          {dialogData.text}
        </Dialog>
      )}

      {/* Renders error diagram if an error occur. */}
      {errorDialog && (
        <DialogError
          show={errorDialog}
          closeDialog={() => setErrorDialog(false)}
        >
          {errorMessages?.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>
      )}
    </div>
  );
}

type SelectTranslatorWrapperProps = {
  uid: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};
const SelectTranslatorWrapper = ({
  uid,
  checked,
  onChange,
}: SelectTranslatorWrapperProps) => {
  const t = useT();

  const renderTooltip = (props: any) => (
    <Tooltip {...props}>
      {t(
        "When on, this action will run only once. If scanned again, the action will be the default."
      )}
    </Tooltip>
  );

  return (
    <div className="select-wrapper d-flex align-items-center gap-4 col-lg-4">
      <Checkbox
        uid={uid}
        className="mb-0"
        checked={checked}
        getValue={(checked: boolean, _name: string, _uid: string) =>
          onChange(checked)
        }
        name="action voucher"
        label={t("Set as voucher")}
        placeholder={t("action-voucher")}
      />
      <OverlayTrigger
        placement="top"
        delay={{ show: 50, hide: 50 }}
        overlay={renderTooltip}
      >
        <span>
          <InfoIcon />
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default AudioPanel;
