import momentTimezone from "moment-timezone";
import moment         from 'moment';

const TIMEZONE = 'Europe/Zurich';

/**
 * @class ./services/api/utils/DateTime/DateTime
 */
class DateTime
{
    /**
     * Returns time diff in hours.
     *
     * @param {String} time
     */
    getHourDiff = (time) =>
    {
        const now = moment.utc();
        const end = moment(time);

        const duration = moment.duration(now.diff(end));
        const durationHours = duration.asHours(); // For days duration.asDays()

        return durationHours;
    };

    /**
     * Formats input time string to europe date format dd-mm-yyyy hh:mm.
     *
     * @param {String} time
     */
    formatDateTimeString = (time) =>
    {
        let timeObj = new Date(time);

        return [
            [
                ("0" + timeObj.getUTCDate()).slice(-2),
                ("0" + (timeObj.getMonth() + 1)).slice(-2),
                timeObj.getFullYear().toString()
            ].join('-'),
            [
                ("0" + timeObj.getUTCHours()).slice(-2),
                ("0" + timeObj.getUTCMinutes()).slice(-2)
            ].join(':')
        ].join(' ');
    };

    /**
     * Transforms datetime format from dd-mm-yyyy hh:mm to mm-dd-yyyy hh:mm.
     *
     * @param {String} dateString
     *
     * @returns {String}
     */
    replaceYearAndDate = (dateString) =>
    {
        let datetimeArray = dateString.split(" ");
        let date = datetimeArray[0];
        let dateArray = date.split("-");
        let firstPart = dateArray[0];
        let month = dateArray[1];
        let lastPart = dateArray[2];
        let formattedDate = [lastPart, month, firstPart].join("-");

        return datetimeArray[1] ? [formattedDate, datetimeArray[1]].join(" ") : formattedDate;
    };

    /**
     * Checks if date is DST.
     *
     * @param {String} dateTime
     *
     * @return {Boolean}
     */
    isDateDST = (dateTime) =>
    {
        let isDateDST = false;
        let formattedDateTime;

        if(this.isDateTimeISOFormat(dateTime)) {
            isDateDST = momentTimezone.tz(dateTime, TIMEZONE).isDST();
        } else {
            formattedDateTime = this.replaceYearAndDate(dateTime);
            isDateDST = momentTimezone.tz(formattedDateTime, TIMEZONE).isDST();
        }

        return isDateDST;
    };

    /**
     * Returns local date time.
     *
     * @param {String} dateTimeISOFormat
     *
     * @return {String}
     */
    getLocalDateTime = (dateTimeISOFormat) =>
    {
        let calculatedDateTime;

        if(this.isDateDST(dateTimeISOFormat)) {
            calculatedDateTime = moment.utc(dateTimeISOFormat).add(2, 'hours').format('YYYY-MM-DD HH:mm');
        } else {
            calculatedDateTime = moment.utc(dateTimeISOFormat).add(1, 'hours').format('YYYY-MM-DD HH:mm');
        }

        return calculatedDateTime;
    };

    /**
     * Returns UTC time in "YYYY-MM-DD".
     *
     * @param {String} dateTime
     *
     * @return {String}
     */
    getFormattedUTCDate = (dateTime) =>
    {
        let formattedUTCDate;
        if(this.isDateTimeISOFormat(dateTime)) {
            formattedUTCDate = moment(dateTime, moment.ISO_8601).utc().format("YYYY-MM-DD");
        }

        return formattedUTCDate;

    };

    /**
     * Returns time in "HH:mm" format.
     *
     * @param {String} time
     *
     * @returns {String}
     */
    getFormattedTime = (time) =>
    {
        let formattedTime = '';

        if(time) {
            formattedTime = moment(time, "HH:mm:ss").format('HH:mm');
        }

        return formattedTime;
    };

    /**
     * Formats datetime.
     *
     * @param {String} dateTime
     *
     * @return {String}
     */
    getFormattedUTCDateTime = (dateTime) =>
    {
        return moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm");
    };

    /**
     * Checks if datetime is in ISO format.
     *
     * @param {String} dateTime
     *
     * @return {Boolean}
     */
    isDateTimeISOFormat = (dateTime) =>
    {
        let isDateISOFormat = false;

        if(moment(dateTime, moment.ISO_8601, true).isValid()) {

            isDateISOFormat = true;
        }

        return isDateISOFormat;
    };

    /**
     * Returns formatted date
     *
     * @param {String} dateTime
     *
     * @return {String}
     */
    getFormattedDateTime = (dateTime) =>
    {
        let formattedDateTime;
        if(this.isDateTimeISOFormat(dateTime)) {
            let localDateTime = this.getLocalDateTime(dateTime);
            formattedDateTime = this.replaceYearAndDate(localDateTime);
        } else {
            formattedDateTime = dateTime;
        }

        return formattedDateTime;
    };

    /**
     * Returns UTC Time from local time format.
     *
     * @param {String} localTime
     *
     * @return {String}
     */
    getUTCTimeFromLocalTime = (localTime) =>
    {
        let UTCTime;

        let formattedLocalTime = this.replaceYearAndDate(localTime);
        if(this.isDateDST(formattedLocalTime)) {
            UTCTime = moment(formattedLocalTime,"YYYY-MM-DD HH:mm").subtract(2, 'hours').format('YYYY-MM-DD HH:mm');
        } else {
            UTCTime = moment(formattedLocalTime,"YYYY-MM-DD HH:mm").subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
        }

        return UTCTime;
    };

    /**
     * Returns formatted UTC time.
     *
     * @param {String} dateTime
     *
     * @return {String}
     */
    getUTCTime = (dateTime) =>
    {
        let UTCTime;
        let formattedDateTime;
        if(!this.isDateTimeISOFormat(dateTime)) {
            formattedDateTime = this.replaceYearAndDate(dateTime);

            if(this.isDateDST(dateTime)) {
                UTCTime = moment(formattedDateTime,"YYYY-MM-DD HH:mm").subtract(2, 'hours').format('YYYY-MM-DD HH:mm');
            } else {
                UTCTime = moment(formattedDateTime,"YYYY-MM-DD HH:mm").subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
            }
        } else {
            UTCTime = dateTime;
        }

        return UTCTime;
    };

    /**
     * Returns DST offset.
     *
     * @param {String} dateTime
     *
     * @return {Number}
     */
    getDSTOffset = (dateTime) =>
    {
        let UTCOffset;
        if (this.isDateDST(dateTime)) {
            UTCOffset = 2;
        } else {
            UTCOffset = 1;
        }

        return UTCOffset;
    };

}

export default DateTime;