/**
 * @class scenes/VenueManagement/scenes/Devices/services/Devices
 */
class Devices
{
    /**
     * Checks if device belonging to checkpoint name.
     *
     * @param {Object} device
     * @param {String} checkpointName
     *
     * @returns {Boolean}
     */
    isDeviceOnCheckpoint = (device, checkpointName) =>
    {
        let isDeviceOnCheckpoint = false;

        if (device.state.checkpoint.latestAdminValueApplied) {
            if (device.state.checkpoint.reportedValue === checkpointName) {
                isDeviceOnCheckpoint = true;
            }
        } else {
            if (device.state.checkpoint.adminRequestedValue === checkpointName) {
                isDeviceOnCheckpoint = true;
            }
        }

        return isDeviceOnCheckpoint;
    };
}

export default Devices;