import React, { Component } from "react";
import { ThemeLayout } from "../../themes/default/Layout";
import { VenueService } from "../../services/api/Venue";
import { ShowService } from "../../services/api/Show";
import { ActionFilterService } from "../../services/api/ActionFilter";
import { ResponseService } from "../../services/utils/Response";
import { Redirect } from "react-router-dom";
import { DialogError } from "../../themes/default/Dialog";

import "./style.css";

var sidebarNavigationMenuItems = {
  Dashboard: {
    items: {
      Venues: {
        href: "/",
        number: null,
      },
      Shows: {
        href: "/shows",
        number: null,
      },
      Devices: {
        href: "/devices",
        number: null,
      },
      Providers: {
        href: "/providers",
        number: null,
      },
    },
  },
  Venues: {
    items: {
      "Venue Dashboard": {
        href: "/venues/dashboard",
        number: null,
      },
      Shows: {
        href: "/venues/shows",
        number: "",
      },
      "Zones & Checkpoints": {
        href: "/venues/zones-and-checkpoints",
        number: null,
      },
      Devices: {
        href: "/venues/devices",
        number: "",
      },
      Actions: {
        href: "/venues/actions",
        number: null,
      },
      Logs: {
        href: "/venues/logs",
        number: null,
      },
    },
  },
  Shows: {
    items: {
      "Show Details": {
        href: "/shows/details",
        number: null,
      },
      "Show Properties": {
        href: "/shows/properties",
        number: null,
      },
      "Show Tickets": {
        href: "/shows/tickets",
        number: null,
      },
    },
  },
};

/**
 * @class ./components/Layout
 */
class Layout extends Component {
  /**
   * @type {ShowService}
   */
  showService;

  /**
   * @type {VenueService}
   */
  venueService;

  /**
   * @type {ActionFilterService}
   */
  actionFilterService;

  /**
   * @type {Object}
   */
  responseService;

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.showService = new ShowService();
    this.venueService = new VenueService();
    this.actionFilterService = new ActionFilterService();
    this.responseService = new ResponseService();
  }

  state = {
    selectedId: null,
    selectedVenue: null,
    selectedShow: null,
    section: null,
    redirectUser: false,
    showErrorDialog: false,
    errorMessages: [],
    topNavigationMenuItems: {
      Venues: {
        href: "/",
        icon: {
          path: "./images/home.svg",
          alt: "Home",
        },
        dropdown: null,
      },
    },
    sidebarNavigationMenuItems: sidebarNavigationMenuItems,
  };

  /**
   * @override
   */
  UNSAFE_componentWillMount() {
    if (this.props.children.props.match) {
      this.setState({
        section: this.props.section,
        selectedId: this.props.children.props.match.params.id,
      });
    } else {
      this.setState({
        redirectUser: true,
      });
    }
  }

  /**
   * @override
   */
  componentDidMount() {
    if (this.state.section === "venues") {
      const allShowsPromise = this.venueService.getAllShows(
        this.state.selectedId
      );
      const getVenuePromise = this.venueService.getOne(this.state.selectedId);
      const getAllHandheldsPromise = this.venueService.getAllHandhelds(
        this.state.selectedId
      );
      const getAllActionFiltersPromise = this.actionFilterService.getAll(
        this.state.selectedId
      );

      let sidebarNavigationMenuItemsCopy = sidebarNavigationMenuItems;
      Promise.all([
        allShowsPromise,
        getVenuePromise,
        getAllHandheldsPromise,
        getAllActionFiltersPromise,
      ]).then(([venueShows, venue, venueHandhelds, actionFilters]) => {
        if (
          venueShows.status === 200 &&
          venue.status === 200 &&
          venueHandhelds.status === 200
        ) {
          sidebarNavigationMenuItemsCopy["Venues"]["items"]["Shows"]["number"] =
            venueShows.data.totalResultCount;
          sidebarNavigationMenuItemsCopy["Venues"]["items"]["Devices"][
            "number"
          ] = venueHandhelds.data.totalResultCount;
          sidebarNavigationMenuItemsCopy["Venues"]["items"]["Actions"] &&
            (sidebarNavigationMenuItemsCopy["Venues"]["items"]["Actions"][
              "number"
            ] = actionFilters.data.length > 0 ? actionFilters.data.length : "");

          this.setState({
            selectedVenue: venue.data,
            sidebarNavigationMenuItems: sidebarNavigationMenuItemsCopy,
          });
        } else {
          var errorMessages = "";
          errorMessages =
            venueShows.status !== 200
              ? venueShows.data
              : venue.status !== 200
              ? venue.data
              : venueHandhelds.status !== 200 && venueHandhelds.data;

          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(errorMessages),
          });
        }
      });
    }

    if (this.state.section === "shows") {
      this.showService.getOne(this.state.selectedId).then((response) => {
        if (response.status === 200) {
          this.setState({
            selectedShow: response.data,
          });
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
      });
    }

    // Hide some pages for mobile view
    if (this.props.isMobileView) {
      delete sidebarNavigationMenuItems.Venues.items["Logs"];
      delete sidebarNavigationMenuItems.Venues.items["Actions"];

      delete sidebarNavigationMenuItems.Shows.items["Show Properties"];
      delete sidebarNavigationMenuItems.Shows.items["Show Tickets"];
    }
  }

  /**
   * @returns {XML}
   */
  render() {
    return (
      <div>
        {/* Renders layout if certain conditions are met otherwise redirects user to home page. */}
        {this.state.section && this.state.selectedId ? (
          <ThemeLayout
            section={this.state.section}
            loggedUser={this.props.loggedUser}
            selectedId={this.state.selectedId}
            selectedShow={this.state.selectedShow}
            isMobileView={this.props.isMobileView}
            selectedVenue={this.state.selectedVenue}
            currentUserId={this.props.currentUserId}
            topNavigationMenuItems={this.state.topNavigationMenuItems}
            sidebarNavigationMenuItems={this.state.sidebarNavigationMenuItems}
          >
            {React.cloneElement(this.props.children, {
              venue: this.state.selectedVenue,
              show: this.state.selectedShow,
            })}
          </ThemeLayout>
        ) : (
          this.state.redirectUser && <Redirect exact to={"/"} />
        )}

        {/* Renders error diagram if an error occur. */}
        <DialogError
          show={this.state.showErrorDialog}
          closeDialog={() => this.setState({ showErrorDialog: false })}
        >
          {this.state.errorMessages.map((message, index) => {
            return <p key={index}>{message}</p>;
          })}
        </DialogError>
      </div>
    );
  }
}

export default Layout;
