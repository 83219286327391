import React, { Component }        from 'react';
import { T, useT }                 from '@transifex/react';
import { ShowService }             from '../../../../services/api/Show';
import { SimpleTag }               from '../../../../components/SimpleTag';
import { Alert, AlertTypeSuccess } from '../../../../themes/default/Alert';
import { SceneTitle }              from '../../../../themes/default/Title/components/Scene';
import { Panel }                   from '../../../../themes/default/Layout/components/Panel';
import { Input }                   from '../../../../themes/default/Form/components/Input';
import { Button}                   from '../../../../themes/default/Form/components/Button';

import ShowImage                   from '../../images/ico-no-image.jpg';

import './style.css';

/**
 * @TODO Missing form fields validation
 * @class /src/scenes/ShowManagement/scenes/Properties/Properties
 */
class Properties extends Component
{
    /**
     * @type {String}
     */
    showId;

    /**
     * @type {Object}
     */
    showService;

    state = {
        show: {},
        isPromiseFinished: false,
        showSuccessMessage: false
    };

    /**
     * @override
     *
     * @param {Object} props
     */
    constructor (props)
    {
        super(props);

        this.showService = new ShowService();
    }

    /**
     * @override
     */
    componentDidMount ()
    {
        this.showId = this.props.match.params.id;

        this.setState({
            showLoadingScreen: true
        });

        this.showService.getOne(this.showId).then(response => {
            if (JSON.stringify(this.state.show) !== JSON.stringify(response)) {
                this.setState({
                    show : response.data,
                    isPromiseFinished : true
                });
            }
            this.setState({
                showLoadingScreen: false
            });
        });

    }

    /**
     * Updates show's property.
     *
     * @param {String} property
     * @param {String} value
     */
    updateShowProperty = (property,value) =>
    {
        if (this.state.show[property] !== value) {
            let show = {...this.state.show};
            show[property] = value;

            this.setState({
                show: show
            });
        }
    };

    /**
     * Returns event input's value.
     *
     * @param {String} value
     */
    getEventValue = (value) => this.updateShowProperty('event', value);

    /**
     * Returns location input's value.
     *
     * @param {String} value
     */
    getLocationValue = (value) => this.updateShowProperty('location', value);

    /**
     * Returns town input's value.
     *
     * @param {String} value
     */
    getTownValue = (value) => this.updateShowProperty('town', value);

    /**
     * Returns value of simple tag component.
     *
     * @param {Array} value
     */
    getSimpleTagValue = (value) =>
    {
        let show = {...this.state.show};
        let customProperties = {};

        if(value !== "undefined" && !this.isEmpty(show)) {
            value.map((value, index) => {
                let customPropertyArray = value.split(' = ');
                let customPropertyArrayKey = customPropertyArray[0];
                customProperties[customPropertyArrayKey] = customPropertyArray[1];
                return true;
            });
        }

        show.customProperties = customProperties;

        this.setState({
            show: show
        });

    };

    /**
     * Returns array of show's custom properties in format for Simple Tag Component.
     *
     * @returns {Array}
     */
    getCustomPropertyArray = () =>
    {
        let customProperties = this.state.show.customProperties;
        let customPropertiesArray = [];

        if(this.state.show.customProperties !== "undefined" && !this.isEmpty(this.state.show.customProperties)) {
            Object.keys(customProperties).map(function (key) {
                customPropertiesArray.push(key + " = " + customProperties[key]);

                return true;
            });
        }

        return customPropertiesArray;
    };


    /**
     * Checks if tickets object is empty.
     *
     * @param {Object} tickets
     *
     * @returns {Boolean}
     */
    isEmpty = (tickets) =>
    {
        for(let i in tickets) {
            if(tickets.hasOwnProperty(i)) {
                return false;
            }
        }
        return true;
    };

    /**
     * Updates show according to local state show object value.
     */
    updateShow = () =>
    {
        this.setState({
            showLoadingScreen: true
        });

        this.showService.updateOne(this.showId,this.state.show).then(response => {
            if (response.status === 204) {
                this.setState({
                    showSuccessMessage: true
                });
            }
            this.setState({
                showLoadingScreen: false
            });
        })
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            this.state.isPromiseFinished &&
            <div className="ShowProperties">
                
                <Tite name={this.state.show.name}/>

                {this.state.showSuccessMessage &&
                    <Alert type={AlertTypeSuccess}><T _str="Show {showName} has been successfully updated!" showName={this.state.show.name} /></Alert>
                }
                
                <p><T _str="Inspect and manage a show's properties. These fields are inactive for shows created by integrated ticketing systems." /></p>
                
                <Panel title="" colorize>
                    <div className="col-12 properties">
                        <div className="col-8">
                            <Input
                                readOnly={this.state.show.providerId !== null}
                                name="event"
                                label={<T _str="Event" />}
                                value={this.state.show.event}
                                getValue={this.getEventValue}
                            />
                            <Input
                                readOnly={this.state.show.providerId !== null}
                                name="location"
                                label={<T _str="Location" />}
                                value={this.state.show.location}
                                getValue={this.getLocationValue}
                            />
                            <Input
                                readOnly={this.state.show.providerId !== null}
                                name="town"
                                label={<T _str="Town" />}
                                value={this.state.show.town}
                                getValue={this.getTownValue}
                            />
                            <p className="margin-top"><T _str="Custom properties of show (can be used in show-to-venue filters):" /></p>
                            <SimpleTag value={this.getCustomPropertyArray()} getValue={this.getSimpleTagValue}/>
                        </div>
                        <div className="col-4">
                            { this.state.show.imageUrl ?
                                <img src={this.state.show.imageUrl} alt="Show" className="img-rounded img-responsive"/> :
                                <img src={ShowImage} alt="Show" className="img-rounded img-responsive"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 buttons">
                        <div className="col-6">
                            <Button onClickHandler={this.updateShow}><T _str="Save Changes" /></Button>
                        </div>
                    </div>
                    <div className="helper"><T _str="Helper" /></div>
                </Panel>
            </div>
        );
    }
};

const Tite = ({ name }) => {
    const t = useT();
    return (
        <SceneTitle text={t("Show Properties")}>
            <p>{name}</p>
        </SceneTitle>
    )
};

export default Properties;
