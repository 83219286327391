import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const ExpandingCard = ({ children, show }) =>
(
    <div id="expanding-card-container" className={show ? "expanding-card-open" : "expanding-card-close"}>
        <div className="expanding-card-body">
            {children}
        </div>
    </div>
);

ExpandingCard.propTypes = {
    show: PropTypes.bool.isRequired
};

export default ExpandingCard;