import React, { useEffect, useState } from 'react';
import { CSVLink }                    from "react-csv";
import PropTypes                      from 'prop-types';
import { useT }                       from '@transifex/react';
import { Switch }                     from '../../../../../../../../themes/default/Form/components/Switch';
import { Button }                     from '../../../../../../../../themes/default/Form';
import IconMore                       from '../../../../../../../../assets/img/ico-more.svg';
import IconLess                       from '../../../../../../../../assets/img/ico-less.svg';
import { DownloadIcon }               from '../../../../../../../../components/Images/Images';

import IconArrows from './images/arrows.svg';

import './style.css';

const List = (props) => 
{
    /**
     * Selects certain show for calculation.
     *
     * @param {Object} value
     * @param {Object} name
     */
    const selectShow = (value,name) =>
    {
        if (typeof name !== 'undefined') {
            props.selectDeselectShow(value,name);
        }
    };

    return (
        <div className="ZoneSettingsListWrapper" style={{"width":window.innerWidth > 991 ? window.innerWidth - 400 : window.innerWidth - 20 }}>
            <table className="List ZoneSettingsList">
                <thead>
                    <tr>
                        <ZoneSettingsHeaderColumns zones={props.zones} zonesInfo={props.zonesInfo} sort={props.sort} />
                    </tr>
                </thead>
                <tbody>
                    {props.data && <RenderBody data={props.data} props={props} selectShow={selectShow} zonesInfo={props.zonesInfo}/> }
                </tbody>
            </table>
        </div>
    );
};

const RenderBody = ({ props, data, selectShow, zonesInfo }) => {
    
    const t = useT();
    const [csvData, setCsvData] = useState(null);
    const [zoneData, setZoneData] = useState(null);
    const [expandedData, setExpandedData] = useState([]);
    const [subExtraOpenedArray, setSubExtraOpenedArray] = useState([]);

    useEffect(() => {
        if (zonesInfo !== undefined && zonesInfo.length > 0) {
            let zoneData = {};
            zonesInfo.map(i => {
                zoneData = {
                    ...zoneData,
                    [i.show.id] : i.ticketsWithoutZoneDetails
                }
                return null;
            })
            setZoneData(zoneData);
            downloadTicketsWithoutZoneDetails(zoneData);
        };
    }, [zonesInfo]);

    // Cleaning state if the props calculation change
    useEffect(() => {
        if (!props.isCalculation ) {
            setCsvData(null);
            setZoneData(null);
            setExpandedData([]);
            setSubExtraOpenedArray([]);
        }
    }, [props.isCalculation]);

    const downloadTicketsWithoutZoneDetails = data => {        
        let finalData = {};

        Object.keys(data).map(key => {
            let customPropertiesColHeader = [];
            data[key].length > 0 && data[key].map(i => {                
                customPropertiesColHeader = Object.keys(i.customProperties).length > 0 && Object.keys(i.customProperties);
                return null;
            });

            const logsDownloadData =  [["Barcode", "External Ticket Id", "Validity", "Personalised Ticket First Name", "Personalised Ticket Last Name",  "Personalised Ticket Salutation", ...customPropertiesColHeader ]];
            
            data[key].map(ticket => {
                let  dynamicProperties = [];
    
                customPropertiesColHeader.map(cp => {
                    const value = ticket.customProperties[cp];
                    dynamicProperties.push(value);
                    return null;
                });

                logsDownloadData.push([
                    ticket.barcode,
                    ticket.externalTicketId,
                    ticket.validity,
                    ticket.personalisedTicketFirstName,
                    ticket.personalisedTicketLastName,
                    ticket.personalisedTicketSalutation,
                    ...dynamicProperties,
                ]);
                return null;
            });

            finalData = {
                ...finalData,
                [key] : logsDownloadData
            }
            return null;
        });
        setCsvData(finalData);
    };

    const SubExtraRowExpaningController = id => {
        const clone = [...subExtraOpenedArray];
        const idx = clone.indexOf(id)        
        idx === -1 ? clone.push(id) : clone.splice(idx, 1);
        setSubExtraOpenedArray(clone)
    };

    const expandingManager = id => {
        let expandedDataClone = [...expandedData];
        const idx = expandedDataClone.indexOf(id)
        idx === -1 ? expandedDataClone.push(id) : expandedDataClone.splice(idx, 1);
        setExpandedData(expandedDataClone);
    };

    return (
        data.map((rowData,index) => {
            let showId = Object.entries(rowData)[0][0];
            return (
                <React.Fragment key={index}>
                    <tr>
                        {!props.isCalculation &&
                            <td>
                                <Switch name={showId} label='' getValue={selectShow} checked={props.selectedShows.includes(showId)}/>
                            </td>
                        }
                        {
                            Object.entries(rowData)[0][1].map((cellData, index) => {
                                let value       = typeof cellData === "object" ? cellData.value     : cellData;
                                let isWarning   = typeof cellData === "object" ? cellData.isWarning : null;
                                let isDanger    = typeof cellData === "object" ? cellData.isDanger  : null;
                                let align       = typeof cellData === "object" ? cellData.align     : 'text-left';
                                let isClickable = typeof cellData === "object" ? cellData.clickable : null;
                                let isMultiple  = typeof cellData === "object" ? cellData.multiple  : null;

                                return !isMultiple
                                    ?   <td className={["",isWarning && "warning",isDanger && "danger",align,isClickable && "clickable"].join(" ")}
                                            key={index}
                                            title={value ? value : undefined}
                                            onClick={() => (props.isCalculation && isClickable) && props.selectShow(showId)}
                                        >
                                            { isDanger &&
                                                <React.Fragment>
                                                    <span className={`tickets-without-zone-box ${expandedData.includes(showId) ? "expanded" : "not-expanded"}`} onClick={() => expandingManager(showId)}></span>
                                                    <div className="download">
                                                        { csvData &&
                                                            <CSVLink filename={showId} data={csvData[showId]}>
                                                                <DownloadIcon />
                                                            </CSVLink>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            }
                                            { value }
                                        </td>
                                    :   <td key={index} className={["",align].join(" ")}>
                                            {
                                                value.map((val, index) => <p key={index}>{val}</p>)
                                            }
                                        </td>
                            })
                        }
                    </tr>
                    {expandedData.length > 0 && expandedData.includes(showId) &&
                        <tr>
                            <td colSpan="6" className='p-0'>
                                { zoneData[showId].map((ticket, index) => (             
                                    <table key={index} className='List ZoneSettingsList subtable m-0'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t("Barcode")}</th>
                                                <th>{t("External Ticket ID")}</th>
                                                <th>{t("Ticket Validity")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Button className="expanding-subextradata" onClickHandler={() => SubExtraRowExpaningController(ticket.externalTicketId) }>
                                                        <img src={subExtraOpenedArray.includes(ticket.externalTicketId) ? IconLess : IconMore} alt="More"/>
                                                    </Button>
                                                </td>
                                                <td className="barcode-cell">
                                                    {ticket.barcode}
                                                </td>
                                                <td>{ticket.externalTicketId}</td>
                                                <td>{ticket.validity}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" className="p-0">
                                                    {subExtraOpenedArray.includes(ticket.externalTicketId) && ticket.customProperties !== {} &&
                                                        <React.Fragment>

                                                            <PersonalisedTicketData name="personalisedTicketFirstName"  ticket={ticket} />
                                                            <PersonalisedTicketData name="personalisedTicketLastName"   ticket={ticket} />
                                                            <PersonalisedTicketData name="personalisedTicketSalutation" ticket={ticket} />

                                                            {Object.keys(ticket.customProperties).map((cpkey, idx) => (
                                                                <div className="row" key={idx}>    
                                                                    <div className="col-4 text-start p-4 py-2">
                                                                        {cpkey}
                                                                    </div>
                                                                    <div className="col-4 text-start p-4 py-2 fw-bold">
                                                                        {ticket.customProperties[cpkey]}
                                                                    </div>
                                                                </div>
                                                        
                                                            ))}
                                                        </React.Fragment>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ))}
                            </td>
                        </tr>
                    }
                </React.Fragment>
            );
        })
    )
};

const PersonalisedTicketData = ({ name, ticket }) => 
(
    <div className="row">
        <div className="col-4 text-start p-4 py-2">
            {name}
        </div>
        <div className="col-4 text-start p-4 py-2 fw-bold">
            {ticket[name] !== null ? ticket[name] : 'N/A'}
        </div>
    </div>
);

/**
 * Returns columns for zone settings list.
 *
 * @param {Array} zones
 * @param {Array} zonesInfo
 *
 * @returns {Object}
 */
const ZoneSettingsHeaderColumns = ({ zones, zonesInfo, sort }) =>
{
    const t = useT();
    const begin = t('Begin');
    const show = t('Show');
    const tickets = t('#Tickets');

    const zoneSettingsColumns = {
        ''        : { 'subtitle' : null, 'sortable' : false, 'width' : '100px' },
        [begin]   : { 'subtitle' : null, 'sortable' : true, 'width' : '150px', 'fieldValue': 'begin' },
        [show]    : { 'subtitle' : null, 'sortable' : true, 'width' : '600px', 'fieldValue': 'name' },
        [tickets] : { 'subtitle' : null, 'sortable' : false, 'width' : '150px', 'align' : 'text-end' }
    };

    let columns = {...zoneSettingsColumns};

    if (zones !== undefined && zonesInfo !== undefined && zones.length > 0 && zonesInfo.length  > 0 ) {

        delete columns[''];

        columns['#Tickets without zone']= {'subtitle' : null, 'sortable' : false, 'width' : '250px', 'align' : 'text-end' };
        columns['#Tickets valid in multiple zones']= {'subtitle' : null, 'sortable' : false, 'width' : '250px', 'align' : 'text-end' };
        
        zones.map(zone => {
            columns['#Tickets valid in ' + zone.name] = {'subtitle' : null, 'sortable' : false, 'width' : '250px', 'align' : 'text-end' };
            return null;
        });
    }

    return (
        Object.entries(columns).map((column,index) =>
            <th key={index} style={column[1].width && {"width":column[1].width,"minWidth":column[1].width}} className={column[1].align && column[1].align}>
                { column[0] }
                {
                    column[1].subtitle !== null && <span className="grayed">{column[1].subtitle}</span>
                }
                {
                    column[1].sortable && <img className="pull-right" src={IconArrows} alt="Arrows" onClick={() => sort(column[1].fieldValue)}/>
                }
            </th>
        ))
};

List.propTypes = {
    data               : PropTypes.array,
    selectDeselectShow : PropTypes.func,
    selectedShows      : PropTypes.array,
    sort               : PropTypes.func,
    isCalculation      : PropTypes.bool,
    selectShow         : PropTypes.func
};

export default List;