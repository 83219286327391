import { MouseEventHandler } from "react";
import { useT } from "@transifex/react";

export const CheckIcon = () => (
  <svg
    version="1.1"
    id="check-icon"
    x="0px"
    y="0px"
    viewBox="0 0 108 108"
    width="20"
    height="20"
  >
    <polyline
      style={{
        fill: "none",
        strokeWidth: "4",
        strokeMiterlimit: "10",
        stroke: "#00999a",
      }}
      points="102.89,20.42 36.83,87.58 5.11,56.43 "
    />
  </svg>
);

type CloseIconProps = {
  onClick: MouseEventHandler<SVGSVGElement> | undefined;
  stroke: string;
};
export const CloseIcon = (props: CloseIconProps) => (
  <svg
    onClick={props?.onClick}
    version="1.1"
    id="close-icon"
    x="0px"
    y="0px"
    viewBox="0 0 108 108"
    width="20"
    height="20"
  >
    <line
      stroke={props?.stroke ?? "red"}
      style={{ fill: "none", strokeWidth: "4", strokeMiterlimit: "10" }}
      x1="88.08"
      y1="20.51"
      x2="22.22"
      y2="87.49"
    />
    <line
      stroke={props?.stroke ?? "red"}
      style={{ fill: "none", strokeWidth: "4", strokeMiterlimit: "10" }}
      x1="86.9"
      y1="86.58"
      x2="19.92"
      y2="20.72"
    />
  </svg>
);

export const WifiIcon = ({ className = "" }) => (
  <svg
    className={className}
    version="1.1"
    id="wifi-icon"
    x="0px"
    y="0px"
    viewBox="0 0 138 138"
    width="17"
    height="17"
  >
    <path
      fill="#1D1D1B"
      d="M12.41,68.63l12.17-12.16c3.22,3.23,5.03,7.6,5.03,12.16c0,4.56-1.81,8.94-5.04,12.16L12.41,68.63z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeWidth="16"
      strokeMiterlimit="10"
      d="M37.32,43.72c13.76,13.76,13.76,36.06,0,49.82"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeWidth="16"
      strokeMiterlimit="10"
      d="M55.12,25.92c23.59,23.59,23.59,61.84,0,85.42"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeWidth="16"
      strokeMiterlimit="10"
      d="M73.51,7.54c33.74,33.74,33.74,88.44,0,122.19"
    />
  </svg>
);

export const BatteryIcon = ({ batteryPercentage = -1 }) => {
  let className = "battery-";
  let width;
  switch (true) {
    case batteryPercentage < 10:
      className += "empty";
      width = "0";
      break;
    case batteryPercentage < 50:
      className += "quarter";
      width = "15";
      break;
    case batteryPercentage < 75:
      className += "half";
      width = "25";
      break;
    case batteryPercentage >= 75:
      className += "full";
      width = "49";
      break;
    case batteryPercentage < 0:
    default:
      className = "";
      width = "0";
  }

  return (
    <svg
      version="1.1"
      id="battery-icon"
      className={className}
      x="0px"
      y="0px"
      viewBox="0 0 78.52 48.92"
      width="19"
    >
      <path d="M69.81,34.13c0,1.59,0.04,3.08-0.01,4.57c-0.09,2.87-2,4.85-4.87,4.85c-18.4,0.03-36.81,0.03-55.21,0   c-2.77,0-4.78-1.95-4.79-4.73c-0.06-9.6-0.06-19.19,0-28.79c0.02-2.79,2.06-4.65,4.85-4.65c18.4-0.01,36.81-0.02,55.21,0   c2.78,0,4.69,1.9,4.81,4.7c0.06,1.54,0.01,3.08,0.01,4.57c3.34,1.27,3.82,1.98,3.82,5.45c0,3.04,0.01,6.08,0,9.12   C73.63,32.25,72.9,33.21,69.81,34.13z M66.25,18.4c0-2.53-0.04-5.01,0.01-7.5c0.03-1.37-0.53-1.98-1.91-1.92   c-1.07,0.05-2.14,0.01-3.22,0.01C44.4,8.99,27.66,9,10.91,8.97c-1.52,0-2.47,0.21-2.46,2.1c0.07,8.94,0.05,17.89,0.01,26.83   c0,1.36,0.45,1.89,1.87,1.89c17.99-0.04,35.99-0.04,53.98,0c1.52,0,1.98-0.59,1.95-2.01c-0.07-2.43-0.02-4.86-0.02-7.4   c0.78-0.05,1.37-0.17,1.94-0.1c1.54,0.19,1.93-0.51,1.87-1.94c-0.1-2.26-0.03-4.53-0.03-6.8c0-2.85,0-2.85-2.91-3   C66.9,18.54,66.68,18.48,66.25,18.4z" />
      <rect x="12.83" y="13.09" width={width} height="22.57" />
    </svg>
  );
};

export const UsersIcon = ({ fill = "##fff", stroke = "#000" }) => (
  <svg
    version="1.1"
    id="users-icon"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <g>
      <path
        fill={fill}
        stroke={stroke}
        strokeMiterlimit="10"
        d="M10.8,7.7c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8C9.1,3.9,10.8,5.6,10.8,7.7z"
      />
      <path
        fill={fill}
        stroke={stroke}
        strokeMiterlimit="10"
        d="M13.4,17.1v0.7H0.7v-0.7c0-3.1,2.5-5.6,5.6-5.6h1.5C10.9,11.5,13.4,14,13.4,17.1z"
      />
    </g>
    <g>
      <path
        fill={fill}
        stroke={stroke}
        strokeMiterlimit="10"
        d="M20.8,7.7c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8C19.1,3.9,20.8,5.6,20.8,7.7z"
      />
      <path
        fill={fill}
        stroke={stroke}
        strokeMiterlimit="10"
        d="M23.3,17.1v0.7H10.6v-0.7c0-3.1,2.5-5.6,5.6-5.6h1.5C20.8,11.5,23.3,14,23.3,17.1z"
      />
    </g>
    <g>
      <path
        fill={fill}
        stroke={stroke}
        strokeMiterlimit="10"
        d="M15.8,10c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8C14.1,6.2,15.8,7.9,15.8,10z"
      />
      <path
        fill={fill}
        stroke={stroke}
        strokeMiterlimit="10"
        d="M18.4,19.4v0.7H5.6v-0.7c0-3.1,2.5-5.6,5.6-5.6h1.5C15.9,13.8,18.4,16.3,18.4,19.4z"
      />
    </g>
  </svg>
);

export const UserIcon = () => (
  <svg
    id="user-icon"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    width="25"
  >
    <circle
      stroke="#000"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.25"
      className="st0"
      cx="12"
      cy="7.3"
      r="4.8"
    />
    <path
      stroke="#000"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.25"
      className="st0"
      d="M13,12.3H11c-3.9,0-7.1,3.2-7.1,7.1v2.1H20v-2.1C20,15.4,16.9,12.3,13,12.3z"
    />
  </svg>
);

export const Logout = () => (
  <svg
    id="logout-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="21"
  >
    <path
      fillRule="evenodd"
      d="M22.471 20.999H8.712a.527.527 0 0 1-.53-.525V14.95h1.06v5h12.699V1.046H9.242v4.999h-1.06V.519c0-.288.238-.524.53-.524h13.759c.291 0 .528.236.528.524v19.955a.526.526 0 0 1-.528.525zM12.429 7.714a.523.523 0 0 1 .009-.743.533.533 0 0 1 .748.009l3.097 3.142a.523.523 0 0 1 .01.743l-3.107 3.151a.534.534 0 0 1-.748.009.524.524 0 0 1-.009-.744l2.226-2.258H.521a.528.528 0 0 1-.529-.525c0-.29.237-.526.529-.525h14.134l-2.226-2.259z"
    />
  </svg>
);

export const LoadingIcon = () => (
  <svg
    id="loading-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="25"
  >
    <path
      fill="#ffffff"
      fillRule="evenodd"
      d="M27.874 8.77l-2.838 4.14c-.011.015-.028.021-.039.035a.702.702 0 0 1-.168.149c-.025.016-.045.037-.072.049a.67.67 0 0 1-.296.073.674.674 0 0 1-.321-.089c-.011-.006-.024-.003-.036-.01l-4.391-2.642a.722.722 0 0 1-.248-.978.693.693 0 0 1 .961-.251l3.252 1.957c-.635-5.493-5.225-9.77-10.784-9.77-5.989 0-10.863 4.966-10.863 11.069 0 6.103 4.874 11.069 10.863 11.069 3.834 0 7.423-2.093 9.37-5.462a.695.695 0 0 1 .961-.252.722.722 0 0 1 .248.977C21.276 22.637 17.223 25 12.895 25 6.131 25 .63 19.394.63 12.502.63 5.609 6.131.004 12.895.004c6.09 0 11.141 4.55 12.091 10.483l1.738-2.534a.694.694 0 0 1 .976-.179.725.725 0 0 1 .174.996z"
    />
  </svg>
);

export const DateIcon = () => (
  <svg
    id="date-icon"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 25 25"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#009A9A"
      d="M22.8,22.9H2.2c-0.2,0-0.4-0.2-0.4-0.4l0,0v-0.5  c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4c0,0,0,0,0,0v0.1h19.7v-3.4c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0c0.2,0,0.4,0.2,0.4,0.4  c0,0,0,0,0,0v3.8C23.2,22.7,23,22.9,22.8,22.9C22.8,22.9,22.8,22.9,22.8,22.9z M23.2,17.1L23.2,17.1C23.2,17.1,23.2,17.2,23.2,17.1  L23.2,17.1L23.2,17.1C23.1,17.2,23.1,17.2,23.2,17.1l0,0.1l0,0c0,0,0,0,0,0l0,0l0,0l0,0l0,0c0,0,0,0,0,0l-3.8,3.8c0,0,0,0,0,0l0,0  l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0l0,0l0,0l0,0c0,0,0,0,0,0H2.2c-0.2,0-0.4-0.2-0.4-0.4V2  c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0h3.9V0.9c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0C6.8,0.5,7,0.7,7,0.9c0,0,0,0,0,0V5  c0,0.2-0.2,0.4-0.4,0.4C6.3,5.4,6.2,5.2,6.2,5c0,0,0,0,0,0V2.4H2.6v5.1h19.7V2.4h-2.3c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4l0,0  h2.7c0.2,0,0.4,0.2,0.4,0.4c0,0,0,0,0,0L23.2,17.1C23.2,17.1,23.2,17.1,23.2,17.1C23.2,17.1,23.2,17.1,23.2,17.1z M19.4,19.9  l2.4-2.4h-2.4V19.9z M22.4,8.3H2.6v12.1h15.9v-3.4c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0h3.4C22.4,16.7,22.4,8.3,22.4,8.3z M18.4,5.4  C18.2,5.4,18,5.2,18,5V2.4H8.2C8,2.4,7.8,2.2,7.8,2S8,1.6,8.2,1.6l0,0H18V0.9c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4  c0,0,0,0,0,0V5C18.8,5.2,18.7,5.4,18.4,5.4C18.4,5.4,18.4,5.4,18.4,5.4z M2.2,23.7h20.5c0.2,0,0.4,0.2,0.4,0.4  c0,0.2-0.2,0.4-0.4,0.4H2.2c-0.2,0-0.4-0.2-0.4-0.4C1.8,23.9,2,23.7,2.2,23.7z"
    />
  </svg>
);

interface DownloadIconProps {
  fill?: string;
  width?: string;
  className?: string;
}

export const DownloadIcon = ({ fill = "#fff", width = "20", className = undefined } : DownloadIconProps) => (
  <svg
    id="download-icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 250 250"
    width={width}
    height={width}
    className={className}
  >
    <path
      fill={fill}
      d="M15.3,150c8.2,0,16.2,0,24.3,0c0,20.3,0,40.5,0,60.8c56.9,0,113.7,0,170.6,0c0-20.2,0-40.5,0-60.8c8.2,0,16.2,0,24.4,0
            c0,0.3,0,0.6,0,0.9c0,19.9,0,39.9,0,59.8c0,11.8-7.9,21.5-19.4,24c-1.7,0.4-3.4,0.5-5.1,0.5c-56.7,0-113.4,0-170.2,0
            c-12.3,0-22.3-8.5-24.3-20.7c-0.2-1.2-0.3-2.4-0.3-3.6c0-20,0-40.1,0-60.1C15.3,150.6,15.3,150.4,15.3,150z"
    />
    <path
      fill={fill}
      d="M64.1,112.1c16.4,0,32.5,0,48.7,0c0-32.5,0-64.9,0-97.4c8.1,0,16.2,0,24.4,0c0,32.4,0,64.8,0,97.4c16.3,0,32.4,0,48.8,0
            c-20.4,24.4-40.6,48.7-60.9,73.1C104.7,160.9,84.5,136.6,64.1,112.1z"
    />
  </svg>
);

export const CopyIcon = ({
  fill = "#000",
  width = "23",
  height = "24",
  onClick = () => {},
  title = "",
}) => {
  const t = useT();
  if (!title) {
    title = t("Copy to clipboard");
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="copy-Icon"
      x="0px"
      y="0px"
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 23.23 26.22"
    >
      <title>{title}</title>
      <path
        fill={fill}
        d="M19.73,5.46H7.88c-1.28,0-2.32,1.04-2.32,2.32v15.1c0,1.28,1.04,2.32,2.32,2.32h11.85c1.28,0,2.32-1.04,2.32-2.32V7.78
                C22.05,6.5,21.01,5.46,19.73,5.46z M19.77,23.02H7.69V7.64h12.08V23.02z"
      />
      <path
        fill={fill}
        d="M16.53,1.02v2.2H3.32v15.4H1.18V3.32c0-1.27,1.03-2.3,2.29-2.3H16.53z"
      />
    </svg>
  );
};

export const SortingArrowsIcon = ({
  fill = "#00999a",
  width = "8",
  height = "18",
  className = "",
  onClick = () => {},
}) => (
  <svg
    version="1.1"
    className={`pull-right sorting-arrow ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 8 14"
    width={width}
    height={height}
    onClick={onClick}
  >
    <polygon fill={fill} points="4,0 8,5 0,5 " />
    <polygon fill={fill} points="4,14 0,9 8,9 " />
  </svg>
);

export const PlayAudioIcon = ({ fill = "#5ec162", width = "22" }) => (
  <svg
    width={width}
    height={width}
    version="1.1"
    id="play"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
  >
    <polygon fill={fill} points="14.1,10.2 8.4,14.5 8.4,5.5 	" />
    <path
      fill={fill}
      d="M10,0.4c-5.3,0-9.6,4.3-9.6,9.6c0,5.3,4.3,9.6,9.6,9.6c5.3,0,9.6-4.3,9.6-9.6C19.6,4.7,15.3,0.4,10,0.4z M10,17.7
            c-4.3,0-7.7-3.4-7.7-7.7c0-4.3,3.4-7.7,7.7-7.7c4.3,0,7.7,3.4,7.7,7.7C17.7,14.3,14.3,17.7,10,17.7z"
    />
  </svg>
);

export const PauseAudioIcon = ({ fill = "#5ec162", width = "22" }) => (
  <svg
    width={width}
    height={width}
    version="1.1"
    id="play"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
  >
    <path
      fill={fill}
      d="M10,0.4c-5.3,0-9.6,4.3-9.6,9.6c0,5.3,4.3,9.6,9.6,9.6c5.3,0,9.6-4.3,9.6-9.6C19.6,4.7,15.3,0.4,10,0.4z M10,17.7
            c-4.3,0-7.7-3.4-7.7-7.7c0-4.3,3.4-7.7,7.7-7.7c4.3,0,7.7,3.4,7.7,7.7C17.7,14.3,14.3,17.7,10,17.7z"
    />
    <rect fill={fill} x="7.3" y="5.5" width="1.8" height="9" />
    <rect fill={fill} x="11.1" y="5.5" width="1.8" height="9" />
  </svg>
);

export const TrashIcon = ({
  fill = "#d32f2f",
  width = "21",
  height = "30",
}) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    id="trash"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
  >
    <polygon
      fill={fill}
      points="16.8,2.2 13.4,2.2 12.4,1.3 7.6,1.3 6.6,2.2 3.2,2.2 3.2,4.2 16.8,4.2 	"
    />
    <path
      fill={fill}
      d="M14.8,18.7H5c-0.5,0-0.9-0.4-0.9-0.9V5.1h11.7v12.6C15.8,18.3,15.4,18.7,14.8,18.7z"
    />
  </svg>
);

export const InfoIcon = ({ fill = "#212529", width = "20", height = "20" }) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="info"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
  >
    <path
      d="M10.1,0.4c-5.3,0-9.7,4.2-9.7,9.5c0,5.3,4.3,9.6,9.6,9.6c5.3,0,9.6-4.3,9.6-9.5C19.6,4.7,15.4,0.4,10.1,0.4z M10,17.7
            c-4.2,0-7.7-3.4-7.7-7.7c0-4.2,3.5-7.7,7.6-7.7c4.3,0,7.8,3.4,7.8,7.7C17.7,14.2,14.2,17.6,10,17.7z"
    />
    <path
      d="M11,14.2c0,0.4-0.1,0.5-0.5,0.5c-1.4,0-1.4,0-1.4-1.3v-1.6c0-0.8,0-1.6,0-2.3C9,9.1,9.2,9,9.6,9c1.4,0,1.4,0,1.4,1.4
            C10.9,11.7,10.9,13,11,14.2z"
    />
    <path d="M9.8,7.1C8.9,7.1,9,7.3,9,6.2c0-1,0-1,1-1c0.9,0,0.9,0,0.9,1.1C10.9,7.3,11.1,7.1,9.8,7.1z" />
  </svg>
);

export const SingleArrowsIcon = ({
  fill = "#0000008f",
  width = "20",
  height = "18",
}) => (
  <svg
    version="1.1"
    id="single-arrows"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 36.98 23.45"
  >
    <polygon
      fill={fill}
      points="35.54,5.21 22.49,18.25 18.48,22.26 14.48,18.26 1.44,5.21 5.44,1.21 18.49,14.25 31.53,1.2 "
    />
  </svg>
);
