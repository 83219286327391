import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { useT }             from '@transifex/react';

import IconArrows           from './images/arrows.svg';

import './style.css';

/**
 * @class /src/scenes/ShowManagement/scenes/Tickets/components/List/List
 */
class List extends Component {
    /**
     * Renders list's body cell.
     *
     * @param {*} cellData
     * @param {Number} rowIndex
     * @param {Number} cellIndex
     * @param {String} id
     *
     * @returns {XML}
     */
    renderTableCell = (cellData, rowIndex, cellIndex, id) => {
        return (
            (typeof cellData !== 'object') &&
            <td key={rowIndex + '-' + cellIndex} className={['', cellIndex === 0 && 'barcode'].join('')} title={cellData}>
                {cellData}
            </td>

        );
    };

    /**
     * Renders list body.
     *
     * @param {Object} data
     *
     * @returns {any[]}
     */
    renderBody = (data) => {
        return data && Object.entries(data).map((rowData, rowIndex) =>
            <tr key={rowData[0]} onClick={() => this.props.ticketDetails(rowData)}>
                {
                    rowData[1].map((cellData, cellIndex) =>
                        this.renderTableCell(cellData, rowIndex, cellIndex, rowData[0])
                    )
                }
            </tr>
        )
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <table className="List table table-striped">
                <thead>
                    <tr>
                        <RenderHeadRow />
                    </tr>
                </thead>

                <tbody>
                    {this.renderBody(this.props.data)}
                </tbody>
            </table>
        );
    }
};

/**
 * Renders list head row.
 *
 * @returns {any[]}
 */
const RenderHeadRow = () => {
    const t = useT();

    const barcode = t("Barcode");
    const validity = t("Validity");
    const firstName = t("First Name");
    const lastName = t("Last Name");
    const salutation = t("Salutation");
    const ticketUsageLimit = t("Limit");

    let ticketColumns = {
        [barcode]: { 'subtitle': null, 'sortable': true, 'fieldValue': 'barcode' },
        [validity]: { 'subtitle': null, 'sortable': false, 'fieldValue': 'validity' },
        [firstName]: { 'subtitle': null, 'sortable': true, 'fieldValue': 'personalisedTicketFirstName' },
        [lastName]: { 'subtitle': null, 'sortable': true, 'fieldValue': 'personalisedTicketLastName' },
        [salutation]: { 'subtitle': null, 'sortable': true, 'fieldValue': 'personalisedTicketSalutation' },
        [ticketUsageLimit]: { 'subtitle': null, 'sortable': false, 'fieldValue': 'ticketUsageLimit' },
    };

    return Object.entries(ticketColumns).map((column, index) =>
        <th key={index}>{column[0]}
            {
                column[1].subtitle !== null ? <span className="grayed">{column[1].subtitle}</span> : null
            }
            {
                column[1].sortable && <img className="pull-right" src={IconArrows} alt="Arrows" onClick={() => this.props.sort(column[1].fieldValue)} />
            }
        </th>
    )
};

List.propTypes = {
    ticketDetails: PropTypes.func.isRequired
};

export default List;