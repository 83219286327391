export const isFilterEmpty = (filters) =>
{
    for(let level in filters) {
        if(filters.hasOwnProperty(level)) {
            if (!!filters[level]['value'].length) {
                return false;
            }
        }
    }
    return true;
};

export const getNumberOfLevelsInFilters = (filters) => {
    let levels = 0;
    for(let level in filters) {
        if(filters.hasOwnProperty(level))
            if (filters[level]['value'].length) {
                levels++;
            }
    };
    return levels;
};

export const filterConvert = (filters, uid) => {
    let value = '';
    let isForced = {};

    Object.entries(filters).map(filterRow => {
        // Converting isForced
        isForced = {
            ...isForced,
            [filterRow[0]] : filterRow[1].isForced
        };
        // Converting value
        filterRow[1]['value'].map((filter,index) => {
            value += 
                (index < (filterRow[1]['value'].length-1))
                    ?	(filter + ' && ')
                    :	filter;
            return null;
        });
        value += ' || ';
        return null;
    });
    
    return {
        'value' : value.substr(0,(value.length - 4)),
        'uid' : uid ? uid : null,
        'isForced' : isForced
    };
};
