import React, { Component, createRef } from 'react';
import { SidebarMenu }      from './components/SidebarMenu';
import PropTypes            from 'prop-types';
import { NavLink }          from 'react-router-dom';
import { VenueService }     from '../../../../../services/api/Venue';
import { ShowService }      from '../../../../../services/api/Show';

import IconBack             from './images/ico-back.svg';

import './style.css';

/**
 * @class themes/default/Layout/components/Sidebar
 */
class Sidebar extends Component
{
    state = {
        selectedMenuItem : ''
    };

    /**
     * Constructor.
     *
     * @param {Object} props
     */
    constructor(props) {
        super(props);
        this.sidebarRef = createRef();
        this.venueService = new VenueService();
        this.showService = new ShowService();
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClick)
    };

    handleClick = (e) => {
        e.target.getAttribute('name') === 'blur-background' && this.props.handleMouseClick &&
            this.props.handleMouseClick();
    };

    /**
     * @override
     */
    UNSAFE_componentWillMount()
    {
        this.setState({
            selectedMenuItem : (this.capitalizeWord(this.props.section))
        });
    }

    /**
     * Returns capitalized word.
     *
     * @param {String} word
     */
    capitalizeWord = (word) =>
    {
        return word.charAt(0).toUpperCase() + word.slice(1);
    };

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClick)
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <div ref={this.sidebarRef} className={`Sidebar ${this.props.show ? "openSidebar" : "closeSidebar" && !this.props.show ? "hide" : ""} ${this.props.section === 'Dashboard' ? 'dashboard-sidebar' : ''}`}>
                
                {/* Renders collapsed menu section according to selected menu item. */}
                <div className="main-menu text-uppercase collapsed-with-title">
                    {
                        this.props.section !== 'Dashboard' && <NavLink exact to={this.props.section !== "venues" ? ("/" + this.props.section) : "/"}>
                            <img src={IconBack} alt="Back"/>
                            {this.state.selectedMenuItem}
                        </NavLink>
                    }
                </div>

                {/* Renders menu items section according to selected menu item. */}
                {
                    this.state.selectedMenuItem !== '' &&
                        <SidebarMenu
                            id={this.props.selectedId}
                            section={this.props.section}
                            sidebarNavigationMenuItems={this.props.sidebarNavigationMenuItems[this.state.selectedMenuItem]['items']}
                        />
                }

                {
                    this.props.isMobileView && this.props.section !== "Dashboard" &&
                        Object.entries(this.props.sidebarNavigationMenuItems['Dashboard']['items']).map((menuItem,index) =>
                        
                        this.capitalizeWord(this.props.section) !== menuItem[0] &&
                            <div key={index} className="main-menu text-uppercase collapsed-with-title extra-tabs-mobile">
                                <NavLink exact to={menuItem[1].href}>
                                    {menuItem[0]}
                                </NavLink>
                            </div>    
                    )
                }

            </div>
        );
    }
}

Sidebar.propTypes = {
    show : PropTypes.bool.isRequired,
    sidebarNavigationMenuItems : PropTypes.object.isRequired,
    selectedId : PropTypes.string.isRequired,
    section : PropTypes.string.isRequired,
    handleMouseClick: PropTypes.func.isRequired
};

export default Sidebar;
