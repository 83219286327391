import React     from 'react';
import { Input } from '../Input';
import PropTypes from 'prop-types';

import './style.css';

const Email = ({ label, name, type, className, value, required, readOnly, disabled, placeholder, onChangeHandler, maxLength }) => 
(
    <div>
        <Input
            label={ label }
            name={ name }
            type={ type }
            className={ className }
            value={ value }
            required={ required }
            readOnly={ readOnly }
            disabled={ disabled }
            placeholder={ placeholder }
            onChangeHandler={ onChangeHandler }
            maxLength={ maxLength }/>
    </div>
);

Email.defaultProps = {
    type: 'email',
    placeholder: 'Email',
    name: '',
    label: '',
    required : false,
    readOnly : false,
    disabled : false,
    maxLength : '',
    className : ''
};

Email.propTypes = {
    label : PropTypes.string,
    name : PropTypes.string.isRequired,
    required : PropTypes.bool,
    readOnly : PropTypes.bool,
    disabled : PropTypes.bool,
    placeholder : PropTypes.string.isRequired,
    className : PropTypes.string
};

export default Email;