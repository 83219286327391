import React     from 'react';
import PropTypes from 'prop-types';
import Radio     from '../Radio/Radio';

import './style.css';

const RadioGroup = ({ name, getValue, className, labels, defaultValue, disabled, disabledValues, readOnly, split, changeSelectedHandler}) => 
(
    <fieldset id={ name } onChange={ getValue } className={ ['RadioGroup', className].join(' ') }>
        {
            Object.entries(labels).map((label, key) => 
            (
                <Radio
                    key={ key }
                    name={ name }
                    defaultValue={ defaultValue }
                    className={ className }
                    disabled={ disabled ? disabled : disabledValues === label[0] }
                    readOnly={ readOnly }
                    value={ label[0] }
                    label={ label[1] }
                    split={split}
                    changeSelectedHandler={e => changeSelectedHandler && changeSelectedHandler(e)}
                />
            ))
        }
    </fieldset>
);

RadioGroup.propTypes = {
    name: PropTypes.string.isRequired,
    disabledValues : PropTypes.string,
    readOnly : PropTypes.bool,
    defaultValue : PropTypes.string,
    disabled : PropTypes.bool,
    className : PropTypes.string,
    labels : PropTypes.object.isRequired,
    getValue : PropTypes.func.isRequired
};

export default RadioGroup;