import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AudioPanel from "./AudioPanel";
import { useT } from "@transifex/react";
import { VenueService } from "../../../../../services/api/Venue";
import Rule from "../../../../../components/Rule/Rule";
import { DialogError } from "../../../../../themes/default/Dialog";
import { ResponseService } from "../../../../../services/utils/Response";
import Panel from "../../../../../themes/default/Layout/components/Panel/Panel";

/**
 * @class components/Form/Form
 */
class ActionPanel extends Component {
  state = {
    tagValue: "",
    showErrorDialog: false,
    errorMessages: [],
  };

  /**
   * @type {ResponseService}
   */
  responseService;

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.venueService = new VenueService();
    this.responseService = new ResponseService();
    this.getTagValue();
  }

  /**
   * Parses response and set string value of filter ready to be render.
   */
  getTagValue = () => {
    let filters = "";

    this.props.tagValue.map((andRules, index) => {
      andRules.map((orRule, index) => {
        let operator = "";
        switch (orRule.operator) {
          case "equal":
            operator = "=";
            break;
          case "notEqual":
            operator = "!=";
            break;
          case "less":
            operator = "<";
            break;
          case "greater":
            operator = ">";
            break;
          case "lessEqual":
            operator = "<=";
            break;
          case "greaterEqual":
            operator = ">=";
            break;
          case "contains":
            operator = "@=";
            break;
          case "notContains":
            operator = "!@=";
            break;
          default:
            operator = "=";
        }
        if (andRules.length - 1 === index) {
          filters += orRule.field + " " + operator + " " + orRule.operand;
        } else {
          filters +=
            orRule.field + " " + operator + " " + orRule.operand + " && ";
        }

        return true;
      });

      if (this.props.tagValue.length - 1 > index) {
        filters += " || ";
      }

      return true;
    });

    this.state.tagValue = filters;
  };

  /**
   * Generates Action panel title.
   *
   * @returns {String}
   */
  getPanelTitle = () => {
    let orderForPrint = parseInt(this.props.order, 10) + 1;

    return orderForPrint + ". Action: " + this.props.title;
  };

  render() {
    return (
      <Panel
        colorize
        draggable
        className="remove edit action-controls"
        panelId={this.props.order}
        title={this.getPanelTitle()}
        removeHandler={this.props.removeHandler}
        editHandler={this.props.editHandler}
      >
        <AudioPanel
          actionFilter={this.props.actionFilter}
          onActionFilterChanged={this.props.onActionFilterChanged}
          panelId={this.props.panelId}
        />
        <RuleWrapper
          keys={this.props.keys}
          uid={this.props.panelId}
          venueId={this.props.venueId}
          tagValue={this.state.tagValue}
          venueService={this.venueService}
          getValue={this.props.getTagValue}
          errorHandler={this.props.errorHandler}
          errorMessages={this.state.errorMessages}
          setState={(data) =>
            this.setState({
              errorMessages: data.errorMessages,
              showErrorDialog: data.showErrorDialog,
            })
          }
        />

        {/* Renders error diagram if an error occur. */}
        <DialogError
          show={this.state.showErrorDialog}
          closeDialog={() => this.setState({ showErrorDialog: false })}
        >
          {this.state.errorMessages.map((message, index) => {
            return <p key={index}>{message}</p>;
          })}
        </DialogError>
      </Panel>
    );
  }
}

const RuleWrapper = ({
  getValue,
  uid,
  tagValue,
  errorHandler,
  keys,
  venueId,
}) => {
  const t = useT();
  const [config, setConfig] = useState(null);

  useEffect(() => {
    keys &&
      setConfig({
        keys: keys,
        operations: {
          "=": "=",
          "!=": "!=",
          ">": ">",
          "<": "<",
          ">=": ">=",
          "<=": "<=",
          "@=": "@=",
          "!@=": "!@=",
        },
        buttonTitle: t("Add first filter item"),
        dialogTitle: t("Create new action"),
        value: tagValue,
        venueId: venueId,
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="row">
      <div className="col-lg-12">
        {config && (
          <Rule
            uid={uid}
            config={config}
            getValue={getValue}
            errorHandler={errorHandler}
          />
        )}
      </div>
    </div>
  );
};

ActionPanel.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  removeHandler: PropTypes.func,
  panelId: PropTypes.string,
  errorHandler: PropTypes.func.isRequired,
  configs: PropTypes.object,
  actionList: PropTypes.object,
  actionFilter: PropTypes.object,
  onActionFilterChanged: PropTypes.func,
};

export default ActionPanel;
