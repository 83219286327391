import moment from 'moment';
import { DateTimeService } from '../../../services/utils/DateTime';

/**
 * @class scenes/DeviceManagement/services/List
 */
class List
{
    /**
     * @type {Object}
     */
    dateTimeService;

    /**
     * @override
     */
    constructor ()
    {
        this.dateTimeService = new DateTimeService();
    }

    /**
     * Returns adapted data for device management list.
     *
     * @param {Array} responseData
     *
     * @returns {Array}
     */
    getDeviceManagementData = (responseData) =>
    {
        let deviceManagementData = [];

        responseData.length !== 0 && responseData.map(device => {
            let deviceData = {};

            deviceData['id'] = device.id;
            deviceData['name'] = device.name;
            deviceData['model'] = device.deviceModel;
            deviceData['battery'] = {
                data: device.state.batteryLevel,
                className: "battery-cell"
            };
            deviceData['lastStatusReport'] = device.state.lastStatusReport;
            if (device.state.lastStatusReport) {
                deviceData['connection'] = this.dateTimeService.replaceYearAndDate(this.dateTimeService.getLocalDateTime(device.state.lastStatusReport));
            }
            deviceData['venueId'] = device.venueId;
            deviceData['stagingStatus'] = device.stagingStatus;
            deviceData['isBYOD'] = device.isBYOD;
            deviceData['venueName'] = device.venueName;
            deviceData['enroledAt'] = device.createdAt;
            deviceData['cloneFrom'] = device.sourceHandheldName;
            deviceData['promoterName'] = device.promoterId;
            deviceData['clonedFromDeviceId'] = device.sourceHandheldId;
            deviceData['uniqueIdentifier'] = device.deviceUniqueIdentifier;
            deviceData['promoterId'] = device.promoterId;
            deviceData['pushToken'] = device.pushToken;
            deviceData['imei'] = device.imei;
            deviceData['enrollmentCode'] = device.enrollmentCode;
            deviceData['appVersion'] = device.state.appVersion;

            deviceManagementData.push(deviceData);

            return true;
        });

        return deviceManagementData;
    };
}

export default List;