import React       from 'react';
import PropTypes   from 'prop-types';
import { NavLink } from 'react-router-dom';

import './style.css';

const TopNavigationMenu = ({ topNavigationMenuItems, isHouseButtonClicked, showSidebar}) =>
(
    <div className="TopNavigationMenu col-10 text-uppercase text-left p-0">
        <nav>
            <ul>
                {Object.entries(topNavigationMenuItems).map((menuItem,index) =>
                    (
                        <li key={index} className={`${menuItem[1].dropdown !== null ? 'drop' : '' } ${(index === 0 && !showSidebar) ? 'no-hamburger' : ""}`}>
                            <NavLink exact to={menuItem[1].href} activeClassName="active" onClick={isHouseButtonClicked}>
                                <img src={require('' + menuItem[1].icon.path + '')} alt={menuItem[1].icon.alt} className="nav-icon" />
                                {menuItem[0]}
                            </NavLink>
                            {menuItem[1].dropdown !== null &&
                                <ul className="drop-menu">
                                    {
                                        Object.entries(menuItem[1].dropdown).map((subMenuItem,index) =>
                                        (
                                            <li key={index}>
                                                <a href={subMenuItem[1].href}>{subMenuItem[0]}</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                        </li>
                    ))
                }
            </ul>
        </nav>
    </div>
);

TopNavigationMenu.propTypes = {
    showSidebar : PropTypes.bool,
    isHouseButtonClicked : PropTypes.func,
    topNavigationMenuItems : PropTypes.object.isRequired
};

export default TopNavigationMenu;