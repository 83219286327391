import {
    ValidatorService,
    DateTime,
    NotBlank,
    Time,
    MinLength
} from 'pretty-validator';

/**
 * @class scenes/ShowManagement/scenes/Details/services/Validator/Validator
 */
class Validator {
    /**
     * @type {Object}
     */
    validatorService;

    /**
     * @override
     */
    constructor ()
    {
        this.validatorService = new ValidatorService()
    }

    /**
     * Calls field validator depends on field name.
     *
     * @param {String} value
     * @param {String} name
     *
     * @returns {Array}
     */
    validateField = (value, name) => {
        let errors;

        switch (name) {
            case 'name':
                errors = this.validateNameField(value);
                break;
            case 'print-title':
                errors = this.validatePrintTitleField(value);
                break;
            case 'begin':
                errors = this.validateBeginField(value);
                break;
            case 'end':
                errors = this.validateEndField(value);
                break;
            case 'doors-open-at':
                errors = this.validateDoorsOpenField(value);
                break;
            case 'allow-reenter-after':
                errors = this.validateReenterAfterField(value);
                break;
            case 'grace-period':
                errors = this.validateGracePeriodField(value);
                break;
            default:
                errors = [];
        }

        return errors;
    };

    /**
     * Validates Name Field.
     *
     * @param {String} value
     *
     * @returns {ActiveX.IXMLDOMParseError}
     */
    validateNameField = (value) =>
    {
        return this.validatorService.validate(value, [
            new NotBlank(), new MinLength(3)
        ]);
    };

    /**
     * Validates Name Field.
     *
     * @param {String} value
     *
     * @returns {ActiveX.IXMLDOMParseError}
     */
    validatePrintTitleField = (value) =>
    {
        return this.validatorService.validate(value, [
            new NotBlank(), new MinLength(3)
        ]);
    };

    /**
     * Validates End Date Field.
     *
     * @param {String} value
     *
     * @returns {ActiveX.IXMLDOMParseError}
     */
    validateEndField = (value) =>
    {
        return this.validatorService.validate(value, [
            new DateTime()
        ]);
    };

    /**
     * Validates Begin date filed.
     *
     * @param {String} value
     *
     * @returns {ActiveX.IXMLDOMParseError}
     */
    validateBeginField = (value) =>
    {
        return this.validatorService.validate(value, [
            new DateTime()
        ]);
    };

    /**
     * Validates Doors Open filed.
     *
     * @param {String} value
     *
     * @returns {Array}
     */
    validateDoorsOpenField = (value) =>
    {
        let errors = [];

        if (value) {
            errors = this.validatorService.validate(value, [
                new Time()
            ]);
        }

        return errors;
    };

    /**
     * Validates Grace Period filed.
     *
     * @param {String} value
     *
     * @returns {Array}
     */
    validateGracePeriodField = (value) =>
    {
        let errors = [];

        if (value) {
            errors = this.validatorService.validate(value, [
                new Time()
            ]);
        }

        return errors;
    };

    /**
     * Validates Reenter After filed.
     *
     * @param {String} value
     *
     * @returns {ActiveX.IXMLDOMParseError}
     */
    validateReenterAfterField = (value) =>
    {
        let constraints = [ new NotBlank('You must enter time') ];

        if (value) {
            constraints.push(  new Time() );
        }

        return this.validatorService.validate(value, constraints);
    };
}

export default Validator;