import React, { useState } from 'react';
import PropTypes           from 'prop-types';
import { Header }          from './components/Header';
import { Sidebar }         from './components/Sidebar';
import { Footer }          from './components/Footer';

import './style.css';

const Layout = ({ loggedUser, section, selectedId, sidebarNavigationMenuItems, topNavigationMenuItems, children, isMobileView, selectedShow, selectedVenue }) =>
{
    const [showSidebar, setShowSidebar ]= useState(!isMobileView);
    
    return (
        <div className="Layout">
            <Header
                loggedUser={loggedUser}
                showSidebar={showSidebar}
                isMobileView={isMobileView}
                topNavigationMenuItems={topNavigationMenuItems}
                toggleSidebar={() => setShowSidebar(!showSidebar)}
            />
            <div className="col-12 p-0 wrapper">
                <Sidebar
                    section={section}
                    show={showSidebar}
                    selectedId={selectedId}
                    selectedShow={selectedShow}
                    isMobileView={isMobileView}
                    selectedVenue={selectedVenue}
                    handleMouseClick={() => setShowSidebar(false)}
                    sidebarNavigationMenuItems={sidebarNavigationMenuItems}
                />
                <div className={`Content ${!showSidebar ? "full-width" : "" }`} >
                    {children}
                </div>
            </div>
            <Footer />
            {isMobileView && showSidebar && <div className="blur-background" name="blur-background"/>}
        </div>
    );
}

Layout.propTypes = {
    topNavigationMenuItems : PropTypes.object.isRequired,
    sidebarNavigationMenuItems : PropTypes.object.isRequired,
    selectedId : PropTypes.string.isRequired,
    section : PropTypes.string.isRequired
};

export default Layout;