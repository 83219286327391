import axios from 'axios/index';

/**
 * @class ./services/api/Ticket/Ticket
 */
class Ticket
{

    /**
     * Returns tickets for given show Id.
     *
     * @param {String} showId
     * @param {Object} paramObject
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *     {
     *       "barcode": "string",
     *       "validity": "valid",
     *       "salutation": "string",
     *       "customProperties": {},
     *       "history": [
     *         {
     *           "handheldId": "string",
     *           "venueId": "string",
     *           "checkpoint": {
     *             "name": "string",
     *             "from": "string",
     *             "to": "string",
     *             "permissionCheckOnly": true
     *           },
     *           "time": "2018-03-01T13:28:30.900Z",
     *           "result": "success"
     *         }
     *       ]
     *     }
     *   ],
     *   "resultCount": 0,
     *   "totalResultCount": 0,
     *   "pageSize": 0,
     *   "currentPage": 0,
     *   "totalPageCount": 0
     * }
     *
     */
    getAllForShow = (showId, paramObject) =>
    {
        return axios.get('/shows/' + encodeURIComponent(showId) + '/tickets',
            {
                params: paramObject
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Creates one ticket for given showId.
     *
     * @param {String} showId
     * @param {Object} ticket
     *
     * @returns {Object}
     *
     * @example Request/Response:
     *     {
     *       "barcode": "string",
     *       "validity": "valid",
     *       "salutation": "string",
     *       "customProperties": {
     *          "additionalProp1": "string"
     *          "additionalProp2": "string"
     *          "additionalProp3": "string"
     *       },
     *       "history": [
     *         {
     *           "handheldId": "string",
     *           "venueId": "string",
     *           "checkpoint": {
     *             "name": "string",
     *             "from": "string",
     *             "to": "string",
     *             "permissionCheckOnly": true
     *           },
     *           "time": "2018-03-01T13:28:30.900Z",
     *           "result": "success"
     *         }
     *       ]
     *     }
     *
     */
    createOneForShow = (showId, ticket) =>
    {
        return axios.post('/shows/' + encodeURIComponent(showId) + '/tickets',
            ticket
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns one ticket for the given show Id and barcode.
     *
     * @param {String} showId
     * @param {String} barcode
     *
     * @returns {Object}
     *
     * @example Response:
     *     {
     *       "barcode": "string",
     *       "validity": "valid",
     *       "salutation": "string",
     *       "customProperties": {
     *          "additionalProp1": "string"
     *          "additionalProp2": "string"
     *          "additionalProp3": "string"
     *       },
     *       "history": [
     *         {
     *           "handheldId": "string",
     *           "venueId": "string",
     *           "checkpoint": {
     *             "name": "string",
     *             "from": "string",
     *             "to": "string",
     *             "permissionCheckOnly": true
     *           },
     *           "time": "2018-03-01T13:28:30.900Z",
     *           "result": "success"
     *         }
     *       ]
     *     }
     *
     */
    getOne = (showId, barcode) =>
    {
        return axios.get('/shows/' + encodeURIComponent(showId) + '/tickets/' + encodeURIComponent(barcode))
            .then(response => response.data)
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Updates one ticket for the given show Id and barcode.
     *
     * @param {String} showId
     * @param {String} barcode
     * @param {Object} ticket
     *
     * @returns {Object}
     *
     * @example Request:
     *     {
     *       "barcode": "string",
     *       "validity": "valid",
     *       "salutation": "string",
     *       "customProperties": {
     *          "additionalProp1": "string"
     *          "additionalProp2": "string"
     *          "additionalProp3": "string"
     *       },
     *       "history": [
     *         {
     *           "handheldId": "string",
     *           "venueId": "string",
     *           "checkpoint": {
     *             "name": "string",
     *             "from": "string",
     *             "to": "string",
     *             "permissionCheckOnly": true
     *           },
     *           "time": "2018-03-01T13:28:30.900Z",
     *           "result": "success"
     *         }
     *       ]
     *     }
     *
     */
    updateOne = (showId, barcode, ticket) =>
    {
        return axios.put('/shows/' + encodeURIComponent(showId) + '/tickets',
            ticket,
            {
                params: {barcode: barcode}
            }
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            }
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns an array of all properties that can be used as "field" for ticket search.
     *
     *
     * @returns {Object}
     *
     * @example Response:
     * [
     *  "string"
     * ]
     *
     */
     getQueryableFields = () =>
     {
         return axios.get('/tickets/queryablefields')
             .then(response => {
                 return {
                     status: response.status,
                     data: response.data
                 };
             })
             .catch(error => {
                     return {
                         status: error.response.status,
                         data: error.response.data
                     };
                 }
             );
     };

}

export default Ticket;
