import React                    from 'react';
import { ThemeLayoutDashboard } from '../../themes/default/Layout';

import './style.css';

const LayoutDashboard = ({children , isMobileView, loggedUser, section}) =>
{
    const topNavigationMenuItems = {
        Venues : {
            href : '/',
            icon : {
                path : './images/home.svg',
                alt : 'Home'
            },
            dropdown : null
        }
    };

    const sidebarNavigationMenuItems = {
        Dashboard : {
            items : {
                Venues : {
                    href : '/',
                    number : null
                },
                Shows : {
                    href : '/shows',
                    number : null
                },
                Devices : {
                    href : '/devices',
                    number : null
                },
                Providers : {
                    href : '/providers',
                    number : null
                }
            }
        },
        Venues : {
            items : {
                'Venue Dashboard' : {
                    href : '/venues/dashboard',
                    number : null
                },
                Shows : {
                    href : '/venues/shows',
                    number : ''
                },
                'Zones & Checkpoints' : {
                    href : '/venues/zones-and-checkpoints',
                    number : null
                },
                Devices : {
                    href : '/venues/devices',
                    number : ''
                },
                Actions : {
                    href : '/venues/actions',
                    number : null
                },
                Logs : {
                    href : '/venues/logs',
                    number : null
                }
            }
        },
        Shows : {
            items : {
                'Show Details' : {
                    href : '/shows/details',
                    number : null
                },
                'Show Properties' : {
                    href : '/shows/properties',
                    number : null
                },
                'Show Tickets' : {
                    href : '/shows/tickets',
                    number : null
                }
            }
        },
        Usersmanagement : {
            items : {}
        }
    }

    return (
        <ThemeLayoutDashboard
            section={section}
            isMobileView={isMobileView}
            loggedUser={loggedUser}
            topNavigationMenuItems={topNavigationMenuItems}
            sidebarNavigationMenuItems={sidebarNavigationMenuItems}
        >
            {React.cloneElement(children, { loggedUser: loggedUser })}
        </ThemeLayoutDashboard>
    );
}

export default LayoutDashboard;