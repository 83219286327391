import React      from 'react';
import PropTypes  from 'prop-types';
import { T }      from '@transifex/react';
import Dialog     from '../../../../themes/default/Dialog/Dialog';

const ConfirmationDialog = ({showDeleteConfirmationDialog, deleteProvider, closeDeleteConfirmationDialog, providerData }) =>
(
    <Dialog
        mainButton={<T _str="confirm"/>}
        title={<T _str="Confirmation dialog"/>}
        showModal={showDeleteConfirmationDialog}
        action={() => deleteProvider() }
        closeModal={closeDeleteConfirmationDialog}
    >
        <h4 className="text-left mb-4"><T _str="Are you sure you want to delete this provider?"/></h4>

        <div className="row">
            <div className="col-3"><T _str="Name"/></div>
            <div className="col-9">{providerData.name.cellData}</div>
        </div>
        <div className="row mb-2">
            <div className="col-3"><T _str="Client Id"/></div>
            <div className="col-9">{providerData.clientId }</div>
        </div>
    </Dialog>     
);

ConfirmationDialog.propTypes = {
    providerData:  PropTypes.object,
    deleteProvider:  PropTypes.func,
    showDeleteConfirmationDialog:  PropTypes.bool,
    closeDeleteConfirmationDialog:  PropTypes.func,
};

export default ConfirmationDialog;