const STAGED_HANDHELD_VALUE = 'staged';

/**
 * @class ./scenes/VenueManagement/scenes/VenueDashboard/services/Matrix/Matrix
 */
class Matrix
{
    /**
     * Checks if handheld is assigned to the certain checkpoint.
     *
     * @param {String} status
     * @param {Object} handheldCheckpoint
     * @param {String} checkpointName
     *
     * @returns {Boolean}
     */
    isHandheldAssignedToCheckpoint = (status,handheldCheckpoint,checkpointName) =>
    {
        let isHandheldAssignedToCheckpoint = false;

        let isStaged = status === STAGED_HANDHELD_VALUE;
        let isReportedValue = (handheldCheckpoint.latestAdminValueApplied && handheldCheckpoint.reportedValue === checkpointName);
        let isRequestedValue = (!handheldCheckpoint.latestAdminValueApplied && handheldCheckpoint.adminRequestedValue === checkpointName);

        if (isStaged && (isReportedValue || isRequestedValue)) {
            isHandheldAssignedToCheckpoint = true;
        }

        return isHandheldAssignedToCheckpoint;
    };

    /**
     * Returns handheld status.
     *
     * @param {Object} handheldShow
     *
     * @returns {Object}
     */
    getHandheldStatuses = (handheldShow) =>
    {
        let isChecked = false;
        let isForced = false;
        let isPendingIconShown = false;

        if (handheldShow.forcedActive === true) {
            isChecked = true;
            isForced = true;
        } else {
            if (handheldShow.latestAdminValueApplied === true) {
                isChecked = handheldShow.reportedValue;
            } else {
                isChecked = handheldShow.adminRequestedValue;
                isPendingIconShown = true;
            }
        }

        return {
            isChecked          : isChecked,
            isForced           : isForced,
            isPendingIconShown : isPendingIconShown
        };
    };

    /**
     * Checks if all devices for certain checkpoint are turnned on.
     *
     * @param {Array} shows
     * @param {Object} checkpoints
     * @param {String} checkpointName
     */
    getCollapsedCheckpointShowsStatuses = (shows, checkpoints, checkpointName) => {
        const checkpointShowsStatuses = [];
      
        shows.forEach((show, index) => {
            const checkpointShowStatus = {
                [show.id]: {
                    status: [],
                    forcedActive: show.forcedActive,
                    checkpoint: checkpointName
                }
            };
        
            checkpoints.forEach(checkpoint => {
                if (checkpoint.name === checkpointName) {
                    checkpoint.devices.forEach(device => {
                        device.switches[index] &&
                            checkpointShowStatus[show.id].status.push(device.switches[index].isChecked);
                    });
                }
            });
        
            const checkpointHasFalseStatus = checkpointShowStatus[show.id].status.includes(false);
            const checkpointHasTrueStatus = checkpointShowStatus[show.id].status.includes(true);
        
            checkpointShowStatus[show.id].status = !checkpointHasFalseStatus;
            checkpointShowStatus[show.id].mixedStatus = checkpointHasFalseStatus && checkpointHasTrueStatus;
        
            checkpointShowsStatuses.push(checkpointShowStatus);
        });
      
        return checkpointShowsStatuses;
    };

    /**
     * Returns dashboard matrix shows array.
     *
     * @param {Array} shows
     *
     * @returns {Array}
     */
    getMatrixShows = (shows) =>
    {
        let matrixShows = [];
        shows.map(show => {
            let showObject = {
                id    : show.id,
                name  : show.name,
                begin : show.begin
            };
            matrixShows.push(showObject);

            return true;
        });

        return matrixShows;
    };

/**
 * Returns JSON data for venue dashboard matrix.
 *
 * @param {Object} venue
 * @param {Object} shows
 * @param {Object} checkpoints
 *
 * @returns {Object}
 */
    getDashboardMatrixData = (venue, shows, checkpoints) => {
        const matrixData = {
            shows: this.getMatrixShows(shows),
            checkpoints: [],
            totalNumberOfDevices: 0,
            checkpointsStatuses: {},
        };
    
        checkpoints.forEach((checkpoint) => {
            const checkpointObject = {
                name: checkpoint.name,
                devices: [],
            };
        
            if (venue.handhelds) {
                Object.entries(venue.handhelds).forEach(([handheldId, handheld]) => {
                    if (this.isHandheldAssignedToCheckpoint(handheld.stagingStatus, handheld.checkpoint, checkpoint.name)) {
                        const handheldObject = {
                            batteryLevel: handheld.batteryLevel,
                            wifiSignal: handheld.wifiSignal,
                            name: handheld.name,
                            id: handheldId,
                            switches: [],
                        };
            
                        shows.forEach((show) => {
                            Object.entries(handheld.shows).forEach(([deviceId, deviceShow]) => {
                                if (deviceId === show.id) {
                                    const switchObject = {
                                        isChecked: this.getHandheldStatuses(deviceShow).isChecked,
                                        isForced: this.getHandheldStatuses(deviceShow).isForced,
                                        isPending: this.getHandheldStatuses(deviceShow).isPendingIconShown,
                                        show: show,
                                    };
                                    handheldObject.switches.push(switchObject);
                                }
                            });
                        });
            
                        checkpointObject.devices.push(handheldObject);
                        matrixData.totalNumberOfDevices++;
                    }
                });
            }
        
            matrixData.checkpoints.push(checkpointObject);
        
            matrixData.checkpoints.forEach((matrixCheckpoint) => {
                if (matrixCheckpoint.name === checkpoint.name) {
                    matrixCheckpoint.collapsedDeviceStatuses = this.getCollapsedCheckpointShowsStatuses(
                        shows,
                        matrixData.checkpoints,
                        checkpoint.name
                    );
                }
            });
        });
    
        return matrixData;
    };
}

export default Matrix;
