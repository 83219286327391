/**
 * @class ./services/api/utils/Sort/Sort
 */
class Sort
{
    /**
     * Returns new sorting column value.
     *
     * @param {String} oldColumn
     * @param {String} newColumn
     *
     * @returns {String}
     */
    getSortValue = (oldColumn, newColumn) =>
    {
        let sort;

        switch (oldColumn) {
            case '':
                sort = newColumn;
                break;
            case newColumn:
                sort = '-' + newColumn;
                break;
            case '-' + newColumn:
                sort = '';
                break;
            default:
                sort = newColumn
        }

        return sort
    }
}

export default Sort;