import React     from 'react';
import PropTypes from 'prop-types';
import { useT }  from '@transifex/react';

import './style.css';

const TinyCards = ({ ticketCountsByValidity, totalTicketsCount }) => {
    const t = useT();

    const getValidityStyle = validity => {
        let validityClass = '';

        switch (validity) {
            case 'valid':            
                validityClass = 'custom-text-success';
                break;
            case 'invalid':            
                validityClass = 'custom-text-error';
                break;
            default:
                validityClass = 'custom-text-info';
                break;
        }
        return validityClass;
    }

    return (
        <div className='tiny-cards'>
            {ticketCountsByValidity && Object.entries(ticketCountsByValidity).map((validity, index) => (
                <div key={index} className="tiny-card">
                    <span className='custom-text-small custom-text-capitalize'>{validity[0]}</span>
                    <span className={getValidityStyle(validity[0])}><strong>{validity[1]}</strong></span>
                </div>
            ))}
            <div className="tiny-card">
                <span className='custom-text-small custom-text-capitalize'>{t("total")}</span>
                <span className="custom-text-info"><strong>{totalTicketsCount}</strong></span>
            </div>
        </div>
    );
};

TinyCards.propTypes = {
    ticketCountsByValidity: PropTypes.object.isRequired,
    totalTicketsCount: PropTypes.number.isRequired
};

export default TinyCards;