import React,{ Component }  from 'react';
import PropTypes            from 'prop-types';
import { MatrixCheckpoint } from './components/Checkpoint';

const MATRIX_COLUMN_WIDTH = 150;

/**
 * @class ./scenes/VenueManagement/scenes/VenueDashboard/components/Matrix/components/Checkpoints/Checkpoints
 */
class Checkpoints extends Component
{
    state = {
        expandedCheckpoints : []
    };

    /**
     * Calculates width of the matrix column wrapper.
     */
    getMatrixWrapperWidth = (checkpointName,numberOfDevices) =>
    {
        let wrapperWidth;
        if (this.state.expandedCheckpoints.includes(checkpointName)) {
            wrapperWidth = numberOfDevices * MATRIX_COLUMN_WIDTH + 'px';
        } else {
            wrapperWidth = MATRIX_COLUMN_WIDTH + 'px';
        }

        return {
            'width' : wrapperWidth
        };
    };

    /**
     * Handles checkpoint expand/collapse functionallity.
     *
     * @param {String} checkpointId
     */
    toggleCheckpoint = (checkpointId) =>
    {
        if (!this.state.expandedCheckpoints.includes(checkpointId)) {
            this.setState({
                ...this.state,
                expandedCheckpoints : [...this.state.expandedCheckpoints, checkpointId]
            });
        } else {
            this.setState({
                ...this.state,
                expandedCheckpoints : this.state.expandedCheckpoints.filter(expCheckpoointId => expCheckpoointId !== checkpointId)
            });
        }
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            this.props.checkpoints.map((checkpoint,index) => checkpoint.devices.length > 0 &&
                <div key={index} className="matrix-col-wrapper" style={this.getMatrixWrapperWidth(checkpoint.name,checkpoint.devices.length)}>
                    {checkpoint.devices.map((device,index) => (
                        <MatrixCheckpoint
                            key={index}
                            index={index}
                            device={device}
                            checkpoint={checkpoint}
                            activateShowAtAll={this.props.activateShowAtAll}
                            expandedCheckpoints={this.state.expandedCheckpoints}
                            deactivateShowAtAll={this.props.deactivateShowAtAll}
                            activateOrDeactivateDevice={this.props.activateOrDeactivateDevice}
                            toggleCheckpoint={this.toggleCheckpoint}
                        />
                    ))}
                </div>
            )
        );
    }
}

Checkpoints.propTypes = {
    checkpoints                : PropTypes.array.isRequired,
    activateOrDeactivateDevice : PropTypes.func.isRequired,
    activateShowAtAll          : PropTypes.func.isRequired,
    deactivateShowAtAll        : PropTypes.func.isRequired
};

export default Checkpoints;