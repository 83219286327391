import { Input } from "../Input";
import { useT } from "@transifex/react";

import "./style.css";
import { useId } from "react";

type SearchProps = {
  label: string;
  name?: string;
  type?: string;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  getValue: (value: string) => void;
  maxLength?: number;
  value: string;
};
const Search = ({
  label = "",
  name,
  type = "text",
  className = "",
  required = false,
  readOnly = false,
  disabled = false,
  getValue,
  maxLength,
  value,
}: SearchProps) => {
  const t = useT();
  const search = t("Search");
  var id = useId();

  name = name ?? id;

  return (
    <div className={"Search"}>
      <Input
        label={label}
        name={name}
        type={type}
        className={className}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={search}
        getValue={getValue}
        maxLength={maxLength}
        value={value}
      />
    </div>
  );
};

export default Search;
