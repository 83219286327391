import React, { Component }                 from 'react';
import { Route, Switch, Redirect }          from 'react-router-dom';
import { EditUser }                         from './scenes/EditUser';
import { UsersManagement }                  from './scenes/UsersManagement';
import { VenueManagementScene }             from './scenes/VenueManagement';
import { VenueDashboardScene }              from './scenes/VenueManagement/scenes/VenueDashboard';
import { ShowsScene }                       from './scenes/VenueManagement/scenes/Shows';
import { ZonesAndCheckpointsScene }         from './scenes/VenueManagement/scenes/ZonesAndCheckpoints';
import { ZoneSettingsScene }                from './scenes/VenueManagement/scenes/ZonesAndCheckpoints/scenes/ZoneSettings';
import { DevicesScene }                     from './scenes/VenueManagement/scenes/Devices';
import { ActionsScene }                     from './scenes/VenueManagement/scenes/Actions';
import { LogsScene }                        from './scenes/VenueManagement/scenes/Logs';
import { StatisticsScene }                  from './scenes/VenueManagement/scenes/Statistics';
import { DeviceManagementScene }            from './scenes/DeviceManagement';
import { ShowManagementScene }              from './scenes/ShowManagement';
import { ProviderManagementScene }          from './scenes/ProviderManagement';
import { DetailsScene }                     from './scenes/ShowManagement/scenes/Details';
import { PropertiesScene }                  from './scenes/ShowManagement/scenes/Properties';
import { TicketsScene }                     from './scenes/ShowManagement/scenes/Tickets';
import { Layout, LayoutDashboard }          from './components/Layout';
import { ThemeLayoutEmpty }                 from './themes/default/Layout';
import LayoutEmpty                          from './themes/default/Layout/LayoutEmpty';
import { LoginScene }                       from './scenes/Login';
import { PageNotFoundScene }                from './scenes/Error';
import LoginService                         from './services/login/Login';
import SilentRenewTokenService              from './services/login/SilentRenewToken';
import UserManagerInstance                  from './configs/login/UserManagerInstance';
import Axios                                from './configs/api/Axios';
import favicon_dev                          from './themes/img/favicons/favicon_dev.png';
import favicon_stage                        from './themes/img/favicons/favicon_stage.png';
import favicon_prod                         from './themes/img/favicons/favicon_prod.png';
import { UsersService }                     from './services/api/Users';
import { tx }                               from '@transifex/native';

import './AccessControl.css';

/*
 * tx init is using the project token (NOT THE API TOKEN)
 * it can be generated from inside the project -> Resources -> <SELECT_YOUR_RESOURCES> -> Manage Native Credentials
 */
tx.init({
    token: '1/ee7856fe3fa4c06452dc798b81c2a50c6452af8d',
});

// Languages locale list
const languagesList = {
    'en' : 'en',
    'de' : 'de_CH',
    'fr' : 'fr_CH'
};

/**
 * Component that is used to render publicly accessible route.
 * In order to work pass to component, as props: layout, component which needs to render, and (optionally) path.
 * Example: <AccessControlPublicRoute layout={SomeLayout} component={SomeComponent} path={"/some-path"} />
 *
 * @param {XML} Component
 * @param {XML} Layout
 * @param {Object} rest
 *
 * @returns {XML}
 */
const AccessControlPublicRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />
);

/**
 * Component that is used to render private route (the one that only logged users can access).
 * In order to work pass to component, as props :
 * layout, component, path and boolean value which tells if user is logged in or not.
 * Example: <AccessControlPrivateRoute path='/some-path' exact isUserLoggedIn={booleanValue} layout={SomeLayout} component={SomeComponent}/>
 *
 * @param {XML} Component
 * @param {XML} Layout
 * @param {Boolean} isUserLoggedIn
 * @param {Object} rest
 *
 * @returns {XML}
 */
const AccessControlPrivateRoute = ({ component: Component, layout: Layout, isUserLoggedIn: isLoggedIn, section, loggedUser, ...rest }) => {    
    const isMobileView = {
        isMobileView:window.innerWidth < 992
    };
    
    return (
        <Route {...rest} render={props => (
            <Layout section={section} {...isMobileView} loggedUser={loggedUser} >
                { isLoggedIn ? <Component {...props } {...isMobileView}/> : <Redirect to="/login"/> }
            </Layout>
        )} />
    )
};

/**
 * Component that is used to render login route. If user is logged in, /login route will redirect him to the dashboard page.
 * In order to work pass to component, as props :
 * layout, component, path and boolean value which tells if user is logged in or not.
 * <AccessControlLoginRoute path='/login' exact isUserLoggedIn={booleanValue} layout={SomeLayout} component={SomeComponent}/>
 *
 * @param {XML} Component
 * @param {XML} Layout
 * @param {Boolean} isUserLoggedIn
 * @param {Object} rest
 *
 * @returns {XML}
 */
const AccessControlLoginRoute = ({ component: Component, layout: Layout, isUserLoggedIn: isLoggedIn, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            <Layout>
                { !isLoggedIn ? <Component {...props} /> : <Redirect to="/"/> }
            </Layout>
        )} />
    )
};

/**
 * @class ./AccessControl
 */
class AccessControl extends Component {

    state = {
        isUserLoggedIn : false,
        isPromiseFinished: false,
        loggedUser: null
    };

    /**
     * Constructor.
     *
     * @param {Object} props
     */
    constructor(props) {
        super(props);
        let self = this;
        this.usersService = new UsersService();

        UserManagerInstance.getUser().then(function (user) {
            if (user) {
                self.setState({
                    isUserLoggedIn: true
                });
                self.usersService.getUser(user.profile.sub)
                .then(response => {
                    if ( response.status === 200) {
                        self.setState({
                            loggedUser: response.data,
                        });
                        const lang = response.data.language;
                        const currentLocale = tx.getCurrentLocale()
                        languagesList[lang] !== currentLocale && tx.setCurrentLocale(languagesList[lang]);
                    }
                })
            }
            self.setState({
                isPromiseFinished: true
            });
        });

        UserManagerInstance.events.addUserSignedOut(function () {
            console.log('User signed out of op');
            UserManagerInstance.signoutRedirect();
        });

        UserManagerInstance.events.addAccessTokenExpiring(function () {
            console.log("token expiring");
        });

        UserManagerInstance.events.addAccessTokenExpired(function () {
            console.log("token expired");
            UserManagerInstance.signoutRedirect();
        });

        UserManagerInstance.events.addUserLoaded(function () {
            console.log("new user loaded");
            UserManagerInstance.getUser().then(function (user) {
              Axios(user.access_token);
            })
        });

        const url = window.location.href;

        // Set the name of the environment accordign to the URL
        const currentEnvironment =
            url.indexOf(':3000') !== -1
                ?   'dev'
                :   url.indexOf('.stage.') !== -1 ? 'stage'
                    : 'prod';

        this.colorAndFaviconChanger(currentEnvironment);
    };

    colorAndFaviconChanger = environment => {
        this.changePrimaryColors(environment);
        this.changeFavicon(environment);
    };

    /**
     * Changes primary colors
     */
    changePrimaryColors = environment => {

        let head = document.getElementsByTagName('head')[0];
        let styleElement = document.createElement('style');
        styleElement.setAttribute('type','text/css');
        
        var primaryColor;
        switch (environment) {
            case "dev":
                primaryColor = "pink";
                break;
            case "stage":
                primaryColor = "purple";
                break;
            default:
                primaryColor = "teal";
        }
        
        const style = `:root { 
            --primary-color-rgb: var(--${primaryColor}-rgb) !important; 
            --primary-color: var(--${primaryColor}) !important; 
            --primary-dark-color-rgb: var(--dark-${primaryColor}-rgb)  !important;
            --primary-dark-color: var(--dark-${primaryColor})  !important;
        }`;

        styleElement.appendChild(document.createTextNode(style));
        head.appendChild(styleElement);
    };

    /**
     * Changes the Favicon regarding the environment
     */
    changeFavicon = environment => {

        let favicon = document.querySelector('link[rel="shortcut icon"]');
        let head = document.getElementsByTagName('head')[0];

        favicon = document.createElement('link');
        favicon.setAttribute('rel', 'shortcut icon');

        favicon.setAttribute('type', 'image/png');
        environment === 'dev'
            ?   favicon.setAttribute('href', favicon_dev)
            :   environment === 'stage'
                ?   favicon.setAttribute('href', favicon_stage)
                :   favicon.setAttribute('href', favicon_prod);

        head.appendChild(favicon);
    }

    /**
     * TODO signin_oidc and silent_renew routes are public
     *
     * @returns {XML}
     */
    render()
    {
        const {isUserLoggedIn} = this.state;

        /**
         * Routes data array (same sorting is important)
         */
        const privateRoutesData = [
            {isUserLoggedIn: isUserLoggedIn, layout: LayoutDashboard, component:VenueManagementScene,     path:'/', section:"Dashboard"},
            {isUserLoggedIn: isUserLoggedIn, layout: LayoutDashboard, component:UsersManagement,          path:'/usersmanagement', section:"usersmanagement" },
            {isUserLoggedIn: isUserLoggedIn, layout: LayoutDashboard, component:EditUser,                 path:'/user/:id', section:"usersmanagement"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:VenueDashboardScene,      path:'/venues/dashboard/:id', section:"venues"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:ShowsScene,               path:'/venues/shows/:id', section:"venues"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:ZonesAndCheckpointsScene, path:'/venues/zones-and-checkpoints/:id', section:"venues"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:ZoneSettingsScene,        path:'/venues/zones-and-checkpoints/:id/zone-settings', section:"venues"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:DevicesScene,             path:'/venues/devices/:id', section:"venues"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:ActionsScene,             path:'/venues/actions/:id', section:"venues"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:LogsScene,                path:'/venues/logs/:id', section:"venues"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:StatisticsScene,          path:'/venues/statistics/:id', section:"venues"},
            {isUserLoggedIn: isUserLoggedIn, layout: LayoutDashboard, component:DeviceManagementScene,    path:'/devices', section:"Dashboard"},
            {isUserLoggedIn: isUserLoggedIn, layout: LayoutDashboard, component:ShowManagementScene,      path:'/shows', section:"Dashboard"},
            {isUserLoggedIn: isUserLoggedIn, layout: LayoutDashboard, component:ProviderManagementScene,  path:'/providers', section:"Dashboard"},
            {isUserLoggedIn: isUserLoggedIn, layout: LayoutDashboard, component:ProviderManagementScene,  path:'/providers/:providerId', section:"Dashboard"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:DetailsScene,             path:'/shows/details/:id', section:"shows"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:PropertiesScene,          path:'/shows/properties/:id', section:"shows"},
            {isUserLoggedIn: isUserLoggedIn, layout: Layout,          component:TicketsScene,             path:'/shows/tickets/:id', section:"shows"}
        ];

        return (
            this.state.isPromiseFinished &&
            <div className="AccessControl">
                <Switch>
                    {privateRoutesData.map((item, idx) =>
                        <AccessControlPrivateRoute
                            exact
                            key={idx}
                            path={item.path}
                            layout={item.layout}
                            component={item.component}
                            loggedUser={this.state.loggedUser}
                            isUserLoggedIn={item.isUserLoggedIn}
                            section={item.section ? item.section : null}
                        />
                    )}
                    <AccessControlLoginRoute path='/login' exact isUserLoggedIn={isUserLoggedIn} layout={ThemeLayoutEmpty} component={LoginScene}/>
                    <AccessControlPublicRoute path='/signin_oidc' component={LoginService} layout={LayoutEmpty} />
                    <AccessControlPublicRoute path='/silent_renew' component={SilentRenewTokenService} layout={LayoutEmpty} />
                    <AccessControlPublicRoute layout={ThemeLayoutEmpty} component={PageNotFoundScene}/>
                </Switch>
            </div>
        );
    }
}

export default AccessControl;
