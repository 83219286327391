import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import ReactDOM             from 'react-dom';

import './style.css';

/**
 * @class Form/Switch/Switch
 */
class Switch extends Component
{
    state = {
        checked: false
    };

    /**
     * @override
     */
    UNSAFE_componentWillMount ()
    {
        this.handleCheckedProperty();
    };

    /**
     * @override
     */
    componentDidUpdate ()
    {
        this.handleCheckedProperty();
    };

    /**
     * Handles whether switch is checked or not.
     */
    handleCheckedProperty = () =>
    {
        if (this.props.checked && this.props.checked !== this.state.checked) {
            this.setState({
                checked : this.props.checked
            });
            this.props.getValue(this.props.checked)
        } else if (!this.props.checked && this.state.checked === true) {
            this.setState({
                checked : false
            });
            this.props.getValue(this.props.checked)
        }
    };

    /**
     * Handles input value on change and updates state.
     */
    handleChange = () =>
    {
        this.setState({
            checked : ReactDOM.findDOMNode(this.inputField).checked
        });
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <label className={[
                'Switch',
                this.props.className,
                this.props.required && 'required',
                this.props.disabled && 'disabled'
            ].join(' ')}>
                <span className={'switch-label'}>
                    { this.props.label }
                </span>
                <input type={ this.props.type } name={ this.props.name } className={ this.props.className }
                       required={ this.props.required } disabled={ this.props.disabled }
                       readOnly={ this.props.readOnly } placeholder={ this.props.placeholder }
                       checked={ this.state.checked } ref={(input) => this.inputField = input}
                       onChange={() => {
                                this.handleChange();
                                this.props.getValue(ReactDOM.findDOMNode(this.inputField).checked, ReactDOM.findDOMNode(this.inputField).name)
                            }
                       }
                />
                <span className={'switch-slider round'} />
            </label>
        );
    }
}

Switch.defaultProps = {
    type: 'checkbox',
    required : false,
    readOnly : false,
    disabled : false,
    className : '',
};

Switch.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required : PropTypes.bool,
    readOnly : PropTypes.bool,
    disabled : PropTypes.bool,
    className : PropTypes.string,
    getValue : PropTypes.func
};

export default Switch;