import { DateTimeService } from '../../../../../../../../services/utils/DateTime';

/**
 * @class ./scenes/VenueManagement/scenes/ZonesAndCheckpoints/scenes/ZoneSettings/services/List/List
 */
class List
{
    /**
     * @type {Object}
     */
    dateTimeService;

    /**
     * @override
     */
    constructor ()
    {
        this.dateTimeService = new DateTimeService();
    }

    /**
     * Converts operator string to operator sign and returns it.
     *
     * @param {String} operatorString
     *
     * @returns {String}
     */
    getOperatorSign = (operatorString) =>
    {
        let operatorSign;
        switch (operatorString) {
            case 'equal':
                operatorSign = '=';
                break;
            case 'notEqual':
                operatorSign = '!=';
                break;
            case 'less':
                operatorSign = '<';
                break;
            case 'greater':
                operatorSign = '>';
                break;
            case 'lessEqual':
                operatorSign = '<=';
                break;
            case 'greaterEqual':
                operatorSign = '>=';
                break;
            default:
                operatorSign = '=';
        }

        return operatorSign;
    };

    /**
     * Returns adapted data for zone settings review list.
     *
     * @param {Array} shows
     * @param {Array} selectedShows
     * @param {Array} zones
     * @param {Array} zonesInfo
     *
     * @returns {Array}
     */
    getZoneSettingsData = (shows, selectedShows, zones, zonesInfo) =>
    {
        let zoneSettingsData = [];
        shows.map(show => {
            if (!zones.length || (selectedShows.includes(show.id) && zones.length)) {
                let zoneSettingsRowData = {};
                let date = this.dateTimeService.replaceYearAndDate(this.dateTimeService.getLocalDateTime(show.begin));
                zoneSettingsRowData[show.id] = [];
                zoneSettingsRowData[show.id].push(date);
                zoneSettingsRowData[show.id].push({
                    value : show.name,
                    clickable : true
                });
                zoneSettingsRowData[show.id].push({
                    value : show.ticketCount,
                    align : 'text-end'
                });
                if (zonesInfo.length) {
                    zonesInfo.map(zoneInfo => {
                        if (zoneInfo.show.id === show.id) {
                            zoneSettingsRowData[show.id].push({
                                value : zoneInfo.ticketsWithoutZone,
                                isDanger : zoneInfo.ticketsWithoutZone > 0,
                                align : 'text-end'
                            });
                            zoneSettingsRowData[show.id].push({
                                value : zoneInfo.ticketsValidInMultipleZones,
                                isWarning : zoneInfo.ticketsValidInMultipleZones > 0,
                                align : 'text-end'
                            });
                            if (zones.length) {
                                zones.map(zone => {
                                    let zonesCount = zones.length;
                                    Object.entries(zoneInfo.ticketCountsPerZone).map(perZone => {
                                        zonesCount--;
                                        let zoneName = zone.name;
                                        if (zoneName.toLowerCase() === perZone[0].toLowerCase()) {
                                            zoneSettingsRowData[show.id].push({
                                                value : perZone[1],
                                                align : 'text-end'
                                            });
                                        }

                                        return true;
                                    });
                                    for (let i=1;i<=zonesCount;i++) {
                                        zoneSettingsRowData[show.id].push({
                                            value : '0',
                                            align : 'text-end'
                                        });
                                    }

                                    return true;
                                });
                            }
                        }

                        return true;
                    });
                }
                zoneSettingsData.push(zoneSettingsRowData);
            }

            return true;
        });

        return zoneSettingsData;
    };

    /**
     * Returns adapted data for zone settings review rules list.
     *
     * @param {Array} showId
     * @param {Array} zonesInfo
     *
     * @returns {Array}
     */
    getZoneSettingsRulesData = (showId,zonesInfo) =>
    {
        let zoneSettingsRulesData = [];
        if (zonesInfo.length) {
            zonesInfo.map(zoneInfo => {
                if (zoneInfo.show.id === showId) {
                    if (zoneInfo.multipleAssignmentWarnings.length) {
                        zoneInfo.multipleAssignmentWarnings.map((warning,index) => {
                            let zoneSettingsRulesRowData = {};
                            zoneSettingsRulesRowData[index] = [];
                            zoneSettingsRulesRowData[index].push({
                                value : warning.ticketCount,
                                align : 'text-end'
                            });
                            let rulesArray = [];
                            warning.rulesCausingMultipleAssignments.map(multipleAssignmentsRule => {
                                let zoneRule = multipleAssignmentsRule.zoneName + ': ';
                                if (!!multipleAssignmentsRule.filterRule.length) {
                                    multipleAssignmentsRule.filterRule.map((rule,index) => {
                                        let rulesString = '"' + rule.field + '" ' + this.getOperatorSign(rule.operator) + ' "' + rule.operand + '"';
                                        if (multipleAssignmentsRule.filterRule.length > (index + 1)) {
                                            rulesString += ' and '
                                        }
                                        zoneRule += rulesString;

                                        return true;
                                    });
                                } else {
                                    zoneRule += 'all tickets of venue are valid';
                                }
                                rulesArray.push(zoneRule);

                                return true;
                            });
                            zoneSettingsRulesRowData[index].push({
                                value : rulesArray,
                                multiple : true
                            });
                            zoneSettingsRulesData.push(zoneSettingsRulesRowData);

                            return true;
                        });
                    } else {
                        let zoneSettingsRulesRowData = {};
                        zoneSettingsRulesRowData[0] = [];
                        zoneSettingsRulesRowData[0].push('');
                        zoneSettingsRulesRowData[0].push('');
                        zoneSettingsRulesData.push(zoneSettingsRulesRowData);
                    }
                }

                return true;
            });
        }

        return zoneSettingsRulesData;
    };
}

export default List;