import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Footer }           from './components/Footer';
import { Header }           from './components/Header';
import { Sidebar }          from './components/Sidebar';
import { DashboardMenu }    from './components/DashboardMenu';

import './style.css';

/**
 * @class Layout/LayoutDashboard
 */
class LayoutDashboard extends Component
{
    state = {
        searchedValue : '',
        path : '',
        isHomeButtonClicked: false,
        showSidebar: false
    };

    /**
     * @override
     */
    UNSAFE_componentWillMount ()
    {
        if (this.props.children.props.location && this.state.path !== this.props.children.props.location.pathname) {
            this.setState({
                path : this.props.children.props.location.pathname
            })
        }
    };

    /**
     * When home button is clicked, it sets state accordingly.
     */
    homeButtonClicked = () =>
    {
        this.setState({
            isHomeButtonClicked: true
        })
    };

    /**
     * When menu item is clicked, it sets state accordingly.
     */
    menuItemClicked = () =>
    {
        this.setState({
            isHomeButtonClicked: false
        });
    };

    toggleSidebar = (newState) => {
        this.setState({showSidebar: newState})
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <div className="LayoutDashboard">
                <Header
                    toggleSidebar={() => this.toggleSidebar(!this.state.showSidebar)}
                    showSidebar={this.state.showSidebar}
                    topNavigationMenuItems={this.props.topNavigationMenuItems}
                    isHomeButtonClicked={this.homeButtonClicked}
                    isMobileView={this.props.isMobileView}
                    loggedUser={this.props.loggedUser}
                />
                {
                    !this.props.isMobileView && <DashboardMenu
                        path={this.state.path}
                        isHomeButtonClicked={this.state.isHomeButtonClicked}
                        isMenuItemClicked={this.menuItemClicked}
                    />
                }
                <div className="col-12 content">
                    <Sidebar
                        section={this.props.section}
                        selectedId=""
                        isMobileView={this.props.isMobileView}
                        show={this.state.showSidebar}                    
                        handleMouseClick={() => this.toggleSidebar(false)}
                        sidebarNavigationMenuItems={this.props.sidebarNavigationMenuItems}
                    />
                    {this.props.children}
                </div>
                <Footer />
                {this.props.isMobileView && this.state.showSidebar && <div className="blur-background" name="blur-background"/>}
            </div>
        );
    }
}

LayoutDashboard.propTypes = {
    topNavigationMenuItems : PropTypes.object.isRequired,
    sidebarNavigationMenuItems : PropTypes.object.isRequired,
    isMobileView : PropTypes.bool
};

export default LayoutDashboard;