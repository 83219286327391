import React from "react";
import PropTypes from "prop-types";
import { SortableHandle } from "react-sortable-hoc";
import IconLayers from "./images/layers.svg";

import "./style.css";

const Panel = ({
  colorize = false,
  draggable = false,
  layersIcon = false,
  className = "",
  title = "",
  titleIcon = "",
  panelId = "",
  directionUpHandler = () => {},
  directionDownHandler = () => {},
  editHandler = () => {},
  removeHandler = () => {},
  style,
  children,
}) => {
  /**
   * Generates Panel header.
   *
   * @returns {XML}
   */
  const getHeader = () => {
    const up = className.includes("up");
    const down = className.includes("down");
    const edit = className.includes("edit");
    const remove = className.includes("remove");

    const hasIcone = [up, down, edit, remove].includes(true);

    return SortableHandle(() => (
      <div className={`PanelHeader mb-3 ${colorize ? "fill" : ""}`}>
        {title && title !== "" && (
          <div className={`row ${draggable ? "draggable" : ""}`}>
            <div className={`${hasIcone ? "col-8" : "col-12"}`}>
              <h3>
                {layersIcon && <img src={IconLayers} alt="Layers" />}
                {title}
              </h3>
            </div>
            {hasIcone && (
              <div className="col-4 text-end">
                {up && (
                  <div
                    className="icon up"
                    onClick={() => directionUpHandler(panelId)}
                  />
                )}
                {down && (
                  <div
                    className="icon down"
                    onClick={() => directionDownHandler(panelId)}
                  />
                )}
                {edit && (
                  <div
                    className="icon edit"
                    onClick={() => editHandler(panelId)}
                  />
                )}
                {remove && (
                  <div
                    className="icon remove"
                    onClick={() => removeHandler(panelId)}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    ));
  };

  const PanelHeader = getHeader();

  return (
    <div className={`Panel ${className}`} style={style}>
      <PanelHeader />
      {children}
    </div>
  );
};

Panel.propTypes = {
  draggable: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  className: PropTypes.string,
  colorize: PropTypes.bool,
  removeHandler: PropTypes.func,
  editHandler: PropTypes.func,
  directionDownHandler: PropTypes.func,
  directionUpHandler: PropTypes.func,
};

export default Panel;
