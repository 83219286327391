import React from "react";
import PropTypes from "prop-types";
import { useT } from "@transifex/react";
import IconLoading from "./images/ico-loading.svg";
import { Button } from "../../themes/default/Form/components/Button";

const RefreshButton = ({ onClickHandler, className = "" }) => {
  const t = useT();
  const title = t("Refresh");
  return (
    <Button className={className} onClickHandler={onClickHandler}>
      <img src={IconLoading} alt={title} title={title} />
    </Button>
  );
};

RefreshButton.propTypes = {
  className: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired,
};

export default RefreshButton;
