import React           from 'react';
import PropTypes       from 'prop-types';
import { BatteryIcon } from '../../../Images';

import './style.css';

const Battery = ({level, className}) => (
    <div className={`battery ${className && className}`}>
        <BatteryIcon batteryPercentage={level} />
    </div>
);

Battery.propTypes = {
    level : PropTypes.number.isRequired
};

export default Battery;