import React     from 'react';
import { T }     from '@transifex/react';
import { Link }  from 'react-router-dom';
import IconLogin from './images/ico-login.svg';

import './style.css';

const PageNotFound = () =>
(
    <div id="PageNotFound" className="row m-0">
        <div id="wrapper" className="col-6 col-offset-3">
            <p>404</p>
            <p>
                <span><T _str="Page not found"/></span>
            </p>
            <p>
                <Link to="/login" className="btn btn-info">
                    <T _str="Go to Login Page"/>
                    <img src={IconLogin} alt="Login"/>
                </Link>
            </p>
        </div>
    </div>
);

export default PageNotFound;