import React, { useEffect, useState, useRef } from 'react';
import PropTypes                              from 'prop-types';
import IconArrows                             from './images/arrows.svg';
import IconArrowsDisabled                     from './images/arrows_disabled.svg';
import { MultiSelectDropdown }                from './components/MultiSelectDropdown';

import './style.css';

const MultiSelect = ({ list, getValue, disabled }) =>
{
    const [listOpen, setListOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState(null);
    const mainElement = useRef();

    useEffect(() => {
        const selectedItems = list.filter( item => item.selected).map(item => item.title);
        const seletedItemsString = selectedItems.join(', ');

        if(selectedItems.length === 0){
            setHeaderTitle("Select option");
        }
        else if(selectedItems.length > 6) {
            setHeaderTitle(`${selectedItems.length} selected`);
        }
        else {
            setHeaderTitle(`${seletedItemsString}`);
        }
    }, [list]);

    /**
     * Handles clicking outside of dropdown area.   
     */
    const handleCloseDropdown = () => setListOpen(false);

    /**
     * Handles dropdown list click.
     */
    const handleToggleList = () => setListOpen(!listOpen);


    return (
        <div className={disabled ? "dd-wrapper disabled" : "dd-wrapper"} ref={mainElement}>
            <div
                className="dd-header"
                onClick={() => !disabled && handleToggleList()}
            >
                <div className="dd-header-title">{headerTitle}</div>
                { disabled ?
                    <img src={IconArrowsDisabled} alt="arrows"/> :
                    <img src={IconArrows} alt="arrows"/>
                }
            </div>
            <MultiSelectDropdown 
                list={list} 
                listOpen={listOpen}
                getValue={getValue} 
                mainElement={mainElement.current}
                onCloseDropdown={handleCloseDropdown}
            />
        </div>
    );
}

MultiSelect.propTypes = {
    list: PropTypes.array.isRequired,
    getValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default MultiSelect;