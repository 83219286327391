import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Wireless }         from './components/Wireless';
import { Battery }          from './components/Battery';

import './style.css';

/**
 * @class components/Device/DevicePlain
 */
class DevicePlain extends Component
{
    /**
     * Renders info content.
     *
     * @returns {XML}
     */
    renderInfoContent = () =>
    {
        const {plain, wireless, battery, numberOfDevices, away} = this.props;
        let infoContent;
        if (!plain) {
            infoContent = (
                <div className={`info ${away ? "away" : ""}`}>
                    <Wireless wifiSignal={wireless} />
                    <Battery level={battery} />
                    <div className={'device-line'}></div>
                </div>
            );
        } else {
            infoContent = (
                <div className={`info ${away ? "away" : ""}`}>
                    <p className="numberOfDevices">{numberOfDevices}</p>
                    <div className={'device-line'}> </div>
                </div>
            );
        }

        return infoContent;
    };

    /**
     * Renders device name.
     *
     * @returns {XML}
     */
    renderDeviceName = () =>
    {
        const {plain, name} = this.props;
        let deviceName;
        if (!plain) {
            deviceName = (
                <div className={'deviceName'}>
                    { name }
                </div>
            );
        }

        return deviceName;
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <div className={'devicePlain'}>
                {this.renderInfoContent()}
                {this.renderDeviceName()}
            </div>
        );
    }
}

DevicePlain.propTypes = {
    wireless        : PropTypes.number,
    battery         : PropTypes.number,
    name            : PropTypes.string,
    plain           : PropTypes.bool,
    numberOfDevices : PropTypes.number,
    away            : PropTypes.bool
}

export default DevicePlain;