import React, { Component } from 'react';
import { NavLink }          from 'react-router-dom';
import PropTypes            from 'prop-types';
import { withRouter } from "react-router";

import './style.css';

/**
 * @class themes/default/Layout/components/DashboardMenu/DashboardMenu
 */
class DashboardMenu extends Component
{
    state = {
        selectedMenuItem : ''
    };

    /**
     * @override
     */
    UNSAFE_componentWillMount ()
    {
        let selectedMenuItem = '';
        if (this.props.path !== this.state.selectedMenuItem) {

            switch (this.props.path)
            {
                case '/':
                    selectedMenuItem = 'Venues';
                break;
                case '/shows':
                    selectedMenuItem = 'Shows';
                break;
                case '/devices':
                    selectedMenuItem = 'Devices';
                break;
                case '/providers':
                    selectedMenuItem = 'Providers';
                    break;
                default:
                    selectedMenuItem = '';
            }
        };

        if (this.props.match.path === "/usersmanagement"   || this.props.match.path === "/user/:id") {
            switch (this.props.match.path)
            {
                case '/usersmanagement':
                    selectedMenuItem = 'Users Management';
                break;
                case '/user/:id':
                    selectedMenuItem = 'Edit User';
                break;
                default:
                    selectedMenuItem = '';
            }
        };
        
        this.setState({ selectedMenuItem });
    }

    /**
     * @override
     */
    componentDidUpdate ()
    {
        if(this.state.selectedMenuItem !== 'Venues' && this.props.isHomeButtonClicked) {
            this.setState({
                selectedMenuItem: 'Venues'
            })
        }
    }

    /**
     * Updates state according to selected menu item.
     *
     * @param {String} menuItem
     */
    menuItemClickHandler = (menuItem) =>
    {
        this.setState({
            selectedMenuItem : menuItem
        });

        this.props.isMenuItemClicked();

    };

    /**
     * @returns {XML}
     */
    render()
    {
        const dashBoardTabs = [
            {
                name : "Venues",
                to : "/"
            },
            {
                name : "Shows",
                to : "/shows"
            },
            {
                name : "Devices",
                to : "/devices"
            },
            {
                name : "Providers",
                to : "/providers"
            },
        ];

        const usersTabs = [
            {
                name : this.props.match.path === "/usersmanagement" ? "Users Management" : this.props.match.path === "/user/:id" && "Edit User",
                to : this.props.path
            }
        ];

        const currentTabs = (this.state.selectedMenuItem === "Users Management" || this.state.selectedMenuItem === "Edit User") ? usersTabs : dashBoardTabs;

        return (
            <div className="col-12 p-0 DashboardMenu">
                <div className="row p-0 tabs-container">

                    {currentTabs.map((data, idx) => (
                        <TabElement
                            key={idx}
                            to={data.to}
                            name={data.name}
                            menuItemClickHandler={this.menuItemClickHandler}
                            selectedMenuItem={this.state.selectedMenuItem}
                        />
                    ))}

                </div>
            </div>
        );
    }
};

const TabElement = ({ selectedMenuItem, menuItemClickHandler, name, to }) =>
(
    <div className={`col-4 text-uppercase text-center menu-item ${selectedMenuItem === name ? "selected-item" : ""} `}
        onClick={() => menuItemClickHandler(name)}>
        <NavLink exact to={to} activeClassName="selected-item">
            {name}
        </NavLink>
    </div>
);

DashboardMenu.propTypes = {
    path : PropTypes.string.isRequired,
    isHomeButtonClicked: PropTypes.bool
};

export default withRouter(DashboardMenu);