import axios from 'axios/index';

/**
 * @class ./services/api/Checkpoint/Checkpoint
 */
class Checkpoint
{

    /**
     * Returns paginates list of all checkpoints in this venue by venue Id.
     *
     * @param {String} venueId
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *  {
     *    "name": "string",
     *    "from": "string",
     *    "to": "string",
     *    "permissionCheckOnly": true
     *  }
     *  ],
     *   "resultCount": 0,
     *   "totalResultCount": 0,
     *   "pageSize": 0,
     *   "currentPage": 0,
     *   "totalPageCount": 0
     * }
     *
     */
    getAll = (venueId) =>
    {
        let params =  {
            Sort: 'name',
            pageSize: 9999
        };

        return axios.get('/venues/' + encodeURIComponent(venueId) + '/checkpoints',
            {
                params: params
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Creates one checkpoint.
     *
     * @param {String} venueId
     * @param {Object} checkpoint
     *
     * @returns {Object}
     *
     * @example Request/Response:
     *  {
     *    "name": "string",
     *    "from": "string",
     *    "to": "string",
     *    "permissionCheckOnly": true
     *  }
     *
     */
    createOne = (venueId, checkpoint) =>
    {
        return axios.post('/venues/' + encodeURIComponent(venueId) + '/checkpoints',
            checkpoint
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns one checkpoint by name.
     *
     * @param {String} venueId
     * @param {String} name
     *
     * @returns {Object}
     *
     * @example Response:
     *  {
     *    "name": "string",
     *    "from": "string",
     *    "to": "string",
     *    "permissionCheckOnly": true
     *  }
     *
     */
    getOne = (venueId, name) =>
    {
        return axios.get('/venues/' + encodeURIComponent(venueId) + '/checkpoints/' + encodeURIComponent(name))
            .then(response => response.data)
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Updates one checkpoint based on venue Id.
     *
     * @param {String} venueId
     * @param {String} name
     * @param {Object} checkpoint
     *
     * @returns {Object}
     *
     * @example Request:
     *  {
     *    "id": "string",
     *    "name": "string",
     *    "color": "string",
     *    "sound": "string",
     *   "text": "string"
     *  }
     *
     */
    updateOne = (venueId, name, checkpoint) =>
    {
        return axios.put('/venues/' + encodeURIComponent(venueId) + '/checkpoints/' + encodeURIComponent(name),
            checkpoint
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            }
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Deletes one checkpoint by name.
     *
     * @param {String} venueId
     *
     * @param {String} name
     *
     * @returns {Object}
     *
     */
    deleteOne = (venueId, name) =>
    {
        return axios.delete('/venues/' + encodeURIComponent(venueId) + '/checkpoints/' + encodeURIComponent(name))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            }).catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

}

export default Checkpoint;