import React, { Component } from "react";
import { DateTimeService } from "../../../../services//utils/DateTime";
import { T, useT } from "@transifex/react";
import Panel from "../../../../themes/default/Layout/components/Panel/Panel";
import { SceneTitle } from "../../../../themes/default/Title/components/Scene";
import { Search, RadioGroup, Select } from "../../../../themes/default/Form";
import { Select2 } from "../../../../themes/default/Form/components/Select2";
import { List } from "./components/List";
import { Tag } from "../../../../components/Tag";
import {
  Button,
  ButtonSize,
} from "../../../../themes/default/Form/components/Button";
import { ShowFilterService } from "../../../../services/api/ShowFilter";
import { SortService } from "../../../../services/utils/Sort";
import { VenueService } from "../../../../services/api/Venue";
import { ResponseService } from "../../../../services/utils/Response";
import { LoadingScreen } from "../../../../components/Loading";
import { DialogError } from "../../../../themes/default/Dialog";
import Dialog from "../../../../themes/default/Dialog/Dialog";
import { Pagination } from "../../../../components/Pagination";

import IconReload from "../../images/ico-loading.svg";

import "./style.css";

const VENUE_IN_TIME_FRAME_YES = "1";
const VENUE_IN_TIME_FRAME_NO = "0";
const SHOW_TIME_RANGE_ALL = "1";
const SHOW_TIME_RANGE_FUTURE = "2";
const SHOW_TIME_RANGE_TIME_FRAME = "3";

/**
 *  Stores latest timeout id.
 */
let timeoutId;

/**
 * Generates object with properties range from 1 - numberOfProperties value.
 *
 * @param {Number} numberOfProperties
 */
const generateNumericProperty = (numberOfProperties) => {
  let property = {};

  for (let i = 1; i <= numberOfProperties; i++) {
    property[i] = i.toString();
  }

  return property;
};

/**
 * @class scenes/Shows/Shows
 */
class Shows extends Component {
  /**
   * @type {Object}
   */
  venueService;

  /**
   * @type {Object}
   */
  showFilterService;

  /**
   * @type {Object}
   */
  sortService;

  /**
   * @type {String}
   */
  venueId;

  /**
   * @type {Object}
   */
  responseService;

  state = {
    loadData: "0",
    keepData: "0",
    tableSearchValue: null,
    showsPeriodValue: SHOW_TIME_RANGE_ALL,
    pageSize: 50,
    showsFilterKeys: {},
    showsFilterValue: null,
    shows: [],
    currentPage: 1,
    venue: {},
    useTimeFrame: null,
    totalPageCount: 1,
    loadDropsData: generateNumericProperty(30),
    keepDropsData: generateNumericProperty(5),
    resultsPerPageDropData: {
      1: "1",
      5: "5",
      20: "20",
      50: "50",
      100: "100",
      500: "500",
    },
    sort: "begin",
    showLoadingScreen: false,
    showErrorDialog: false,
    errorMessages: [],
    showContinuousShowConfirmDialog: false,
  };

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.venueId = this.props.match.params.id;
    this.showFilterService = new ShowFilterService();
    this.sortService = new SortService();
    this.venueService = new VenueService();
    this.responseService = new ResponseService();
  }

  /**
   * @override
   */
  componentDidMount() {
    this.showFilterService.getAllShowPropertyKeys().then((response) => {
      if (response.status === 200) {
        let showsFilterKeys = {};
        Object.entries(response.data).map((propertyKey) => {
          showsFilterKeys[propertyKey[1]] = propertyKey[1];

          return true;
        });
        this.setState({
          showsFilterKeys: showsFilterKeys,
        });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
    });

    this.getShowFiltersFromApi();
  }

  UNSAFE_componentWillReceiveProps(props, prevProps) {
    if (props.venue && props.venue !== prevProps.venue) {
      let useTimeFrame = props.venue.useTimeframe
        ? VENUE_IN_TIME_FRAME_YES
        : VENUE_IN_TIME_FRAME_NO;
      let loadData = props.venue.loadShowsInAdvance.toString();
      let keepData = props.venue.keepShowsAfterEnd.toString();

      this.setState({
        venue: props.venue,
        useTimeFrame: useTimeFrame,
        showsPeriodValue: props.venue.useTimeframe
          ? SHOW_TIME_RANGE_TIME_FRAME
          : SHOW_TIME_RANGE_ALL,
        loadData,
        keepData,
      });

      this.getShows(
        1,
        this.state.pageSize,
        this.state.sort,
        props.venue.useTimeframe
          ? SHOW_TIME_RANGE_TIME_FRAME
          : SHOW_TIME_RANGE_ALL
      );
    }
  }

  /**
   * Gets showFilter data from API.
   */
  getShowFiltersFromApi = () => {
    this.showFilterService.getAll(this.venueId).then((response) => {
      if (response.status === 200) {
        this.getTagValue(response.data.rules);
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
    });
  };

  /**
   * Parses response and set string value of filter ready to be render.
   *
   * @param {Array} tagValueResponse
   */
  getTagValue = (tagValueResponse) => {
    let filters = {
      value: "",
      isForced: {},
    };

    tagValueResponse.map((andRules, index) => {
      andRules.conditions.map((orRule, index) => {
        let operator = "";
        switch (orRule.operator) {
          case "equal":
            operator = "=";
            break;
          case "notEqual":
            operator = "!=";
            break;
          case "less":
            operator = "<";
            break;
          case "greater":
            operator = ">";
            break;
          case "lessEqual":
            operator = "<=";
            break;
          case "greaterEqual":
            operator = ">=";
            break;
          case "contains":
            operator = "@=";
            break;
          case "notContains":
            operator = "!@=";
            break;
          default:
            operator = "=";
        }
        if (andRules.conditions.length - 1 === index) {
          filters.value += orRule.field + " " + operator + " " + orRule.operand;
        } else {
          filters.value +=
            orRule.field + " " + operator + " " + orRule.operand + " && ";
        }

        return true;
      });

      filters.isForced[index + 1] = andRules.forceActive;
      if (tagValueResponse.length - 1 > index) {
        filters.value += " || ";
      }

      return true;
    });

    this.setState({
      showsFilterValue: filters,
    });
  };

  /**
   * Sends request to update API.
   */
  applyFiltersHandler = (tag) => {
    this.setState({
      showLoadingScreen: true,
    });
    this.showFilterService
      .reevaluateShowFilter(this.venueId)
      .then((response) => {
        if (response.status === 204) {
          this.getShows(
            this.state.currentPage,
            this.state.pageSize,
            this.state.sort
          );
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
  };

  /**
   * Gets useTimeframe from venue props.
   */
  getVenueData = () => {
    this.venueService.getOne(this.venueId).then((response) => {
      if (response.status === 200) {
        let useTimeFrame = response.data.useTimeframe
          ? VENUE_IN_TIME_FRAME_YES
          : VENUE_IN_TIME_FRAME_NO;
        let loadData = response.data.loadShowsInAdvance.toString();
        let keepData = response.data.keepShowsAfterEnd.toString();

        this.setState({
          venue: response.data,
          useTimeFrame: useTimeFrame,
          showsPeriodValue: response.data.useTimeframe
            ? SHOW_TIME_RANGE_TIME_FRAME
            : SHOW_TIME_RANGE_ALL,
          loadData,
          keepData,
        });

        this.getShows(
          1,
          this.state.pageSize,
          this.state.sort,
          this.state.showsPeriodValue
        );
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
    });
  };

  /**
   * Returns timeframe object with from and to dates.
   *
   * @param {Number} fromValue
   * @param {Number} toValue
   *
   * @returns {Object}
   */
  getTimeframeObject = (fromValue, toValue) => {
    let fromObj = new Date();
    let toObj = new Date();
    let from = new Date(
      fromObj.setDate(fromObj.getDate() - parseInt(fromValue, 10))
    );
    let to = new Date(toObj.setDate(toObj.getDate() + parseInt(toValue, 10)));
    return {
      from: from.toISOString(),
      to: to.toISOString(),
    };
  };

  /**
   * Updates state according to selected load data value.
   *
   * @param {String} selectValue
   */
  getLoadDataValue = (selectValue) => {
    let value = parseInt(selectValue, 10);
    if (
      value &&
      this.state.venue.loadShowsInAdvance !== value &&
      this.state.useTimeFrame === VENUE_IN_TIME_FRAME_YES
    ) {
      let venue = {
        loadShowsInAdvance: value,
        keepShowsAfterEnd: this.state.venue.keepShowsAfterEnd,
        name: this.state.venue.name,
        useTimeframe: true,
      };

      this.venueService.updateOne(this.venueId, venue).then((response) => {
        if (response.status === 204) {
          this.getVenueData();
          if (this.state.showsPeriodValue === SHOW_TIME_RANGE_TIME_FRAME) {
            this.getShows(
              1,
              this.state.pageSize,
              this.state.sort,
              this.state.showsPeriodValue
            );
          }
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
      });
    }
  };

  /**
   * Updates state according to selected keep data value.
   *
   * @param {String} selectValue
   */
  getKeepDataValue = (selectValue) => {
    let value = parseInt(selectValue, 10);
    if (
      value &&
      this.state.venue.keepShowsAfterEnd !== value &&
      this.state.useTimeFrame === VENUE_IN_TIME_FRAME_YES
    ) {
      let venue = {
        keepShowsAfterEnd: value,
        loadShowsInAdvance: this.state.venue.loadShowsInAdvance,
        name: this.state.venue.name,
        useTimeframe: true,
      };

      this.venueService.updateOne(this.venueId, venue).then((response) => {
        if (response.status === 204) {
          this.getVenueData();
          if (this.state.showsPeriodValue === SHOW_TIME_RANGE_TIME_FRAME) {
            this.getShows(
              1,
              this.state.pageSize,
              this.state.sort,
              this.state.showsPeriodValue
            );
          }
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
      });
    }
  };

  /**
   * Updates state according to selected table radio value.
   *
   * @param {Object} event
   */
  getPeriodValue = (event) => {
    let showsFrom = null;
    let showsTo = null;
    let value = event.target.value;

    this.getShows(1, this.state.pageSize, this.state.sort, value);

    this.setState({
      showsPeriodValue: value,
      showsFrom: showsFrom,
      showsTo: showsTo,
    });
  };

  /**
   * Handles radio button values for first panel -> will change in production.
   *
   * @param event
   */
  handleUseTimeFrameRadioButtonValue = (event) => {
    let value = event.target.value;

    if (value === VENUE_IN_TIME_FRAME_YES) {
      this.setState({ showContinuousShowConfirmDialog: true });
    } else {
      this.useTimeFrameRadioButtonAction(value);
    }
  };

  /**
   * Updates state according to selected first panel radio button.
   *
   * @param {string} value
   */
  useTimeFrameRadioButtonAction = (value) => {
    let useTimeFrame = value === VENUE_IN_TIME_FRAME_YES;
    let venue = {
      useTimeframe: useTimeFrame,
      name: this.state.venue.name,
      loadShowsInAdvance: useTimeFrame
        ? parseInt(VENUE_IN_TIME_FRAME_YES, 10)
        : parseInt(VENUE_IN_TIME_FRAME_NO, 10),
      keepShowsAfterEnd: useTimeFrame
        ? parseInt(VENUE_IN_TIME_FRAME_YES, 10)
        : parseInt(VENUE_IN_TIME_FRAME_NO, 10),
    };

    if (
      value === VENUE_IN_TIME_FRAME_NO &&
      this.state.showsPeriodValue === SHOW_TIME_RANGE_TIME_FRAME
    ) {
      this.getShows(1, this.state.pageSize, this.state.sort);

      this.setState({
        showsPeriodValue: SHOW_TIME_RANGE_ALL,
      });
    }
    this.setState({
      showLoadingScreen: true,
    });
    this.venueService.updateOne(this.venueId, venue).then((response) => {
      if (response.status === 204) {
        this.getVenueData();
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        // showLoadingScreen: false
      });
    });
  };

  /**
   * Returns appropriate number if All in timeframe radio button is disabled or not.
   *
   * @return {String}
   */
  isAllInTimeFrameDisabled = () => {
    let disabledRadioButton = SHOW_TIME_RANGE_TIME_FRAME;
    if (this.state.useTimeFrame === VENUE_IN_TIME_FRAME_YES) {
      disabledRadioButton = "";
    } else if (this.state.showsPeriodValue === SHOW_TIME_RANGE_TIME_FRAME) {
      this.setState({
        showsPeriodValue: SHOW_TIME_RANGE_ALL,
      });
    }

    return disabledRadioButton;
  };

  /**
   * Updates state according to returned value from table search field.
   *
   * @param {String} value
   */
  getSearchValue = (value) => {
    this.setState({
      tableSearchValue: encodeURIComponent(value),
    });

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      if (value.length > 2 || value.length === 0) {
        this.getShows(
          1,
          this.state.pageSize,
          this.state.sort,
          this.state.showsPeriodValue
        );
      }
    }, 300);
  };

  /**
   * Gets appropriate number of shows (according to page size) and updates the state.
   *
   * @param {Number} pageSize
   * @param {Number} page
   * @param {String} sort
   * @param {String} timeRange
   */
  getShows = (page, pageSize, sort, timeRange = SHOW_TIME_RANGE_ALL) => {
    let filter = "";
    let dateRange = "";

    if (this.state.tableSearchValue !== null) {
      filter += "name@=*" + this.state.tableSearchValue;
    }

    switch (timeRange) {
      case SHOW_TIME_RANGE_FUTURE:
        dateRange = "future";
        break;
      case SHOW_TIME_RANGE_TIME_FRAME:
        dateRange = "timeframe";
        break;
      default:
        dateRange = "all";
    }

    let parameterObject = {
      ...(sort !== "" ? { Sort: sort } : {}),
      dateRange: dateRange,
      Filter: filter,
      Page: page,
      PageSize: pageSize,
    };

    if (!this.state.showLoadingScreen) {
      this.setState({
        showLoadingScreen: true,
      });
    }
    this.venueService
      .getAllShows(this.venueId, parameterObject)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.resultCount !== 0) {
            this.setState({
              shows: response.data.results,
              currentPage:
                response.data.currentPage === null
                  ? 1
                  : response.data.currentPage,
              totalPageCount: response.data.totalPageCount,
              pageSize: response.data.pageSize,
            });
          } else {
            this.setState({
              shows: [],
              currentPage: 1,
              totalPageCount: 1,
              pageSize: this.state.pageSize,
            });
          }
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }

        setTimeout(() => {
          this.setState({ showLoadingScreen: false });
        }, 2000);
      });
  };

  /**
   * Updates show's state value according to pagination properties.
   *
   * @param {Object} valueObject
   */
  showsPerPageHandler = (valueObject) => {
    if (this.state.pageSize !== parseInt(valueObject.value, 10)) {
      this.getShows(
        this.state.currentPage,
        valueObject.value,
        this.state.sort,
        this.state.showsPeriodValue
      );
    }
  };

  /**
   * Updates show's state value according to selected page.
   *
   * @param {Number} page
   */
  changePageHandler = (page) => {
    if (this.state.currentPage !== page) {
      this.getShows(page, this.state.pageSize, this.state.sort);
    }
  };

  /**
   * Sorts Name of Show, Begin, End, Event, Location, Town and Tickets columns ascending or descending
   * and sets state accordingly.
   *
   * @param {String} column
   */
  sortColumn = (column) => {
    let sort = this.sortService.getSortValue(this.state.sort, column);

    this.setState({
      sort: sort,
    });

    this.getShows(
      this.state.currentPage,
      this.state.pageSize,
      sort,
      this.state.showsPeriodValue
    );
  };

  /**
   * Returns value of tag component.
   *
   * @param {Object} filters
   */
  getShowFilters = (filters) => {
    let rules = [];
    if (filters.value) {
      let rowConditions = filters.value.split(" || ");

      rowConditions.map((rowCondition, index) => {
        let rulesRow = {};
        rulesRow["conditions"] = [];
        let filtersLevel = parseInt(index, 10) + 1;
        Object.entries(filters.isForced).map((isFilterRowForced) => {
          if (parseInt(isFilterRowForced[0], 10) === filtersLevel) {
            rulesRow["forceActive"] = isFilterRowForced[1];
          }

          return true;
        });

        let rowAndConditions = rowCondition.split(" && ");
        rowAndConditions.map((rowAndCondition) => {
          let operators = ["=", "!=", "<", ">", "<=", ">=", "@=", "!@="];
          let splitOperator = "";
          operators.map((operator) => {
            if (rowAndCondition.search(operator) >= 0) {
              splitOperator = operator;
            }

            return true;
          });
          if (splitOperator !== "") {
            let condition = {};
            let operator = "";
            switch (splitOperator) {
              case "=":
                operator = "equal";
                break;
              case "!=":
                operator = "notEqual";
                break;
              case "<":
                operator = "less";
                break;
              case ">":
                operator = "greater";
                break;
              case "<=":
                operator = "lessEqual";
                break;
              case ">=":
                operator = "greaterEqual";
                break;
              case "@=":
                operator = "contains";
                break;
              case "!@=":
                operator = "notContains";
                break;
              default:
                operator = "=";
            }
            condition["operator"] = operator;
            condition["field"] = rowAndCondition.split(
              " " + splitOperator + " "
            )[0];
            condition["operand"] = rowAndCondition.split(
              " " + splitOperator + " "
            )[1];
            rulesRow["conditions"].push(condition);
          }

          return true;
        });

        rules.push(rulesRow);

        return true;
      });
    }

    this.setState({
      showLoadingScreen: true,
    });
    this.showFilterService
      .updateOne(this.venueId, { rules: rules })
      .then((response) => {
        if (response.status === 204) {
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
  };

  /**
   * @returns {XML}
   */
  render() {
    const {
      loadData,
      keepData,
      tableSearchValue,
      showsPeriodValue,
      pageSize,
      showsFilterKeys,
      showsFilterValue,
      shows,
      currentPage,
      venue,
      useTimeFrame,
      totalPageCount,
      loadDropsData,
      keepDropsData,
      sort,
      showLoadingScreen,
      showErrorDialog,
      errorMessages,
      showContinuousShowConfirmDialog,
      resultsPerPageDropData,
    } = this.state;

    const dialogTitle = <T _str="Assign shows to" />;
    const buttonTitle = <T _str="Add first filter item" />;
    const applyMessage = (
      <T _str="The above filters assign shows to this venue in regular intervals. But you can also:" />
    );

    const getTagConfig = {
      keys: showsFilterKeys,
      operations: {
        "=": "=",
        "!=": "!=",
        ">": ">",
        "<": "<",
        ">=": ">=",
        "<=": "<=",
        "@=": "@=",
        "!@=": "!@=",
      },
      buttonTitle: buttonTitle.props["_str"],
      dialogTitle: dialogTitle.props["_str"] + venue.name,
      applyMessage: applyMessage.props["_str"],
      value: showsFilterValue,
    };

    return (
      <div className="Shows">
        <Tite name={this.props.venue?.name} />

        <RenderTimeFrameOptions
          keepData={keepData}
          loadData={loadData}
          useTimeFrame={useTimeFrame}
          loadDropsData={loadDropsData}
          keepDropsData={keepDropsData}
          getLoadDataValue={this.getLoadDataValue}
          getKeepDataValue={this.getKeepDataValue}
          handleUseTimeFrameRadioButtonValue={
            this.handleUseTimeFrameRadioButtonValue
          }
        />

        {/* Renders Shows results. */}
        <Panel
          title={
            <T
              _str="Shows are assigned to the venue {venueName} if ..."
              venueName={venue.name}
            />
          }
        >
          {showsFilterValue !== null && (
            <Tag
              config={getTagConfig}
              applyFiltersHandler={this.applyFiltersHandler}
              getValue={this.getShowFilters}
            />
          )}
        </Panel>

        <RenderShowResults
          sort={sort}
          shows={shows}
          pageSize={pageSize}
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          tableSearchValue={tableSearchValue}
          showsPeriodValue={showsPeriodValue}
          resultsPerPageDropData={resultsPerPageDropData}
          getShows={this.getShows}
          sortColumn={this.sortColumn}
          getSearchValue={this.getSearchValue}
          getPeriodValue={this.getPeriodValue}
          changePageHandler={this.changePageHandler}
          showsPerPageHandler={this.showsPerPageHandler}
          isAllInTimeFrameDisabled={this.isAllInTimeFrameDisabled}
        />

        {showLoadingScreen && <LoadingScreen />}

        {/* Renders error diagram if an error occur. */}
        <DialogError
          show={showErrorDialog}
          closeDialog={() => this.setState({ showErrorDialog: false })}
        >
          {errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>

        {/* Renders continuous show confirmation dialog. */}
        {showContinuousShowConfirmDialog && (
          <Dialog
            mainButton={<T _str="confirm" />}
            title={<T _str="Confirmation dialog" />}
            showModal={showContinuousShowConfirmDialog}
            action={() => {
              this.setState({ showContinuousShowConfirmDialog: false });
              this.useTimeFrameRadioButtonAction(VENUE_IN_TIME_FRAME_YES);
            }}
            closeModal={() =>
              this.setState({ showContinuousShowConfirmDialog: false })
            }
          >
            <h4 className="text-center">
              <T _str="Are you sure you want to activate continuous show?" />
            </h4>
          </Dialog>
        )}
      </div>
    );
  }
}

const Tite = ({ name }) => {
  const t = useT();
  return (
    <SceneTitle text={t("Venue Shows")}>
      <p>{name}</p>
    </SceneTitle>
  );
};

// Renders Shows results.
const RenderShowResults = ({
  shows,
  tableSearchValue,
  showsPeriodValue,
  pageSize,
  resultsPerPageDropData,
  currentPage,
  sort,
  totalPageCount,
  getSearchValue,
  getPeriodValue,
  isAllInTimeFrameDisabled,
  showsPerPageHandler,
  getShows,
  sortColumn,
  changePageHandler,
}) => {
  const t = useT();

  const dateTimeService = new DateTimeService();

  const timeRangeData = {
    1: t("all"),
    2: t("all in future"),
    3: t("all in timeframe"),
  };

  /**
   * Returns columns for venue shows list.
   *
   * @returns {Object}
   */
  const getShowColumns = () => {
    return {
      [t("Show")]: {
        subtitle: null,
        sortable: true,
        fieldValue: "name",
        width: "12%",
      },
      [t("Begin")]: {
        subtitle: null,
        sortable: true,
        fieldValue: "begin",
        width: "14%",
      },
      [t("End")]: {
        subtitle: null,
        sortable: true,
        fieldValue: "end",
        width: "14%",
      },
      [t("Event")]: {
        subtitle: null,
        sortable: true,
        fieldValue: "event",
        width: "10%",
      },
      [t("Location")]: {
        subtitle: null,
        sortable: true,
        fieldValue: "location",
        width: "9%",
      },
      [t("Town")]: {
        subtitle: null,
        sortable: true,
        fieldValue: "town",
        width: "9%",
      },
      [t("Provider")]: { subtitle: null, sortable: false, width: "8%" },
      [t("External ID")]: { subtitle: null, sortable: false, width: "10%" },
      [t("Tickets")]: {
        subtitle: null,
        sortable: true,
        fieldValue: "ticketCount",
        width: "7%",
      },
      [t("Forced")]: { subtitle: null, sortable: false, width: "7%" },
    };
  };

  /**
   * Returns adapted data for venue shows list.
   *
   * @param {Object} responseData
   *
   * @returns {Array}
   */
  const getShowData = (responseData) => {
    let showsData = [];

    responseData.map((show) => {
      let showData = {};
      showData["show"] = [show.name, show.id];
      if (show.begin) {
        showData["begin"] = dateTimeService.replaceYearAndDate(
          dateTimeService.getLocalDateTime(show.begin)
        );
      }
      if (show.end) {
        showData["end"] = dateTimeService.replaceYearAndDate(
          dateTimeService.getLocalDateTime(show.end)
        );
      }
      showData["event"] = show.event;
      showData["location"] = show.location;
      showData["town"] = show.town;
      showData["provider"] = [show.providerName, show.providerId];
      showData["externalId"] = show.externalId;
      showData["tickets"] = show.ticketCount;
      showData["forced"] = show.forcedActive;
      showsData.push(showData);

      return true;
    });

    return showsData;
  };

  return (
    <Panel
      title={t("Results of the selected show filters")}
      layersIcon
      className={[
        "",
        !shows.length &&
          tableSearchValue === null &&
          showsPeriodValue === SHOW_TIME_RANGE_ALL &&
          "no-list-results",
      ].join(" ")}
    >
      <div className="row">
        <div className="col-12 col-lg-4">
          <Search
            name="search"
            placeholder={t("Search")}
            getValue={getSearchValue}
          />
        </div>

        <div className="col-12 col-lg-4 results-inputs-container">
          <span className="show-results">{t("Show results")}</span>
          <RadioGroup
            name="time_range"
            className="inline"
            labels={timeRangeData}
            getValue={getPeriodValue}
            defaultValue={showsPeriodValue}
            disabledValues={isAllInTimeFrameDisabled()}
          />
        </div>

        <div className="col-12 col-lg-4 perpages-container">
          <div className="row">
            <div className="col-12 col-lg-5 text-end only-desktop">
              {t("shows per page")}
            </div>
            <div className="col-12 col-lg-7 text-end">
              <Select
                name="shows-results-perpage"
                initialValue={"" + pageSize + ""}
                getValue={showsPerPageHandler}
                data={resultsPerPageDropData}
              />
              <span className="only-mobile">{t("shows per page")}</span>
              <div className="reload">
                <Button
                  size={ButtonSize.Tiny}
                  onClickHandler={() =>
                    getShows(currentPage, pageSize, sort, showsPeriodValue)
                  }
                >
                  <img src={IconReload} alt={t("Reload")} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {shows.length > 0 && (
        <div
          className="venue-shows-table-container"
          style={{
            width: `${
              window.innerWidth > 991
                ? window.innerWidth - 500
                : window.innerWidth - 45
            }px`,
          }}
        >
          <List
            sort={sortColumn}
            columns={getShowColumns()}
            data={getShowData(shows)}
          />
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalPageCount={totalPageCount}
        changePageHandler={changePageHandler}
      />

      <div className="helper">{t("Helper")}</div>
    </Panel>
  );
};

// Renders TimeFrame options.
const RenderTimeFrameOptions = ({
  useTimeFrame,
  handleUseTimeFrameRadioButtonValue,
  loadDropsData,
  getLoadDataValue,
  getKeepDataValue,
  keepData,
  loadData,
  keepDropsData,
}) => {
  const t = useT();

  const venueRadiosData = {
    0: t("This venue is temporary, It has a start and an end-date."),
    1: t(
      "This venue is continuous, Upcoming shows are loaded to devices depending on the show date."
    ),
  };

  return (
    <Panel className="first">
      {useTimeFrame !== null && (
        <RadioGroup
          name="first_radio"
          getValue={handleUseTimeFrameRadioButtonValue}
          defaultValue={useTimeFrame}
          labels={venueRadiosData}
          split={true}
        />
      )}

      {loadData && keepData && (
        <div
          className={[
            "row p-0 time-frame",
            useTimeFrame === VENUE_IN_TIME_FRAME_NO ? "disabledContent" : "",
          ].join(" ")}
        >
          <div className="col-12 p-0">
            {t(
              "Define the time frame when tickets of shows are be delivered on devices"
            )}
            .
          </div>
          <div className="row p-0">
            <div className="col-4 col-md-4 col-lg-2 p-0">{t("Load data")}</div>
            <div className="col-3 col-md-4 col-lg-2">
              <Select2
                name={loadData}
                value={loadData}
                disabled={useTimeFrame === VENUE_IN_TIME_FRAME_NO}
                getValue={getLoadDataValue}
                data={loadDropsData}
              />
            </div>
            <div className="col-5 col-md-4 col-lg-2 p-0">
              {t("days in advance")}
            </div>
          </div>
          <div className="row p-0">
            <div className="col-4 col-md-4 col-lg-2 p-0">{t("Keep data")}</div>
            <div className="col-3 col-md-4 col-lg-2 ">
              <Select2
                name={keepData}
                value={keepData}
                disabled={useTimeFrame === VENUE_IN_TIME_FRAME_NO}
                getValue={getKeepDataValue}
                data={keepDropsData}
              />
            </div>
            <div className="col-5 col-md-4 col-lg-2 p-0">
              {t("days after the show")}
            </div>
          </div>
        </div>
      )}
      <div className="helper">{t("Helper")}</div>
    </Panel>
  );
};

export default Shows;
