import React            from 'react';
import PropTypes        from 'prop-types';
import { SingleFilter } from '../../Filter';
import IconMore         from '../images/ico-more.svg';

const FiltersRow = ({ rowIndex, getNumberOfLevelsInFiltersObject, filters, filtersRow, andButtonClickHandler, editButtonClickHandler, removeButtonClickHandler }) => 
(
    <span data-testid={`filter-row-${rowIndex}`} className="filters-row">
        {rowIndex > 0 && getNumberOfLevelsInFiltersObject(filters) > 1  &&
            <span data-testid="filter-or-span" className="or-between">
                or
            </span>
        }
        {filtersRow[1]['value'].map((filter,index) => (
            <span key={index}>
                {index > 0 &&
                    <span data-testid="filter-and-span" className="and-between">
                        and
                    </span>
                }
                <SingleFilter
                    filter             ={filter}
                    editClickHandler   ={() => editButtonClickHandler(filtersRow[0],index)}
                    deleteClickHandler ={() => removeButtonClickHandler(filtersRow[0],index)}
                />
            </span>
        ))}
        <span data-testid="create-filter-and-button" className="and text-uppercase" onClick={() => andButtonClickHandler(filtersRow[0])}>
            and
            <img src={IconMore} alt="And" title="And"/>
        </span>
    </span>
);

FiltersRow.propTypes = {
    rowIndex                         : PropTypes.number,
    filters                          : PropTypes.object,
    filtersRow                       : PropTypes.array,
    getNumberOfLevelsInFiltersObject : PropTypes.func,
    andButtonClickHandler            : PropTypes.func,
    editButtonClickHandler           : PropTypes.func,
    removeButtonClickHandler         : PropTypes.func,
}

export default FiltersRow;