import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import { NavLink }           from 'react-router-dom';
import { T, useT }           from '@transifex/react';
import { SortingArrowsIcon } from '../../../../components/Images';
import { LoadingForTables }  from '../../../../components/Loading';
import { ListService }       from '../../../../components/List/services';

import './style.css';

/**
 * @class src/scenes/ShowManagement/components/List/List
 */
class List extends Component
{
    /**
     * Returns an object with information for certain table cell.
     *
     * @param {Array | String | Array} columnData
     *
     * @returns {Object}
     */
    getCellData = (columnData) =>
    {
        let cellData;
        let noRecord = true;
        let title;
        let className;

        if (typeof columnData === 'object') {
            cellData = columnData.cellData === '' ? 'No record' : columnData.cellData;
            noRecord = columnData.cellData === '';
            title = columnData.cellData;
            className = columnData.className
        }
        else if (columnData === '') {
            cellData = "No record";
            title = "No record";
        }
        else {
            cellData = <span>{columnData}</span>;
            noRecord = false;
            title = columnData
        }

        return {
            'cellData' : cellData,
            'noRecord' : noRecord,
            'title'    : title,
            'className' : className
        };
    };

    /**
     * Renders list's body cell.
     *
     * @param {*} cellData
     * @param {Number} rowIndex
     * @param {Number} cellIndex
     * @param {String} id
     *
     * @returns {XML}
     */
    renderTableCell = (cellData, rowIndex, cellIndex, id) =>
    {
        return (
            <td key={rowIndex + '-' + cellIndex}
                title={this.getCellData(cellData).title}
                className={[this.getCellData(cellData).className, this.getCellData(cellData).noRecord && "grayed"].join(" ")}>
                {
                    (cellData.href && !this.getCellData(cellData).noRecord) ?
                        <NavLink exact to={cellData.href + '' + id}>
                            {this.getCellData(cellData).cellData}
                        </NavLink> :
                        this.getCellData(cellData).cellData
                }
            </td>
        );
    };

    /**
     * Renders list body.
     *
     * @param {Object} data
     *
     * @returns {any[]}
     */
    renderBody = (data) =>
    {
        if (Object.entries(data).length > 0) {
            return Object.entries(data).map((rowData, rowIndex) => {
                return (
                    <tr key={rowData[0]}>
                        {
                            rowData[1].map((cellData, cellIndex) => this.renderTableCell(cellData, rowIndex, cellIndex, rowData[0]))
                        }
                    </tr>
                );
            })
        }
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <React.Fragment>
                <table className="List table-hover table table-striped">
                    <thead>
                        <tr>
                            <RenderHeadRow isMobileView={this.props.isMobileView} sort={this.props.sort} currentSorting={this.props.currentSorting}/>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderBody(this.props.data) }
                    </tbody>
                </table>

                <IsLoadingOrNoResults data={this.props.data} showLoadingScreen={this.props.showLoadingScreen} />

            </React.Fragment>
        );
    }
}

const IsLoadingOrNoResults = ({ showLoadingScreen, data }) => {
    if (showLoadingScreen) {
        return <LoadingForTables />
    }
    else if (!showLoadingScreen && Object.entries(data).length === 0) {
        return <div className='no-results'><T _str="NO RESULTS FOUND"/></div>
    }
    return null
};

/**
 * Renders list head row.
 *
 * @param {Object} columns
 *
 * @returns {any[]}
 */
const RenderHeadRow = ({ isMobileView, sort, currentSorting }) =>
{
    const listService = new ListService();

    const t = useT();

    const show = t('Show');
    const begin = t('Begin');
    const end = t('End');
    const event = t('Event');
    const location = t('Location');
    const town = t('Town');
    const provider = t('Provider');
    const externalShowId = t('External Show ID');
    const tickets = t('Tickets');

    /**
     * Show list columns.
     *
     * @type {Object}
     */
    const columns = {
        [show] : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'name' },
        [begin] : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'begin', 'width' : '152px' },
        [end] : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'end', 'width' : '152px' },
        [event] : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'event', 'width' : '10%' },
        [location] : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'location', 'width' : '10%' },
        [town] : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'town', 'width' : '9%' },
        [provider] : { 'subtitle' : null, 'sortable' : false, 'fieldValue': 'provider', 'width' : '8%' },
        [externalShowId] : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'externalId', 'width' : '194px' },
        [tickets] : { 'subtitle' : null, 'sortable' : false, 'fieldValue': 'name', 'width' : '125px' }
    };

    // delete some columns if mobile view is enabled
    if (isMobileView)
    {
        delete columns['Event'];
        delete columns['Location'];
        delete columns['Town'];
        delete columns['Provider'];
        delete columns['Tickets'];
    };

    return Object.entries(columns).map((column,index) =>
        <th key={index} style={{"width":column[1].width}}>{column[0]}
            {
                column[1].subtitle !== null && <span className="grayed">{column[1].subtitle}</span>
            }
            {
                column[1].sortable &&
                    <SortingArrowsIcon className={`pull-right sorting-arrow ${ listService.sortingArrowClassController(column, currentSorting) }`} onClick={() => sort(column[1].fieldValue)}  />
            }
        </th>
    )
};

List.propTypes = {
    data : PropTypes.object,
    showLoadingScreen : PropTypes.bool.isRequired
};

export default List;