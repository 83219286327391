import React               from 'react';
import UserManagerInstance from '../../configs/login/UserManagerInstance';

const Login =  () => {
    UserManagerInstance.signinRedirectCallback().then(function () {
        window.location = "/";
    }).catch(function (e) {
        console.error(e);
    });

    return <br/>;
};

export default Login;