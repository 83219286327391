import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import Radio                from '../Radio/Radio';

import './style.css';

/**
 * @class Form/Radio/Radio
 */
class ToggleSwitch extends Component
{
    state = {
        checkedValue : this.props.defaultValue
    };

    /**
     * Toggles checked field
     *
     * @param event
     */
   radioClickHandler = (event) =>
   {
       this.setState({
           checkedValue: event
       });
   };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <fieldset id={ this.props.name } onChange={ this.props.getValue.bind(this) }
                      className={ [
                          'ToggleSwitch',
                          this.props.className,
                          this.props.disabled ? 'disabled' : ''
                      ].join(' ') }>
                {
                    Object.entries(this.props.labels).map((label, key) =>
                        <Radio key={ key }
                            changeSelectedHandler={this.radioClickHandler}
                            checkedValue={ this.state.checkedValue }
                            name={ this.props.name }
                            defaultValue={ this.props.defaultValue }
                            className={ this.props.className }
                            disabled={ this.props.disabled ? this.props.disabled : this.props.disabledValues === label[0] }
                            readOnly={ this.props.readOnly }
                            value={label[0]}
                            label={label[1]} />
                        )
                }
            </fieldset>
        );
    }
}

ToggleSwitch.propTypes = {
    name: PropTypes.string.isRequired,
    disabledValues : PropTypes.string,
    readOnly : PropTypes.bool,
    defaultValue : PropTypes.string,
    disabled : PropTypes.bool,
    className : PropTypes.string,
    labels : PropTypes.object.isRequired,
    getValue : PropTypes.func.isRequired
};

export default ToggleSwitch;