import React          from 'react';
import { useT }       from '@transifex/react';
import Graph          from './images/area_graph.png';
import { Panel }      from '../../../../themes/default/Layout/components/Panel';
import { SceneTitle } from '../../../../themes/default/Title/components/Scene';

const Statistics = () => {
    const t = useT();
    return (
        <div>
            <SceneTitle text={t("Statistics")}/>
            <Panel>
                <h4>{t("No of booked in tickets")}</h4>
                <img alt={'Graph'} src={ Graph }/>
            </Panel>
        </div>
    )
};

export default Statistics;
