import React, { Component } from 'react';
import moment               from 'moment';
import PropTypes            from 'prop-types';
import DatePicker           from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerInput.css';

class DatePickerInput extends Component {
    state = {
        value: null
    }

    componentDidMount() {
        const { initialDate } =this.props;
        initialDate !== undefined && this.setState({ value : moment(initialDate )._d });
    };
   
    changeHandler = value => {
        this.setState({ value });
        this.props.callBack(value);
    };

    render () {
        const {disabled, timeFormat, timeIntervals, dateFormat, placeholderText, hasIcon } = this.props;

        return (
            <div className={`date-picker ${hasIcon ? "has-icon" : "" }`}>
                <DatePicker
                    timeFormat      ={timeFormat}
                    timeIntervals   ={timeIntervals}
                    dateFormat      ={dateFormat}
                    placeholderText ={placeholderText}
                    selected        ={this.state.value}
                    disabled        ={disabled}
                    onChange        ={this.changeHandler}
                    {...this.props}
                />
            </div>
        );
    };
};

DatePickerInput.defaultProps = {
    timeFormat: "HH:mm",
    timeIntervals: 15,
    dateFormat: 'dd-MM-yyyy HH:mm',
    placeholderText: 'dd-MM-yyyy HH:mm',
    disabled: false,
    hasIcon: false
};

DatePickerInput.propTypes = {
    callBack        : PropTypes.func,
    disabled        : PropTypes.bool,
    className       : PropTypes.string,
    placeholderText : PropTypes.string,
    dateFormat      : PropTypes.string,
    timeIntervals   : PropTypes.number,
    hasIcon         : PropTypes.bool
};

export default DatePickerInput;