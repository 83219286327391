import React     from 'react';
import { Input } from '../Input';
import PropTypes from 'prop-types';

import './style.css';

const Color = ({ label, name, type, className, value, required, readOnly, placeholder, maxLength }) => 
(
    <div className={'Color'}>
        <Input
            label={ label }
            name={ name }
            type={ type }
            className={ className }
            value={ value }
            required={ required }
            readOnly={ readOnly }
            placeholder={ placeholder }
            maxLength={ maxLength}/>
    </div>
);

Color.defaultProps = {
    type: 'color',
    label: 'Color picker',
    name: 'color' + Math.floor(Math.random() * 10),
    required : false,
    readOnly : false,
    disabled : false,
    maxLength : '',
    placeholder : '',
    className : ''
};

Color.propTypes = {
    label : PropTypes.string.isRequired,
    name : PropTypes.string.isRequired,
    required : PropTypes.bool,
    readOnly : PropTypes.bool,
    disabled : PropTypes.bool,
    placeholder : PropTypes.string,
    className : PropTypes.string
};

export default Color;