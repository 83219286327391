import axios from 'axios/index';

/**
 * @class ./services/api/ActionsFilter/ActionFilter
 */
class ActionFilter
{

    /**
     * Returns paginates list of all actionFilters.
     *
     * @param {String} venueId
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *   "results": [
     *     {
     *       "name": "string",
     *       "from": "string",
     *       "to": "string",
     *       "permissionCheckOnly": true
     *     }
     *   ],
     *   "resultCount": 0,
     *   "totalResultCount": 0,
     *   "pageSize": 0,
     *   "currentPage": 0,
     *   "totalPageCount": 0
     * }
     *
     */
    getAll = (venueId) =>
    {
        return axios.get('/venues/' + encodeURIComponent(venueId) + '/actionfilters')
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Creates one action.
     *
     * @param {String} venueId
     * @param {Object} actionFilter
     *
     * @returns {Object}
     *
     * @example Request/Response:
     *  {
     *    "actionId": "string",
     *    "name": "string",
     *    "confirmationRequired": true,
     *    "rules": [
     *      [
     *       {
     *        "operator": "equal",
     *        "field": "string",
     *        "operand": "string"
     *       }
     *      ]
     *    ]
     *  }
     */
    createOne = (venueId, actionFilter) =>
    {
        return axios.post('/venues/' + encodeURIComponent(venueId) + '/actionfilters',
            actionFilter
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns one action based on action Id.
     *
     * @param {String} venueId
     * @param {String} name
     *
     * @returns {Object}
     *
     * @example Response:
     *  {
     *    "actionId": "string",
     *    "name": "string",
     *    "confirmationRequired": true,
     *    "rules": [
     *      [
     *       {
     *        "operator": "equal",
     *        "field": "string",
     *        "operand": "string"
     *       }
     *      ]
     *    ]
     *  }
     *
     */
    getOne = (venueId, name) =>
    {
        return axios.get('/venues/' + encodeURIComponent(venueId) + '/actionfilters/' + encodeURIComponent(name))
            .then(response => response.data)
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Updates one action based on action Id.
     *
     * @param {String} venueId
     * @param {String} name
     * @param {Object} actionFilter
     *
     * @returns {Object}
     *
     * @example Request:
     *  {
     *    "actionId": "string",
     *    "name": "string",
     *    "confirmationRequired": true,
     *    "rules": [
     *      [
     *       {
     *        "operator": "equal",
     *        "field": "string",
     *        "operand": "string"
     *       }
     *      ]
     *    ]
     *  }
     *
     */
    updateOne = (venueId, name, actionFilter) =>
    {
        return axios.put('/venues/' + encodeURIComponent(venueId) + '/actionfilters/' + encodeURIComponent(name),
            actionFilter
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Deletes one action filter.
     *
     * @param {String} venueId
     * @param {String} name
     *
     * @returns {Object}
     *
     */
    deleteOne = (venueId, name) =>
    {
        return axios.delete('/venues/' + encodeURIComponent(venueId) + '/actionfilters/' + encodeURIComponent(name))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            }).catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

}

export default ActionFilter;