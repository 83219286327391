import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { WifiIcon }         from '../../../Images';

import './style.css';

/**
 * @class components/Device/Device
 */
class Wireless extends Component
{
    /**
     * Returns wifi signal percentage class.
     *
     * @param {Number} wifiSignal
     *
     * @returns {String}
     */
    getWifiClass = (wifiSignal) =>
    {
        let className = '';

        switch (true) {
            case wifiSignal < 25:
                className += 'weak';
                break;
            case wifiSignal < 50:
                className += 'normal';
                break;
            case wifiSignal < 75:
                className += 'good';
                break;
            case wifiSignal >= 75:
                className += 'full';
                break;
            default:
                className = '';
        }

        return className;
    };

    /**
     * @returns {XML}
     */
    render()
    {
        const { away, wifiSignal } = this.props;
        return (
            <div className={'wireless'}>
                {/* <i className={`fa fa-wifi ${away ? 'away' : ''} ${this.getWifiClass(wifiSignal)}`} aria-hidden="true"/> */}

                <WifiIcon className={`wifi ${this.getWifiClass(wifiSignal)} ${away ? 'away' : ''}`}/>
            </div>
        );
    }
}

export default Wireless;

Wireless.propTypes = {
    away : PropTypes.bool,
    wifiSignal : PropTypes.number.isRequired,
};