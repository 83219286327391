import React, { useEffect, useState } from 'react';
import PropTypes                      from 'prop-types';
import { useT }                       from '@transifex/react';
import { LoadingScreen }              from '../../../../../../components/Loading';
import { TicketService }             from '../../../../../../services/api/Ticket';
import { Select, Search, Button }     from '../../../../../../themes/default/Form';
import { DialogError }                from '../../../../../../themes/default/Dialog';
import { ResponseService }            from '../../../../../../services/utils/Response';

import './style.css';

const LogSearch = ({ searchLogs }) =>
{
    const t = useT();

    const [searchBy, setSearchBy] = useState( null);
    const [filterKeys, setFilterKeys] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState( null);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [showLoadingScreen, setShowLoadingScreen] = useState( false);

    const ticketService = new TicketService();
    const responseService = new ResponseService();
    
    useEffect(() =>
    {
        setShowLoadingScreen(true)
        
        ticketService.getQueryableFields().then(response => {
            if (response.status === 200) {
                let filterKeys = {};

                response.data.map(key => {
                    filterKeys[key] = key;
                    return true;
                });

                setFilterKeys(filterKeys);
                setShowLoadingScreen(false);

            } else {
                setShowErrorDialog(true);
                setErrorMessages(responseService.getErrorMessages(response.data));
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Updates state according to selected search by criteria.
     *
     * @param {Object} returnedObject
     */
    const getSearchByValue = (returnedObject) =>
    {
        if (searchBy !== returnedObject.value && returnedObject.value !== null) {
            setSearchBy(returnedObject.value);
        }
    };

    const logsSearchCriteria = {
        'by': searchBy,
        'criteria': searchCriteria
    };

    return (
        <div className="row m-0 LogSearch">
            <div className="col-12 p-0">{t("Ticket search")}</div>
            <div className="col-5 col-md-2 p-0">
                <Select
                    data={filterKeys}
                    initialValue={ Object.entries(filterKeys).length
                        ?   Object.entries(filterKeys)[0][0]
                        :   null
                    }
                    getValue={getSearchByValue}
                />
            </div>
            <div className="col-7 col-md-8 p-0">
                <Search
                    placeholder="Search"
                    name="logs-search-criteria"
                    getValue={(criteria) => setSearchCriteria(encodeURIComponent(criteria))}
                />
            </div>
            <div className="col-6 col-md-2 mt-2 mt-md-0 p-0">
                <Button className="m-0" onClickHandler={() => searchLogs(logsSearchCriteria)}>
                    {t("Search")}
                </Button>
            </div>

            { showLoadingScreen && <LoadingScreen/> }

            <DialogError show={showErrorDialog} closeDialog={() => setShowErrorDialog(false) }>
                {
                    errorMessages.map((message,index) => <p key={index}>{message}</p> )
                }
            </DialogError>
        </div>
    );
}

LogSearch.propTypes = {
    searchLogs: PropTypes.func
};

export default LogSearch;