import React               from "react";
import PropTypes           from 'prop-types';
import { T }               from '@transifex/react';
import { useHistory }      from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconCopy            from '../../images/ico-copy.svg';
import { Text }            from '../../../../themes/default/Form';
import { Toggle }          from '../../../../themes/default/Toggle';
import IconBlackLoading    from '../../images/ico-black-loading.svg';
import Dialog              from '../../../../themes/default/Dialog/Dialog';
import arrows              from '../../../../themes/default/List/images/arrows.svg';

const EditDialog = ({
    V2Configuration, selectedProvider, showEditProviderDialog, providerData, copied, newProviderisV2SeeTicketsValue,
    clientCredential, isPreDefineProvider, closeEditProviderModal, name,editProvider, getIsV2Provider, getProviderNameValue, newProviderNameValue,
    copyClientCredential, onCopy, openChangeClientSecretConfirmationDialog, getToggleValue, getV2SettingsUpdate}) => {

    let history = useHistory();

    return (
        <Dialog
            title={<T _str="Edit provider"/>}
            mainButton={<T _str="Update Provider"/>}
            className="edit-provider-dialog"
            showModal={showEditProviderDialog}
            closeModal={isPreDefineProvider ? () => history.push('/providers') : closeEditProviderModal}
            action={editProvider}
            secondaryButton={<T _str="cancel"/>}
        >
            <ProviderType
                name={name}
                arrows={arrows}
                getIsV2Provider={getIsV2Provider}
                selectedProvider={selectedProvider}
            />

            <ClientName
                providerData={providerData}
                getProviderNameValue={getProviderNameValue}
                newProviderNameValue={newProviderNameValue}
            />

            <ClientId
                onCopy={onCopy}
                IconCopy={IconCopy}
                providerData={providerData}
                copyClientCredential={copyClientCredential}
            />
            
            <ClientSecret
                onCopy={onCopy}
                IconCopy={IconCopy}
                providerData={providerData}
                IconBlackLoading={IconBlackLoading}
                copyClientCredential={copyClientCredential}
                openChangeClientSecretConfirmationDialog={openChangeClientSecretConfirmationDialog}
            />

            <Active
                providerData={providerData}
                getToggleValue={getToggleValue}
            />
            
            <NoOfShows providerData={providerData} />        

            { copied && <CopiedNotification clientCredential={clientCredential} /> }

            { newProviderisV2SeeTicketsValue &&
                <V2Configurations
                    V2Configuration={V2Configuration}
                    getV2SettingsUpdate={getV2SettingsUpdate}
                />
            }

        </Dialog>
    )
};

const ProviderType = ({ selectedProvider, getIsV2Provider, arrows, name }) =>
(
    <div>
        <label><T _str="Import Type"/></label>
        <div className="theme-select">
            <select name={name} defaultValue={selectedProvider.isSeeTicketsV2 ? "V2" : "Default"} onChange={(e) => getIsV2Provider(e) } >
                <option value="V2">V2</option>
                <option value="Default">Default</option>
            </select>
        </div>
    </div>
);

const ClientName = ({ providerData, getProviderNameValue, newProviderNameValue }) =>
(
    <div className="row name">
        <div className="col-12">
            <T _str="Name"/>
            <Text name="device_name" value={ providerData.name.cellData } getValue={ getProviderNameValue }/>
        </div>
    </div>
);

const ClientId = ({ providerData, onCopy, IconCopy, copyClientCredential }) =>
(
    <div className="row client-id mt-3">
        <div className="col-12"><T _str="Client Id"/></div>
        <div className="col-12">
            { providerData.clientId }
            <CopyToClipboard onCopy={onCopy} text={providerData.clientId}>
                <img src={IconCopy} alt="Copy" title="Copy to clipboard" onClick={() => copyClientCredential('id')}/>
            </CopyToClipboard>
        </div>
    </div>
);

const ClientSecret = ({ providerData, onCopy, IconCopy, copyClientCredential, openChangeClientSecretConfirmationDialog, IconBlackLoading }) =>
(
    <div className="row client-secret mt-3">
        { providerData.clientSecret
            ?   <React.Fragment>
                    <div className="col-12" ><T _str="Client Secret"/></div>
                    <div className="col-12 show-client-secret">
                        { providerData.clientSecret }
                        <CopyToClipboard onCopy={onCopy} text={providerData.clientSecret}>
                            <img src={IconCopy} alt="Copy" title="Copy to clipboard" onClick={() => copyClientCredential('secret')} id="copyClientSecret"/>
                        </CopyToClipboard>
                    </div>
                </React.Fragment>
            :   <React.Fragment>
                    <div className="col-12" ><T _str="Client Secret"/></div>
                    <div className="col-12">
                        &#9733;&#9733;&#9733;&#9733;&#9733;&#9733;&#9733;&#9733;&#9733;&#9733;&#9733;
                        <img src={IconBlackLoading} alt="Loading" title="Change client secret" onClick={() => openChangeClientSecretConfirmationDialog()}/>
                    </div>
                </React.Fragment>
        }
    </div>
);

const Active = ({ providerData, getToggleValue }) =>
(
    <div className="row mt-3">
        <div className='col-12'><T _str="Active"/></div>
        <div className='col-12'>
            <Toggle 
                label=""
                callBack={getToggleValue}
                isChecked={providerData.active}
            />
        </div>
    </div>
);

const NoOfShows = ({ providerData }) =>
(
    <div className="row number-of-Shows mt-3">
        <div className="col-4" ><T _str="No. of Shows"/></div>
        <div className="col-8">{providerData.shows}</div>
    </div>
);

const CopiedNotification = ({clientCredential}) =>
(
    <div className="row">
        <div className="col-12">
            <p className="copied"><T _str="Client {credential} copied to clipboard" credential={clientCredential} /></p>
        </div>
    </div>
);

const V2Configurations = ({ V2Configuration, getV2SettingsUpdate }) =>
(
    <div className="V2-provider">
        <div className="col-12">
            <label>Company ID</label>                            
            <Text
                name="companyId"
                getValue={ e => getV2SettingsUpdate(e, "companyId") }
                value={ V2Configuration.companyId }
            />
        </div>
        <div className="row">
            <label className="d-block"><T _str="Import data, X days in advance"/>:</label>
            <div className="col-6">
                <label className="d-block">Shows</label>
                <Text
                    name="daysAheadForEventsImport"
                    getValue={ e => getV2SettingsUpdate(e, "daysAheadForEventsImport") }
                    value={ V2Configuration.daysAheadForEventsImport }
                />
            </div>
            <div className="col-6">
                <label className="d-block">Barcodes</label>
                <Text
                    name="daysAheadForBarcodesImport"
                    getValue={ e => getV2SettingsUpdate(e, "daysAheadForBarcodesImport") }
                    value={ V2Configuration.daysAheadForBarcodesImport }
                />
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <label className="d-block">Hot period starts x hours before show</label>
                <Text
                    name="hoursAheadForBarcodesImportHotPeriod"
                    getValue={ e => getV2SettingsUpdate(e, "hoursAheadForBarcodesImportHotPeriod") }
                    value={ V2Configuration.hoursAheadForBarcodesImportHotPeriod }
                />
            </div>
            <div className="col-6">
                <label className="d-block">Hot period ends x hours after show</label>
                <Text
                    name="hoursAfterForBarcodesImportHotPeriod"
                    getValue={ e => getV2SettingsUpdate(e, "hoursAfterForBarcodesImportHotPeriod") }
                    value={ V2Configuration.hoursAfterForBarcodesImportHotPeriod }
                />
            </div>
        </div>
    </div>
);

EditDialog.propTypes = {
    name : PropTypes.string,
    copied : PropTypes.bool,
    providerData : PropTypes.object,
    V2Configuration : PropTypes.object,
    clientCredential : PropTypes.string,
    selectedProvider : PropTypes.object,
    showEditProviderDialog : PropTypes.bool,
    newProviderisV2SeeTicketsValue : PropTypes.bool,
    onCopy : PropTypes.func.isRequired,
    editProvider : PropTypes.func.isRequired,
    getToggleValue : PropTypes.func.isRequired,
    getIsV2Provider : PropTypes.func.isRequired,
    getV2SettingsUpdate : PropTypes.func.isRequired,
    getProviderNameValue : PropTypes.func.isRequired,
    copyClientCredential : PropTypes.func.isRequired,
    closeEditProviderModal : PropTypes.func.isRequired,
    isPreDefineProvider : PropTypes.bool.isRequired,
    openChangeClientSecretConfirmationDialog : PropTypes.func.isRequired,
};

export default EditDialog;