/**
 * @class scenes/VenueManagement/scenes/Logs/services/Ticket
 */
class Ticket
{
    /**
     * Returns name of ticket buyer.
     *
     * @param {Object} ticket
     *
     * @returns {String}
     */
    getTicketBuyerName = (ticket) =>
    {
        return [
            ticket.personalisedTicketSalutation ? ticket.personalisedTicketSalutation : '',
            ticket.personalisedTicketFirstName ? ticket.personalisedTicketFirstName : '',
            ticket.personalisedTicketLastName ? ticket.personalisedTicketLastName : ''
        ].join(' ');
    };

    /**
     * Returns adapted data for venue tickets (logs) list.
     *
     * @param {Object} responseData
     *
     * @returns {Object}
     */
    getVenueTickets = (responseData) =>
    {
        let ticketsData = {};
        let ticketsLogs;
        let logData;

        if (responseData && responseData.length) {
            responseData.map(ticket => {
                ticketsLogs = [];

                ticketsData[ticket.barcode] = {
                    'name' : this.getTicketBuyerName(ticket)
                };

                ticket.history !== null && ticket.history.map(log => {
                    logData = {};
                    logData['time'] = log.time;
                    logData['zone'] = log.checkpoint.from || log.checkpoint.to;
                    logData['checkpoint'] = log.checkpoint;
                    logData['deviceName'] = log.handheldName;
                    logData['status'] = log.result;

                    ticketsLogs.push(logData);

                    return true;
                });

                ticketsData[ticket.barcode]['logs'] = ticketsLogs;
                ticketsData[ticket.barcode]['validity'] = ticket.validity.toLowerCase();
                ticketsData[ticket.barcode]['externalTicketId'] = ticket.externalTicketId;

                return true;
            });
        }

        return ticketsData;
    };
}

export default Ticket;