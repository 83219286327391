import React, { Component }     from 'react';
import { T, useT }              from '@transifex/react'
import { Panel }                from '../../../../themes/default/Layout/components/Panel';
import { Button, ButtonAdd }    from '../../../../themes/default/Form/components/Button';
import { SceneTitle }           from '../../../../themes/default/Title/components/Scene';
import Dialog                   from '../../../../themes/default/Dialog/Dialog';
import { DialogError }          from '../../../../themes/default/Dialog';
import { Input }                from '../../../../themes/default/Form/components/Input';
import { LoadingScreen }        from '../../../../components/Loading';
import { Rule }                 from '../../../../components/Rule';
import { Checkpoint }           from '../../../../components/Checkpoint';
import { ValidatorService,
         NotBlank,
         MinLength,
         MaxLength,
         Type }                 from 'pretty-validator'
import { ZonesService,
         CheckpointsService,
         ListService }          from './services';
import { ZoneService }          from '../../../../services/api/Zone';
import { CheckpointService }    from '../../../../services/api/Checkpoint';
import { VenueService }         from '../../../../services/api/Venue';
import { ResponseService }      from '../../../../services/utils/Response';

import './style.css';

/**
 * @class ./scenes/ZonesAndCheckpoints/ZonesAndCheckpoints
 */
class ZonesAndCheckpoints extends Component
{
    /**
     * @type {Object}
     */
    zonesService;

    /**
     * @type {Object}
     */
    globalZoneService;

    /**
     * @type {Object}
     */
    checkpointService;

    /**
     * @type {Object}
     */
    globalCheckpointsService;

    /**
     * @type {Object}
     */
    validatorService;

    /**
     * @type {Object}
     */
    listService;

    /**
     * @type {Object}
     */
    venueService;

    /**
     * @type {Object}
     */
    responseService;

    state = {
        checkpoints: null,
        showDeleteConfirmationDialog: false,
        showAddZoneModal: false,
        showEditZoneDialog: false,
        zonesReviewInfo: [],
        newZoneNameErrors: '',
        newZoneName: '',
        zones: [],
        showLoadingScreen: false,
        ticketPropertyKeys: {},
        showErrorDialog: false,
        errorMessages: []
    };

    /**
     * Constructor.
     *
     * @param {Object} props
     */
    constructor(props)
    {
        super(props);

        this.venueId = this.props.match.params.id;

        this.zonesService = new ZonesService();
        this.globalZoneService = new ZoneService();
        this.checkpointsService = new CheckpointsService();
        this.globalCheckpointsService = new CheckpointService();
        this.validatorService = new ValidatorService();
        this.listService = new ListService();
        this.venueService = new VenueService();
        this.responseService = new ResponseService();
    };

    /**
     * @override
     */
    componentDidMount () {
        this.venueId = this.props.match.params.id;

        this.getZonesResponseData();
        this.getTicketPropertyKeys();
    }

    /**
     * Gets all ticket property keys for certain venue.
     */
    getTicketPropertyKeys = () =>
    {
        this.setState({
            showLoadingScreen: true
        });
        this.venueService.getAllTicketPropertyKeys(this.venueId).then(response => {
            if (response.data) {
                if (response.status === 200) {
                    let ticketPropertyKeys = {};
                    response.data.map(ticketPropertyKey => {
                        ticketPropertyKeys[ticketPropertyKey] = ticketPropertyKey;

                        return true;
                    });

                    this.setState({
                        ticketPropertyKeys: ticketPropertyKeys
                    });
                } else {
                    this.setState({
                        showErrorDialog: true,
                        errorMessages: this.responseService.getErrorMessages(response.data)
                    });
                }
            }
            this.setState({
                showLoadingScreen: false
            });
        });
    };

    /**
     * Gets all zones and sets state accordingly or creates default zone and checkpoint if no zone exists.
     */
    getZonesResponseData = () =>
    {
        this.setState({
            showLoadingScreen: true,
            zone: []
        });
        this.globalZoneService.getAll(this.venueId).then(response => {
            if (response.status === 200) {
                if (response.data.results !== null) {
                    this.setState({
                        zones: response.data.results,
                        showLoadingScreen: false
                    }, this.getCheckpointsResponseData);
                } else {
                    this.createDefaultZoneAndCheckpoint();
                }
            } else {
                this.setState({
                    showErrorDialog: true,
                    errorMessages: this.responseService.getErrorMessages(response.data)
                });
            }
        });
    };

    /**
     * Creates default Zone `Public Area`.
     */
    createDefaultZoneAndCheckpoint = () => {
        this.globalZoneService.getAll(this.venueId).then(response => {
            if (response.status === 200 && response.data.totalResultCount === 0) {
                let defaultZoneName = 'Public Area';
                this.setState({
                    showLoadingScreen: true
                });
                this.globalZoneService.createOne(
                    this.venueId,
                    {
                        name: defaultZoneName
                    }
                ).then(response => {
                        if (response.status === 201) {
                            this.createDefaultCheckpoint(defaultZoneName);
                        } else {
                            this.setState({
                                showErrorDialog: true,
                                errorMessages: this.responseService.getErrorMessages(response.data)
                            });
                        }
                        this.setState({
                            showLoadingScreen: false
                        });
                    }
                );
            }
        })
    };

    /**
     * Gets all checkpoints.
     *
     * @return {Array}
     */
    getCheckpointsResponseData = () => {
        this.setState({
            showLoadingScreen: true,
            checkpoints: null
        });
        this.globalCheckpointsService.getAll(this.venueId).then(response => {
            if (response.status === 200) {
                this.setState({
                    checkpoints: response.data,
                    showLoadingScreen: false
                });
            } else {
                this.setState({
                    showErrorDialog: true,
                    errorMessages: this.responseService.getErrorMessages(response.data),
                    showLoadingScreen: false
                });
            }
        });
    };

    /**
     * Creates default checkpoint.
     *
     * @param {String} zoneName
     * @param {String} checkpointName
     */
    createDefaultCheckpoint = (zoneName, checkpointName = 'Main Entry') =>
    {
        this.setState({
            showLoadingScreen: true
        });
        this.globalCheckpointsService.createOne(
            this.venueId,
            {
                name: checkpointName,
                from: null,
                to: zoneName,
                permissionCheckOnly: false
            }
        ).then(response=> {
            if (response.status === 201) {
                this.getZonesResponseData();
            } else {
                this.setState({
                    showErrorDialog: true,
                    errorMessages: this.responseService.getErrorMessages(response.data)
                });
            }
            this.setState({
                showLoadingScreen: false
            });
        })
    };

    /**
     * Redirects user to the review zone settings scene.
     */
    goToReviewZoneSettingScene = () =>
    {
        const path = '/venues/zones-and-checkpoints/' + this.venueId + '/zone-settings';
        this.props.history.push(path);
    };

    /**
     * Checks if zone name is valid and if not adds error to input field.
     *
     * @returns {Boolean}
     */
    isValidZoneName = (name = this.state.newZoneName) =>
    {
        let format = /[!@#$%^&*()_+=[\]{};:\\|,.<>/?]/;
        let errors = this.validatorService.validate(this.state.newZoneName, [
            new NotBlank(), new MinLength(3), new MaxLength(50), new Type('string')
        ]);

        if (format.test(name)) {
            errors = ['Zone name should not contain special characters.'];
        }

        let isValid = errors.length === 0;

        if (!isValid) {
            this.setState({
                newZoneNameErrors: errors
            });
        }

        return isValid
    };

    /**
     * Sends request for creating new zone.
     */
    createNewZone = () =>
    {
        if ( this.isValidZoneName() ) {
            this.setState({
                showLoadingScreen: true
            });
            this.globalZoneService.createOne(
                this.venueId,
                {
                    name: this.state.newZoneName
                }
            ).then(response => {
                    if (response.status === 201) {
                        this.getZonesResponseData();
                    } else {
                        this.setState({
                            showErrorDialog: true,
                            errorMessages: this.responseService.getErrorMessages(response.data)
                        });
                    }
                    this.setState({
                        showLoadingScreen: false
                    });
                }
            );
            this.setState({
                newZoneName: '',
                newZoneNameErrors: '',
                showAddZoneModal: false
            });
        }
    };

    /**
     * Updates state value responsible for showing edit zone modal.
     */
    closeEditZoneModal = () =>
    {
        this.setState({
            newZoneNameErrors: '',
            showEditZoneDialog: false
        });
    };

    /**
     * Sets state when adding new value.
     *
     * @param value
     */
    receiveValue = (value) =>
    {
        let nameFieldErrors = this.state.newZoneNameErrors;

        this.setState({
            newZoneName: value,
            newZoneNameErrors: this.isValidZoneName(value) ? [] : nameFieldErrors
        });
    };

    /**
     * Removes Zone.
     */
    removeZone = () => {
        this.setState({
            showLoadingScreen: true
        });
        this.globalZoneService.deleteOne(this.venueId, this.state.zoneName).then(response => {
                if (response.status === 204) {
                    this.getZonesResponseData();
                    this.closeDeleteConfirmationDialog();
                } else {
                    this.setState({
                        showErrorDialog: true,
                        errorMessages: this.responseService.getErrorMessages(response.data)
                    });
                }
                this.setState({
                    showLoadingScreen: false
                });
            }
        );
    };

    /**
     * Edits zone.
     */
    editZone = () =>
    {
        if ( this.isValidZoneName() ) {
            this.setState({
                showLoadingScreen: true
            });

            let data = {
                name: this.state.newZoneName
            };

            this.state.zones.map(zone => {
                if (this.state.zoneName === zone.name) {
                    data = {
                        ...data,
                        rules: zone.rules
                    }
                }
                return null;
            });

            this.globalZoneService.updateOne(
                this.venueId,
                this.state.zoneName,
                data
            ).then(response => {
                    if (response.status === 204) {
                        this.getZonesResponseData();
                    } else {
                        this.setState({
                            showErrorDialog: true,
                            errorMessages: this.responseService.getErrorMessages(response.data)
                        });
                    }
                    this.setState({
                        showLoadingScreen: false
                    });
                }
            );
            this.closeEditZoneModal();
        }
    };

    /**
     * Updates state and opens confirmation dialog.
     *
     * @param {String} zoneName
     */
    openEditZoneDialog = (zoneName) =>
    {
        this.setState({
            showEditZoneDialog: true,
            zoneName: zoneName
        });
    };

    /**
     * Updates state and closes delete confirmation dialog.
     */
    closeDeleteConfirmationDialog = () =>
    {
        this.setState({
            showDeleteConfirmationDialog: false,
            zoneName: ''
        });
    };

    /**
     * Updates state and opens delete dialog.
     *
     * @param {String} zoneName
     */
    openDeleteConfirmationDialog = (zoneName) =>
    {
        this.setState({
            showDeleteConfirmationDialog: true,
            zoneName: zoneName
        });
    };

    /**
     * Returns value of tag component.
     *
     * @param {Object} filters
     */
    getZoneFilters = (filters) =>
    {
        let rules = [];
        if (filters.value) {
            let rowConditions = filters.value.split(' || ');

            rowConditions.map(rowCondition => {
                let rulesRow = [];

                let rowAndConditions = rowCondition.split(' && ');
                rowAndConditions.map(rowAndCondition => {
                    let operators = ['=','!=','<','>','<=','>=','@=','!@='];
                    let splitOperator = '';
                    operators.map(operator => {
                        if (rowAndCondition.search(operator) >= 0) {
                            splitOperator = operator;
                        }

                        return true;
                    });
                    if (splitOperator !== '') {
                        let rule = {};
                        let operator = '';
                        switch (splitOperator) {
                            case '=':
                                operator = 'equal';
                                break;
                            case '!=':
                                operator = 'notEqual';
                                break;
                            case '<':
                                operator = 'less';
                                break;
                            case '>':
                                operator = 'greater';
                                break;
                            case '<=':
                                operator = 'lessEqual';
                                break;
                            case '>=':
                                operator = 'greaterEqual';
                                break;
                            case '@=':
                                operator = 'contains';
                                break;
                            case '!@=':
                                operator = 'notContains';
                                break;
                            default:
                                operator = '=';
                        }
                        rule['operator'] = operator;
                        rule['field'] = rowAndCondition.split(' ' + splitOperator + ' ')[0];
                        rule['operand'] = rowAndCondition.split(' ' + splitOperator + ' ')[1];
                        rulesRow.push(rule);
                    }

                    return true;
                });

                rules.push(rulesRow);

                return true;
            });
        }
        this.setState({
            showLoadingScreen: true
        });
        this.globalZoneService.updateOne(this.venueId, filters.uid,{'name':filters.uid,'rules':rules}).then(response => {
            if (response.status === 204) {
                this.getZonesResponseData();
            }
            this.setState({
                showLoadingScreen: false
            });
        });
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <div className="ZonesAndCheckpoints">

                <Tite name={this.props.venue?.name} />
                
                {/* Renders zones and checkpoints top section. */}
                <div className="top-section">
                    <Button className={ButtonAdd} onClickHandler={() => this.setState({showAddZoneModal: true})}>
                        <T _str="Create new"/>
                    </Button>
                    <Button onClickHandler={() => this.goToReviewZoneSettingScene()}>
                        <T _str="Review zone settings"/>
                    </Button>
                    <h4><T _str="Define where tickets are valid"/></h4>
                    <T _str="Everywhere where you put your stuff for checking tickets you need a checkpoint"/>
                </div>
                
                <RenderZonesAndCheckpoints
                    venueId={this.venueId}
                    zones={this.state.zones}
                    zonesService={this.zonesService}
                    checkpoints={this.state.checkpoints}
                    checkpointsService={this.checkpointsService}
                    ticketPropertyKeys={this.state.ticketPropertyKeys}
                    getZoneFilters={this.getZoneFilters}
                    responseService={ response => this.setState({
                        showErrorDialog: true,
                        errorMessages: this.responseService.getErrorMessages(response.data)
                    })}
                    openEditZoneDialog={this.openEditZoneDialog}
                    openDeleteConfirmationDialog={this.openDeleteConfirmationDialog}
                />

                {/* Shows New Zone dialog. */}
                {this.state.showAddZoneModal &&
                    <Dialog
                        action={() => this.createNewZone()}
                        showModal={this.state.showAddZoneModal}
                        title={<T _str="Create new yoshi zone"/>}
                        closeModal={() => this.setState({
                            newZoneNameErrors: '',
                            showAddZoneModal: false
                        })}
                        >
                        <Input
                            focus
                            name="Zone name"
                            label={<T _str="Name of zone"/>}
                            getValue={this.receiveValue}
                            className={this.state.newZoneNameErrors.length > 0 ? 'error' : ''}
                            />
                        {this.state.newZoneNameErrors.length > 0 &&
                            this.state.newZoneNameErrors.map((error, key) => <div key={key} className="error">
                                    - {error}
                                </div>
                        )}
                    </Dialog>
                }

                {/* Renders edit dialog. */}
                {this.state.showEditZoneDialog &&
                    <Dialog
                        action={() => this.editZone()}
                        mainButton={<T _str="Update"/>}
                        title={<T _str="Edit yoshi zone"/>}
                        closeModal={ this.closeEditZoneModal }
                        showModal={ this.state.showEditZoneDialog }
                        >
                        <Input
                            focus
                            name="Action name"
                            getValue={this.receiveValue}
                            value={ this.state.zoneName }
                            label={<T _str="New Zone Name"/>}
                            className={ this.state.newZoneNameErrors.length > 0 ? 'error' : ''}
                        />
                        { this.state.newZoneNameErrors.length > 0 &&
                            this.state.newZoneNameErrors.map( (error, key) => <div key={ key } className="error">
                                    - { error }
                                </div>
                        )}
                    </Dialog>
                }
                
                {/* Renders confirmation dialog. */}
                {this.state.showDeleteConfirmationDialog &&
                    <Dialog
                        mainButton={<T _str="Remove"/>}
                        action={() => this.removeZone() }
                        title={<T _str="Confirmation dialog"/>}
                        closeModal={ this.closeDeleteConfirmationDialog }
                        showModal={this.state.showDeleteConfirmationDialog}
                    >
                        <h4 className="text-center">
                            <T _str="Are you sure you want to remove this zone?"/>
                        </h4>
                    </Dialog>
                }

                { this.state.showLoadingScreen ? <LoadingScreen/> : null }

                {/* Renders error diagram if an error occur. */}
                <DialogError show={this.state.showErrorDialog} closeDialog={() => this.setState({showErrorDialog : false})}>
                    {
                        this.state.errorMessages.map((message,index) => <p key={index}>{message}</p>)
                    }
                </DialogError>
            </div>
        );
    }
};

const Tite = ({ name }) => {
    const t = useT();
    return (
        <SceneTitle text={t("Zones & Checkpoints")} >
            <p>{name}</p>
        </SceneTitle>
    )
}

// Render zones and checkpoints.
const RenderZonesAndCheckpoints = ({ responseService, checkpoints, zonesService, zones, ticketPropertyKeys, openDeleteConfirmationDialog, openEditZoneDialog, checkpointsService, venueId, getZoneFilters}) =>
{
    const t = useT();
    if (checkpoints) {
        return Object.entries(zonesService.getZonesData(zones)).map((zone,index) => {
            let filtersConfig = {
                'keys' : ticketPropertyKeys,
                'operations' : {'=':'=','!=':'!=','>':'>','<':'<','>=':'>=','<=':'<=','@=':'@=','!@=':'!@='},
                'buttonTitle' : t('Create first filter item'),
                'dialogTitle' : t("Create new filter item for ") + zone[0],
                'value' : zone[1].filters,
                'venueId' : venueId
            };
            return (
                <Panel
                    key={index}
                    title={zone[0]}
                    panelId={zone[0]}
                    colorize className="remove edit"
                    removeHandler={openDeleteConfirmationDialog}
                    editHandler={openEditZoneDialog}
                >
                    <Checkpoint
                        zone={zone[0]}
                        venueId={venueId}
                        data={checkpointsService.getCheckpointsData(checkpoints, zone[0])}
                    />
                    <h3><T _str="Tickets are valid for this zone if:"/></h3>
                    <Rule
                        config={filtersConfig}
                        uid={zone[0]}
                        getValue={getZoneFilters}
                        errorHandler={responseService}
                    />
                </Panel>
            );
        })
    }
    return <br />
};

export default ZonesAndCheckpoints;