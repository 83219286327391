import React, { Component } from "react";
import { T, useT } from "@transifex/react";
import { Search } from "../../themes/default/Form/components/Search";
import { Input } from "../../themes/default/Form/components/Input";
import { Select } from "../../themes/default/Form/components/Select";
import { MultiSelect } from "../../themes/default/Form/components/MultiSelect";
import {
  Button,
  ButtonAdd,
  ButtonSize,
} from "../../themes/default/Form/components/Button";
import { List } from "./components/List";
import { ProviderService } from "../../services/api/Provider";
import { ShowService } from "../../services/api/Show";
import { SelectService } from "./services";
import { ResponseService } from "../../services/utils/Response";
import { LoadingScreen } from "../../components/Loading";
import { SortService } from "../../services/utils/Sort";
import Dialog from "../../themes/default/Dialog/Dialog";
import { ExpandingCard } from "../../themes/default/ExpandingCard";
import { DialogError } from "../../themes/default/Dialog";
import { Toggle } from "../../themes/default/Toggle";
import {
  ValidatorService,
  NotBlank,
  DateTime,
  Date,
  MinLength,
  MaxLength,
  Type,
} from "pretty-validator";
import { Pagination } from "../../components/Pagination";
import { DateTimeService } from "../../services/utils/DateTime";
import { DatePickerInput, DatePeriodPicker } from "../../components/DatePicker";
import moment from "moment";
import IconReload from "./images/ico-loading.svg";
import { CloseIcon } from "../../components/Images";

import "./style.css";
import "react-datepicker/dist/react-datepicker.css";

/**
 *  Stores latest timeout id.
 */
let timeoutId;

/**
 * @class ./scenes/ShowManagement/ShowManagement
 */
class ShowManagement extends Component {
  /**
   * @type {Object}
   */
  validatorService;

  /**
   * @type {Object}
   */
  showService;

  /**
   * @type {Object}
   */
  sortService;

  /**
   * @type {Object}
   */
  selectService;

  /**
   * @type {Object}
   */
  responseService;

  /**
   * @type {Object}
   */
  providerService;

  state = {
    searchShowName: "",
    newShowName: "",
    newShowNameErrors: [],
    printTitle: "",
    printTitleErrors: [],
    beginDateChecked: false,
    beginDate: "",
    beginAfterDate: "",
    beginDateErrors: [],
    endDateChecked: false,
    endDate: "",
    endBeforeDate: "",
    endDateErrors: [],
    showCreatedByYoshiChecked: false,
    pageSize: 50,
    currentPage: 1,
    totalPageCount: 1,
    sort: "",
    showAddShowModal: false,
    queryParameter: "",
    shows: {},
    multiSelectData: [],
    showLoadingScreen: false,
    showErrorDialog: false,
    errorMessages: [],
    showAdvanceSearchOptions: false,
    providersList: {},
    providers: [],
    clientId: "--",
  };

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.showService = new ShowService();
    this.selectService = new SelectService();
    this.sortService = new SortService();
    this.validatorService = new ValidatorService();
    this.responseService = new ResponseService();
    this.dateTimeService = new DateTimeService();
    this.providerService = new ProviderService();
  }

  /**
   * @override
   */
  componentDidMount() {
    document.title = "See Tickets - Shows";
    this.handleShowList(this.getParameterObjectFromLocalStorage());
    this.loadMultiSelectOptions();
  }

  /**
   * Sets the state of MultiSelectData.
   */
  loadMultiSelectOptions = () =>
    this.setState({ multiSelectData: this.selectService.getBarcodeOptions() });

  /**
   * Handles MultiSelect dropdown list toggle.
   *
   * @param {Object} item
   */
  handleToggleMultiSelect = (item) => {
    const multiSelectData = [...this.state.multiSelectData];
    const index = multiSelectData.indexOf(item);
    multiSelectData[index] = { ...multiSelectData[index] };
    multiSelectData[index].selected = !multiSelectData[index].selected;

    this.setState({
      multiSelectData,
    });
  };

  /**
   * Retrieves list of shows and sets state accordingly.
   *
   * @param {Object} showParams
   */
  handleShowList = (showParams) => {
    this.setState({
      showLoadingScreen: true,
    });

    this.fetchProviders();

    let params = {
      filter: showParams.filter,
      page: showParams.page,
      sort: showParams.sort,
      pageSize: showParams.pageSize,
      onlyAdminShows: showParams.onlyAdminShows,
    };

    this.showService.getAll(params).then((response) => {
      if (response.status === 200) {
        if (
          JSON.stringify(response.data) !== JSON.stringify(this.state.shows)
        ) {
          this.setState({
            shows: response.data,
            currentPage: response.data.currentPage,
            totalPageCount: response.data.totalPageCount,
          });
        }
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Retrieves list of providers.
   */
  fetchProviders = () => {
    const params = { PageSize: 9999, Page: 1 };

    this.providerService.getAll(params).then((response) => {
      if (response.status === 200) {
        if (
          JSON.stringify(response.data) !== JSON.stringify(this.state.providers)
        ) {
          const obj = { "--": "--" };
          response.data.results.map((provider) => {
            obj[provider.clientId] = provider.name;
            return null;
          });

          this.setState({ providersList: obj });
        }
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
    });
  };

  /**
   * Returns request's parameter object containing all filters.
   *
   * @param {Number} page
   * @param {String} sort
   * @param {Number} pageSize
   * @param {String} beginDate
   * @param {String} endDate
   * @param {Boolean} createdByYoshiAdmin
   * @param {Boolean} isBeginChecked
   * @param {Boolean} isEndChecked
   *
   * @returns {Object}
   */
  getParameterObject = (
    page = this.state.currentPage,
    sort = this.state.sort,
    pageSize = this.state.pageSize,
    beginDate = this.state.beginAfterDate,
    endDate = this.state.endBeforeDate,
    createdByYoshiAdmin = this.state.showCreatedByYoshiChecked,
    isBeginChecked = this.state.beginDateChecked,
    isEndChecked = this.state.endDateChecked,
    clientId = this.state.clientId
  ) => {
    let filter = this.state.queryParameter;

    if (filter !== "" && beginDate !== "" && isBeginChecked) {
      filter += ",";
    }

    if (beginDate !== "" && isBeginChecked) {
      filter += "begin>" + beginDate;
    }

    if (
      (beginDate !== "" && isBeginChecked && endDate !== "" && isEndChecked) ||
      (this.state.queryParameter !== "" && endDate !== "" && isEndChecked)
    ) {
      filter += ",";
    }

    if (endDate !== "" && isEndChecked) {
      filter += "end<" + endDate;
    }

    if (clientId !== "--") {
      const value = `providerId==${clientId}`;
      filter += filter !== "" ? `,${value}` : value;
    }

    let params = {
      searchShowName: this.state.searchShowName,
      filter: filter,
      page: page,
      sort: sort,
      pageSize: pageSize,
      onlyAdminShows: createdByYoshiAdmin,
      beginAfterDate: beginDate,
      endBeforeDate: endDate,
      beginDateChecked: isBeginChecked,
      endDateChecked: isEndChecked,
      clientId: clientId,
      showAdvanceSearchOptions: this.state.showAdvanceSearchOptions,
    };

    this.setParameterObjectToLocalStorage(params);

    return params;
  };

  getParameterObjectFromLocalStorage = () => {
    let params = JSON.parse(localStorage.getItem("showsParams"));

    if (params) {
      this.setState({
        searchShowName: params.searchShowName,
        page: params.currentPage,
        sort: params.sort,
        pageSize: params.pageSize,
        beginAfterDate: params.beginAfterDate,
        endBeforeDate: params.endBeforeDate,
        showCreatedByYoshiChecked: params.onlyAdminShows,
        beginDateChecked: params.beginDateChecked,
        endDateChecked: params.endDateChecked,
        clientId: params.clientId,
        showAdvanceSearchOptions: params.showAdvanceSearchOptions,
      });

      return params;
    }

    return this.getParameterObject();
  };

  setParameterObjectToLocalStorage = (params) => {
    localStorage.setItem("showsParams", JSON.stringify(params));
  };

  removeParameterObjectFromLocalStorage = () => {
    localStorage.removeItem("showsParams");
  };

  clearFilters = () => {
    this.removeParameterObjectFromLocalStorage();
    this.setState(
      {
        queryParameter: "",
        searchShowName: "",
        page: 1,
        sort: "",
        pageSize: 50,
        totalPageCount: 1,
        beginAfterDate: "",
        endBeforeDate: "",
        showCreatedByYoshiChecked: false,
        beginDateChecked: false,
        endDateChecked: false,
        clientId: "--",
        showAdvanceSearchOptions: false,
      },
      function () {
        this.handleShowList(this.getParameterObject());
      }
    );
  };

  /**
   * Sets state when adding new value.
   *
   * @param value
   */
  receiveShowNameValue = (value) => {
    let nameFieldErrors = this.state.newShowNameErrors;

    this.setState({
      newShowName: value,
      newShowNameErrors: this.isValidShowName(value) ? [] : nameFieldErrors,
    });
  };

  /**
   * Sets state when adding new value.
   *
   * @param value
   */
  receivePrintTitleValue = (value) => {
    let nameFieldErrors = this.state.printTitleErrors;

    this.setState({
      printTitle: value,
      printTitleErrors: this.isValidPrintTitle(value) ? [] : nameFieldErrors,
    });
  };

  /**
   * Sets state when adding new value.
   *
   * @param value
   */
  receiveBeginDateValue = (value) => {
    let nameFieldErrors = this.state.beginDateErrors;

    let isBeginDateEarlierThanEndDate = false;

    if (value !== "" && this.state.endDate !== "") {
      const convertedStartDate = moment(
        value,
        "DD-MM-YYYY HH:mm"
      ).toISOString();
      const convertedEndDate = moment(
        this.state.endDate,
        "DD-MM-YYYY HH:mm"
      ).toISOString();

      isBeginDateEarlierThanEndDate =
        moment(convertedStartDate).isBefore(convertedEndDate);
    } else if (value !== "" && this.state.endDate === "") {
      isBeginDateEarlierThanEndDate = true;
    }

    const error = this.isValidBeginDate(value)
      ? isBeginDateEarlierThanEndDate
        ? []
        : [
            <T _str="This is not valid datetime." />,
            <T _str="Begin Date cannot be after the End Date" />,
          ]
      : nameFieldErrors;

    this.setState({
      beginDate: value,
      beginDateErrors: error,
      endDateErrors: this.isValidEndDate(value) ? [] : this.state.endDateErrors,
    });
  };

  /**
   * Sets state when adding new value.
   *
   * @param value
   */
  receiveEndDateValue = (value) => {
    let nameFieldErrors = this.state.endDateErrors;

    let isBeginDateEarlierThanEndDate = false;

    if (value !== "" && this.state.beginDate !== "") {
      const convertedStartDate = moment(
        this.state.beginDate,
        "DD-MM-YYYY HH:mm"
      ).toISOString();
      const convertedEndDate = moment(value, "DD-MM-YYYY HH:mm").toISOString();

      isBeginDateEarlierThanEndDate =
        moment(convertedStartDate).isBefore(convertedEndDate);
    } else if (value !== "" && this.state.beginDate === "") {
      isBeginDateEarlierThanEndDate = true;
    }

    const error = this.isValidEndDate(value)
      ? isBeginDateEarlierThanEndDate
        ? []
        : [
            <T _str="This is not valid datetime." />,
            <T _str="End Date cannot be before the Begin Date" />,
          ]
      : nameFieldErrors;

    this.setState({
      endDate: value,
      endDateErrors: error,
      beginDateErrors: this.isValidBeginDate(value)
        ? []
        : this.state.beginDateErrors,
    });
  };

  isBeginDateBeforeEndDate = () => {
    const convertedStartDate = moment(
      this.state.beginDate,
      "DD-MM-YYYY HH:mm"
    ).toISOString();
    const convertedEndDate = moment(
      this.state.endDate,
      "DD-MM-YYYY HH:mm"
    ).toISOString();
    return moment(convertedStartDate).isBefore(convertedEndDate);
  };

  /**
   * Updates state value responsible for showing create new venue modal.
   */
  closeAddShowModal = () => {
    this.setState({
      newShowName: "",
      newShowNameErrors: "",
      printTitle: "",
      printTitleErrors: "",
      beginDate: "",
      beginDateErrors: "",
      endDate: "",
      endDateErrors: "",
      multiSelectData: this.selectService.getBarcodeOptions(),
      showAddShowModal: false,
    });
  };

  /**
   * Sends request for creating new show. and redirect to this show
   */
  createNewShow = () => {
    if (
      this.isValidShowName() &&
      this.isValidPrintTitle() &&
      this.isValidBeginDate() &&
      this.isValidEndDate() &&
      this.isBeginDateBeforeEndDate()
    ) {
      this.setState({
        showLoadingScreen: true,
      });

      this.showService
        .createOne({
          name: this.state.newShowName,
          printTitle: this.state.printTitle,
          begin: this.dateTimeService.getUTCTimeFromLocalTime(
            this.state.beginDate
          ),
          end: this.dateTimeService.getUTCTimeFromLocalTime(this.state.endDate),
          barcodeSymbologies: this.selectService.barcodeOptionsToTitleList(
            this.state.multiSelectData
          ),
        })
        .then((response) => {
          if (response.status === 201) {
            this.props.history.push(`/shows/details/${response.data.id}`);
          } else {
            this.setState({
              showErrorDialog: true,
              errorMessages: this.responseService.getErrorMessages(
                response.data
              ),
            });
          }
          this.setState({
            showLoadingScreen: false,
          });
        });
      this.setState({
        newShowNameErrors: "",
        printTitleErrors: "",
        beginDateErrors: "",
        endDateErrors: "",
        showAddShowModal: false,
      });
    }
  };

  /**
   * Checks if show name is valid and if not adds error to input field.
   *
   * @returns {Boolean}
   */
  isValidShowName = (name = this.state.newShowName) => {
    let errors = this.validatorService.validate(name, [
      new NotBlank(),
      new MinLength(3),
      new MaxLength(50),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        newShowNameErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Checks if print title is valid and if not adds error to input field.
   *
   * @returns {Boolean}
   */
  isValidPrintTitle = (name = this.state.printTitle) => {
    let errors = this.validatorService.validate(name, [
      new NotBlank(),
      new MinLength(3),
      new MaxLength(50),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        printTitleErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Checks if begin date is valid and if not adds error to input field.
   *
   * @param {String} date
   *
   * @returns {Boolean}
   */
  isValidBeginDate = (date = this.state.beginDate) => {
    let errors = this.validatorService.validate(date, [
      new NotBlank(),
      new DateTime(),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        beginDateErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Checks if begin date is valid and if not adds error to input field.
   *
   * @param {String} date
   *
   * @returns {Boolean}
   */
  isValidDate = (date) => {
    let errors = this.validatorService.validate(date, [
      new NotBlank(),
      new Date(),
      new Type("string"),
    ]);

    return errors.length === 0;
  };

  /**
   * Transforms datetime format from dd-mm-yyyy hh:mm to mm-dd-yyyy hh:mm.
   *
   * @param {String} dateString
   *
   * @returns {String}
   */
  formatDate = (dateString) => {
    let datetimeArray = dateString.split(" ");
    let date = datetimeArray[0];
    let dateArray = date.split("-");
    let day = dateArray[0];
    let month = dateArray[1];
    let year = dateArray[2];
    let formattedDate = [month, day, year].join("-");

    return datetimeArray[1]
      ? [formattedDate, datetimeArray[1]].join(" ")
      : formattedDate;
  };

  /**
   * Checks if end date is valid and if not adds error to input field.
   *
   * @param {String} date
   *
   * @returns {Boolean}
   */
  isValidEndDate = (date = this.state.endDate) => {
    let errors = this.validatorService.validate(date, [
      new NotBlank(),
      new DateTime(),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        endDateErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Handles state when opening New Venue modal.
   */
  handleAddVenueButtonClick = () => this.setState({ showAddShowModal: true });

  /**
   * Returns shows per page value from dropdown.
   *
   * @param {Object} value
   */
  getShowsPerPageValue = (value) => {
    let pageSize = parseInt(value.value, 10);

    if (this.state.pageSize !== pageSize) {
      this.setState({
        pageSize: pageSize,
      });

      this.handleShowList(
        this.getParameterObject(1, this.state.sort, pageSize)
      );
    }
  };

  /**
   * Returns search value.
   *
   * @param {String} value
   */
  getSearchValue = (value) => {
    // don't refresh the show list if the search value isn't different from what's in state
    if (value === this.state.searchShowName) {
      return;
    }

    let queryParameter = "";

    if (value) {
      queryParameter =
        "(name|externalid|event|town|location)@=*" + encodeURIComponent(value);
    }

    this.setState({
      queryParameter: queryParameter,
      searchShowName: value,
    });

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      if (value.length > 2 || value.length === 0) {
        this.handleShowList(this.getParameterObject(1));
      }
    }, 300);
  };

  /**
   * Returns only shows created by YoshiV admin checkpoint's value.
   *
   * @param {Boolean} value
   */
  getOnlyShowCreatedByAdminCheckboxValue = (value) => {
    this.setState({ showCreatedByYoshiChecked: value });

    this.handleShowList(
      this.getParameterObject(
        1,
        this.state.sort,
        this.state.pageSize,
        this.state.beginAfterDate,
        this.state.endBeforeDate,
        value
      )
    );
  };

  /**
   * Returns begin after checkpoint's value.
   *
   * @param {Boolean} value
   */
  getBeginAfterCheckboxValue = (value) => {
    this.setState({ beginDateChecked: value });

    this.handleShowList(
      this.getParameterObject(
        1,
        this.state.sort,
        this.state.pageSize,
        this.state.beginAfterDate,
        this.state.endBeforeDate,
        this.state.showCreatedByYoshiChecked,
        value
      )
    );
  };

  /**
   * Returns end before checkpoint's value.
   *
   * @param {Boolean} value
   */
  getEndBeforeCheckboxValue = (value) => {
    this.setState({ endDateChecked: value });

    this.handleShowList(
      this.getParameterObject(
        1,
        this.state.sort,
        this.state.pageSize,
        this.state.beginAfterDate,
        this.state.endBeforeDate,
        this.state.showCreatedByYoshiChecked,
        this.state.beginDateChecked,
        value
      )
    );
  };

  /**
   * Returns begin after value.
   *
   * @param {String} value
   */
  getBeginAfterValue = (date) => {
    const value = date !== null ? moment(date).format("DD-MM-YYYY") : "";
    if (this.isValidDate(value)) {
      this.handleShowList(
        this.getParameterObject(
          1,
          this.state.sort,
          this.state.pageSize,
          this.formatDate(value)
        )
      );
    }

    this.setState({
      beginAfterDate: value !== "" ? this.formatDate(value) : "",
    });
  };

  /**
   * Returns end before value.
   *
   * @param {String} value
   */
  getEndBeforeValue = (date) => {
    const value = date !== null ? moment(date).format("DD-MM-YYYY") : "";
    if (this.isValidDate(value)) {
      this.handleShowList(
        this.getParameterObject(
          1,
          this.state.sort,
          this.state.pageSize,
          this.state.beginAfterDate,
          this.formatDate(value)
        )
      );
    }

    this.setState({
      endBeforeDate: value !== "" ? this.formatDate(value) : "",
    });
  };

  /**
   * Sorts Venue name column ascending or descending and sets state accordingly.
   *
   * @param {String} column
   */
  sortColumn = (column) => {
    let sort = this.sortService.getSortValue(this.state.sort, column);

    this.setState({ sort });

    this.handleShowList(this.getParameterObject(this.state.currentPage, sort));
  };

  /**
   * Updates state and closes error dialog.
   */
  closeErrorDialog = () => this.setState({ showErrorDialog: false });

  /**
   * Updates shown shows according to selected page.
   *
   * @param {Number} page
   */
  changePageHandler = (page) => {
    if (this.state.currentPage !== page) {
      this.handleShowList(this.getParameterObject(page));
    }
  };

  toggleAdvanceSearchOptions = () => {
    const { showAdvanceSearchOptions } = this.state;

    this.setState({
      beginDateChecked: false,
      endDateChecked: false,
      beginAfterDate: "",
      endBeforeDate: "",
      showAdvanceSearchOptions: !showAdvanceSearchOptions,
      clientId: "--",
      showLoadingScreen: showAdvanceSearchOptions,
    });

    this.state.showAdvanceSearchOptions &&
      setTimeout(() => {
        this.handleShowList(this.getParameterObject());
      }, 800);
  };

  getProvider = (obj) => {
    this.setState({
      showLoadingScreen: true,
    });

    const clientId =
      obj.value !== undefined ? (obj.value !== "--" ? obj.value : "--") : "--";

    obj.value !== this.state.clientId && this.setState({ clientId });

    setTimeout(() => {
      this.handleShowList(this.getParameterObject());
    }, 500);
  };

  /**
   * Returns adapted data for show list.
   *
   * @param {Object} responseData
   *
   * @returns {Object}
   */
  getShowData = (responseData, isMobileView) => {
    let tableData = {};

    responseData["results"] &&
      responseData["results"].map((show) => {
        let showData = [];

        let showNameColumn = {
          cellData: show.name,
          href: "/shows/details/",
        };

        showData.push(showNameColumn);
        if (show.begin) {
          showData.push(
            this.dateTimeService.replaceYearAndDate(
              this.dateTimeService.getLocalDateTime(show.begin)
            )
          );
        } else {
          showData.push("");
        }

        if (show.end) {
          showData.push(
            this.dateTimeService.replaceYearAndDate(
              this.dateTimeService.getLocalDateTime(show.end)
            )
          );
        } else {
          showData.push("");
        }

        if (!isMobileView) {
          if (show.event === null) {
            showData.push("");
          } else {
            showData.push(show.event);
          }

          if (show.location === null) {
            showData.push("");
          } else {
            showData.push(show.location);
          }

          if (show.town === null) {
            showData.push("");
          } else {
            showData.push(show.town);
          }

          if (show.providerName === null) {
            showData.push("");
          } else {
            showData.push(show.providerName);
          }
        }

        if (show.externalId === null) {
          showData.push("");
        } else {
          showData.push({
            cellData: show.externalId,
            className: "ex-show-id",
          });
        }

        const ticketCountColumn = {
          cellData: show.ticketCount,
          className: "tickets-Count",
        };
        !isMobileView && showData.push(ticketCountColumn);

        tableData[show.id] = showData;

        return true;
      });

    return tableData;
  };

  /**
   * @returns {XML}
   */
  render() {
    const { isMobileView } = this.props;

    return (
      <div className="ShowManagement">
        <div className="row controls-container p-0 m-0 mb-3">
          <div className="d-flex search">
            <Search
              className="col-md"
              name="show-name"
              placeholder={<T _str="Search" />}
              getValue={this.getSearchValue}
              value={this.state.searchShowName}
            />
            <Toggle
              label={<T _str="Only shows created by YoshiV Admin" />}
              isChecked={this.state.showCreatedByYoshiChecked}
              callBack={this.getOnlyShowCreatedByAdminCheckboxValue}
            />
          </div>

          <div className="buttons">
            <div className="d-flex buttons-wrapper">
              <Select
                data={{ 5: "5", 20: "20", 50: "50", 100: "100", 200: "200" }}
                getValue={this.getShowsPerPageValue}
                initialValue={this.state.pageSize}
              />

              <Button
                className={ButtonAdd}
                onClickHandler={() => this.handleAddVenueButtonClick()}
              >
                <T _str="Create new" />
              </Button>

              <Button
                className={ButtonSize.Tiny}
                onClickHandler={() =>
                  this.handleShowList(this.getParameterObject())
                }
              >
                <img src={IconReload} alt="Reload" />
              </Button>

              <Button
                className={ButtonSize.Tiny}
                onClickHandler={() => this.clearFilters()}
              >
                {CloseIcon({ stroke: "white" })}
              </Button>

              <a
                className="expanding-card-button"
                href="#0"
                onClick={this.toggleAdvanceSearchOptions}
              >
                <T _str="Advanced Search" />
              </a>
            </div>
          </div>
        </div>

        <ExpandingCard show={this.state.showAdvanceSearchOptions}>
          <div className="row controls-container p-0 m-0">
            <div className="d-flex dates">
              <Select
                getValue={this.getProvider}
                data={this.state.providersList}
                initialValue={this.state.clientId}
              />

              <Toggle
                label={<T _str="Begin date" />}
                isChecked={this.state.beginDateChecked}
                callBack={this.getBeginAfterCheckboxValue}
              />

              <DatePickerInput
                callBack={this.getBeginAfterValue}
                className="mini-date small-date-field"
                dateFormat="dd-MM-yyyy"
                placeholderText="dd-mm-yyyy"
                disabled={!this.state.beginDateChecked}
                value={this.state.beginAfterDate}
              />

              <Toggle
                label={<T _str="End before" />}
                isChecked={this.state.endDateChecked}
                callBack={this.getEndBeforeCheckboxValue}
              />
              <DatePickerInput
                callBack={this.getEndBeforeValue}
                className="mini-date small-date-field"
                dateFormat="dd-MM-yyyy"
                placeholderText="dd-mm-yyyy"
                disabled={!this.state.endDateChecked}
                value={this.state.endBeforeDate}
              />
            </div>
          </div>
        </ExpandingCard>

        <div className="Shows-list-container">
          <List
            sort={this.sortColumn}
            isMobileView={isMobileView}
            currentSorting={this.state.sort}
            showLoadingScreen={this.state.showLoadingScreen}
            data={this.getShowData(this.state.shows, isMobileView)}
          />
        </div>

        <Pagination
          currentPage={this.state.currentPage}
          totalPageCount={this.state.totalPageCount}
          changePageHandler={this.changePageHandler}
        />

        {this.state.showLoadingScreen && <LoadingScreen />}

        {/* Create new show dialog. Shows New Show dialog.*/}
        <NewShowDialog
          showAddShowModal={this.state.showAddShowModal}
          newShowNameErrors={this.state.newShowNameErrors}
          printTitleErrors={this.state.printTitleErrors}
          beginDateErrors={this.state.beginDateErrors}
          endDateErrors={this.state.endDateErrors}
          multiSelectData={this.state.multiSelectData}
          createNewShow={() => this.createNewShow()}
          closeAddShowModal={this.closeAddShowModal}
          receiveShowNameValue={this.receiveShowNameValue}
          receivePrintTitleValue={this.receivePrintTitleValue}
          receiveBeginDateValue={this.receiveBeginDateValue}
          receiveEndDateValue={this.receiveEndDateValue}
          handleToggleMultiSelect={this.handleToggleMultiSelect}
        />

        {/* Error diagram if an error occur. */}
        <DialogError
          show={this.state.showErrorDialog}
          closeDialog={this.closeErrorDialog}
        >
          {this.state.errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>
      </div>
    );
  }
}

const NewShowDialog = ({
  showAddShowModal,
  newShowNameErrors,
  printTitleErrors,
  beginDateErrors,
  endDateErrors,
  multiSelectData,
  createNewShow,
  closeAddShowModal,
  receiveShowNameValue,
  receivePrintTitleValue,
  receiveBeginDateValue,
  receiveEndDateValue,
  handleToggleMultiSelect,
}) => {
  const t = useT();

  return (
    showAddShowModal && (
      <Dialog
        title={t("Create new show")}
        showModal={showAddShowModal}
        action={createNewShow}
        closeModal={closeAddShowModal}
      >
        {/* Returns input field for name of show along with validation errors if any. */}
        <div>
          <Input
            label={t("Show name")}
            name="show name"
            getValue={receiveShowNameValue}
            className={newShowNameErrors.length > 0 ? "error" : ""}
            focus
          />
          {newShowNameErrors.length > 0 &&
            newShowNameErrors.map((error, key) => (
              <div key={key} className="error">
                - {error}
              </div>
            ))}
        </div>

        {/* Input field for print title along with validation errors if any. */}
        <div>
          <Input
            label={t("Print title")}
            name="print title"
            getValue={receivePrintTitleValue}
            className={printTitleErrors.length > 0 ? "error" : ""}
          />
          {printTitleErrors.length > 0 &&
            printTitleErrors.map((error, key) => (
              <div key={key} className="error">
                - {error}
              </div>
            ))}
        </div>

        <DatePeriodPicker
          showTimeSelect
          beginDateErrors={beginDateErrors}
          endDateErrors={endDateErrors}
          beginDateCallBack={receiveBeginDateValue}
          endDateCallBack={receiveEndDateValue}
        />

        {/* Returns Multiselect dropdown for barcode type selection. */}
        <div className="multi-select my-3">
          <label>
            <T _str="Select barcode(s)" />
          </label>
          <MultiSelect
            list={multiSelectData}
            getValue={handleToggleMultiSelect}
          />
        </div>
      </Dialog>
    )
  );
};

export default ShowManagement;
