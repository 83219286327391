import React          from 'react';
import PropTypes      from 'prop-types';
import {
    AlertTypeError,
    AlertTypeInfo,
    AlertTypeSuccess} from './AlertType';

import './style.css'

const Alert = ({ type, title, children}) => 
(
    <div className={`Alert ${type}`} >
        <div className="title">
            <div className="icon" />
            <strong>{ title }</strong>
        </div>
        { children }
    </div>
);

Alert.propTypes = {
    type : PropTypes.oneOf([AlertTypeSuccess, AlertTypeError, AlertTypeInfo]),
};

export default Alert;