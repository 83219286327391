import React, { Component, useId } from 'react';
import { DateTimeService }  from '../../services/utils/DateTime';
import { DragSource }       from 'react-dnd'
import PropTypes            from 'prop-types';
import { Wireless }         from './components/Wireless';
import { Battery }          from './components/Battery';
import { Tooltip }          from 'react-tooltip';
import IconRemove           from './images/ico-remove.svg';

import './style.css';

const DEVICE_LAST_SEEN_HOURS_DELAY = 2;

/**
 * Defines type of device
 *      example: multiple types of devices
 *
 * @type {{ACCEPTS: string}}
 */
const Types = {
    DEVICE: 'device'
};

/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 *
 * You can find more detailed documentation at following link
 * @link http://react-dnd.github.io/react-dnd/docs-drag-source.html
 */
const deviceSource = {
    canDrag(props) {
        return props.data.stagingStatus === 'staged';
    },

    beginDrag(props, monitor, component) {
        return { id: props.data.id };
    },

    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }

        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();

        props.handleDeviceDrop(item.id, dropResult.checkpointName);
    }
};

/**
 * Global variable for storing current timeout id.
 *
 * @type {Number}
 */
let timeoutId = 0;

/**
 * Global variable for storing last scroll position.
 *
 * @type {Number}
 */
let lastScreenY = 0;

/**
 * Global variable for storing last scroll direction.
 *
 * @type {String}
 */
let lastScrollDirection = '';

/**
 * Specifies which props to inject into your component.
 *
 * @param {Object} connect
 * @param {Object} monitor
 *
 * @returns {Object}
 */
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

/**
 * @class components/Device/Device
 */
class Device extends Component
{
    /**
     * @type {Object}
     */
    dateTimeService;

    /**
     * @override
     */
    constructor (props)
    {
        super(props);

        this.dateTimeService = new DateTimeService();
    }

    /**
     * Checks if device is away for certain time.
     *
     * @param {String} lastStatusReport
     *
     * @returns {Boolean}
     */
    isDeviceIsAway = (lastStatusReport) =>
    {
        let hours = this.dateTimeService.getHourDiff(lastStatusReport);

        return hours >= DEVICE_LAST_SEEN_HOURS_DELAY;
    };

    /**
     * Renders overlay element above device.
     *
     * @return {XML}
     */
    getOverlay = () =>
    {
        const {data} = this.props;
        const id = "overlay-" + data.id.replace("::", "");

        if (data.stagingStatus !== 'staged') {
            const status = ['stagingRequest', 'staging'].includes(data.stagingStatus) ? 'staging' : 'destaging';
            return (
                <div className={`overlay ${status}`}>
                    <a id={id}><div /></a>
                    <Tooltip anchorSelect={"#" + id} content={status}/>
                </div>
            );
        } else {
            if (!data.state.checkpoint.latestAdminValueApplied) {
                const status = 'waiting';
                return (
                    <div className="overlay waiting">
                    <a id={id}><div /></a>
                    <Tooltip anchorSelect={"#" + id} content={status}/>
                    </div>
                )
            }
            if (this.isDeviceIsAway(data.state.lastStatusReport)) {
                const status = 'away';
                return (
                    <div className="overlay away">
                        <a id={id}><div /></a>
                        <Tooltip anchorSelect={"#" + id} content={status}/>
                    </div>
                )
            }
        }
    };

    /**
     * Renders delete icon for device
     *
     * @return {XML}
     */
    getDeleteIcon = () =>
    {
        if (this.props.data.stagingStatus === 'staged') {
            const {data} = this.props;
            const id = "deleteicon-" + data.id.replace("::", "");

            return (
                <div className={'delete-icon'}>
                    <a id={id}><img src={IconRemove} onClick={() => this.props.deviceDestageHandler(this.props.data.id)} alt="Remove"/></a>
                    <Tooltip anchorSelect={"#" + id} content="Remove Device"/>
                </div>
            );
        }
    };

    /**
     * Returns info about device status.
     */
    getInfo = () =>
    {
        const away = this.isDeviceIsAway(this.props.data.state.lastStatusReport);
        return (
            <div className={`info ${away ? "away" : ""}`}>
                { this.getOverlay() }
                { this.getDeleteIcon() }
                <Wireless away={away} wifiSignal={this.props.data.state.wifiSignal}/>
                <Battery level={this.props.data.state.batteryLevel} />
                <div className={'device-line'} />
            </div>
        )
    };

    /**
     * Creates smooth scroll from start position to target.
     *
     * @param {Number} targetScroll
     * @param {Number} startScroll
     * @param {Number} recursion
     *
     * @returns {void}
     */
    smoothScroll(targetScroll, startScroll, recursion = 50) {
        let i = 150;
        let temporaryTarget;

        if (targetScroll > startScroll) {
            temporaryTarget = startScroll + 25
        } else {
            temporaryTarget = startScroll - 25
        }

        recursion--;

        if (i < startScroll && recursion > 0) {
            timeoutId = setTimeout(() => {
                window.scrollTo(0, temporaryTarget);
                this.smoothScroll(targetScroll,  temporaryTarget, recursion);
            }, 20);
        }
    }

    /**
     * Handles device drag.
     *
     * @param {Object} event
     */
    handleDrag = (event) => {
        let screenY = event.screenY;
        let windowHeight = window.innerHeight;
        let pageBottom = window.outerHeight;
        let windowScrollTop = document.documentElement.scrollTop;
        // let windowScrollBottom = document.documentElement.scrollBottom;

        let diff = screenY - (windowScrollTop / 2);

        this.getScrollDirection(screenY, lastScreenY);
        if (screenY === 0) {
            if (diff < 0 && lastScrollDirection === 'up') {
                clearTimeout(timeoutId);
                this.smoothScroll(windowScrollTop - windowHeight, windowScrollTop);

            } else if (lastScrollDirection === 'down') {
                clearTimeout(timeoutId);
                this.smoothScroll(pageBottom + windowHeight, windowScrollTop);
            }
        } else {
            clearTimeout(timeoutId);
        }

        lastScreenY = screenY;
    };

    /**
     * Gets scroll direction.
     *
     * @param {Number} screenY
     * @param {Number} lastScreenY
     *
     * @returns {String}
     */
    getScrollDirection = (screenY, lastScreenY) =>
    {
        if (screenY) {
            if (screenY > lastScreenY) {
                lastScrollDirection = 'down';
            } else if (screenY < lastScreenY) {
                lastScrollDirection = 'up';
            }
        }

        return lastScrollDirection;
    };

    /**
     * @returns {XML}
     */
    render()
    {
        const { data, connectDragSource } = this.props;
        const id = data.id;

        return connectDragSource(
            <div id={id} className="device mt-3" onDrag={this.handleDrag}>
                { this.getInfo(id) }
                <div className="deviceName">
                    { data.name }
                </div>
            </div>
        );
    }
}

Device.propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    data: PropTypes.object.isRequired
};

export default DragSource(Types.DEVICE, deviceSource, collect)(Device);