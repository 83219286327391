import React        from 'react';
import { UT, useT } from '@transifex/react'

import './style.css';

const Info = () => 
{
    const t = useT();
    return (
        <div className="Info">
            <p>{t("Welcome to your new venue!")}</p>
            <div className="info-list">
                <div>{t("It works as soon you have defined following elements:")}</div>
                <ul>
                    <li>
                        <UT _str="which <strong>shows</strong> belong to the the venue" />
                    </li>
                    <li>
                        <UT _str="which <strong>zones</strong> and checkpoints has your location" />
                    </li>
                    <li>
                        <UT _str="which <strong>devices</strong> should be used for checking tickets" />
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Info;