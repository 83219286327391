import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import { ListService } from "./services";
import QRCode from "qrcode.react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { T, useT } from "@transifex/react";
import { List } from "./components/List";
import {
  CopyIcon,
  CloseIcon,
  SingleArrowsIcon,
  CheckIcon,
} from "../../components/Images";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LoadingScreen } from "../../components/Loading";
import { Search, Text } from "../../themes/default/Form";
import { SortService } from "../../services/utils/Sort";
import { Alert, AlertTypeInfo } from "../../themes/default/Alert";
import { HandheldService } from "../../services/api/Handheld";
import { Dialog, DialogError } from "../../themes/default/Dialog";
import { Pagination } from "../../components/Pagination";
import { ResponseService } from "../../services/utils/Response";
import { RefreshButton } from "../../components/RefreshButton";
import { Battery } from "../../components/Device/components/Battery";
import { Input } from "../../themes/default/Form/components/Input";
import {
  Button,
  ButtonAdd,
  ButtonSize,
} from "../../themes/default/Form/components/Button";
import { Select } from "../../themes/default/Form/components/Select";
import {
  ValidatorService,
  NotBlank,
  MinLength,
  MaxLength,
  Type,
} from "pretty-validator";

import "./style.css";

/**
 *  Stores latest timeout id.
 */
let timeoutId;

/**
 * @class ./scenes/DeviceManagement/DeviceManagement
 */
class DeviceManagement extends Component {
  /**
   * @type {Object}
   */
  validatorService;

  /**
   * @type {Object}
   */
  sortService;

  /**
   * @type {Object}
   */
  handheldService;

  /**
   * @type {Object}
   */
  responseService;

  state = {
    devices: [],
    searchedDeviceName: "",
    showCreateNewDeviceDialog: false,
    showDeleteConfirmationDialog: false,
    showResetConfirmationDialog: false,
    showLoadingScreen: false,
    showEditDialog: false,
    newDeviceName: "",
    newDeviceModel: "",
    newDeviceNameErrors: "",
    deviceData: {},
    venueName: "",
    sort: "",
    enrollmentCode: "",
    newEnrollmentDeviceName: "",
    isInputDisabled: false,
    showErrorDialog: false,
    errorMessages: [],
    isPromiseFinished: false,
    currentPage: 1,
    totalPageCount: 1,
    pageSize: 20,
    filterQuery: "(name|deviceModel|venueName)@=*",
    groupFilterValue: "",
    deleteByodDevice: false,
  };

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.listService = new ListService();
    this.sortService = new SortService();
    this.handheldService = new HandheldService();
    this.validatorService = new ValidatorService();
    this.responseService = new ResponseService();
  }

  /**
   * @override
   */
  componentDidMount() {
    this.handleDevicesList(
      this.state.currentPage,
      this.state.pageSize,
      this.state.sort
    );
  }

  /**
   * Refreshes devices list.
   *
   * @param {Number} page
   * @param {Number} pageSize
   * @param {String} sort
   */
  handleDevicesList = (page, pageSize, sort, groupFilterValue) => {
    let isBYODQuery = this.state.groupFilterValue;
    groupFilterValue !== undefined && (isBYODQuery = groupFilterValue);

    let filter =
      this.state.searchedDeviceName !== ""
        ? this.state.filterQuery +
          this.state.searchedDeviceName +
          (isBYODQuery !== "" ? `,${isBYODQuery}` : "")
        : isBYODQuery;

    let parameterObject = {
      ...(sort !== "" ? { Sort: sort } : {}),
      Filter: filter,
      Page: page,
      PageSize: pageSize,
    };

    this.setState({
      showLoadingScreen: true,
    });
    this.handheldService.getAll(parameterObject).then((response) => {
      if (response.status === 200) {
        if (
          JSON.stringify(response.data.results) !==
          JSON.stringify(this.state.devices)
        ) {
          this.setState({
            devices: response.data.results,
            pageSize: response.data.pageSize,
            currentPage: response.data.currentPage,
            totalPageCount: response.data.totalPageCount,
          });
        }
        this.setState({
          showLoadingScreen: false,
          isPromiseFinished: true,
        });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
          showLoadingScreen: false,
          isPromiseFinished: true,
        });
      }
    });
  };

  /**
   * Updates state according to device name search criteria.
   *
   * @param {String} searchValue
   */
  searchDevices = (searchValue) => {
    const isBYODQuery =
      this.state.groupFilterValue !== ""
        ? `,${this.state.groupFilterValue}`
        : "";

    let value = encodeURIComponent(searchValue);
    let parameterObject = {
      Filter: this.state.filterQuery + value + isBYODQuery,
      Page: 1,
      PageSize: this.state.pageSize,
    };

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      if (searchValue.length > 2 || searchValue.length === 0) {
        this.setState({
          showLoadingScreen: true,
        });
        this.handheldService.getAll(parameterObject).then((response) => {
          if (response.status === 200) {
            if (
              JSON.stringify(response.data.results) !==
              JSON.stringify(this.state.devices)
            ) {
              this.setState({
                devices: response.data.results,
                searchedDeviceName: value,
                currentPage: response.data.currentPage,
                totalPageCount: response.data.totalPageCount,
              });
            }
          } else {
            this.setState({
              showErrorDialog: true,
              errorMessages: this.responseService.getErrorMessages(
                response.data
              ),
            });
          }
          this.setState({
            showLoadingScreen: false,
          });
        });
      }
    }, 300);
  };

  /**
   * Updates venue's state value according to selected page.
   *
   * @param {Number} page
   */
  changePageHandler = (page) => {
    if (this.state.currentPage !== page) {
      this.handleDevicesList(page, this.state.pageSize, this.state.sort);
    }
  };

  /**
   * Updates state value responsible for create new device dialog opening.
   */
  openCreateNewDeviceDialog = () => {
    this.setState({
      showCreateNewDeviceDialog: true,
    });
  };

  /**
   * Updates state value responsible for create new device dialog opening.
   */
  closeCreateNewDeviceDialog = () => {
    this.setState({
      showCreateNewDeviceDialog: false,
      enrollmentCode: "",
      isInputDisabled: false,
      newEnrollmentDeviceName: "",
      newDeviceNameErrors: "",
    });
  };

  /**
   * Sorts Device name column ascending or descending and sets state accordingly.
   */
  sortColumn = (column) => {
    let sort = this.sortService.getSortValue(this.state.sort, column);

    this.setState({
      sort: sort,
    });

    this.handleDevicesList(this.state.currentPage, this.state.pageSize, sort);
  };

  /**
   * Checks if venue name is valid and if not adds error to input field.
   *
   * @returns {Boolean}
   */
  isValidDeviceName = (name = this.state.newEnrollmentDeviceName) => {
    let errors = this.validatorService.validate(name, [
      new NotBlank(),
      new MinLength(3),
      new MaxLength(30),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        newDeviceNameErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Sends request for obtaining enrollment code for new device and sets state accordingly.
   */
  setEnrollmentCode = () => {
    if (this.isValidDeviceName()) {
      let handheld = {
        name: this.state.newEnrollmentDeviceName,
      };
      this.setState({
        showLoadingScreen: true,
      });
      this.handheldService.getEnrollmentCode(handheld).then((response) => {
        if (response.status === 200) {
          this.setState({
            newDeviceNameErrors: "",
            enrollmentCode: response.data.enrollmentCode,
            isInputDisabled: true,
          });
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
    }
  };

  /**
   * Sets state when adding new value.
   *
   * @param value
   */
  receiveValue = (value) => {
    let nameFieldErrors = this.state.newDeviceNameErrors;

    this.setState({
      newEnrollmentDeviceName: value,
      newDeviceNameErrors: this.isValidDeviceName(value) ? [] : nameFieldErrors,
    });
  };

  /**
   * Gets value from device name input field.
   *
   * @param {String} value
   */
  getDeviceNameValue = (value) => {
    this.setState({
      newDeviceName: value,
    });
  };

  /**
   * Gets value from device model input field.
   *
   * @param {String} value
   */
  getDeviceModelValue = (value) => {
    this.setState({
      newDeviceModel: value,
    });
  };

  /**
   * Formats elapsed time string by input time.
   *
   * @param {Number} amount
   * @param {String} type
   *
   * @returns {String}
   */
  static getFormatTimeLapseText(amount, type) {
    let text = "";

    if (amount > 1) {
      text = "" + amount + " " + type + "s ago";
    } else {
      text = "" + amount + " " + type + " ago";
    }

    return text;
  }

  /**
   * Calculates last seen device time and outputs relevant string.
   *
   * @returns {String}
   */
  getDeviceConnectionStatus = () => {
    const lastStatusReport = this.state.deviceData.lastStatusReport;

    let connectionStatus = "destaged";

    if (this.state.deviceData.stagingStatus !== connectionStatus) {
      connectionStatus = moment(lastStatusReport).fromNow();
    }

    return connectionStatus;
  };

  /**
   * Updates Device.
   *
   * @param {Object} deviceData
   */
  updateDevice = (deviceData) => {
    let model = this.state.newDeviceModel
      ? this.state.newDeviceModel
      : deviceData.model;
    let name = this.state.newDeviceName
      ? this.state.newDeviceName
      : deviceData.name;

    let handheld = {
      name: name,
      deviceModel: model,
    };

    this.setState({
      showLoadingScreen: true,
    });
    this.handheldService.updateOne(deviceData.id, handheld).then((response) => {
      if (response.status === 204) {
        this.handleDevicesList(
          this.state.currentPage,
          this.state.pageSize,
          this.state.sort
        );
        this.closeEditDialog();
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Updates state and opens edit dialog.
   *
   * @param {Object} deviceData
   */
  openEditDialog = (deviceData) => {
    this.setState({
      showEditDialog: true,
      deviceData: deviceData,
      venueName: deviceData.venueName,
    });
  };

  /**
   * Resets state and closes edit dialog.
   */
  closeEditDialog = () => {
    this.setState({
      showEditDialog: false,
      newDeviceModel: "",
      newDeviceName: "",
    });
  };

  /**
   * Updates state and opens reset dialog.
   *
   * @param {Object} deviceData
   */
  openResetConfirmationDialog = (deviceData) => {
    this.setState({
      showResetConfirmationDialog: true,
      deviceData: deviceData,
      deleteByodDevice: deviceData.isBYOD,
    });
  };

  /**
   * Updates state and opens delete dialog.
   *
   * @param {Object} deviceData
   */
  openDeleteConfirmationDialog = (deviceData) => {
    this.setState({
      showDeleteConfirmationDialog: true,
      deviceData: deviceData,
    });
  };

  /**
   * Updates state and closes delete confirmation dialog.
   */
  closeDeleteConfirmationDialog = () => {
    this.setState({
      showDeleteConfirmationDialog: false,
      deviceData: {},
    });
  };

  /**
   * Updates state and closes delete confirmation dialog.
   */
  closeResetConfirmationDialog = () => {
    this.setState({
      showResetConfirmationDialog: false,
      deviceData: {},
    });
  };

  /**
   * Removes Device.
   *
   * @param {String} deviceId
   */
  deleteDevice = (deviceId) => {
    this.setState({
      showLoadingScreen: true,
    });
    this.handheldService.deleteOne(deviceId).then((response) => {
      if (response.status === 204) {
        this.handleDevicesList(
          this.state.currentPage,
          this.state.pageSize,
          this.state.sort
        );
        this.closeDeleteConfirmationDialog();
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Removes Device.
   *
   * @param {String} deviceId
   */
  resetDevice = (deviceId, deleteByodDevice) => {
    this.setState({
      showLoadingScreen: true,
    });
    this.handheldService.reset(deviceId, deleteByodDevice).then((response) => {
      if (response.status === 204) {
        this.handleDevicesList(
          this.state.currentPage,
          this.state.pageSize,
          this.state.sort
        );
        this.closeResetConfirmationDialog();
        const deleted = <T _str="Device deleted successfully." />;
        const reseted = <T _str="Device reseted successfully." />;
        toast.success(deleteByodDevice ? deleted : reseted);
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
        const warning = <T _str="Oops something went wrong ...!" />;
        toast.warning(warning);
      }
      this.setState({
        showLoadingScreen: false,
        deleteByodDevice: false,
      });
    });
  };

  /**
   * Updates state and closes error dialog.
   */
  closeErrorDialog = () => {
    this.setState({
      showErrorDialog: false,
    });
  };

  /**
   * Returns Devices per page value from dropdown.
   *
   * @param {Object} value
   */
  getShowsPerPageValue = (value) => {
    let pageSize = parseInt(value.value, 10);

    if (this.state.pageSize !== pageSize) {
      this.setState({ pageSize });

      this.handleDevicesList(1, pageSize, this.state.sort);
    }
  };

  /** */
  getGroupFilterValue = ({ value }) => {
    let groupFilterValue;

    switch (value) {
      case "1":
        groupFilterValue = "";
        break;
      case "2":
        groupFilterValue = "isBYOD==false";
        break;
      case "3":
        groupFilterValue = "isBYOD==true";
        break;
      default:
        groupFilterValue = "";
        break;
    }

    this.setState({ groupFilterValue });

    this.handleDevicesList(
      1,
      this.state.pageSize,
      this.state.sort,
      groupFilterValue
    );
  };

  /**
   * @returns {XML}
   */
  render() {
    const {
      deviceData,
      showResetConfirmationDialog,
      sort,
      devices,
      showLoadingScreen,
      errorMessages,
      enrollmentCode,
      currentPage,
      totalPageCount,
      showEditDialog,
      venueName,
      showDeleteConfirmationDialog,
      showErrorDialog,
      deleteByodDevice,
    } = this.state;

    return (
      <div className="col-12 p-0 DeviceManagement">
        <ListOptions
          sort={sort}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          searchDevices={this.searchDevices}
          handleDevicesList={this.handleDevicesList}
          getGroupFilterValue={this.getGroupFilterValue}
          getShowsPerPageValue={this.getShowsPerPageValue}
          openCreateNewDeviceDialog={this.openCreateNewDeviceDialog}
        />

        <List
          data={this.listService.getDeviceManagementData(devices)}
          editDevice={this.openEditDialog}
          resetDevice={this.openResetConfirmationDialog}
          removeDevice={this.openDeleteConfirmationDialog}
          sort={this.sortColumn}
          currentSorting={sort}
          isMobileView={this.props.isMobileView}
          showLoadingScreen={showLoadingScreen}
          errorMessages={errorMessages}
        />

        <CreateNewDeviceDialog
          enrollmentCode={enrollmentCode}
          isInputDisabled={this.state.isInputDisabled}
          newDeviceNameErrors={this.state.newDeviceNameErrors}
          showCreateNewDeviceDialog={this.state.showCreateNewDeviceDialog}
          receiveValue={this.receiveValue}
          setEnrollmentCode={this.setEnrollmentCode}
          closeCreateNewDeviceDialog={this.closeCreateNewDeviceDialog}
        />

        {showLoadingScreen && <LoadingScreen />}

        <Pagination
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          changePageHandler={this.changePageHandler}
        />

        {showEditDialog && (
          <EditDialog
            deviceData={deviceData}
            showEditDialog={showEditDialog}
            updateDevice={this.updateDevice}
            closeEditDialog={this.closeEditDialog}
            getDeviceNameValue={this.getDeviceNameValue}
            getDeviceModelValue={this.getDeviceModelValue}
            getDeviceConnectionStatus={this.getDeviceConnectionStatus}
          />
        )}

        {showDeleteConfirmationDialog && (
          <Dialog
            title={<T _str="Confirmation dialog" />}
            showModal={showDeleteConfirmationDialog}
            action={() => this.deleteDevice(deviceData.id)}
            closeModal={this.closeDeleteConfirmationDialog}
            mainButton={<T _str="Delete Device" />}
          >
            <h4 className="text-center">
              <T _str="Are you sure you want to delete this device?" />
            </h4>
          </Dialog>
        )}

        {showResetConfirmationDialog && (
          <Dialog
            title={<T _str="Confirmation dialog" />}
            showModal={showResetConfirmationDialog}
            action={() => this.resetDevice(deviceData.id, deleteByodDevice)}
            closeModal={this.closeResetConfirmationDialog}
            mainButton={<T _str="Reset Device" />}
          >
            <h4 className="mb-4">
              <T _str="Are you sure you want to reset all data from this venue?" />
            </h4>
            {deviceData?.isBYOD && (
              <label className="byod-device-confiration Checkbox displayInlineBlock false false">
                <T _str="Your device is also will be delete from the access system" />
                <input
                  type="checkbox"
                  name="allow-byod-delete"
                  checked={deleteByodDevice}
                  onChange={(value) =>
                    this.setState({ deleteByodDevice: value.target.checked })
                  }
                />
                <span className="checkmark"></span>
              </label>
            )}
          </Dialog>
        )}

        <DialogError show={showErrorDialog} closeDialog={this.closeErrorDialog}>
          {errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>

        <ToastContainer position="top-center" />
      </div>
    );
  }
}

/**
 * Renders device management's list options.
 *
 * @returns {XML}
 */
const ListOptions = ({
  searchDevices,
  openCreateNewDeviceDialog,
  handleDevicesList,
  currentPage,
  pageSize,
  sort,
  getShowsPerPageValue,
  getGroupFilterValue,
}) => {
  const t = useT();
  document.title = "See Tickets - " + t("Devices");

  const groupFilterOptions = {
    1: t("All"),
    2: t("Rental devices"),
    3: t("Public devices (BYOD)"),
  };

  return (
    <div className="row p-0 m-0 mb-4 table-options">
      <div className="col-md-9 p-0">
        <div className="row">
          <div className="col-md-6">
            <Search name="search" getValue={searchDevices} />
          </div>
          <div className="col-md-6 devices-dropmenu-group">
            <Select
              data={{ 5: "5", 20: "20", 50: "50", 100: "100", 200: "200" }}
              getValue={getShowsPerPageValue}
              initialValue={pageSize}
            />

            <Select
              data={groupFilterOptions}
              getValue={getGroupFilterValue}
              initialValue={groupFilterOptions["1"]}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3 p-0">
        <div className="col-md-12 text-end p-0">
          <Button
            className={ButtonAdd}
            onClickHandler={openCreateNewDeviceDialog}
          >
            <T _str="Create new" />
          </Button>
          <RefreshButton
            className={ButtonSize.Tiny}
            onClickHandler={() =>
              handleDevicesList(currentPage, pageSize, sort)
            }
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Renders create new device dialog.
 *
 * @returns {XML}
 */
const CreateNewDeviceDialog = ({
  enrollmentCode,
  showCreateNewDeviceDialog,
  isInputDisabled,
  newDeviceNameErrors,
  closeCreateNewDeviceDialog,
  receiveValue,
  setEnrollmentCode,
}) => {
  const ENROLMENT_CODE_EMPTY = enrollmentCode === "";

  return (
    showCreateNewDeviceDialog && (
      <Dialog
        mainButton={<T _str="done" />}
        title={<T _str="Add new device" />}
        mainButtonDisabled={ENROLMENT_CODE_EMPTY}
        hideSecondaryButton={!ENROLMENT_CODE_EMPTY}
        showModal={showCreateNewDeviceDialog}
        action={closeCreateNewDeviceDialog}
        closeModal={closeCreateNewDeviceDialog}
      >
        <div className="col-12 p-0">
          <Input
            name="Device name"
            label={<T _str="Device name" />}
            getValue={receiveValue}
            disabled={isInputDisabled}
            className={newDeviceNameErrors.length > 0 ? "error" : ""}
            focus
          />

          {newDeviceNameErrors.length > 0 &&
            newDeviceNameErrors.map((error, key) => (
              <div key={key} className="error">
                {" "}
                - {error}
              </div>
            ))}

          <div className="QRCodeDiv text-center">
            {ENROLMENT_CODE_EMPTY ? (
              <Button onClickHandler={setEnrollmentCode}>
                <T _str="Create Code" />
              </Button>
            ) : (
              <div>
                <Alert type={AlertTypeInfo}>
                  <T _str="Scan this code with the new device" />
                </Alert>
                <QRCode size={256} value={enrollmentCode} />
              </div>
            )}
          </div>
        </div>
      </Dialog>
    )
  );
};

/**
 * Renders edit dialog.
 *
 * @returns {XML}
 */
const EditDialog = ({
  showEditDialog,
  deviceData,
  updateDevice,
  closeEditDialog,
  getDeviceNameValue,
  getDeviceConnectionStatus,
  getDeviceModelValue,
}) => {
  const [isTechMenuExpaned, setIsTechMenuExpaned] = useState(false);
  const [data, setData] = useState(null);
  const t = useT();

  useEffect(() => {
    if (deviceData) {
      var technicalInfosObj = new Object();

      deviceData.id && (technicalInfosObj[t("Device ID")] = deviceData.id);
      deviceData.clonedFromDeviceId &&
        (technicalInfosObj[t("Cloned from (Device ID)")] =
          deviceData.clonedFromDeviceId);
      deviceData.uniqueIdentifier &&
        (technicalInfosObj[t("Unique Identifier")] =
          deviceData.uniqueIdentifier);
      deviceData.venueId &&
        (technicalInfosObj[t("Venue ID")] = deviceData.venueId);
      deviceData.promoterId &&
        (technicalInfosObj[t("Promoter ID")] = deviceData.promoterId);
      deviceData.pushToken &&
        (technicalInfosObj[t("Push Token")] = deviceData.pushToken);
      deviceData.imei && (technicalInfosObj[t("Imei")] = deviceData.imei);
      deviceData.enrollmentCode &&
        (technicalInfosObj[t("Enrollment Code")] = deviceData.enrollmentCode);

      setData({ ...deviceData, technicalInfosObj });
    }
  }, [deviceData, t]);

  return (
    data && (
      <Dialog
        title={t("Edit Device")}
        showModal={showEditDialog}
        action={() => updateDevice(data)}
        closeModal={closeEditDialog}
        mainButton={t("Update device")}
      >
        <div className={"row"}>
          <div className="col-12">{t("Device name")}</div>
          <div className="col-12">
            <Text
              name="device_name"
              value={data.name}
              getValue={getDeviceNameValue}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">{t("Model")}</div>
          <div className="col-12">
            <Text
              name="device_model"
              value={data.model}
              getValue={getDeviceModelValue}
            />
          </div>
        </div>

        <div className="row mb-4">
          <CustomField
            value={data.enroledAt}
            colClass="col-6"
            label={t("Enroled at")}
          />
          {data.cloneFrom && (
            <CustomField
              value={data.cloneFrom}
              colClass="col-6"
              label={t("Cloned from")}
            />
          )}
        </div>
        <div className="row mb-4">
          <CustomField
            value={data.lastStatusReport}
            colClass="col-12"
            label={t("Last activity")}
          />
        </div>
        <div className="row mb-4">
          <CustomField value={data.isBYOD} colClass="col-3" label="BYOD">
            {data.isBYOD ? <CheckIcon /> : <CloseIcon />}
          </CustomField>
          <CustomField
            value={`${data.battery.data}%`}
            colClass="col-3"
            label={t("Battery")}
          >
            <Battery
              className="device-battery vertical"
              level={data.battery.data}
            />
          </CustomField>
          <CustomField
            value={getDeviceConnectionStatus()}
            colClass="col-3"
            label={t("Connection")}
          />
          <CustomField
            value={`${data.appVersion}`}
            colClass="col-3"
            label={t("AppVersion")}
          />
        </div>

        {(data.venueName || data.promoterName) && (
          <div className="row mb-4">
            {data.venueName && (
              <Link to={`/venues/dashboard/${data.venueId}`} className="col-6 ">
                <CustomField
                  value={data.venueName}
                  colClass="primary-color"
                  label={t("Venue")}
                />
              </Link>
            )}
            {data.promoterName && (
              <CustomField
                value={data.promoterName}
                colClass="col-6 primary-color"
                label={t("Promoter")}
              />
            )}
          </div>
        )}

        <section
          className={`technical-info mt-4 ${
            isTechMenuExpaned ? "expanded" : "reduced"
          }`}
        >
          <label
            className="d-flex gap-2"
            onClick={() => setIsTechMenuExpaned((value) => !value)}
          >
            <SingleArrowsIcon />
            <span className="text-capitalize">
              {t("technical information")}
            </span>
          </label>

          <div className="row technical-info-list">
            {Object.keys(data.technicalInfosObj).map((i, idx) => (
              <ReadOnlyField
                key={idx}
                value={data.technicalInfosObj[i]}
                title={i}
              />
            ))}
          </div>
        </section>
      </Dialog>
    )
  );
};

const CustomField = ({ colClass, label, value, children }) => (
  <div className={colClass}>
    <label className="custome-label mt-1 d-block w-100">{label}</label>
    {children}
    <span className="d-inline-block mr-4 readonly-value">{value}</span>
  </div>
);

const ReadOnlyField = ({ title, value }) => {
  const t = useT();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [copied]);

  return (
    <div className="readonly">
      <label className="custome-label mt-3 d-block w-100">{title}</label>
      <span
        className="d-inline-block mr-4 readonly-value one-line-with-ellipsis"
        title={value}
      >
        {value}
      </span>
      {copied ? (
        <span className="copied">{t("Copied")}</span>
      ) : (
        <CopyToClipboard onCopy={() => setCopied((i) => !i)} text={value}>
          <CopyIcon fill="var(--primary-color)" />
        </CopyToClipboard>
      )}
    </div>
  );
};

export default DeviceManagement;
