import React, { useState } from "react";
import PropTypes from "prop-types";
import { useT } from "@transifex/react";
import {
  Button,
  ButtonStatus,
} from "../../../../../../themes/default/Form/components/Button";
import IconMore from "../../../../../../assets/img/ico-more.svg";
import IconLess from "../../../../../../assets/img//ico-less.svg";
import { DateTimeService } from "../../../../../../services/utils/DateTime";

import "./style.css";

/**
 * @class scenes/VenueManagement/scenes/Logs/components/LogPanel/LogPanel
 */
const LogPanel = ({ ticket }) => {
  const t = useT();
  const [expandedLogPanelId, setExpandedLogPanelId] = useState(null);
  const dateTimeService = new DateTimeService();

  const expandOrCollapseLogPanel = (logPanelId) => {
    const value = expandedLogPanelId === logPanelId ? null : logPanelId;
    setExpandedLogPanelId(value);
  };

  const validityColorGenerator = (value) => {
    let color = "#000";

    switch (value) {
      case "sold":
        color = "#52bea0";
        break;
      case "unsold":
        color = "#d23e27";
        break;
      default:
        break;
    }
    return color;
  };

  const ticketId = ticket[0];
  const ticketInfo = ticket[1];

  return (
    <div
      className={`LogPanel ${expandedLogPanelId !== null ? "expanded" : ""}`}
    >
      <div
        onClick={() =>
          ticketInfo.logs.length && expandOrCollapseLogPanel(ticketId)
        }
        className="row m-0 text-center fw-bold ticket-info"
      >
        <div className="col-1 text-center">
          <Button
            onClickHandler={() => expandOrCollapseLogPanel(ticketId)}
            status={
              !ticketInfo.logs.length
                ? ButtonStatus.Disabled
                : ButtonStatus.Empty
            }
          >
            <img
              src={expandedLogPanelId !== null ? IconLess : IconMore}
              alt="More"
            />
          </Button>
        </div>
        <div className="col-2 ticket-id" title={ticketId}>
          {ticketId}
        </div>
        <div className="col-2 name" title={ticketInfo.name}>
          {ticketInfo.name}
        </div>
        <div className="col-1"></div>
        <div className="col-1"></div>
        <div
          className="col-3 externalTicketId"
          title={ticketInfo.externalTicketId}
        >
          {ticketInfo.externalTicketId}
        </div>
        <div
          className="col-2 validity"
          style={{ color: validityColorGenerator(ticketInfo.validity) }}
        >
          {ticketInfo.validity}
        </div>
      </div>
      <div
        className={`checkpoint-logs ${
          expandedLogPanelId === null ? "collapsed" : ""
        }`}
      >
        <div className="row m-0 fw-bold text-center py-2 logs-header">
          <div className="col-2 offset-3 ">{t("Log date")}</div>
          <div className="col-1">{t("Access Zone")}</div>
          <div className="col-1">{t("Checkpoint")}</div>
          <div className="col-3">{t("Device")}</div>
          <div className="col-2">{t("Access Status")}</div>
        </div>
        {ticketInfo.logs.map((log, index) => (
          <div key={index} className="row m-0 text-center checkpoint-log">
            <div
              className={`col-2 offset-3 ${!log.status ? "access-denied" : ""}`}
            >
              {dateTimeService.replaceYearAndDate(
                dateTimeService.getLocalDateTime(log.time)
              )}
            </div>
            <div className={`col-1 ${!log.status ? "access-denied" : ""}`}>
              {log.zone}
            </div>
            <div className={`col-1 ${!log.status ? "access-denied" : ""}`}>
              {log.checkpoint.name}
            </div>
            <div className={`col-3 ${!log.status ? "access-denied" : ""}`}>
              {log.deviceName}
            </div>
            <div className={`col-2 ${!log.status ? "access-denied" : ""}`}>
              {log.status}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

LogPanel.propTypes = {
  ticket: PropTypes.array.isRequired,
};

export default LogPanel;
