import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import { T, useT }           from '@transifex/react'
import { LoadingScreen }     from '../../components/Loading';
import { Dialog }            from '../../themes/default/Dialog';
import { DialogError }       from '../../themes/default/Dialog';
import { CheckpointService } from '../../services/api/Checkpoint';
import { ResponseService }   from '../../services/utils/Response';
import { Input }             from '../../themes/default/Form/components/Input';
import { Button, ButtonAdd } from '../../themes/default/Form/components/Button';
import Radio                 from '../../themes/default/Form/components/Radio/Radio';
import { ValidatorService,
         NotBlank }          from 'pretty-validator';

import IconHome              from './images/ico-home.svg';
import IconCancel            from './images/ico-cancel.svg';

import './style.css';

/**
 * @class components/Checkpoint/Checkpoint
 */
class Checkpoint extends Component
{
    /**
     * @type {Object}
     */
    validatorService;

    state = {
        openCreateCheckpointDialog : false,
        openConfirmationDialog : false,
        openInfoDialog : false,
        checkpointPermission : 'check-and-check-in',
        checkpointName : '',
        checkpointArea : null,
        editCheckpoint : null,
        deleteCheckpoint : null,
        checkpoints : {},
        editCheckpointPermission: '',
        isPromiseFinished: false,
        showLoadingScreen: false,
        nameFieldErrors : [],
        showErrorDialog: false,
        errorMessages: []
    };

    /**
     * Constructor.
     *
     * @param {Object} props
     */
    constructor(props)
    {
        super(props);

        this.venueId = this.props.venueId;
        this.zoneName = this.props.zone;
        this.checkpointService = new CheckpointService();
        this.validatorService = new ValidatorService();
        this.responseService = new ResponseService();
    };

    /**
     * @override
     */
    UNSAFE_componentWillMount()
    {
        if (JSON.stringify(this.state.checkpoints) !== JSON.stringify(this.props.data) &&
            !this.getNumberOfCheckpoints(this.state.checkpoints))
        {
            this.setState({
                checkpoints : this.props.data,
                isPromiseFinished : true
            });
        }
    };

    /**
     * Checks if name is valid and if not adds error to input field.
     *
     * @param {String} name
     *
     * @returns {Boolean}
     */
    isNameFieldValid = (name = this.state.checkpointName) =>
    {
        let format = /[!@#$%^&*()_+=[\]{};:\\|,.<>/?]/;
        let errors = this.validatorService.validate(name,[new NotBlank()]);
        if (format.test(name)) {
            errors = ['Checkpoint name should not contain special characters.'];
        }

        let isValid = errors.length === 0;

        if (!isValid) {
            this.setState({
                nameFieldErrors : errors
            });
        }

        return isValid;
    };

    /**
     * Returns number of checkpoints.
     *
     * @param {Object} checkpoints
     *
     * @returns {number}
     */
    getNumberOfCheckpoints = (checkpoints) =>
    {
        let numberOfCheckpoints = 0;
        for(let checkpoint in checkpoints) {
            if(checkpoints.hasOwnProperty(checkpoint))
                numberOfCheckpoints++;
        }

        return numberOfCheckpoints;
    };

    /**
     * Returns typed checkpoint name.
     *
     * @param {String} name
     */
    getCheckpointName = (name) =>
    {
        let nameFieldErrors = this.state.nameFieldErrors;

        this.setState({
            checkpointName : name,
            nameFieldErrors : this.isNameFieldValid(name) ? [] : nameFieldErrors
        });
    };

    /**
     * Returns selected checkpoint permission and sets state accordingly.
     * Returned value is in form of object so, in order to get value, access to target.value property.
     *
     * @param {Object} permissionObject
     */
     getSelectedCheckpointPermission = (value) =>
     {
        this.setState({
             checkpointPermission : value,
             editCheckpointPermission: this.state.editCheckpoint ? value : ''
         });
 
     };

    /**
     * Creates new checkpoint or edit an existing one.
     * In case of editing set isEdit param to true, otherwise false.
     *
     * @param {Boolean} isEdit
     */
    createOrEditCheckpoint = (isEdit) =>
    {
        if (this.isNameFieldValid()) {
            let newCheckpoints = {...this.state.checkpoints};
            let areaCheckpoints = newCheckpoints[this.state.checkpointArea];
            let isProtected = false;
            let fromZone = null;
            let toZone = null;
            let checkpointPermission = false;

            if(this.state.checkpointArea === 'entry') {
                toZone = this.zoneName;
            } else {
                fromZone = this.zoneName;
            }

            if(
                (this.state.editCheckpoint && this.state.editCheckpointPermission === 'check-only') ||
                this.state.checkpointPermission === 'check-only'
            ) {
                checkpointPermission = true;
            }

            let checkpoint = {
                name: this.state.checkpointName,
                from: fromZone,
                to: toZone,
                permissionCheckOnly: checkpointPermission
            };

            if (isEdit) {
                let oldCheckPointName = this.state.editCheckpoint;
                if (areaCheckpoints[this.state.editCheckpoint].protected === true) {
                    isProtected = true;
                }
                this.updateCheckpoint(oldCheckPointName, checkpoint).then(response => {
                    if (response) {
                        delete areaCheckpoints[this.state.editCheckpoint];
                        areaCheckpoints[this.state.checkpointName] = {
                            'permission' : this.state.editCheckpointPermission ? this.state.editCheckpointPermission : 'check-and-check-in',
                            'protected' : isProtected
                        };
                        newCheckpoints[this.state.checkpointArea] = areaCheckpoints;

                        this.setState({
                                checkpoints : newCheckpoints,
                                openCreateCheckpointDialog : false,
                                isPromiseFinished : true
                            },
                            this.setState({
                                checkpointPermission : 'check-and-check-in',
                                checkpointName : '',
                                checkpointArea : null,
                                editCheckpoint : null,
                                editCheckpointPermission : '',
                                nameFieldErrors : []
                            })
                        );
                    }
                });
            } else {
                this.createNewCheckpoint(checkpoint).then(response => {
                    if (response) {
                        areaCheckpoints[this.state.checkpointName] = {
                            'permission' : this.state.checkpointPermission ? this.state.checkpointPermission : 'check-and-check-in',
                            'protected' : isProtected
                        };
                        newCheckpoints[this.state.checkpointArea] = areaCheckpoints;

                        this.setState({
                                checkpoints : newCheckpoints,
                                openCreateCheckpointDialog : false,
                                isPromiseFinished : true
                            },
                            this.setState({
                                checkpointPermission : 'check-and-check-in',
                                checkpointName : '',
                                checkpointArea : null,
                                editCheckpoint : null,
                                editCheckpointPermission : '',
                                nameFieldErrors : []
                            })
                        );
                    }
                });
            }
        }
    };

    /**
     * Sends request for creating new checkpoint.
     *
     * @param {Object} checkpoint
     *
     * @returns {Promise}
     */
    createNewCheckpoint = (checkpoint) =>
    {
        this.setState({
            showLoadingScreen: true,
            isPromiseFinished : false
        });
        return this.checkpointService.createOne(this.venueId, checkpoint)
            .then(response => {
                    this.setState({
                        showLoadingScreen: false
                    });
                    if (response.status === 201) {
                        return response.status === 201
                    } else {
                        this.showErrorDialogAndSetState(response.data);

                        return false;
                    }
                }
            );
    };

    /**
     * Shows error dialog and sets state accordingly.
     *
     * @param {String} responseData
     */
    showErrorDialogAndSetState = (responseData) =>
    {
        this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(responseData),
            openCreateCheckpointDialog : false,
            isPromiseFinished : true
        });
    };

    /**
     * Sends request for updating checkpoint.
     *
     * @param {String} oldCheckpointName
     * @param {Object} checkpoint
     *
     * @returns {Promise}
     */
    updateCheckpoint = (oldCheckpointName, checkpoint) =>
    {
        this.setState({
            showLoadingScreen: true,
            isPromiseFinished : false
        });
        return this.checkpointService.updateOne(this.venueId, oldCheckpointName, checkpoint)
            .then(response => {
                    this.setState({
                        showLoadingScreen: false
                    });
                    if (response.status === 204) {
                        return response.status === 204
                    } else {
                        this.showErrorDialogAndSetState(response.data);

                        return false;
                    }
                }
            );
    };

    /**
     * @TODO Handle response
     * Sends request for deleting checkpoint.
     *
     * @returns {Promise}
     */
    deleteCheckpoint = () => {
        this.setState({
            showLoadingScreen: true,
            isPromiseFinished : false
        });
        return this.checkpointService.deleteOne(this.venueId, this.state.deleteCheckpoint)
            .then(response => {
                this.setState({
                    showLoadingScreen: false,
                    isPromiseFinished : true
                });
                    return response.status === 204
                }
            );
    };

    /**
     * Handles click on the checkpoint (Edit checkpoint).
     *
     * @param {Object} checkpoint
     * @param {String} area
     */
    handleClickOnCheckpoint = (checkpoint, area) =>
    {
        this.openCreateCheckpointDialog(area);

        let checkpointName = checkpoint[0];
        let checkpointPermission = checkpoint[1].permission;

        this.setState({
            editCheckpoint : checkpointName,
            checkpointName: checkpointName,
            editCheckpointPermission: checkpointPermission
        });
    };

    /**
     * Removes checkpoint from checkpoint's object.
     */
    removeCheckpoint = () =>
    {
        let newCheckpoints = {...this.state.checkpoints};
        let areaCheckpoints = newCheckpoints[this.state.checkpointArea];

        delete areaCheckpoints[this.state.deleteCheckpoint];

        newCheckpoints[this.state.area] = areaCheckpoints;

        this.setState({
            checkpoints : newCheckpoints
        });
    };

    /**
     * Opens confirmation dialog and updates state.
     *
     * @param {String} checkpointName
     * @param {String} area
     */
    openConfirmationDialog = (checkpointName, area) =>
    {
        if (!this.state.checkpoints[area][checkpointName].protected) {
            this.setState({
                openConfirmationDialog : true,
                deleteCheckpoint : checkpointName,
                checkpointArea : area
            });
        }
    };

    /**
     * Updates state and closes confirmation dialog.
     */
    closeConfirmationDialog = () =>
    {
        this.setState({
            openConfirmationDialog : false,
            deleteCheckpoint : null,
            checkpointArea : null
        });
    };

    /**
     * Removes checkpoint.
     */
    confirmConfirmationDialog = () =>
    {
        this.deleteCheckpoint().then(response => {
            if (response) {
                this.removeCheckpoint();

                this.setState({
                    openConfirmationDialog : false,
                    deleteCheckpoint : null,
                    checkpointArea : null
                });
            }
        });
    };

    /**
     * Updates state and opens create checkpoint dialog.
     *
     * @param {String} area
     */
    openCreateCheckpointDialog = (area) =>
    {
        this.setState({
            openCreateCheckpointDialog : true,
            checkpointArea : area,
            editCheckpoint : null,
            editCheckpointPermission: '',
        });
    };

    /**
     * Creates new or edit existing checkpoint.
     */
    confirmCreateCheckpointDialog = () =>
    {
        if (!this.state.editCheckpoint) {
            this.createOrEditCheckpoint(false);
        } else {
            this.createOrEditCheckpoint(true);
        }
    };

    /**
     * Updates state and closes create checkpoint dialog.
     */
    closeCreateCheckpointDialog = () =>
    {
        this.setState({
            openCreateCheckpointDialog : false,
            checkpointPermission : 'check-and-check-in',
            checkpointName : '',
            checkpointArea : null,
            editCheckpoint : null,
            editCheckpointPermission : '',
            nameFieldErrors : []
        });
    };

    /**
     * Updates state and opens info dialog.
     */
    openInformationDialog = () =>
    {
        this.setState({
            openInfoDialog : true
        });
    };

    /**
     * Updates state and closes info dialog.
     */
    closeInformationDialog = () =>
    {
        this.setState({
            openInfoDialog : false
        });
    };

    /**
     * Updates state and closes error dialog.
     */
    closeErrorDialog = () =>
    {
        this.setState({
            showErrorDialog : false
        });
    };

    /**
     * @returns {XML}
     */
    render ()
    {
        let addTopMargin = false;
        let difference = 0;

        if (
            this.getNumberOfCheckpoints(this.state.checkpoints.exit) > 5 ||
            this.getNumberOfCheckpoints(this.state.checkpoints.entry) > 5
        )
        {
            addTopMargin = true;
            difference = this.getNumberOfCheckpoints(this.state.checkpoints.entry) - 5;
        };

        const checkpointsClass = `checkpoints ${this.getNumberOfCheckpoints(this.state.checkpoints.entry) > 1 && "has-many-checkpoints"}`;
        const checkpointsSectionClass = `entry col-12 col-lg-2 text-end p-0 ${this.getNumberOfCheckpoints(this.state.checkpoints.entry) && "has-entry-checkpoint"}`;
        const centralEventSectionClass = `event col-12 col-lg-8 text-center p-0 ${this.getNumberOfCheckpoints(this.state.checkpoints.exit) ? "has-exit-checkpoint" : ""} ${this.getNumberOfCheckpoints(this.state.checkpoints.entry) ? "has-entry-checkpoint" : ""} ${this.getNumberOfCheckpoints(this.state.checkpoints.entry) > 1 ? "has-many-entry-checkpoints" : ""} ${this.getNumberOfCheckpoints(this.state.checkpoints.exit) > 1 ? "has-many-exit-checkpoints" : ""}`;

        return (
            <div>
                <div className="Checkpoint row p-0 m-0">

                    {/* entry checkpoints section. */}
                    <div className={checkpointsSectionClass}>
                        <div>
                            <p><T _str="Entry Checkpoint"/></p>
                        </div>
                        <div className={checkpointsClass}>
                            {
                                Object.entries(this.state.checkpoints.entry).map((checkpoint, index) => {
                                    const innerWidth = window.innerWidth;
                                    const modifiedCheckpointName = innerWidth < 500 ? checkpoint[0].substring(0,11) + ' ...' : checkpoint[0].substring(0,19) + ' ...'
                            
                                    return (
                                        <p key={index} className={checkpoint[1]['permission']}>
                                            <CustomToolTip checkpoint={checkpoint} />
                                            <span onClick={() => this.handleClickOnCheckpoint(checkpoint,'entry')}>
                                                {checkpoint[0].length > 17
                                                    ?   modifiedCheckpointName
                                                    :   checkpoint[0]}
                                            </span>
                                            <img src={IconCancel} alt="Cancel" onClick={() => this.openConfirmationDialog(checkpoint[0],'entry')} />
                                        </p>
                                    )
                                }
                            )}
                        </div>
                        <Button className={ButtonAdd} onClickHandler={() => this.openCreateCheckpointDialog('entry')}>
                            <T _str="Add Checkpoint" _charlimit="14"/>
                        </Button>
                    </div>

                    {/* Renders central event section */}
                    <div className={centralEventSectionClass}>
                        <div className="area-wrapper" style={{'marginTop' : addTopMargin && difference * 30 + 'px'}}>
                            <div className="content">
                                <img src={IconHome} alt="Home"/>
                                <p>{this.props.zone}</p>
                            </div>
                        </div>
                    </div>

                    {/* exit checkpoints section. */}
                    <div className={["exit col-12 col-lg-2 text-left p-0", this.getNumberOfCheckpoints(this.state.checkpoints.exit) ? "has-exit-checkpoint" : ""].join(" ")}>
                        <p><T _str="Exit Checkpoint" _charlimit="18"/></p>
                        <div className={`checkpoints ${this.getNumberOfCheckpoints(this.state.checkpoints.exit) > 1 ? "has-many-checkpoints": ""}`}>
                            {
                                Object.entries(this.state.checkpoints.exit).map((checkpoint, index) =>
                                    <p key={index} className={checkpoint[1]['permission']}>
                                        <CustomToolTip checkpoint={checkpoint} />
                                        <span onClick={() => this.handleClickOnCheckpoint(checkpoint,'exit')}>
                                            {checkpoint[0].length > 15 ? checkpoint[0].substring(0,10) + ' ...' : checkpoint[0]}
                                        </span>
                                        <img src={IconCancel} alt="Cancel" onClick={() => this.openConfirmationDialog(checkpoint[0],'exit')}/>
                                    </p>
                            )}
                        </div>
                        <Button className={ButtonAdd} onClickHandler={() => this.openCreateCheckpointDialog('exit')}>
                            <T _str="Add Checkpoint" _charlimit="14"/>
                        </Button>
                    </div>
                </div>

                <span className="helper"><T _str="Helper"/></span>
                
                {this.state.openCreateCheckpointDialog && this.state.isPromiseFinished &&
                    <CreateCheckpointDialog
                        editCheckpoint={this.state.editCheckpoint}
                        nameFieldErrors={this.state.nameFieldErrors}
                        checkpointPermission={this.state.checkpointPermission}
                        editCheckpointPermission={this.state.editCheckpointPermission}
                        openCreateCheckpointDialog={this.state.openCreateCheckpointDialog}
                        getCheckpointName={this.getCheckpointName}
                        closeCreateCheckpointDialog={this.closeCreateCheckpointDialog}
                        confirmCreateCheckpointDialog={this.confirmCreateCheckpointDialog}
                        getSelectedCheckpointPermission={this.getSelectedCheckpointPermission}
                    />
                }

                {/* Renders confirmation dialog (It pop ups when user tries to remove a checkpoint). */}
                {this.state.openConfirmationDialog &&
                    <Dialog
                        mainButton={<T _str="delete"/>}
                        title={<T _str="Delete Checkpoint"/>}
                        action={this.confirmConfirmationDialog}
                        closeModal={this.closeConfirmationDialog}
                        showModal={this.state.openConfirmationDialog}
                        >
                        <h3 className="text-center">
                            <T _str="Are you sure you want to delete {editCheckpoint} checkpoint?" editCheckpoint={this.state.editCheckpoint} />
                        </h3>
                    </Dialog>
                }

                { this.state.showLoadingScreen ? <LoadingScreen/> : null }

                {/* info dialog (It pop ups when user tries to remove the last checkpoint from entry area). ****there is no functionality for now to open this dialog it will be added in the future, put I keep it as a reminder */}
                {this.state.openInfoDialog &&
                    <Dialog
                        mainButton={<T _str="OK"/>}
                        title={<T _str="Info Dialog"/>}
                        action={this.closeInformationDialog}
                        showModal={this.state.openInfoDialog}
                        closeModal={this.closeInformationDialog}
                        >
                        <h3 className="text-center">
                            <T _str="There have to be at least one checkpoint assigned to the zone!"/>
                            <br/>
                            <T _str="It cannot be deleted, only modified"/>.
                        </h3>
                    </Dialog>
                }
                
                {/* Renders error diagram if an error occur. */}
                <DialogError show={this.state.showErrorDialog} closeDialog={this.closeErrorDialog}>
                    {
                        this.state.errorMessages.map((message,index) => <p key={index}>{message}</p>)
                    }
                </DialogError>

            </div>
        );
    }
};

const CustomToolTip = ({ checkpoint }) =>
(
    <span className='dot'>
        <span className='custom-tooltip'>
            {checkpoint[1]['permission'] === 'check-only' && <T _str="Check only"/>}
            {checkpoint[1]['permission'] === 'check-and-check-in' && <T _str="Check + Check in"/>}
        </span>
    </span>
);

/**
 * Renders create (edit) checkpoint dialog.
 *
 * @returns {XML}
 */
const CreateCheckpointDialog = ({ getSelectedCheckpointPermission,  checkpointPermission, openCreateCheckpointDialog, confirmCreateCheckpointDialog, getCheckpointName, nameFieldErrors, closeCreateCheckpointDialog, editCheckpoint, editCheckpointPermission }) =>
{
    const t = useT();
    return (
        <Dialog
            action={confirmCreateCheckpointDialog}
            showModal={openCreateCheckpointDialog}
            closeModal={closeCreateCheckpointDialog}
            mainButton={editCheckpoint ? t("Edit") : t("Create") }
            title={editCheckpoint ? t("Edit Checkpoint") : t("Create Checkpoint") }
            >
            <p className="text-center">
                <strong>
                    <T _str="Every checkpoint needs a unique name. A checkpoint belongs to one Yoshi zone."/>
                </strong>
            </p>
            <div className="col-12 p-0">
                
                <span className="tag-filter-labels">
                    <T _str="Name"/>
                </span>
                
                <Input
                    focus
                    name="checkpoint-name"
                    getValue={getCheckpointName}
                    value={editCheckpoint ? editCheckpoint : ''}
                    className={` ${nameFieldErrors.length === 0 ? editCheckpoint ? editCheckpointPermission : checkpointPermission : ''} ${nameFieldErrors.length > 0 ? "error" : ""}`}
                />

                { nameFieldErrors.length > 0 && nameFieldErrors.map( (error, key) =>
                    <div key={ key } className="col-12 p-0 error">
                        - { error }
                    </div>
                )}

            </div>
            <div className="col-12 input-div p-0">
                
                <label className="permission-label">
                    <T _str="Permission"/>
                </label>

                <fieldset>
                        <Radio
                            name="permissions"
                            className="RadioGroup"
                            value="check-and-check-in"
                            defaultValue={ editCheckpoint ? editCheckpointPermission : checkpointPermission }
                            label={t("check and check-in")}
                            changeSelectedHandler={() => getSelectedCheckpointPermission('check-and-check-in')}
                        />
                        <Radio
                            name="permissions"
                            value="check-only"
                            className="RadioGroup"
                            label={t("check only")}
                            defaultValue={ editCheckpoint ? editCheckpointPermission : checkpointPermission }
                            changeSelectedHandler={() => getSelectedCheckpointPermission('check-only')}
                        />
                </fieldset>

            </div>
            <span className="helper"><T _str="Helper"/></span>
        </Dialog>
    );
};

Checkpoint.propTypes = {
    data : PropTypes.object.isRequired,
    zone : PropTypes.string.isRequired
};

export default Checkpoint;