import React              from 'react';
import PropTypes          from 'prop-types';

import './style.css';

const Select = ({ name, value, data, disabled, getValue }) => 
(
    <div className="theme-select">
        <select 
            name={name}
            className='hasno-svg'
            value={value} 
            disabled={disabled}
            onChange={(e) => getValue(e.target.value)}
        >
            {
                Object.entries(data).map((item, key) => (
                    <option key={key} value={item[0]}>{item[1]}</option>
                ))
            }
        </select>
    </div>
);

Select.defaultProps = {
    disabled    : false
};

Select.propTypes = {
    name            : PropTypes.string.isRequired,
    data            : PropTypes.object.isRequired,
    initialValue    : PropTypes.string,
    disabled        : PropTypes.bool,
    getValue        : PropTypes.func.isRequired,
};

export default Select;