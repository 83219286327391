import { TicketService } from '../../../../../../services/api/Ticket';

/**
 * @class scenes/ShowManagement/services/File/File
 */
class File
{
    showId;

    /**
     * @type {Object}
     */
    ticketService;

    /**
     * @type {String}
     */
    fileName;

    /**
     * @type {String}
     */
    fileContent;

    /**
     * @override
     *
     * @param {Object} props
     */
    constructor (props)
    {
        this.ticketService = new TicketService();
    }

    /**
     * @returns {String}
     */
    getFileName = () =>
    {
        return this.fileName;
    };

    /**
     * @param {String} fileName
     */
    setFileName = (fileName) =>
    {
        this.fileName = fileName;
    };

    /**
     * @returns {String}
     */
    getFileContent = () =>
    {
        return this.fileContent;
    };

    /**
     * @param {String} fileContent
     */
    setFileContent = (fileContent) =>
    {
        this.fileContent = fileContent;
    };

    /**
     * Uploads tickets based on chosen ticket format.
     *
     * @param {String} ticketFormat
     * @param {String} showId
     *
     * @returns {Array}
     */
    uploadTickets = (ticketFormat, showId) => {
        this.showId = showId;

        switch (ticketFormat) {
            case 'starticket': {
                return this.uploadStarticketFormatTickets();
            }
            case 'ticketcorner': {
                return this.uploadTicketcornerFormatTickets();
            }
            case 'barcodesAndCustomProps': {
                return this.uploadBarcodesAndcustomPropsFormatTickets();
            }
            case 'seeticketsDF': {
                return this.uploadSeeticketsDFFormatTickets();
            }
            case 'SeeChPersonalized': {
                return this.uploadSeeChPersonalized();
            }
            default: {
                return this.uploadStarticketFormatTickets();
            }
        }
    };

    /**
     * Returns array of tickets from CSV in Valid Tickets format.
     * The CSV can be exported on http://st-ops.starticket.ch/tcodes as Generic2
     * example :
     * NAME; FIRST NAME; BARCODE
     * Michael; Jackson; g2k16sdn
     *
     * 01 - NAME,       personalisedTicketLastName
     * 02 - FIRST NAME, personalisedTicketFirstName
     * 03 - BARCODE,    barcode
     * @returns {Array}
     */
    uploadSeeChPersonalized = () =>
    {
        let ticketCSVArray = this.CSVToArray(this.fileContent);
        ticketCSVArray.shift();
        let ticketArray = [];
        let ticketObject = {};
        ticketCSVArray.forEach(function(value,index) {
            if(value[2] !== '' && value[2] !== undefined) {
                ticketObject = {
                    barcode: value[2],
                    personalisedTicketFirstName : value[1],
                    personalisedTicketLastName : value[0]
                };
                ticketArray.push(ticketObject);
            }
        });

        return ticketArray;
    };

    /**
     * Returns array of tickets from CSV in Valid Tickets format.
     * The CSV can be exported on http://st-ops.starticket.ch/tcodes
     * example :
     * SHOW,TYPE,BARCODE,NAME,ADD1,ADD2,ADD3,TOWN,COUNTY,POSTCODE,TICKETS,VALUE,CARDNUM,CANCELLED,READ
     * 1855111,9 KULTURLEGI KINDER - 15.70,$00000938,FRAU firstName lastName,ZURICHSTRASSE 77,,,Zürich,,,3,47.1,xxx.xxx@gmx.ch,,1
     *
     * 01 - SHOW,      ignore
     * 02 - TYPE,      custom attribute
     * 03 - BARCODE,   barcode
     * 04 - NAME,      custom attribute
     * 05 - ADD1,      custom attribute
     * 06 - ADD2,      custom attribute
     * 07 - ADD3,      custom attribute
     * 08 - TOWN,      custom attribute
     * 09 - COUNTY,    custom attribute
     * 10 - POSTCODE,  custom attribute
     * 11 - TICKETS,   ignore
     * 12 - VALUE,     custom attribute
     * 13 - CARDNUM,   email
     * 14 - CANCELLED, validity
     * 15 - READ       ignore
     * @returns {Array}
     */
     uploadSeeticketsDFFormatTickets = () =>
    {
        let ticketCSVArray = this.CSVToArray(this.fileContent);
        ticketCSVArray.shift();
        let ticketArray = [];
        let ticketObject = {};
        ticketCSVArray.forEach(function(value) {

            const validity = value[13] === undefined
                ?   'valid'
                :   value[13].toLowerCase() === 'x' ? 'canceled' : 'valid';

            if(value[2] !== '' && value[2] !== undefined) {
                ticketObject = {
                    barcode: value[2],
                    validity: validity,
                    customProperties : {
                        type : value[1],
                        name : value[3],
                        add1 : value[4],
                        add2 : value[5],
                        add3 : value[6],
                        town : value[7],
                        county : value[8],
                        postcode : value[9],
                        value : value[11],
                        cardnum : value[12]
                    }
                };
                ticketArray.push(ticketObject);
            }
        });

        return ticketArray;
    };

    /**
     * Returns array of tickets from CSV in Valid Tickets format.
     *
     * @returns {Array}
     */
     uploadBarcodesAndcustomPropsFormatTickets = () =>
    {
        let obj = this.CSVToArray(this.fileContent, 'Barcodes&CustomProps');
        let ticketCSVArray = obj.data;
        const columnsHeader = obj.header;

        ticketCSVArray.shift();
        let ticketArray = [];
        let ticketObject = {};
        ticketCSVArray.forEach(function(value) {
            let dynamicProperties = {};

            columnsHeader.map((header,idx) => {
                if (idx > 5 && idx < 10 ) {
                    dynamicProperties = {
                        ...dynamicProperties,
                        [header] : value[idx]
                    }
                }
                return null;
            });

            if(value[0] !== '' && value[0] !== undefined) {
                var validity =
                    value[1].toUpperCase() === 'v'
                        ? 'valid'
                        : value[1] === ''
                            ? 'valid'
                            : 'invalid';

                ticketObject = {
                    barcode: value[0],
                    validity: validity,
                    customProperties : {
                        externalTicketId: value[2],
                        personalisedTicketSalutation: value[3],
                        personalisedTicketFirstName: value[4],
                        personalisedTicketLastName: value[5],
                        ...dynamicProperties
                    }
                };
                ticketArray.push(ticketObject);
            }
        });

        return ticketArray;
    };

    /**
     * Returns array of tickets from CSV in Ticketcorner format.
     *
     * @returns {Array}
     */
    uploadTicketcornerFormatTickets = () =>
    {
        let obj = this.CSVToArray(this.fileContent, 'ticketcorner');
        let ticketCSVArray = obj.data;
        const columnsHeader = obj.header;

        ticketCSVArray.shift();
        let ticketArray = [];
        let ticketObject = {};
        ticketCSVArray.forEach(function(value) {

            let dynamicProperties = {};

            columnsHeader.map((header,idx) => {
                if (idx > 1 && idx < 10 ) {
                    const key = `${header.charAt(0).toUpperCase() + header.slice(1)}`;   
                    dynamicProperties = {
                        ...dynamicProperties,
                        [key] : value[idx]
                    }
                }
                return null;
            });

            if(value[0] !== '' && value[1] !== '' && value[0] !== undefined) {
                ticketObject = {
                    barcode: value[0],
                    validity:
                        value[1].toLowerCase() === 'v'
                            ?   'valid'
                            :   value[1].toLowerCase() === 's'
                                ?   'canceled'
                                :   'invalid',

                    ...(value[2]) ?
                        {
                            customProperties: {
                                ...dynamicProperties
                            }
                        } : {}
                };
                ticketArray.push(ticketObject);
            }
        });

        return ticketArray;
    };

    /**
     * Returns array of tickets from CSV in Starticket format.
     *
     * @returns {Array}
     */
    uploadStarticketFormatTickets = () =>
    {
        let ticketCSVArray = this.CSVToArray(this.fileContent);
        ticketCSVArray.shift();
        let ticketArray = [];
        let ticketObject = {};
        let self = this;

        ticketCSVArray.forEach(function(value,index) {
            let validityString = self.getValidityString(value[5]);
            if(value[0] !== '' && value[5] !== '' && value[0] !== undefined) {
                ticketObject = {
                    barcode: value[0],
                    validity: validityString,
                    customProperties: {
                        TicketID : value[3],
                        Category : value[4]
                    }
                };
                ticketArray.push(ticketObject);
            }
        });

        return ticketArray;
    };

    /**
     * Returns ticket validity based on number in CSV file.
     *
     * @param {String} validityNumber
     *
     * @returns {String}
     */
    getValidityString = (validityNumber) =>
    {
        let validityString = '';

        switch(validityNumber) {
            case '0':
                validityString = 'valid';
                break;
            case '1':
                validityString = 'unsold';
                break;
            case '2':
                validityString = 'canceled';
                break;
            case '3':
                validityString = 'replaced';
                break;
            default:
                validityString = 'initial';
        }

        return validityString;
    };

    /**
     * Parses CSV to Array.
     *
     * @param {String} strData
     * @param {String} strDelimiter
     *
     * @returns {Array}
     */
    CSVToArray = ( strData, key ) => {
        const culomnsArray = strData.split(/\r?\n|\r/g)[0];
        
        let objPattern;
        let strDelimiter = "";

        // Defined the delimiter for both cases "," or ";".
        if (strData !== undefined) {
            strData.indexOf(",") !== -1 && (strDelimiter = ',');
            strData.indexOf(";") !== -1 && (strDelimiter = ';');
        };
        const culomnHeader = culomnsArray.split(strDelimiter);

        // Create a regular expression to parse the CSV values.
        objPattern = new RegExp(
            (
                // Delimiters.
                "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
                // Quoted fields.
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                // Standard fields.
                "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
        );
        // Create an array to hold our data. Give the array
        // a default empty first row.
        let arrData = [[]];
        // Create an array to hold our individual pattern
        // matching groups.
        let arrMatches = null;
        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        // eslint-disable-next-line
        while (arrMatches = objPattern.exec( strData )){
            // Get the delimiter that was found.
            let strMatchedDelimiter = arrMatches[ 1 ];
            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (
                strMatchedDelimiter.length &&
                (strMatchedDelimiter !== strDelimiter)
            ){
                // Since we have reached a new row of data,
                // add an empty row to our data array.
                arrData.push( [] );
            }
            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            let strMatchedValue;
            if (arrMatches[ 2 ]){
                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                strMatchedValue = arrMatches[ 2 ].replace(
                    new RegExp( "\"\"", "g" ),
                    "\""
                );
            } else {
                // We found a non-quoted value.
                strMatchedValue = arrMatches[ 3 ];
            }
            // Now that we have our value string, let's add
            // it to the data array.
            arrData[ arrData.length - 1 ].push( strMatchedValue );
        }
        // Return the parsed data.        
        if (key === 'Barcodes&CustomProps' || key === 'ticketcorner' ) {
            return {data:arrData , header:culomnHeader};
        };
        return( arrData );
    };

}

export default File;