import React, { Component } from "react";
import { T } from "@transifex/react";
import Panel from "../../../../../../themes/default/Layout/components/Panel/Panel";
import {
  ButtonAdd,
  ButtonSize,
} from "../../../../../../themes/default/Form/components/Button/index";
import { Button } from "../../../../../../themes/default/Form/index";
import Device from "../../../../../../components/Device/Device";
import { DevicesService } from "../../services";
import { DropTarget } from "react-dnd";
import PropTypes from "prop-types";

import "./style.css";

/**
 * Defines what type of element can be dropped in checkpoint
 *      example: multiple types of devices
 *
 * @type {{ACCEPTS: string}}
 */
const Types = {
  ACCEPTS: "device",
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 *
 * You can find more detailed documentation at following link
 * @link http://react-dnd.github.io/react-dnd/docs-drop-target.html
 */
const checkpointTarget = {
  /**
   * Returns if element can be dropped on checkpoint.
   *
   * @param {Object} props
   * @param {Object} monitor
   *
   * @returns {Boolean}
   */
  canDrop(props, monitor) {
    return true;
  },

  /**
   * Handles drop event.
   *
   * @param {Object} props
   * @param {Object} monitor
   * @param {Object} component
   * @returns {{moved: boolean}|void}
   */
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return;
    }

    let name = props.data ? props.data.name : null;

    return {
      moved: true,
      checkpointName: name,
    };
  },
};

/**
 * Specifies which props to inject into your component.
 *
 * @param {Object} connect
 * @param {Object} monitor
 *
 * @returns {Object}
 */
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}

/**
 * @class scenes/Devices/components/Checkpoint
 */
class Checkpoint extends Component {
  /**
   * @type {Object}
   */
  devicesService;

  /**
   * @override
   */
  constructor() {
    super();

    this.devicesService = new DevicesService();
  }

  /**
   * Creates checkpoint title by concat checkpoint name and devices numbers.
   *
   * @param {Object} checkpoint
   * @param {Object} devices
   *
   * @returns {String}
   */
  getCheckpointTitle = (checkpoint, devices, title1) => {
    let title = title1;
    let devicesCount = 0;

    if (devices && checkpoint) {
      devices.map((device) => {
        return (
          this.devicesService.isDeviceOnCheckpoint(device, checkpoint.name) &&
          devicesCount++
        );
      });
    }

    if (checkpoint) {
      title = `Checkpoint: "${checkpoint.name}" (${devicesCount})`;

      if (checkpoint.devices != null) {
        title = `Checkpoint ${checkpoint.name} (${checkpoint.devices})`;
      }
    }

    return title;
  };

  /**
   * Changes style base on input params.
   *
   * @param {Boolean} isOver
   * @param {Boolean} canDrop
   *
   * @returns {Object}
   */
  getStyle = (isOver, canDrop) => {
    let isActive = isOver && canDrop;
    let style = {};

    if (isActive) {
      style = {
        border: "2px dashed var(--highlight-kermit-green-color)",
        backgroundColor: "var(--highlight-green-light-color)",
      };
    } else if (canDrop) {
      style = {
        border: "2px dashed var(--gray-light-color)",
        backgroundColor: "var(--gray-light-color)",
      };
    }

    return style;
  };

  /**
   * Checks if device is on parking slot.
   *
   * @param {Object} device
   *
   * @returns {Boolean}
   */
  isDeviceOnParkingSlot = (device) => {
    let isDeviceOnParkingSlot = false;
    let deviceCheckpoint = device.state.checkpoint;

    if (
      (deviceCheckpoint.latestAdminValueApplied &&
        !deviceCheckpoint.reportedValue) ||
      (!deviceCheckpoint.latestAdminValueApplied &&
        !deviceCheckpoint.adminRequestedValue)
    ) {
      isDeviceOnParkingSlot = true;
    }

    return isDeviceOnParkingSlot;
  };

  /**
   * @returns {XML}
   */
  render() {
    const { isOver, canDrop, connectDropTarget } = this.props;
    const isActive = isOver && canDrop;
    let checkpointName = this.props.data ? this.props.data.name : null;

    let title = <T _str="Parking slot for substitute / not used devices" />;

    return connectDropTarget(
      <div>
        <Panel
          colorize
          style={this.getStyle(isOver, canDrop)}
          title={this.getCheckpointTitle(
            this.props.data,
            this.props.devices,
            title
          )}
        >
          {this.props.devices &&
            this.props.data &&
            this.props.devices.map(
              (device, key) =>
                this.devicesService.isDeviceOnCheckpoint(
                  device,
                  checkpointName
                ) && (
                  <Device
                    key={key}
                    data={device}
                    deviceDestageHandler={this.props.deviceDestageHandler}
                    handleDeviceDrop={this.props.handleDeviceDrop}
                  />
                )
            )}

          {this.props.devices &&
            !this.props.data &&
            this.props.devices.map(
              (device, key) =>
                this.isDeviceOnParkingSlot(device) && (
                  <Device
                    key={key}
                    data={device}
                    deviceDestageHandler={this.props.deviceDestageHandler}
                    handleDeviceDrop={this.props.handleDeviceDrop}
                  />
                )
            )}

          <div>
            <Button
              className={ButtonAdd}
              size={ButtonSize.Small}
              onClickHandler={() =>
                this.props.handleAddDevicesButtonClick(checkpointName)
              }
            >
              <T _str="add" />
            </Button>
            {isActive ? (
              <T _str="Release to drop" />
            ) : (
              <T _str="Drag device above" />
            )}
          </div>
          {this.props.data && (
            <div>
              <Button
                className={ButtonAdd}
                size={ButtonSize.Small}
                onClickHandler={() =>
                  this.props.handleQRCodeButtonClick(checkpointName)
                }
              >
                <T _str="QR-Code" />
              </Button>
              <T _str="Enroll and stage a new device into this checkpoint" />
            </div>
          )}
        </Panel>
      </div>
    );
  }
}

Checkpoint.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  lastDroppedItem: PropTypes.object,
};

export default DropTarget(Types.ACCEPTS, checkpointTarget, collect)(Checkpoint);
