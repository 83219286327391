import React     from 'react';
import { Input } from '../Input';
import PropTypes from 'prop-types';

const Password = ({ label, name, type, className, value, required, readOnly, placeholder, onChangeHandler, maxLength }) => 
(
    <div>
        <Input
            label={ label }
            name={ name }
            type={ type }
            className={ className }
            value={ value }
            required={ required }
            readOnly={ readOnly }
            placeholder={ placeholder }
            onChangeHandler={ onChangeHandler }
            maxLength={ maxLength}/>
    </div>
);

Password.defaultProps = {
    type: 'password',
    label: '',
    name: '',
    required : false,
    readOnly : false,
    disabled : false,
    maxLength : '',
    placeholder : '',
    className : '',
};

Password.propTypes = {
    label : PropTypes.string,
    name : PropTypes.string.isRequired,
    required : PropTypes.bool,
    readOnly : PropTypes.bool,
    disabled : PropTypes.bool,
    placeholder : PropTypes.string.isRequired,
    className : PropTypes.string
};

export default Password;