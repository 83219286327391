import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { T, useT } from "@transifex/react";
import { useHistory } from "react-router-dom";
import { List } from "../../components/List";
import { SortingArrowsIcon } from "../../components/Images";
import { CheckIcon, CloseIcon, LoadingIcon } from "../../components/Images";
import { LoadingScreen } from "../../components/Loading";
import { UsersService } from "../../services/api/Users";
import { SortService } from "../../services/utils/Sort";
import { Toggle } from "../../themes/default/Toggle";
import { DialogError } from "../../themes/default/Dialog";
import { ResponseService } from "../../services/utils/Response";
import { ListService } from "../../components/List/services";
import Dialog from "../../themes/default/Dialog/Dialog";
import { Input } from "../../themes/default/Form/components/Input";
import {
  Button,
  ButtonAdd,
  ButtonSize,
} from "../../themes/default/Form/components/Button";
import { Select } from "../../themes/default/Form/components/Select";
import Search from "../../themes/default/Form/components/Search/Search";

import "react-toastify/dist/ReactToastify.css";
import "./style.css";

const UsersManagement = ({ loggedUser }) => {
  const t = useT();

  const isAdminTitle = t("Admin");
  const isNotAdminTitle = t("Not an Admin");

  const history = useHistory();
  const [isThisUserAuth, setIsThisUserAuth] = useState(false);

  const [sort, setSort] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [searchUserName, setSearchUserName] = useState("");
  const [showCreateNewUserDialog, setShowCreateNewUserDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newUserFieldClassErrors, setNewUserFieldClassErrors] = useState({});
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({
    username: "",
    lastName: "",
    firstName: "",
    language: "de",
    isAdmin: isAdmin,
    password: "",
  });

  const [usersList, setUsersList] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const sortService = new SortService();
  const usersService = new UsersService();
  const responseService = new ResponseService();

  useEffect(() => {
    document.title = "See Tickets - " + t("Users");
    loggedUser && setIsThisUserAuth(loggedUser.isAdmin);
    fetchUsers();
  }, [loggedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      let queryParameter = "";
      const filter = "(lastname|firstname|username)@=*";
      const valueArray = searchUserName.split(" ");

      valueArray.map(
        (i, idx) =>
          i !== "" &&
          (queryParameter = queryParameter + (idx > 0 ? "," : "") + filter + i)
      );

      let parameterObject = {
        Filter: queryParameter,
        PageSize: 9999,
        Page: 1,
      };
      fetchUsers(parameterObject);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchUserName]); // eslint-disable-line react-hooks/exhaustive-deps

  const openDeleteConfirmationDialog = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const setUserData = (userData) => {
    return {
      id: userData[0],
      name: userData[1][0].cellData,
      username: userData[1][1],
    };
  };

  /**
   * Sorts User name column ascending or descending and sets state accordingly.
   *
   * @param {String} column
   */
  const sortColumn = (column) => {
    let sorting = sortService.getSortValue(sort, column);
    handleUsersList(sorting);
  };

  /**
   * Retrieves list of users and sets state accordingly.
   *
   * @param {String} sort
   */
  const handleUsersList = (newSort) => {
    newSort && setSort(newSort);
    let parameterObject = {
      ...(newSort !== "" ? { Sort: newSort } : {}),
      ...(searchUserName !== ""
        ? { Filter: "(lastname|firstname|username)@=*" + searchUserName }
        : {}),
      PageSize: 9999,
      Page: 1,
    };

    fetchUsers(parameterObject);
  };

  const fetchUsers = (parameterObject) => {
    setShowLoadingScreen(true);

    usersService.getAllUsers(parameterObject).then((response) => {
      if (response.status === 200) setUsersList(response.data);
      else {
        setShowErrorDialog(true);
        setErrorMessages(responseService.getErrorMessages(response.data));
      }
      setShowLoadingScreen(false);
    });
  };

  const receiveSearchValue = (value) => {
    if (!value) {
      return;
    }

    if (value.length > 2 || value.length === 0) {
      setSearchUserName(value);
    }
  };

  const createNewUser = () => {
    if (areInputsValide()) {
      const user = { ...newUser, isAdmin };

      usersService.createUser(user).then((response) => {
        if (response.status === 200) {
          setShowCreateNewUserDialog(false);
          toast.success(
            <div>
              <strong>{response.data.username}</strong>
              <br />
              successfully created.
            </div>
          );
          setTimeout(() => {
            handleUsersList();
          }, 800);
        } else if (response.status === 422) {
          setValidationErrors(response.data.validationErrors);

          let fieldErrObj = {};
          response.data.validationErrors.map(
            (error) =>
              (fieldErrObj[error.field.toLowerCase()] =
                error.field.toLowerCase())
          );
          setNewUserFieldClassErrors(fieldErrObj);
        } else {
          setShowErrorDialog(true);
          setErrorMessages(responseService.getErrorMessages(response.data));
        }
      });
    }
  };

  const areInputsValide = () => {
    let newError = {};

    Object.keys(newUser).map((key) => {
      if (newUser[key] === "") {
        newError[key.toLowerCase()] = "error";
      }
      return null;
    });

    setNewUserFieldClassErrors(newError);

    delete newError.language;

    return Object.keys(newError).length === 0;
  };

  const deleteUser = (id) => {
    setShowLoadingScreen(true);
    setOpenDeleteDialog(false);

    usersService.deleteUser(id).then((response) => {
      if (response.status === 204) {
        handleUsersList();
        toast.success(
          <div>
            <strong className="error">{selectedUser.username}</strong>
            <br />
            successfully deleted.
          </div>
        );
      } else {
        setShowErrorDialog(true);
        setErrorMessages(responseService.getErrorMessages(response.data));
      }
      setShowLoadingScreen(false);
    });
  };

  const receiveNewUserValue = (value, key) => {
    let newError = { ...newUserFieldClassErrors };
    delete newError[key.toLowerCase()];

    setNewUserFieldClassErrors(newError);
    const user = {
      ...newUser,
      isAdmin: isAdmin,
      [key]: value,
    };

    setNewUser(user);
  };

  const editeUser = (data) => {
    history.push(`/user/${data[0]}`);
  };

  const closeAddUserModal = () => {
    setErrorMessages([]);
    setValidationErrors([]);
    setNewUserFieldClassErrors({});
    setShowCreateNewUserDialog(false);
    setShowPassword(false);
  };

  /**
   * Returns adapted data for users list.
   *
   * @param {Object} responseData
   *
   * @returns {Object}
   */
  const getUsersData = (responseData) => {
    let tableData = {};

    responseData["results"] &&
      responseData["results"].map((user) => {
        let usersData = [];

        const firstName = user.firstName !== "string" ? user.firstName : "";
        const lastName = user.lastName !== "string" ? user.lastName : "";

        usersData.push({
          cellData: firstName + " " + lastName,
          onClick: "edit",
        });

        usersData.push(user.username);

        usersData.push({
          cellData: user.isAdmin ? CheckIcon() : CloseIcon(),
          className: user.isAdmin ? isAdminTitle : isNotAdminTitle,
          isImage: true,
        });

        const iconsColumn = {
          cellOptions: {
            delete: true,
          },
        };

        usersData.push(iconsColumn);
        tableData[user.id] = usersData;

        return true;
      });

    return tableData;
  };

  // In case the current users is not an ADMIN
  if (!isThisUserAuth)
    return (
      <div className="container not-allowed">
        <h1>
          <T _str="YOU ARE NOT ALLOWED TO MANAGE THIS PAGE" />
        </h1>
      </div>
    );
  else
    return (
      <div id="users-management">
        <h1>
          <T _str="Users Management" />
        </h1>
        <TableOptions
          handleUsersList={handleUsersList}
          handleCeateUser={() => setShowCreateNewUserDialog(true)}
          receiveSearchValue={receiveSearchValue}
        />

        <List
          sort={sortColumn}
          showLoadingScreen={showLoadingScreen}
          editHandler={editeUser}
          currentSorting={sort}
          columns="UsersHeadRow"
          data={getUsersData(usersList)}
          deleteHandler={(tableData) =>
            openDeleteConfirmationDialog(setUserData(tableData))
          }
        />

        {showCreateNewUserDialog && (
          <CreateNewUserDialog
            isAdmin={isAdmin}
            showPassword={showPassword}
            showPasswordHandler={() => setShowPassword(!showPassword)}
            validationErrors={validationErrors}
            newUserFieldClassErrors={newUserFieldClassErrors}
            showCreateNewUserDialog={showCreateNewUserDialog}
            createNewUser={createNewUser}
            getIsAdmin={(value) => setIsAdmin(value)}
            receiveNewUserValue={receiveNewUserValue}
            closeAddUserModal={closeAddUserModal}
          />
        )}

        <DeleteUserDialog
          deleteUser={deleteUser}
          selectedUser={selectedUser}
          openDeleteDialog={openDeleteDialog}
          closeDeleteUserModal={() => setOpenDeleteDialog(false)}
        />

        <DialogError
          show={showErrorDialog}
          closeDialog={() => setShowErrorDialog(false)}
        >
          {errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>

        {showLoadingScreen && <LoadingScreen />}

        <ToastContainer position="top-center" />
      </div>
    );
};

/**
 * Returns columns for users list.
 *
 * @returns {Object}
 */
export const UsersHeadRow = ({ sort, currentSorting }) => {
  const listServices = new ListService();

  const t = useT();

  const name = t("Name");
  const email = t("Email");
  const admin = t("Admin");

  const usersColumns = {
    [name]: { subtitle: null, sortable: true, fieldValue: "firstname" },
    [email]: { subtitle: null, sortable: true, fieldValue: "username" },
    [admin]: {
      subtitle: null,
      sortable: false,
      fieldValue: "isAdmin",
      className: "is-admin",
    },
    "": {
      subtitle: null,
      sortable: false,
      fieldValue: "delete",
      className: "user-delete",
    },
  };

  return (
    <tr>
      {Object.entries(usersColumns).map((column, index) => (
        <th
          className={column[1].className ? column[1].className : ""}
          key={index}
        >
          {column[0]}
          {column[1].subtitle !== null && (
            <span className="grayed">{column[1].subtitle}</span>
          )}
          {column[1].sortable && (
            <SortingArrowsIcon
              className={`pull-right sorting-arrow ${listServices.sortingArrowClassController(
                column,
                currentSorting
              )}`}
              onClick={() => sort(column[1].fieldValue)}
            />
          )}
        </th>
      ))}
    </tr>
  );
};

const TableOptions = ({
  receiveSearchValue,
  handleCeateUser,
  handleUsersList,
}) => (
  <div className="row p-0 m-0 mb-2 table-options">
    <div className="col-md-3 p-0">
      <Search
        name="search"
        placeholder="Search"
        getValue={receiveSearchValue}
      />
    </div>
    <div className="col-md-9 text-end p-0">
      <Button className={ButtonAdd} onClickHandler={handleCeateUser}>
        <T _str="Create new" />
      </Button>
      <Button className={ButtonSize.Tiny} onClickHandler={(event) => handleUsersList() }>
        <LoadingIcon />
      </Button>
    </div>
  </div>
);

const CreateNewUserDialog = ({
  showPasswordHandler,
  showPassword,
  validationErrors,
  isAdmin,
  getIsAdmin,
  showCreateNewUserDialog,
  newUserFieldClassErrors,
  createNewUser,
  closeAddUserModal,
  receiveNewUserValue,
}) => (
  <Dialog
    title={<T _str="Create new User" />}
    showModal={showCreateNewUserDialog}
    action={() => createNewUser()}
    closeModal={closeAddUserModal}
    mainButton={<T _str="Create" />}
    secondaryButton={<T _str="Cancel" />}
  >
    <Input
      name="Username"
      autocomplete="username"
      label={<T _str="Email" />}
      onChange={(value) => receiveNewUserValue(value, "username")}
      className={newUserFieldClassErrors.username ? "error" : ""}
      focus
    />
    <ValidationErrorMessage errors={validationErrors} field="Username" />

    <Input
      name="First name"
      autocomplete="given-name"
      label={<T _str="First name" />}
      onChange={(value) => receiveNewUserValue(value, "firstName")}
      className={newUserFieldClassErrors.firstname ? "error" : ""}
    />
    <ValidationErrorMessage errors={validationErrors} field="FirstName" />

    <Input
      name="Last name"
      autocomplete="family-name"
      label={<T _str="Last name" />}
      onChange={(value) => receiveNewUserValue(value, "lastName")}
      className={newUserFieldClassErrors.lastname ? "error" : ""}
    />
    <ValidationErrorMessage errors={validationErrors} field="LastName" />

    <Input
      name="Password"
      autocomplete="new-password"
      type={!showPassword ? "password" : "text"}
      label={<T _str="Password" />}
      onChange={(value) => receiveNewUserValue(value, "password")}
      className={newUserFieldClassErrors.password ? "error" : ""}
    />
    <ValidationErrorMessage errors={validationErrors} field="Password" />

    <Toggle
      label={<T _str="Show password" />}
      isChecked={showPassword}
      callBack={showPasswordHandler}
      labelClassName="mb-2 mt-4"
    />

    <div className="row my-4 ">
      <div className="col-6">
        <Select
          initialValue="Deutsch"
          getValue={(value) => receiveNewUserValue(value.value, "language")}
          data={{
            de: "Deutsch",
            en: "English",
            fr: "Français",
            it: "Italiano",
          }}
        />
      </div>
    </div>

    <Toggle
      label={<T _str="Is this an Admin user" />}
      isChecked={isAdmin}
      callBack={getIsAdmin}
      className="my-4"
    />
  </Dialog>
);

const DeleteUserDialog = ({
  selectedUser,
  openDeleteDialog,
  closeDeleteUserModal,
  deleteUser,
}) =>
  openDeleteDialog && (
    <Dialog
      mainButton={<T _str="DELETE" />}
      title="Delete User"
      showModal={openDeleteDialog}
      action={() => deleteUser(selectedUser.id)}
      closeModal={closeDeleteUserModal}
    >
      <p className="error">
        <T _str="Are you sure you want to delete this user" />
      </p>
      <label className="error">{selectedUser.name}</label>
      <br />
      <label className="error">{selectedUser.username}</label>
    </Dialog>
  );

const ValidationErrorMessage = ({ errors, field }) =>
  errors.length > 0 &&
  errors.map(
    (error, key) =>
      error.field === field && (
        <div key={key} className="error">
          - {error.message}
        </div>
      )
  );

export default UsersManagement;
