import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { T }                    from '@transifex/react';
import { Dialog }               from '../../themes/default/Dialog';
import { Input }                from '../../themes/default/Form/components/Input';
import { Button, ButtonAdd }    from '../../themes/default/Form/components/Button';

import IconCancel               from './images/ico-cancel.svg';

import './style.css';

/**
 * @TODO Missing dialog validation
 * @class /src/components/SimpleTag/SimpleTag
 */
class SimpleTag extends Component
{
    state = {
        properties : [],
        openCreateNewCustomPropertyDialog : false,
        openConfirmationDialog : false,
        propertyKey : null,
        propertyValue : null,
        isUpdated : false,
        removePropertyIndex : null
    };

    /**
     * @override
     */
    UNSAFE_componentWillMount ()
    {
        if (this.props.value)
            this.setProperties(this.props.value);
    }

    /**
     * @override
     */
    componentDidMount ()
    {
        if (this.props.getValue && this.state.isUpdated) {
            this.props.getValue(this.getProperties());

            this.setState({
                isUpdated : false
            });
        }
    }

    /**
     * @override
     */
    componentDidUpdate ()
    {
        if (this.props.getValue && this.state.isUpdated) {
            this.props.getValue(this.getProperties());

            this.setState({
                isUpdated : false
            });
        }
    }

    /**
     * Adds new custom property to the properties array.
     */
    addNewCustomProperty = () =>
    {
        let propertiesCopy = [...this.state.properties];
        let newProperty = {
            statement : this.state.propertyKey + ' = ' + this.state.propertyValue
        };
        propertiesCopy.push(newProperty);
        this.setState({
            properties : propertiesCopy,
            openCreateNewCustomPropertyDialog : false,
            propertyKey : null,
            isUpdated : true,
            propertyValue : null
        });
    };

    /**
     * Removes property from properties array.
     */
    removeCustomProperty = () =>
    {
        let propertiesCopy = [...this.state.properties];
        propertiesCopy.splice(this.state.removePropertyIndex, 1);
        this.setState({
            properties : propertiesCopy,
            openConfirmationDialog : false,
            isUpdated : true,
            removePropertyIndex : null
        });
    };

    /**
     * Returns value of simple tag component.
     *
     * @returns {Array}
     */
    getProperties = () =>
    {
        let simpleTagValue = [];
        this.state.properties.map(property => {
            simpleTagValue.push(property.statement);

            return true;
        });

        return simpleTagValue;
    };

    /**
     * Sets value of simple tag component.
     *
     * @param {Array} properties
     */
    setProperties = (properties) =>
    {
        let propertiesCopy = [...this.state.properties];
        properties.map(property => {
            propertiesCopy.push({
                statement : property
            });

            return true;
        });

        this.setState({
            properties : propertiesCopy
        });
    };

    /**
     * Updates state and opens create new custom property dialog.
     */
    openCreateNewCustomPropertyDialog = () => this.setState({ openCreateNewCustomPropertyDialog : true });


    /**
     * Updates state and closes create new custom property dialog.
     */
    closeCreateNewCustomPropertyDialog = () => this.setState({ openCreateNewCustomPropertyDialog : false });

    /**
     * Updates state and opens confirmation dialog.
     *
     * @param {Number} propertyIndex
     */
    openConfirmationDialog = (propertyIndex) =>
    {
        this.setState({
            openConfirmationDialog : true,
            removePropertyIndex : propertyIndex
        });
    };

    /**
     * Updates state and closes confirmation dialog.
     */
    closeConfirmationDialog = () =>
    {
        this.setState({
            openConfirmationDialog : false,
            removePropertyIndex : null
        });
    };

    /**
     * Returns custom property key input's value.
     *
     * @param {String} value
     */
    getCustomPropertyKeyValue = (value) => this.setState({ propertyKey : value });

    /**
     * Returns custom property value input's value.
     *
     * @param {String} value
     */
    getCustomPropertyValueValue = (value) => this.setState({ propertyValue : value });

    /**
     * Renders custom properties.
     *
     * @returns {any[]}
     */
    renderCustomProperties = () =>
    {
        if (!!this.state.properties.length) {
            return this.state.properties.map((property,index) => {
                return (
                    <div key={index} className="custom-property">
                        {property.statement}
                        <img src={IconCancel} alt="Cancel" title="Remove" onClick={() => this.openConfirmationDialog(index)}/>
                    </div>
                );
            });
        }
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <div className="SimpleTag">
                { this.renderCustomProperties() }
                <Button className={ButtonAdd} onClickHandler={this.openCreateNewCustomPropertyDialog}>
                    <T _str="Add Custom property" />
                </Button>
            
                {/* Renders create new custom property dialog. */}
                {this.state.openCreateNewCustomPropertyDialog &&
                    <Dialog
                        mainButton={<T _str="Add" />}
                        action={this.addNewCustomProperty}
                        title={<T _str="Add custom property" />}
                        closeModal={this.closeCreateNewCustomPropertyDialog}
                        showModal={this.state.openCreateNewCustomPropertyDialog}
                    >
                        <div className="row m-0">
                            <div className="col-5 p-0">
                                <Input
                                    focus
                                    value=""
                                    name="key"
                                    label={<T _str="Key" />}
                                    getValue={this.getCustomPropertyKeyValue}
                                />
                            </div>
                            <div className="col-2 text-center equal-sign">
                                =
                            </div>
                            <div className="col-5 p-0">
                                <Input
                                    value=""
                                    name={"value"}
                                    label={<T _str="Value" />}
                                    getValue={this.getCustomPropertyValueValue}
                                />
                            </div>
                        </div>
                        <span className="helper"><T _str="Helper" /></span>
                    </Dialog>
                }

                {this.state.openConfirmationDialog &&
                    <Dialog
                        mainButton={<T _str="Confirm" />}
                        title={<T _str="Confirmation dialog" />}
                        showModal={this.state.openConfirmationDialog}
                        action={this.removeCustomProperty} closeModal={this.closeConfirmationDialog}
                    >
                        <h4 className="text-center"><T _str="Are you sure you want to remove this property?" /></h4>
                    </Dialog>
                }

            </div>
        );
    }
}

SimpleTag.propTypes = {
    getValue : PropTypes.func.isRequired,
    value : PropTypes.array
};

export default SimpleTag;
