import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import IconArrows           from '../../../../../../../assets/img/arrows.svg';
import IconConfirm          from '../../../../../../../assets/img/ico-confirm.svg';

/**
 * @class scenes/VenueManagement/scenes/Shows/components/List/List
 */
class Rules extends Component
{
    /**
     * Renders list head row.
     *
     * @param {Object} columns
     */
    renderHeadRow = (columns) =>
    {
        return Object.entries(columns).map((column,index) => {
            return (
                <th key={index}>{column[0]}
                    {
                        column[1].subtitle !== null ? <span className="grayed">{column[1].subtitle}</span> : null
                    }
                    {
                        column[1].sortable ? <img className="pull-right" src={IconArrows} alt="Arrows" onClick={() => this.props.sort()}/> : null
                    }
                </th>
            );
        })
    };

    /**
     * Renders list body.
     *
     * @param {Array} data
     */
    renderBody = (data) =>
    {
        if (data) {
            return data.map((rowData,index) =>
                <tr key={index}>
                    {
                        Object.entries(rowData).map((cellData, index) =>   
                            <td key={index} colSpan={(cellData[1][0] === '' && cellData[1][1] === '') ? "2" : "1"}
                                className={['',
                                            (cellData[0] === 'alt' && cellData[1] === '') && 'no-record',
                                            (cellData[0] === 'forced') && 'forced text-center'
                                            ].join(' ')}>
                                {
                                    (cellData[0] === 'alt' && cellData[1] === '')
                                        ?   'no alt title'
                                        :   (cellData[0] === 'forced')
                                            ?   (cellData[1])
                                                ?   (
                                                        <img src={IconConfirm} alt={"Gold star"} />
                                                    )
                                                :   null
                                            :   cellData[1]
                                }
                                {
                                    cellData[1][0] === '' && cellData[1][1] === '' && "NA"
                                }
                            </td>
                    )}
                </tr> 
            )
        }
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
             <table className="List">
                <thead>
                <tr>
                    { this.renderHeadRow(this.props.columns) }
                </tr>
                </thead>
                <tbody>
                    { this.renderBody(this.props.data) }
                </tbody>
            </table>
        );
    }
}

Rules.propTypes = {
    columns : PropTypes.object.isRequired,
    data : PropTypes.array
};

export default Rules;