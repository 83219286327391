let Login = {
  authority:
    (window.REACT_APP_IDENTITY_BASE_URL ?? process.env.REACT_APP_IDENTITY_BASE_URL ?? window.REACT_APP_API_BASE_URL ?? process.env.REACT_APP_API_BASE_URL) +
    (window.REACT_APP_LOGIN_AUTHORITY ?? process.env.REACT_APP_LOGIN_AUTHORITY),
  client_id: "admin-web",
  redirect_uri: window.location.origin + "/signin_oidc",
  response_type: "id_token token",
  scope: "openid yoshi-v-admin-api",
  silent_redirect_uri: window.location.origin + "/silent_renew",
  post_logout_redirect_uri: window.location.origin + "/login",
  automaticSilentRenew: true,
};

export default Login;
