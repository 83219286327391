import { createRoot } from "react-dom/client";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import AccessControl from "./AccessControl";
import { BrowserRouter } from "react-router-dom";
import { unregister } from "./registerServiceWorker";

import "bootstrap/dist/css/bootstrap.min.css";

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <AccessControl />
  </BrowserRouter>
);

unregister();
