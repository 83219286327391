import axios from "axios/index";

/**
 * @class ./services/Speech
 */
class Speech {
  static voicesCached = null;

  /**
   *
   * Returns a list of all voices available.
   * The list is only fetched once from the API and cached internally for subsequent usages.
   *
   * @returns {Promise}
   */
  getAll = async () => {
    if (Speech.voicesCached) {
      return Speech.voicesCached;
    }

    try {
      const response = await axios.get(`/speech/voices`);
      Speech.voicesCached = {
        success: true,
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        status: error.response.status,
        data: error.response.data,
      };
    }

    return Speech.voicesCached;
  };

  /**
   * Returns the speech voices for a specific locale and gender.
   *
   * @param {String} locale
   * @param {String} gender
   *
   * @returns {Promise}
   *
   * @example Response:
   * {
   *     "success": true,
   *     "message": "Voices retrieved successfully",
   *     "voices": [
   *         {
   *             "name": "Microsoft Server Speech Text to Speech Voice (en-US, AvaMultilingualNeural)",
   *             "displayName": "Ava Multilingual",
   *             "localName": "Ava Multilingual",
   *             "shortName": "en-US-AvaMultilingualNeural",
   *             "gender": "Female",
   *             "locale": "en-US",
   *             "localeName": "English (United States)",
   *             "styleList": [],
   *             "sampleRateHertz": "48000",
   *             "voiceType": "Neural",
   *             "status": "GA"
   *         }
   *     ],
   *     "filter": {
   *         "language": "en-US",
   *         "gender": null
   *     }
   * }
   *
   */
  getVoices = async (locale, gender) => {
    const voices = await this.getAll();
    if (!voices.success) {
      return voices;
    }

    var filteredVoices = voices.data.voices.filter(
      (voice) => voice.locale === locale && voice.gender === gender
    );
    const result = {
      success: true,
      voices: filteredVoices.sort((a, b) => {
        if (a.displayName > b.displayName) {
          return 1;
        }
        if (a.displayName < b.displayName) {
          return -1;
        }

        return 0;
      }),
    };
    return result;
  };

  /**
   *
   * Gets a list of voice styles for a specific voice, if any style exists for it.
   *
   * @param {String | undefined} shortName
   * @returns
   */
  getStyles = async (shortName) => {
    const voices = await this.getAll();
    if (!voices.success) {
      console.log(voices);
      return [];
    }

    const voice = voices.data.voices.find(
      (voice) => voice.shortName === shortName
    );
    if (!voice) {
      console.log(`Voice ${shortName} not found.`);
      return [];
    }

    voice.styleList.sort();

    return voice.styleList;
  };

  /**
   * Returns the speech voices.
   *
   * @param {String} text
   * @param {String} shortName
   * @param {String | undefined} style
   * @param {Boolean} mergeOkSound
   *
   * @returns {Promise}
   *
   * @example Response:
   *  Base64 audio truck "UklGRrA8AABXQVZFZm10IBAAAAAGAAEAQB8A......"
   * */
  getSpeech = async (text, shortName, style, mergeOkSound) => {
    try {
      const response = await axios.get(
        `/speech?Text=${text}&ShortName=${shortName}${
          style ? "&Style=" + style : ""
        }&MergeOKSound=${mergeOkSound}`
      );
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
  };
}

export default Speech;
