import React,{ Component } from 'react';
import PropTypes           from 'prop-types';
import { T }               from '@transifex/react';
import { DateTimeService } from '../../../../../../../../services/utils/DateTime';

import IconMore from './images/ico-more.svg';

/**
 * @class ./scenes/VenueManagement/scenes/VenueDashboard/components/Matrix/components/Shows/Shows
 */
class Shows extends Component
{
    /**
     * @type {Object}
     */
    dateTimeService;

    /**
     * Constructor.
     *
     * @param {Object} props
     */
    constructor(props)
    {
        super(props);

        this.dateTimeService = new DateTimeService();
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            this.props.shows.map((show,index) => {
                return (
                    <div className="matrix-item" key={index}>
                        {
                            index === 0 &&
                            <div className="section"><T _str="Shows on devices"/></div>
                        }
                        <div className="subsection">
                            <span className="show-name-and-date">
                                {this.dateTimeService.replaceYearAndDate(this.dateTimeService.getLocalDateTime(show.begin))}
                                <span>
                                    {show.name}
                                </span>
                            </span>
                            <span className="pull-right">
                                <img src={IconMore} alt="More" className="pull-right"/>
                            </span>
                            <div className="options-menu">
                                <ul>
                                    <li onClick={() => this.props.activateShowAtAll(show.id)}><T _str="Activate show at all"/></li>
                                    <li onClick={() => this.props.deactivateShowAtAll(show.id)}><T _str="Deactivate show at all"/></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                );
            })
        );
    }
}

Shows.propTypes = {
    shows               : PropTypes.array.isRequired,
    activateShowAtAll   : PropTypes.func.isRequired,
    deactivateShowAtAll : PropTypes.func.isRequired
};

export default Shows;