import React, { Component } from 'react';

import './style.css';

/**
 * @class src/playground/components/MultiSelect/components/MultiSelectDropdown/Dropdown
 */
class Dropdown extends Component
{
    /**
     * @override
     *
     * @param {Object} nextProps
     */
    UNSAFE_componentWillReceiveProps(nextProps)
    {
        if(nextProps.listOpen) {
            document.addEventListener('mousedown', this.handleClickOutside, false);
            document.addEventListener('keydown', this.handleEscapeKey, false)
        }
    }

    /**
     * @override
     */
    componentWillUnmount()
    {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
        document.removeEventListener('keydown', this.handleEscapeKey, false)
    }

    /**
     * Raise event handler if clicked outside of dropdown area.
     *
     * @param {Object} e
     */
    handleClickOutside = (e) =>
    {
        if(!this.props.mainElement.contains(e.target)) {
            this.props.onCloseDropdown();
        }
    };

    /**
     * Raise event handler if escape key pressed.
     *
     * @param {Object} e
     */
    handleEscapeKey = (e) =>
    {
        if(e.keyCode === 27) {
            this.props.onCloseDropdown();
        }
    };

    /**
     * @returns {XML}
     */
    render()
    {
        const { listOpen, list, getValue } = this.props;
        return (
            listOpen &&
            <ul className="dd-list">
                {
                    list.map( item => {
                        return (
                            <li
                                className="dd-list-item"
                                key={item.id}
                                onClick={() => getValue(item)}
                            >
                                <span className={item.selected ? "checkmark active" : "checkmark"} />
                                {item.selected && <span className="checkmark-icon"/>}
                                <span className="dd-list-item__title">{item.title}</span>
                            </li>
                        );
                    } )
                }
            </ul>
        );
    }
}

export default Dropdown;