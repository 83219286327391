import axios from 'axios/index';

/**
 * @class ./services/api/Zone/Zone
 */
class Zone {

    /**
     * Returns paginated list of all zones for the given venue Id.
     *
     * @param {String} venueId
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *    {
     *      "name": "string",
     *      "rules": [
     *        [
     *         {
     *           "operator": "equal",
     *           "field": "string",
     *           "operand": "string",
     *         }
     *       ]
     *      ]
     *    }
     *  ],
     *   "resultCount": 0,
     *   "totalResultCount": 0,
     *   "pageSize": 0,
     *   "currentPage": 0,
     *   "totalPageCount": 0
     * }
     *
     */
    getAll = (venueId) =>
    {
        let params =  {
            Sort: 'name',
            pageSize: 9999
        };

        return axios.get('/venues/' + encodeURIComponent(venueId) + '/zones',
            {
                params: params
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Creates one zone.
     *
     * @param {String} venueId
     * @param {Object} zone
     *
     * @returns {Object}
     *
     * @example Request/Response:
     * {
     *    "name": "string",
     *    "rules": [
     *     [
     *       {
     *        "operator": "equal",
     *        "field": "string",
     *        "operand": "string",
     *       }
     *    ]
     *  ]
     * }
     *
     */
    createOne = (venueId, zone) =>
    {
        return axios.post('/venues/' + encodeURIComponent(venueId) + '/zones',
            zone
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns one zone for given venue by name.
     *
     * @param {String} venueId
     * @param {String} name
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *    "name": "string",
     *    "rules": [
     *     [
     *       {
     *        "operator": "equal",
     *        "field": "string",
     *        "operand": "string",
     *       }
     *    ]
     *  ]
     * }
     *
     */
    getOne = (venueId, name) =>
    {
        return axios.get('/venues/' + encodeURIComponent(venueId) + '/zones/' + encodeURIComponent(name))
            .then(response => response.data)
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Updates one zone for given venue by name.
     *
     * @param {String} venueId
     * @param {String} name
     * @param {Object} zone
     *
     * @returns {Object}
     *
     * @example Request:
     * {
     *    "name": "string",
     *    "rules": [
     *     [
     *       {
     *        "operator": "equal",
     *        "field": "string",
     *        "operand": "string",
     *       }
     *    ]
     *  ]
     * }
     *
     */
    updateOne = (venueId, name, zone) =>
    {
        return axios.put('/venues/' + encodeURIComponent(venueId) + '/zones/' + encodeURIComponent(name),
            zone
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Deletes one zone.
     *
     * @param {String} venueId
     * @param {String} name
     *
     * @returns {Object}
     *
     */
    deleteOne = (venueId, name) =>
    {
        return axios.delete('/venues/' + encodeURIComponent(venueId) + '/zones/' + encodeURIComponent(name))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            }).catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Computes the number of tickets assigned to each zone ang generates warnings for potentially misconfigured filters.
     *
     * @param {String} venueId
     * @param {Array} showsIds
     *
     * @returns {Object}
     *
     * @example Request/Response:
     * [
     *  "showId1","showId2"...
     * ]
     *
     */
    getReviewZoneSettings = (venueId, showsIds) =>
    {
        return axios.post('/venues/' + encodeURIComponent(venueId) + '/reviewzones',
            showsIds
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
            return {
                status: error.response.status,
                data: error.response.data
            };
        });
    };

    /**
     * Returns an autocomplete list of 10 elements for the value.
     *
     * @param {String} venueId
     * @param {String} key
     * @param {String} value
     *
     * @returns {Array}
     *
     */
    getAutocompleteList = ({ key, partialValue, venueId }) => {
        return axios.get('/venues/' + encodeURIComponent(venueId) + '/autocomplete?key=' + encodeURIComponent(key) + '&partialValue=' + encodeURIComponent(partialValue))
        .then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
            return {
                status: error.response.status,
                data: error.response.data
            };
        });
    };
}

export default Zone;