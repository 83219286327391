"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

function _newArrowCheck(innerThis, boundThis) { if (innerThis !== boundThis) { throw new TypeError("Cannot instantiate an arrow function"); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @class ./validator/Validator
 */
var Validator = function Validator() {
  _classCallCheck(this, Validator);

  Object.defineProperty(this, "errors", {
    enumerable: true,
    writable: true,
    value: []
  });
  Object.defineProperty(this, "validate", {
    enumerable: true,
    writable: true,
    value: function value(_value, constraints) {
      _newArrowCheck(this, this);

      var errors = this.errors;
      constraints.forEach(function (constraint) {
        constraint.setValue(_value);

        if (!constraint.isValid()) {
          errors = [].concat(_toConsumableArray(errors), [constraint.getMessage()]);
        }
      });
      return errors;
    }.bind(this)
  });
  Object.defineProperty(this, "getErrors", {
    enumerable: true,
    writable: true,
    value: function value() {
      _newArrowCheck(this, this);

      return this.errors;
    }.bind(this)
  });
};

var _default = Validator;
exports.default = _default;