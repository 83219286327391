import React      from 'react';
import PropTypes  from 'prop-types';
import { useT }   from '@transifex/react'
import { Modal }  from 'react-bootstrap';
import ErrorIcon  from './DialogError/images/error.svg';
import { Button } from '../../../themes/default/Form/components/Button';

import './DialogError/style.css';

const DialogError = ({ show, closeDialog, children}) => 
{
    const t = useT();
    return (
        <Modal show={show} onHide={closeDialog} dialogClassName="DialogError">
            <Modal.Header>
                <img onClick={closeDialog} src={ErrorIcon} alt="Error"/>
            </Modal.Header>
            <Modal.Body>
                <h3 className="text-center">{t("Oh Snap!", { _context:"This is the header of an error message, doesn't really need to be an exact translation, just something like Ops, or What the fuck is enough HAHAHAH " })}</h3>
                <h4 className="text-center">{children}</h4>
            </Modal.Body>
            <Modal.Footer>
                <Button onClickHandler={closeDialog}>{t("Close")}</Button>
            </Modal.Footer>
        </Modal>
    );
}

DialogError.propTypes = {
    show : PropTypes.bool.isRequired,
    closeDialog : PropTypes.func.isRequired
};

export default DialogError;