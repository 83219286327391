import { useEffect, useRef, useState, useId } from "react";

import "./style.css";

type InputProps = {
  type?: string;
  maxLength?: number;
  label: string;
  name: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder: string;
  className: string;
  value: string;
  focus?: boolean;
  autoComplete?: string;
  onBlur?: (value: string, name: string) => void;
  onKeyUp?: (value: string, name: string) => void;
  getValue?: (value: string) => void;
  onChange?: (value: string) => void;
};
const Input = ({
  type = "text",
  maxLength = undefined,
  label,
  name,
  required = false,
  readOnly = false,
  disabled = false,
  placeholder = "",
  className = "",
  value,
  focus = false,
  autoComplete,
  onBlur,
  onKeyUp,
  getValue,
  onChange,
}: InputProps) => {
  const [localValue, setLocalValue] = useState(value);
  const firstRender = useRef(true);
  const inputField = useRef<HTMLInputElement>(null);
  const elementId = useId();

  useEffect(() => {
    (value || value === "") && value !== null && setLocalValue(value);
    focus && inputField.current?.focus();
  }, [value, focus]);

  // Value call back when stop typing
  useEffect(() => {
    // Fix getValue() always being called when the input is rendered for the first time.
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (getValue) {
      const timeoutId = setTimeout(() => {
        getValue(localValue);
      }, 600);
      return () => clearTimeout(timeoutId);
    }
    return;
  }, [localValue, getValue]);

  const valueHandler = (value: string) => {
    setLocalValue(value);
    onChange && onChange(value);
  };

  return (
    <span className={`Input ${className}`}>
      {label && (
        <label
          htmlFor={elementId}
          className={`${className} ${required ? " required" : ""}`}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        name={name}
        ref={inputField}
        value={localValue}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        className={className}
        maxLength={maxLength}
        placeholder={placeholder}
        autoComplete={autoComplete ?? name}
        onChange={(e) => valueHandler(e.target.value)}
        onBlur={onBlur && ((e) => onBlur(e.target.value, name))}
        onKeyUp={onKeyUp && ((e) => onKeyUp(e.currentTarget.value, name))}
        id={elementId}
      />
    </span>
  );
};

export default Input;
