import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { NavLink }          from 'react-router-dom';

import IconArrows           from './images/arrows.svg';
import IconConfirm          from './images/ico-confirm.svg';

import './style.css';

/**
 * @class scenes/VenueManagement/scenes/Shows/components/List/List
 */
class List extends Component
{
    /**
     * Renders list head row.
     *
     * @param {Object} columns
     */
    renderHeadRow = (columns) =>
    {
        return Object.entries(columns).map((column,index) => {
            return (
                <th key={index} style={{"width":column[1].width}}>{column[0]}
                    {
                        column[1].subtitle !== null ? <span className="grayed">{column[1].subtitle}</span> : null
                    }
                    {
                        column[1].sortable ? <img className="pull-right"
                                                  src={IconArrows}
                                                  alt="Arrows"
                                                  onClick={() => this.props.sort(column[1].fieldValue)}/> : null
                    }
                </th>
            );
        })
    };

    /**
     * Renders list body.
     *
     * @param {Array} data
     */
    renderBody = (data) =>
    {
        if (data) {
            return data.map((rowData,index) => (
                <tr key={index}>
                    {
                        Object.entries(rowData).map((cellData, index) => (
                            <td key={index} title={ typeof cellData[1] !== "boolean" ? cellData[1] ? cellData[1] : undefined : undefined}
                                className={`${(cellData[0] === 'alt' && cellData[1] === '') ? 'no-record' : ''} ${cellData[0] === 'forced' ? 'forced text-center' : ''}`}>
                                {
                                    (cellData[0] === 'alt' && cellData[1] === '')
                                        ? 'no alt title'
                                        : cellData[0] === 'provider'
                                            ? <NavLink to={`/providers/${cellData[1][1]}`}>{cellData[1][0]}</NavLink>
                                            : cellData[0] === 'show'
                                                ? <NavLink to={`/shows/details/${cellData[1][1]}`}>{cellData[1][0]}</NavLink>
                                                : cellData[0] === 'forced'
                                                    ? cellData[1]
                                                        ? <img src={IconConfirm} alt={"Checked"} />
                                                        : <React.Fragment />
                                                    : cellData[1]
                                }
                            </td>
                        ))
                    }
                </tr>
            ));
        }
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
             <table className={`${this.props.className ? this.props.className : ""} List table table-striped`}>
                <thead>
                <tr>
                    { this.renderHeadRow(this.props.columns) }
                </tr>
                </thead>
                <tbody>
                    { this.renderBody(this.props.data) }
                </tbody>
            </table>
        );
    }
}

List.propTypes = {
    columns : PropTypes.object.isRequired,
    data : PropTypes.array
};

export default List;