import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import ReactDOM             from 'react-dom';

import './style.css';

/**
 * @class Form/Checkbox/Checkbox
 */
class Checkbox extends Component
{
    state = {
        checked: false
    };

    /**
     * @override
     */
    UNSAFE_componentWillMount ()
    {
        this.handleCheckedValue();
    };

    /**
     * @override
     */
    componentDidUpdate()
    {
        this.handleCheckedValue();
    };

    /**
     * Handles checkbox checked value.
     */
    handleCheckedValue = () =>
    {
        if (this.props.checked !== this.state.checked) {
            this.setState({
                checked : this.props.checked
            });
            this.props.getValue(this.props.checked, this.props.name, this.props.uid)
        }
    };

    /**
     * Handles input value on change and updates state.
     */
    handleChange = () =>
    {
        this.setState({
            checked : ReactDOM.findDOMNode(this.inputField).checked
        });
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <label className={[
                'Checkbox',
                this.props.className,
                this.props.required && 'required',
                this.props.disabled && 'disabled'
            ].join(' ')}>{ this.props.label }
                <input type={ this.props.type } name={ this.props.name } className={ this.props.className }
                       required={ this.props.required } disabled={ this.props.disabled } value={ this.props.value }
                       readOnly={ this.props.readOnly } placeholder={ this.props.placeholder }
                       checked={ this.state.checked } ref={(input) => this.inputField = input}
                       onChange={() => {
                                this.handleChange();
                                this.props.getValue(ReactDOM.findDOMNode(this.inputField).checked, ReactDOM.findDOMNode(this.inputField).name, this.props.uid)
                            }
                       }
                />
                <span className={'checkmark'} />
            </label>
        );
    }
}

Checkbox.defaultProps = {
    type: 'checkbox',
    required : false,
    readOnly : false,
    disabled : false,
    className : '',
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    value : PropTypes.string,
    required : PropTypes.bool,
    readOnly : PropTypes.bool,
    disabled : PropTypes.bool,
    className : PropTypes.string,
    getValue : PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    placeholder : PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired])
};

export default Checkbox;