import React, { Component } from 'react';
import { T, useT }          from '@transifex/react'
import { Button }           from '../../themes/default/Form/components/Button';
import { Dialog }           from '../../themes/default/Dialog';
import { Select }           from '../../themes/default/Form/components/Select';
import { Input }            from '../../themes/default/Form/components/Input';
import { Checkbox }         from '../../themes/default/Form/components/Checkbox';
import PropTypes            from 'prop-types';
import { ValidatorService,
         NotBlank }         from 'pretty-validator';
import IconArrow            from './images/arrow.svg';
import { FiltersRow }       from './FiltersRow';

import { isFilterEmpty,
    getNumberOfLevelsInFilters,
    filterConvert }         from './Util';
    
import './style.css';

/**
 * @class components/Tag
 */
class Tag extends Component
{
    /**
     * @type {Object}
     */
    validatorService;

    state = {
        openCreateFilterItemDialog : false,
        openConfirmationDialog : false,
        valueOfKeyField : "eventId",
        valueOfOperationField : "=",
        valueOfValueField : '',
        removeFilterLevel : null,
        removeFilterIndex : null,
        filters : {},
        level : '1',
        isUpdated : false,
        valueFieldErrors : [],
        isEdit: false
    };

    /**
     * Constructor.
     *
     * @param {Object} props
     */
    constructor(props)
    {
        super(props);

        this.validatorService = new ValidatorService();
    };

    /**
     * @override
     */
    UNSAFE_componentWillMount()
    {
        if (this.props.config.value) {
          this.setValue(this.props.config.value);
        }
    };

    /**
     * @override
     */
    componentDidUpdate()
    {
        if (this.props.getValue && this.state.isUpdated) {
            this.props.getValue(this.getValue());

            this.setState({
                isUpdated : false
            });
        }
    };

    /**
     * Returns the value of a tag component.
     *
     * @returns {Object}
     */
    getValue = () =>
    {
        return filterConvert(this.state.filters, this.props.uid);
    };

    /**
     * Sets tag value retrieved from api.
     *
     * @param {String} response
     */
    setValue = (response) =>
    {
        if (response.value) {
            let filtersRows = response.value.split(' || ');
            let filters = {};

            filtersRows.map((filtersRow,index) => {
                let filtersLevel = parseInt(index, 10) + 1;
                filters[filtersLevel] = {};
                Object.entries(response.isForced).map(isFilterForced => {
                    if (parseInt(isFilterForced[0],10) === filtersLevel) {
                        filters[filtersLevel]['isForced'] = isFilterForced[1];
                    }

                    return true;
                });
                filters[filtersLevel]['value'] = [];
                return (
                    filtersRow.split(' && ').map((filter) => {
                        if (filter) {
                            return filters[filtersLevel]['value'].push(filter);
                        } else {
                            return true;
                        }
                    })
                );
            });

            let level = this.getNumberOfLevelsInFiltersObject(filters);

            if (JSON.stringify(this.state.filters) !== JSON.stringify(filters)) {
                this.setState({
                    filters : filters,
                    level : level
                });
            }
        }
    };

    /**
     * Checks if filters object is empty.
     *
     * @param {Object} filters
     *
     * @returns {Boolean}
     */
    isEmpty = (filters) =>
    {
        return isFilterEmpty(filters)
    };

    /**
     * Checks if value is valid and if not adds error to input field.
     *
     * @param {String} value
     *
     * @returns {Boolean}
     */
    isValueFieldValid = (value = this.state.valueOfValueField) =>
    {
        let errors = this.validatorService.validate(value,[new NotBlank()]);

        let isValid = errors.length === 0;

        if (!isValid) {
            this.setState({
                valueFieldErrors : errors
            });
        }

        return isValid;
    };

    /**
     * Returns number of levels in current filters object.
     *
     * @param {Object} filtersObject
     *
     * @returns {number}
     */
    getNumberOfLevelsInFiltersObject = (filtersObject) =>
    {
        return getNumberOfLevelsInFilters(filtersObject);
    };

    /**
     * Updates state's value responsible for showing (hiding) of dialog.
     */
    openCreateFilterItemDialog = () =>
    {
        this.setState({
            openCreateFilterItemDialog : true
        });
    };

    /**
     * Updates state's value responsible for showing (hiding) of dialog.
     */
    closeCreateFilterItemDialog = () =>
    {
        this.setState({
            openCreateFilterItemDialog : false,
            valueOfKeyField : "eventId",
            valueOfOperationField : "=",
            valueOfValueField : '',
            valueFieldErrors : [],
            level : '1',
            isEdit: false
        });
    };

    /**
     * Returns selected key value.
     *
     * @param {Object} valueObject
     *
     * @returns {String}
     */
    getSelectedKeyValue = (valueObject) =>
    {
        if (this.state.valueOfKeyField !== valueObject.value) {
            this.setState({
                valueOfKeyField : valueObject.value
            });
        }
    };

    /**
     * Returns selected operation value.
     *
     * @param {Object} valueObject
     *
     * @returns {String}
     */
    getSelectedOperationValue = (valueObject) =>
    {
        if (this.state.valueOfOperationField !== valueObject.value) {
            this.setState({
                valueOfOperationField : valueObject.value
            });
        }
    };

    /**
     * Returns typed value.
     *
     * @param {String} typedValue
     *
     * @returns {String}
     */
    getTypedValue = (typedValue) =>
    {
        let valueFieldErrors = [...this.state.valueFieldErrors];
        this.setState({
            valueOfValueField : typedValue,
            valueFieldErrors : this.isValueFieldValid(typedValue) ? [] : valueFieldErrors
        });
    };

    /**
     * Returns value of filters row's checkbox.
     *
     * @param {Boolean} checked
     * @param {String} name
     */
    getCheckboxValue = (checked,name) =>
    {
        let filters = {...this.state.filters};
        if (name !== undefined) {
            let level = parseInt(name.substring(8), 10);
            let filtersRow = filters[level];
            filtersRow['isForced'] = checked;
            filters[level] = filtersRow;
        }

        this.setState({
            filters : filters,
            isUpdated : true
        });
    };

    /**
     * Return true if checkbox is checked.
     *
     * @param {String} filtersLevel
     *
     * @returns {Boolean}
     */
    isChecked = (filtersLevel) =>
    {
        return this.state.filters[filtersLevel]['isForced'];
    };

    /**
     * Adds new filter item to the filters object.
     */
    createNewFilterItem = () =>
    {
        if (this.isValueFieldValid()) {
            let filter = '' + this.state.valueOfKeyField + ' ' + this.state.valueOfOperationField + ' '
                + this.state.valueOfValueField + '';

            let newFiltersObject = {};
            let filtersInnerArray = [];
            let filtersObject = {...this.state.filters};

            if (filtersObject !== {} && filtersObject[this.state.level]) {
                filtersInnerArray = filtersObject[this.state.level]['value'];
                filtersInnerArray = [...filtersInnerArray, filter];
                filtersObject[this.state.level]['value'] = filtersInnerArray;
                newFiltersObject = filtersObject;
            } else {
                filtersObject[this.state.level] = {};
                filtersObject[this.state.level]['value'] = [filter];
                filtersObject[this.state.level]['isForced'] = false;
                newFiltersObject = filtersObject;
            };

            this.setState({
                openCreateFilterItemDialog : false,
                filters : newFiltersObject,
                isUpdated : true,
                valueOfKeyField : "eventId",
                valueOfOperationField : "=",
                valueOfValueField : '',
                valueFieldErrors : [],
                level : '1',
                isEdit: false
            });
        }
    };

    /**
     * Handles click on "or" filter button.
     */
    orButtonClickHandler = () =>
    {
        let currentLevel = this.getNumberOfLevelsInFiltersObject(this.state.filters);

        this.setState({
            level : currentLevel + 1
        });

        this.openCreateFilterItemDialog();
    };

    /**
     * Handles click on "and" filter button.
     *
     * @param {String} level
     */
    andButtonClickHandler = (level) =>
    {
        this.setState({
            level : level
        });

        this.openCreateFilterItemDialog();
    };

    /**
     * Handles click on "edit" filter image.
     *
     * @param {String} level
     */
    editButtonClickHandler = (level,index) =>
    {
        const editedFilter = this.state.filters[level]['value'][index];

        const valueOfKeyField = editedFilter.split(' ')[0];
        const valueOfOperationField = editedFilter.split(' ')[1];
        // valueOfValueField => Take the rest of the string after remove the Field name & the operation
        const valueOfValueField = editedFilter.split(`${editedFilter.split(' ')[0]} ${editedFilter.split(' ')[1]} `)[1];

        this.setState({
            isEdit: true,
            editedFilterObj: {
                key: valueOfKeyField,
                operation : valueOfOperationField,
                value : valueOfValueField,
                index
            },
            level
        });

        this.openCreateFilterItemDialog();
    };

    /**
     * Update the current filter informations
     * 
     * @param {string}  action
     * @param {string}  value
     */
    editFilter = (value,action) =>
    {
        const { level, filters, editedFilterObj} = this.state;

        if (editedFilterObj[action] !== value) {

            const index = editedFilterObj.index;
            const editedFilter = filters[level]['value'][index];
            const editedFilterSplit = editedFilter.split(' ');
        
            const returnExistingValue = arr => {
                let final = "";
                arr.map((str, idx) => {
                    idx > 1 && (final = final === "" ? str : `${final} ${str}`);
                    return null
                });
                return final;
            };

            const valueOfKeyField       = action === 'key'       ? value : editedFilterSplit[0];
            const valueOfOperationField = action === 'operation' ? value : editedFilterSplit[1];
            const valueOfValueField     = action === 'value'     ? value : returnExistingValue(editedFilterSplit)

            filters[level]['value'][index] = `${valueOfKeyField} ${valueOfOperationField} ${valueOfValueField}`;

            this.setState({
                editedFilterObj : {
                    ...editedFilterObj,
                    [action] : value
                }
            });
        };
    };

    /**
     * Removes filter from filters object.
     *
     * @param {String} filterLevel
     * @param {Number} filterIndex
     */
    removeFilter = (filterLevel, filterIndex) =>
    {
        let newFilters = {...this.state.filters};

        let innerFiltersArray = newFilters[filterLevel]['value'];
        innerFiltersArray.splice(filterIndex,1);
        const isLevelEmpty = !(!!innerFiltersArray.length);
        if (isLevelEmpty) {
            delete newFilters[filterLevel];
        } else {
            newFilters[filterLevel]['value'] = innerFiltersArray;
        }
        let newFiltersReindexed = this.reindexFilters(newFilters,filterLevel,isLevelEmpty);

        this.setState({
            filters : newFiltersReindexed,
            isUpdated : true
        });
    };

    /**
     * Returns reindexed filters object when certain rule is removed from filters.
     *
     * @param {Object} filters
     * @param {Number} filterLevel
     * @param {Boolean} isLevelEmpty
     *
     * @returns {Object}
     */
    reindexFilters = (filters,filterLevel,isLevelEmpty) =>
    {
        let reindexedFiltersArray = {};
        Object.entries(filters).map(filter => {
            if (filter[0] > filterLevel && isLevelEmpty) {
                reindexedFiltersArray[filter[0] - 1] = filter[1];
            } else {
                reindexedFiltersArray[filter[0]] = filter[1];
            }

            return true;
        });

        return reindexedFiltersArray;
    };

    /**
     * Handles click on the "remove" button.
     *
     * @param {String} filterLevel
     * @param {String} filterIndex
     */
    removeButtonClickHandler = (filterLevel, filterIndex) =>
    {
        this.setState({
            removeFilterLevel : filterLevel,
            removeFilterIndex : filterIndex,
            openConfirmationDialog : true
        });
    };

    /**
     * Handles click on confirm button in confirmation dialog.
     */
    removeFilterConfirmation = () =>
    {
        this.removeFilter(this.state.removeFilterLevel, this.state.removeFilterIndex);
        this.setState({
            removeFilterLevel : null,
            removeFilterIndex : null,
            openConfirmationDialog : false
        });
    };

    /**
     * Handles click on cancel button in confirmation dialog.
     */
    closeConfirmationDialog = () =>
    {
        this.setState({
            removeFilterLevel : null,
            removeFilterIndex : null,
            openConfirmationDialog : false
        });
    };

    /**
     * Fire the componentDidUpdate and close the create Filter dialog
     */
    updateFilter = () => {
        this.setState({
            isUpdated : true,
            openCreateFilterItemDialog : false,
            isEdit: false
        });
    }

    /**
     * @returns {XML}
     */
    render()
    {
        const width = window.innerWidth <= 991
            ?   (window.innerWidth - 57 + "px")
            :   (window.innerWidth - 500 + "px");

        return (
            <div className="Tag">

                {/* Renders create new filter item button. */}
                {this.isEmpty(this.state.filters) &&
                    <div className="createNewTagBtn">
                        <Button onClickHandler={() => this.openCreateFilterItemDialog()}>
                            { this.props.config.buttonTitle }
                        </Button>
                        <span className="pull-right apply-filters">
                            {this.props.config.applyMessage}
                            <Button onClickHandler={() => this.props.applyFiltersHandler(this.props.uid) }><T _str="Apply Filters Now"/></Button>
                        </span>
                    </div>
                }

                {this.state.openCreateFilterItemDialog &&
                    <CustomDialog
                        props={this.props}
                        isEdit={this.state.isEdit}
                        editedFilterObj={this.state.editedFilterObj}
                        valueFieldErrors={this.state.valueFieldErrors}
                        openCreateFilterItemDialog={this.state.openCreateFilterItemDialog}
                        editFilter={this.editFilter}
                        updateFilter={this.updateFilter}
                        getTypedValue={this.getTypedValue} 
                        createNewFilterItem={this.createNewFilterItem}
                        getSelectedKeyValue ={this.getSelectedKeyValue}
                        getSelectedOperationValue={this.getSelectedOperationValue}
                        closeCreateFilterItemDialog={this.closeCreateFilterItemDialog}
                    />
                }

                {/* Renders filters section. */}
                {!this.isEmpty(this.state.filters) &&
                    <div className="filters">
                        <div className="table-wrapper" style={{ width: width }}>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th><T _str="Filter Rule"/></th>
                                    <th><T _str="Forced Active"/></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.entries(this.state.filters).map((filtersRow,rowIndex) => {
                                        let isRowEmpty = !(!!filtersRow[1]['value'].length);
                                        return  !isRowEmpty &&
                                                <tr key={rowIndex}>
                                                    <td>
                                                        <FiltersRow       
                                                            getNumberOfLevelsInFiltersObject ={this.getNumberOfLevelsInFiltersObject}
                                                            filters                          ={this.state.filters}
                                                            filtersRow                       ={filtersRow}
                                                            andButtonClickHandler            ={this.andButtonClickHandler}
                                                            rowIndex                         ={rowIndex}
                                                            editButtonClickHandler           ={this.editButtonClickHandler}
                                                            removeButtonClickHandler         ={this.removeButtonClickHandler}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Checkbox
                                                            label       =""
                                                            getValue    ={this.getCheckboxValue}
                                                            checked     ={this.isChecked(filtersRow[0])}
                                                            name        ={`Level - ${filtersRow[0]}`} // Translation here is not possible, becasue getCheckboxValue() will deal with the value as a string and not an object to set the filter value, all the funtionality need to be changes if the translation is very important
                                                            placeholder ={`filter-level-${filtersRow[0]}-checkbox`} // placeholder is using to target the element in __tests__
                                                        />
                                                    </td>
                                                </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <span data-testid="create-filter-or-button" className="or text-uppercase" onClick={() => this.orButtonClickHandler()}>
                            <T _str="or"/>
                            <img src={IconArrow} alt="Or" title="Or"/>
                        </span>
                        <span className="pull-right apply-filters">
                            {this.props.config.applyMessage}
                            <Button onClickHandler={() => this.props.applyFiltersHandler(this.props.uid) }>
                                <T _str="Apply Filters Now"/>
                            </Button>
                        </span>
                    </div>
                }
                
                {/* Render confirmation dialog. */}
                {this.state.openConfirmationDialog &&
                    <div data-testid="delete-filter-confirmation">
                        <Dialog
                            mainButton={<T _str="Confirm"/>}
                            title={<T _str="Confirmation dialog"/>}
                            action={this.removeFilterConfirmation}
                            closeModal={this.closeConfirmationDialog}
                            showModal={this.state.openConfirmationDialog}
                        >
                            <h4 className="text-center"><T _str="Are you sure you want to delete filter?"/></h4>
                        </Dialog>
                    </div>
                }

            </div>
        );
    };
};

// Renders dialog for edit this filter if isEdit or creating new filter item if !isEdit.
const CustomDialog = ({ props, openCreateFilterItemDialog, isEdit, editedFilterObj, valueFieldErrors,
    updateFilter, createNewFilterItem, closeCreateFilterItemDialog, editFilter, getSelectedOperationValue, getTypedValue, getSelectedKeyValue }) =>
{
    const t = useT();
    return (
        <div data-testid="render-filter-dialog" >
            <Dialog
                title={props.config.dialogTitle}
                showModal={openCreateFilterItemDialog}
                closeModal={closeCreateFilterItemDialog}
                mainButton={isEdit ? t("Update") : t("Create")}
                action={isEdit ? updateFilter : createNewFilterItem}
            >
                <div className="row tag-dialog">
                    <div className="col-12 p-0 key">
                        <label>{t("Key")}</label>
                        {isEdit
                            ?   <Select
                                    data={props.config.keys}
                                    initialValue ={editedFilterObj.key}
                                    getValue={e => editFilter(e.value,'key')}/>
                            :   <Select
                                    data={props.config.keys}
                                    getValue={getSelectedKeyValue}
                                    initialValue={Object.entries(props.config.keys)[0][0]}/>
                        }
                    </div>
                    <div className="col-12 operation">
                        {isEdit
                            ?   <Select
                                    data={props.config.operations}
                                    getValue={e => editFilter(e.value,'operation')}
                                    initialValue={editedFilterObj.operation}/>
                            :   <Select
                                    data={props.config.operations}
                                    getValue={getSelectedOperationValue}
                                    initialValue={Object.entries(props.config.operations)[0][0]}/>
                        }
                    </div>
                    <div className="col-12 value">
                        <label>{t("Value")}</label>
                        {isEdit
                            ?    <Input
                                    name="filter-value"
                                    value={editedFilterObj.value}
                                    getValue={value => editFilter(value,'value')}
                                    className={valueFieldErrors.length > 0 ? "error" : ""}/>
                            :   <Input
                                    name="filter-value"
                                    className={valueFieldErrors.length > 0 ? "error" : ""}
                                    getValue={getTypedValue}/>
                        }
                    </div>
                    { valueFieldErrors.length > 0 && valueFieldErrors.map( (error, key) =>
                        <div key={ key } className="col-12 p-0 error">
                            - { error }
                        </div>
                    )}
                </div>
                <span className="helper">{t("Helper")}</span>
            </Dialog>
        </div>
    );
};

Tag.propTypes = {
    applyFiltersHandler : PropTypes.func.isRequired,
    config              : PropTypes.object.isRequired,
    getValue            : PropTypes.func,
    uid                 : PropTypes.string
};

export default Tag;