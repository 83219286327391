import React, { Component } from "react";
import { ListService } from "./services/List";
import { T, useT } from "@transifex/react";
import { List } from "./components/List";
import Rules from "./components/Rules";
import { ZoneService } from "../../../../../../services/api/Zone";
import { VenueService } from "../../../../../../services/api/Venue";
import { LoadingScreen } from "../../../../../../components/Loading";
import { RadioGroup, Select } from "../../../../../../themes/default/Form";
import { SortService } from "../../../../../../services/utils/Sort";
import { Pagination } from "../../../../../../components/Pagination";
import { DialogError } from "../../../../../../themes/default/Dialog";
import { ResponseService } from "../../../../../../services/utils/Response";
import { SceneTitle } from "../../../../../../themes/default/Title/components/Scene";
import {
  Button,
  ButtonStatus,
} from "../../../../../../themes/default/Form/components/Button";

import "./style.css";

const VENUE_IN_TIME_FRAME_YES = true;
const SHOW_TIME_RANGE_ALL = "1";
const SHOW_TIME_RANGE_FUTURE = "2";
const SHOW_TIME_RANGE_TIME_FRAME = "3";

/**
 * @class ./scenes/VenueManagement/scenes/ZonesAndCheckpoints/scenes/ZoneSettings/ZoneSettings
 */
class ZoneSettings extends Component {
  /**
   * @type {Object}
   */
  listService;

  /**
   * @type {Object}
   */
  venueService;

  /**
   * @type {Object}
   */
  responseService;

  /**
   * @type {Object}
   */
  zoneService;

  /**
   * @type {Object}
   */
  sortService;

  state = {
    shows: [],
    showLoadingScreen: false,
    showErrorDialog: false,
    errorMessages: [],
    selectedShows: [],
    timeRange: SHOW_TIME_RANGE_ALL,
    pageSize: 50,
    currentPage: 1,
    totalPageCount: 1,
    sort: "begin",
    useTimeframe: null,
    zones: [],
    zonesInfo: [],
    selectedShow: null,
  };

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.venueId = this.props.match.params.id;

    this.listService = new ListService();
    this.venueService = new VenueService();
    this.responseService = new ResponseService();
    this.sortService = new SortService();
    this.zoneService = new ZoneService();
  }

  /**
   * @override
   */
  componentDidMount() {
    this.handleShows(
      1,
      this.state.pageSize,
      this.state.sort,
      this.state.timeRange
    );
    this.venueService.getOne(this.venueId).then((response) => {
      if (response.status === 200) {
        this.setState({
          useTimeframe: response.data.useTimeframe,
        });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
    });
  }

  /**
   * Converts selected date range to coresponding string.
   *
   * @param {String} selectedDateRange
   *
   * @returns {String}
   */
  getDateRangeString = (selectedDateRange) => {
    let dateRange = "";
    switch (selectedDateRange) {
      case SHOW_TIME_RANGE_FUTURE:
        dateRange = "future";
        break;
      case SHOW_TIME_RANGE_TIME_FRAME:
        dateRange = "timeframe";
        break;
      default:
        dateRange = "all";
    }

    return dateRange;
  };

  /**
   * Reveals loading screen.
   */
  showLoading = () => {
    if (!this.state.showLoadingScreen) {
      this.setState({
        showLoadingScreen: true,
      });
    }
  };

  /**
   * Hides loading screen.
   */
  hideLoading = () => {
    this.setState({
      showLoadingScreen: false,
    });
  };

  /**
   * Gets appropriate number of shows (according to page size) and updates the state.
   *
   * @param {String} page
   * @param {String} pageSize
   * @param {String} sort
   * @param {String} dateRange
   */
  handleShows = (page, pageSize, sort, dateRange) => {
    let parameterObject = {
      ...(sort !== "" ? { Sort: sort } : { Sort: "-begin" }),
      dateRange: this.getDateRangeString(dateRange),
      Page: page,
      PageSize: pageSize,
    };
    this.showLoading();
    this.venueService
      .getAllShows(this.venueId, parameterObject)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.resultCount !== 0) {
            this.setState({
              shows: response.data.results,
              totalPageCount: response.data.totalPageCount,
              pageSize: response.data.pageSize,
            });
          } else {
            this.setState({
              shows: [],
            });
          }
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.hideLoading();
      });
  };

  /**
   * Updates state and closes error dialog.
   */
  closeErrorDialog = () => {
    this.setState({
      showErrorDialog: false,
    });
  };

  /**
   * Redirects user to the zones and checkpoints scene.
   */
  goToZonesAndCheckpointsScene = () => {
    this.props.history.goBack();
  };

  /**
   * Gets shows according to selected value.
   *
   * @param {Object} event
   */
  getPeriodValue = (event) => {
    let timeRange = event.target.value;

    this.handleShows(1, this.state.pageSize, this.state.sort, timeRange);

    this.setState({
      timeRange: timeRange,
      currentPage: 1,
    });
  };

  /**
   * Checks if all in time frame is disabled and return boolean.
   *
   * @return {String}
   */
  isAllInTimeFrameDisabled = () => {
    let disabledRadioButton = SHOW_TIME_RANGE_TIME_FRAME;
    if (this.state.useTimeframe === VENUE_IN_TIME_FRAME_YES) {
      disabledRadioButton = "";
    } else if (this.state.timeRange === SHOW_TIME_RANGE_TIME_FRAME) {
      this.setState({
        timeRange: SHOW_TIME_RANGE_ALL,
      });
    }

    return disabledRadioButton;
  };

  /**
   * Updates show's state value according to pagination properties.
   *
   * @param {Object} perPage
   */
  showsPerPageHandler = (perPage) => {
    if (this.state.pageSize !== parseInt(perPage.value, 10)) {
      if (this.state.currentPage !== 1) {
        this.setState({
          currentPage: 1,
        });
      }
      this.handleShows(1, perPage.value, this.state.sort, this.state.timeRange);
    }
  };

  /**
   * Updates show's state value according to selected page.
   *
   * @param {Number} page
   */
  changePageHandler = (page) => {
    if (this.state.currentPage !== page) {
      this.handleShows(
        page,
        this.state.pageSize,
        this.state.sort,
        this.state.timeRange
      );
      this.setState({
        currentPage: page,
        selectedShows: [],
      });
    }
  };

  /**
   * Sorts Name of Show and Begin columns ascending or descending and sets state accordingly.
   *
   * @param {String} column
   */
  sortColumn = (column) => {
    let sort = this.sortService.getSortValue(this.state.sort, column);

    this.setState({
      sort: sort,
    });

    this.handleShows(
      this.state.currentPage,
      this.state.pageSize,
      sort,
      this.state.timeRange
    );
  };

  /**
   * Handles show selection/deselection.
   *
   * @param {Boolean} isSelected
   * @param {String} showId
   */
  selectDeselectShow = (isSelected, showId) => {
    if (this.state.selectedShows.includes(showId) && !isSelected) {
      this.setState({
        selectedShows: this.state.selectedShows.filter(
          (show) => show !== showId
        ),
      });
    } else if (!this.state.selectedShows.includes(showId) && isSelected) {
      this.setState({
        selectedShows: [...this.state.selectedShows, showId],
      });
    }
  };

  /**
   * Calculates number of tickets for each zone of selected shows.
   */
  calculateHandler = () => {
    this.showLoading();
    this.zoneService.getAll(this.venueId).then((response) => {
      if (response.status === 200) {
        this.setState({
          zones: response.data.results,
        });
        this.zoneService
          .getReviewZoneSettings(this.venueId, this.state.selectedShows)
          .then((response) => {
            if (response.status === 200) {
              this.setState({
                zonesInfo: response.data,
              });
            } else {
              this.setState({
                showErrorDialog: true,
                errorMessages: this.responseService.getErrorMessages(
                  response.data
                ),
              });
            }
            this.hideLoading();
          });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
        this.hideLoading();
      }
    });
  };

  /**
   * Clears calculation data.
   */
  clearCalculation = () => {
    this.setState({
      zones: [],
      zonesInfo: [],
      selectedShow: null,
    });
  };

  /**
   * Sets state value for show id in order to show rules info.
   *
   * @param {String} showId
   */
  selectShow = (showId) => {
    if (this.state.selectedShow !== showId) {
      this.setState({
        selectedShow: showId,
      });
    } else {
      this.setState({
        selectedShow: null,
      });
    }
  };

  /**
   * Returns true when user clicks on "Calculate" button and gets zones and zones info.
   *
   * @returns {Boolean}
   */
  isCalculationActive = () => {
    return this.state.zones.length;
  };

  /**
   * @returns {XML}
   */
  render() {
    return (
      <div
        className={`ZoneSettings ${
          this.isCalculationActive() ? "pagination-hidden" : ""
        }`}
      >
        <Tite />

        {/* Renders go back button. */}
        {!this.isCalculationActive() && (
          <Button onClickHandler={() => this.goToZonesAndCheckpointsScene()}>
            <T _str="Go Back" />
          </Button>
        )}

        {/* Renders shows list filters. */}
        {!this.isCalculationActive() && (
          <ShowsListFilters
            pageSize={this.state.pageSize}
            timeRange={this.state.timeRange}
            selectedShows={this.state.selectedShows}
            getPeriodValue={this.getPeriodValue}
            showsPerPageHandler={this.showsPerPageHandler}
            isAllInTimeFrameDisabled={this.isAllInTimeFrameDisabled}
          />
        )}

        {/* Renders list title when certain shows are selected and calculate button is clicked. */}
        {this.isCalculationActive && (
          <h4>
            <T _str="Assignment of tickets to zones" />:
          </h4>
        )}

        <List
          sort={this.sortColumn}
          zones={this.state.zones}
          selectShow={this.selectShow}
          zonesInfo={this.state.zonesInfo}
          selectedShows={this.state.selectedShows}
          selectDeselectShow={this.selectDeselectShow}
          isCalculation={
            !!(this.state.zones.length && this.state.zonesInfo.length)
          }
          data={this.listService.getZoneSettingsData(
            this.state.shows,
            this.state.selectedShows,
            this.state.zones,
            this.state.zonesInfo
          )}
        />

        <Pagination
          changePageHandler={this.changePageHandler}
          currentPage={this.state.currentPage}
          totalPageCount={this.state.totalPageCount}
        />

        {/* Rules info list when certain show is selected. */}
        {this.isCalculationActive() !== 0 && !!this.state.selectedShow && (
          <RulesMultipleZones
            zones={this.state.zones}
            zonesInfo={this.state.zonesInfo}
            selectedShow={this.state.selectedShow}
          />
        )}

        {/* Renders "Calculate" or "Cancel" button conditionally. */}
        {this.isCalculationActive() ? (
          <Button onClickHandler={() => this.clearCalculation()}>
            <T _str="Cancel" />
          </Button>
        ) : (
          <Button
            onClickHandler={() => this.calculateHandler()}
            status={
              !this.state.selectedShows.length ? ButtonStatus.Disabled : ""
            }
          >
            <T _str="Calculate" />
          </Button>
        )}

        {this.state.showLoadingScreen && <LoadingScreen />}

        <DialogError
          show={this.state.showErrorDialog}
          closeDialog={this.closeErrorDialog}
        >
          {this.state.errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>
      </div>
    );
  }
}

const Tite = () => {
  const t = useT();
  return <SceneTitle text={t("Review Zone Settings")} />;
};

const RulesMultipleZones = ({ zones, zonesInfo, selectedShow }) => {
  const t = useT();
  const listService = new ListService();

  const zoneSettingsRulesColumns = {
    "#Tickets": {
      subtitle: null,
      sortable: false,
      width: "100px",
      align: "text-end",
    },
    [t("Rules")]: { subtitle: null, sortable: false, width: "600px" },
  };

  return (
    <div>
      <h4>
        <T _str="Rules causing assignment of tickets to multiple zones:" />
      </h4>
      <Rules
        isCalculation={!!zones.length}
        columns={zoneSettingsRulesColumns}
        data={listService.getZoneSettingsRulesData(selectedShow, zonesInfo)}
      />
    </div>
  );
};

const ShowsListFilters = ({
  selectedShows,
  getPeriodValue,
  timeRange,
  isAllInTimeFrameDisabled,
  showsPerPageHandler,
  pageSize,
}) => {
  const t = useT();

  const timeRangeData = {
    1: t("all"),
    2: t("all in future"),
    3: t("all in timeframe"),
  };

  const perPageData = {
    1: "1",
    5: "5",
    20: "20",
    50: "50",
    100: "100",
    500: "500",
  };

  return (
    <div className="row filters">
      <div className="col-2 p-0">
        {selectedShows.length} <T _str="shows selected" />
      </div>
      <div className="col-8 p-0">
        <RadioGroup
          name="time_range"
          className="inline"
          labels={timeRangeData}
          defaultValue={timeRange}
          getValue={getPeriodValue}
          disabledValues={isAllInTimeFrameDisabled()}
        />
      </div>
      <div className="col-2 p-0">
        <span>
          <T _str="Shows per page" />
        </span>
        <Select
          data={perPageData}
          getValue={showsPerPageHandler}
          initialValue={"" + pageSize + ""}
        />
      </div>
    </div>
  );
};

export default ZoneSettings;
