import axios from "axios/index";

export type Action = {
  id: string;
  name: string;
  color: string;
  sound: string;
  text: string;
};
type GetAllReponse = {
  results: Action[];
  resultCount: number;
  totalResultCount: number;
  pageSize: number;
  currentPage: number;
  totalPageCount: number;
};

/**
 * @class ./services/api/Action/Action
 */
class ActionService {
  static allActionsCached: Action[];

  /**
   * Returns paginates list of all actions.
   *
   * @returns {Action[]}
   *
   * @example Response:
   * {
   *  "results": [
   *  {
   *    "id": "string",
   *    "name": "string",
   *    "color": "string",
   *    "sound": "string",
   *    "text": "string"
   *  }
   *  ],
   *   "resultCount": 0,
   *   "totalResultCount": 0,
   *   "pageSize": 0,
   *   "currentPage": 0,
   *   "totalPageCount": 0
   * }
   *
   */
  async getAll(): Promise<Action[]> {
    if (ActionService.allActionsCached) {
      return ActionService.allActionsCached;
    }

    let params = {
      Sort: "name",
      pageSize: 9999,
    };

    const response = await axios.get<GetAllReponse>("/actions", {
      params: params,
    });
    ActionService.allActionsCached = response.data.results;
    return ActionService.allActionsCached;
  }

  /**
   * Creates one action.
   *
   * @param {Object} action
   *
   * @returns {Object}
   *
   * @example Request/Response:
   *  {
   *    "id": "string",
   *    "name": "string",
   *    "color": "string",
   *    "sound": "string",
   *    "text": "string"
   *  }
   *
   */
  createOne = (action: Action) => {
    return axios
      .post("/actions", action)
      .then((response) => response.data)
      .catch((error) => {
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  };

  /**
   * Returns one action based on action Id.
   *
   * @param {String} id
   *
   * @returns {Action}
   *
   * @example Response:
   *  {
   *    "id": "string",
   *    "name": "string",
   *    "color": "string",
   *    "sound": "string",
   *    "text": "string"
   *  }
   *
   */
  getOne = (id: string) => {
    return axios
      .get<Action>("/actions/" + encodeURIComponent(id))
      .then((response) => response.data)
      .catch((error) => {
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  };

  /**
   * Updates one action based on action Id.
   *
   * @param {String} id
   * @param {Action} action
   *
   * @returns {Object}
   *
   * @example Request:
   *  {
   *    "id": "string",
   *    "name": "string",
   *    "color": "string",
   *    "sound": "string",
   *    "text": "string"
   *  }
   *
   */
  updateOne = (id: string, action: Action) => {
    return axios
      .put("/actions/" + encodeURIComponent(id), action)
      .then((response) => response.data)
      .catch((error) => {
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  };

  /**
   * Deletes one action.
   *
   * @param {String} id
   *
   * @returns {Object}
   *
   */
  deleteOne = (id: string) => {
    return axios
      .delete("/actions/" + encodeURIComponent(id))
      .then((response) => response.data)
      .catch((error) => {
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  };
}

export default ActionService;
