import axios from 'axios/index';

/**
 * @class ./services/api/ShowFilter/ShowFilter
 */
class ShowFilter
{

    /**
     * Returns list of all show filters for the given venue Id.
     *
     * @param {String} venueId
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "rules": [
     *  [
     *    {
     *     "operator": "equal",
     *     "field": "string",
     *     "operand": "string"
     *    }
     *   ]
     *  ]
     * }
     *
     */
    getAll = (venueId) =>
    {
        return axios.get('/venues/' + encodeURIComponent(venueId) +'/showfilters')
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Updates show filters for the given venue Id.
     *
     * @param {String} venueId
     * @param {Object} showFilter
     *
     * @returns {Object}
     *
     * @example Request:
     * {
     *  "rules": [
     *  [
     *    {
     *     "operator": "equal",
     *     "field": "string",
     *     "operand": "string"
     *    }
     *   ]
     *  ]
     * }
     *
     */
    updateOne = (venueId, showFilter) =>
    {
        return axios.put('/venues/' + encodeURIComponent(venueId) +'/showfilters',
            showFilter
        ).then(response => {
            return {
                data: response.data,
                status: response.status
            }
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Triggers reevaluation of the current showfilters of this venue.
     *
     * Triggers reevaluation of the current showfilters of this venue and (un-)assigns shows accordingly.
     * This call will block until the filter evaluation queue finished processing the filter for this show.
     *
     * @param {String} venueId
     *
     * @returns {Object}
     *
     */
    reevaluateShowFilter = (venueId) =>
    {
        return axios.post('/venues/' + encodeURIComponent(venueId) + '/applyshowfilters')
            .then(response => {
                return {
                    data: response.data,
                    status: response.status
                }
            }).catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Returns an array of all properties that can be used as "field" for showfilters.
     *
     * @returns {Object}
     *
     * @example Response:
     * [
     *   "string"
     * ]
     *
     */
    getAllShowPropertyKeys = () =>
    {
        return axios.get('/showpropertykeys')
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

}

export default ShowFilter;