import axios from 'axios/index';

/**
 * @class ./services/api/Provider/Provider
 */
class Provider
{

    /**
     * Returns paginates list of all providers.
     *
     * @param {Object} paramObject
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *    {
     *     "shows": 0,
     *     "name": "string",
     *     "enabled": true,
     *     "clientId": "string"
     *    }
     *  ],
     *   "resultCount": 0,
     *   "totalResultCount": 0,
     *   "pageSize": 0,
     *   "currentPage": 0,
     *   "totalPageCount": 0
     * }
     *
     */
    getAll = (paramObject) =>
    {
        return axios.get('/providers',
            {
                params: paramObject
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };
    /**
     * Returns provider.
     *
     * @param {Object} paramObject
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *    {
     *     "clientId" : string,
     *     "enabled": boolen,
     *     "isSeeTicketsV2": boolen,
     *     "lastShowImport": null,
     *     "name" : string,
     *     "seeTicketsV2Configuration": null,
     *     "shows" : int,
     *    }
     *  ]
     * 
     */
    getOne = (id) =>
    {
        return axios.get(`/providers/${id}`)
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Creates one provider.
     *
     * @param {Object} provider
     *
     * @returns {Object}
     *
     * @example Request:
     *    {
     *      "name": "string"
     *    }
     *
     * @example Response:
     *    {
     *     "clientSecret": "string",
     *     "name": "string",
     *     "enabled": true,
     *     "clientId": 0,
     * }
     *
     */
    createOne = (provider) => {
        return axios.post('/providers',
            provider
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Updates one provider based on client Id.
     *
     * @param {String} id
     * @param {Object} provider
     *
     * @returns {Object}
     *
     * @example Request:
     *    {
     *     "name": "string",
     *     "enabled": true
     *     }
     *
     */
    updateOne = (id, provider) =>
    {
        return axios.put('/providers/' + encodeURIComponent(id),
            provider
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Deletes one provider for the given client Id.
     *
     * @param {String} id
     *
     * @returns {Object}
     *
     */
    deleteOne = (id) =>
    {
        return axios.delete('/providers/' + encodeURIComponent(id))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            }).catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Resets client secret for the given provider client Id.
     *
     * @param {String} id
     *
     * @returns {Object}
     *
     * @example Response:
     *    {
     *     "clientSecret": "string",
     *     "name": "string",
     *     "enabled": true
     *     "clientId": "string"
     *     }
     *
     */
    resetClientSecret = (id) =>
    {
        return axios.post('/providers/' + encodeURIComponent(id) + '/resetsecret').then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    }
}

export default Provider;
