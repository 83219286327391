import axios from "axios/index";
import UserManagerInstance from "../login/UserManagerInstance";

export default function Axios(token) {
  axios.defaults.headers.common = {
    Authorization: "Bearer " + token,
  };
}

UserManagerInstance.getUser().then(function (user) {
  if (user) {
    axios.defaults.headers.common = {
      Authorization: "Bearer " + user.access_token,
    };
  }
});

axios.defaults.baseURL =
  (window.REACT_APP_API_BASE_URL ?? process.env.REACT_APP_API_BASE_URL) + (window.REACT_APP_API_VERSION ?? process.env.REACT_APP_API_VERSION);
