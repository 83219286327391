import axios from "axios/index";

export type Color = {
  id: string;
  code: string;
  name: string;
  sort: number;
};

export default class ColorService {
  static colorsCached: Color[];

  /**
   *
   * Gets all colors available in the system.
   * The result is cached upon the first call and returned in subsequent usages.
   *
   * @returns {Promise<Color[]>}
   */
  async getColors(): Promise<Color[]> {
    if (ColorService.colorsCached) {
      return ColorService.colorsCached;
    }

    var response = await axios.get<Color[]>("/colors");
    ColorService.colorsCached = response.data;
    return ColorService.colorsCached;
  }
}
