import { Button, ButtonProps, ButtonSize } from "../Button";
import { DownloadIcon } from "../../../../../components/Images";
import { useT } from "@transifex/react";

import "./style.css";

type DownloadDataResponse = {
  data: any;
  status: number;
}

interface DownloadButtonProps extends ButtonProps {
  /** The function that provides the data to be downloaded. */
  onDownload: () => Promise<DownloadDataResponse>;
  /** The suggested name to be used for the downloaded file. */
  filename?: string;
}

export default function DownloadButton(props: DownloadButtonProps) {
  const t = useT();
  const downloadButtonProps: DownloadButtonProps = { ...props };

  downloadButtonProps.size ??= ButtonSize.Small;
  downloadButtonProps.className =
    (props.className ?? "") + " btn-secondary downloadbutton";
  downloadButtonProps.title = t(props.title ?? "Download");
  downloadButtonProps.children = (
    <>
      <DownloadIcon fill="var(--primary-color)" />
      <span className="downloadbutton-text">{props.children}</span>
    </>
  );
  downloadButtonProps.onClickHandler = async function () {
    var response = await props.onDownload();
    if (response.status != 200) {
      return;
    }

    var blob = new Blob([response.data]);

    // Create a temporary anchor element to trigger download
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);

    // Set the filename for the downloaded file
    downloadLink.setAttribute('download', props.filename ?? 'data.csv');

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return <Button {...downloadButtonProps} />;
}
