import React, { Component } from 'react';

/**
 * @class ./services/api/utils/DateTime/DateTime
 */
class Error extends Component {

    /**
     * Returns error component generated from array.
     *
     * @param {Array} errors
     *
     * @returns {XML}
     */
    printFieldErrors = (errors) =>
    {
        let errorComponent = (<div/>);

        if (errors && errors.length > 0) {
            errorComponent = errors.map( (error, key) => {
                return (
                    <div key={ key } className={'error'}>
                        - { error }
                    </div>
                )
            })
        }

        return errorComponent;
    }
}

export default Error;