import React       from 'react';
import PropTypes   from 'prop-types';
import { NavLink } from 'react-router-dom';
import IconNext    from './images/ico-next.svg';

import './style.css';

const SidebarMenu = ({sidebarNavigationMenuItems, id, section}) =>
{
    const navTo = (menuItem) => {
        return section !== "Dashboard"
            ? menuItem[1].href + '/' + id
            : menuItem[1].href;
    };

    return (
        <div className="SidebarMenu">
            <ul className="text-uppercase">
                {
                    // Renders sidebar navigation menu by provided json.
                    Object.entries(sidebarNavigationMenuItems).map((menuItem,index) =>
                        <li key={index}>
                            <NavLink exact to={navTo(menuItem)} activeClassName="active">
                                {menuItem[0]}
                                { menuItem[1].number !== null &&
                                    <span className="badge orange">{menuItem[1].number}</span>
                                }
                                <img src={IconNext} alt="Next" className="pull-right"/>
                            </NavLink>
                        </li>
                    )
                }
            </ul>
        </div>
    )
};

SidebarMenu.propTypes = {
    sidebarNavigationMenuItems : PropTypes.object.isRequired,
    id : PropTypes.string.isRequired
};

export default SidebarMenu;