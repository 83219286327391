import { MouseEventHandler, ReactNode } from "react";

import "./style.css";

export const enum ButtonStatus {
  Disabled = "Disabled",
  Empty = "",
}

export const enum ButtonSize {
  Large = "large",
  Medium = "medium",
  Small = "small",
  Tiny = "tiny",
  Empty = "",
}

export const enum ButtonType {
  Social = "Social",
  Ghost = "Ghost",
  Cancel = "Cancel",
  Empty = "",
}

export type ButtonProps = {
  onClickHandler?: MouseEventHandler<HTMLButtonElement>;
  status?: ButtonStatus;
  size?: ButtonSize;
  type?: ButtonType;
  className?: string;
  title?: string;
  children?: ReactNode;
};

const Button = ({
  onClickHandler,
  status = ButtonStatus.Empty,
  size = ButtonSize.Medium,
  type = ButtonType.Empty,
  className = undefined,
  title = undefined,
  children = null,
}: ButtonProps) => (
  <span className="Button">
    <button
      title={title}
      onClick={
        onClickHandler && status !== ButtonStatus.Disabled
          ? onClickHandler
          : undefined
      }
      className={[size, type, status, className].join(" ")}
    >
      <span className="text">{children}</span>
    </button>
  </span>
);

export default Button;
