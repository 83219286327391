import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { T, useT } from "@transifex/react";
import { tx } from "@transifex/native";
import { LoadingScreen } from "../../components/Loading";
import { UsersService } from "../../services/api/Users";
import { Toggle } from "../../themes/default/Toggle";
import { DialogError } from "../../themes/default/Dialog";
import { ResponseService } from "../../services/utils/Response";
import { Input } from "../../themes/default/Form/components/Input";
import {
  Button,
  ButtonSize,
} from "../../themes/default/Form/components/Button";
import { Select } from "../../themes/default/Form/components/Select";

import "react-toastify/dist/ReactToastify.css";
import "./style.css";

const EditUser = ({ loggedUser }) => {
  const t = useT();
  let params = useParams();
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [redirected, setRedirected] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    response: [],
    password: [],
  });
  const [showPasswords, setShowPasswords] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [password, setPassword] = useState("");

  const usersService = new UsersService();
  const responseService = new ResponseService();

  useEffect(() => {
    document.title = "See Tickets - " + t("Edit User");
    if (loggedUser !== null) {
      usersService.getUser(params.id).then((response) => {
        if (response.status === 200) {
          setUser(response.data);
        } else {
          if (response.data === "Only an admin can get another user data") {
            setRedirected(true);
          }
          setErrorMessages(
            (err) =>
              (err = {
                ...err,
                response: responseService.getErrorMessages(response.data),
              })
          );
        }
      });
    }
    setShowLoadingScreen(false);
  }, [loggedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateUser = () => {
    setShowLoadingScreen(true);

    // Languages locale list
    const languagesList = {
      en: "en",
      de: "de_CH",
      fr: "fr_CH",
    };
    const value = user.language;

    usersService.updateUser(user.id, user).then((response) => {
      if (response.status === 200) {
        setUser(response.data);
        toast.success("Updated successfully.");
        tx.setCurrentLocale(languagesList[value]);
      } else {
        setErrorMessages(
          (err) =>
            (err = {
              ...err,
              response: responseService.getErrorMessages(response.data),
            })
        );
      }
      setShowLoadingScreen(false);
    });
  };

  const updateUserPassword = () => {
    if (password !== "") {
      setShowLoadingScreen(true);
      usersService.updatePassword(user.id, password).then((response) => {
        if (response.status === 204) {
          setPassword("");
          toast.success("Password successfully changed.");
        } else {
          setErrorMessages(
            (err) =>
              (err = {
                ...err,
                password: responseService.getErrorMessages(response.data),
              })
          );
        }
        setShowLoadingScreen(false);
      });
    } else passwordValidator(password);
  };

  const inputChangeHandler = (value, key) => {
    setUser((user) => {
      return {
        ...user,
        [key]: value,
      };
    });
  };

  const passwordHandler = (value) => {
    errorMessages.password.length !== 0 &&
      setErrorMessages(
        (err) =>
          (err = {
            ...err,
            password: [],
          })
      );
    setPassword(value);
  };

  const passwordValidator = (newPass) => {
    setErrorMessages(
      (err) =>
        (err = {
          ...err,
          password: newPass !== "" ? [] : ["Passwords cannot be empty!"],
        })
    );
  };

  const closeErrorDialog = () => {
    if (redirected) {
      history.push(`/user/${loggedUser.id}`);
      history.go(`/user/${loggedUser.id}`);
    }
    setErrorMessages({});
  };

  return (
    <div id="edit-user">
      <h1 className="edit-user-title">
        <T _str="Edit user" />
      </h1>
      <h3>
        <T _str="Personal Information" />
      </h3>
      <div className="personal-information container">
        <div className="row">
          <div className="col-12 mt-3">
            <Input
              readOnly={true}
              name="Email"
              value={user?.username}
              label={<T _str="Email" />}
              getValue={() => console.log("")}
              disabled
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <Input
              name="First Name"
              value={user?.firstName}
              autoComplete="First name"
              label={<T _str="First Name" />}
              getValue={(value) => inputChangeHandler(value, "firstName")}
              focus
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <Input
              name="Last Name"
              value={user?.lastName}
              autoComplete="Last name"
              label={<T _str="Last Name" />}
              getValue={(value) => inputChangeHandler(value, "lastName")}
            />
          </div>
          <div className="col-12 col-md-8">
            <Select
              initialValue={user?.language}
              getValue={(value) => inputChangeHandler(value.value, "language")}
              data={{ de: "Deutsch", en: "English", fr: "Français" }}
            />

            {loggedUser?.isAdmin && (
              <Toggle
                className="my-4"
                isChecked={user?.isAdmin}
                label="Is this user an Admin"
                callBack={(value) => inputChangeHandler(!value, "isAdmin")}
              />
            )}
          </div>
          <div className="col-12 col-md-4 text-end">
            <Button className={ButtonSize.Medium} onClickHandler={updateUser}>
              <T _str="Save" />
            </Button>
          </div>
        </div>
      </div>

      <h3>
        <T _str="Security" />
      </h3>
      <div className="security container">
        <div className="row">
          <div className="col-12 col-md-6 mt-3">
            <Input
              type={showPasswords ? "text" : "password"}
              name="Password"
              autoComplete="password"
              getValue={passwordHandler}
              label={<T _str="New Password" />}
              className={`modern-input ${
                errorMessages.password && errorMessages.password?.length !== 0
                  ? "error"
                  : ""
              }`}
              onBlur={() => passwordValidator(password)}
              value={password}
            />
          </div>
          <div className="col-12">
            {errorMessages.password &&
              errorMessages.password.map((err, idx) => (
                <span className="d-block error" key={idx}>
                  - {t(err)}
                </span>
              ))}
          </div>
          <div className="col-12 mt-3">
            <Toggle
              className="my-4"
              isChecked={showPasswords}
              label={<T _str="View Password" />}
              callBack={() => setShowPasswords((e) => (e = !e))}
            />
          </div>
          <div className="col-12 text-end">
            <Button
              className={ButtonSize.Medium}
              onClickHandler={updateUserPassword}
            >
              <T _str="Save" />
            </Button>
          </div>
        </div>
      </div>

      <DialogError
        show={errorMessages.response.length !== 0}
        closeDialog={closeErrorDialog}
      >
        {errorMessages.response.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </DialogError>

      {showLoadingScreen && <LoadingScreen />}

      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditUser;
