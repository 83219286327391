import React, { useState, useEffect } from 'react';
// import { CSVLink }                    from "react-csv";
import { TicketService }              from './services';
import { useT }                       from '@transifex/react'
import { LogPanel }                   from './components/LogPanel';
import { LogSearch }                  from './components/LogSearch';
import { VenueService }               from '../../../../services/api/Venue';
import { LoadingScreen }              from '../../../../components/Loading';
import { Pagination }                 from '../../../../components/Pagination';
import { DialogError }                from '../../../../themes/default/Dialog';
import { ResponseService }            from '../../../../services/utils/Response';
import { SceneTitle }                 from '../../../../themes/default/Title/components/Scene';

import './Logs.css';

const Logs = props =>
{
    const t = useT();
    const [tickets, setTickets] = useState({});
    const [csvData, setCsvData] = useState(null);
    const [errorMessages, setRrrorMessages] = useState([]);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);
    const [paginationData, setPaginationData] = useState({ 
        currentPage: 1,
        totalPageCount: 1,
        pageSize: 50,
        totalTickets: ''
    });

    const venueService = new VenueService();
    const ticketService = new TicketService();
    const responseService = new ResponseService();

    useEffect (() => {
        fetchTickets(paginationData.currentPage, paginationData.pageSize);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchTickets = (page,pageSize) => {
        let parameterObject = {
            Page: page,
            PageSize: pageSize
        };

        venueService.getAllTickets(props.match.params.id, parameterObject).then(response => {
            if (response.status === 200) {
                if (response.data.results !== null) {
                    
                    setPaginationData({
                        currentPage: response.data.currentPage,
                        totalPageCount: response.data.totalPageCount,
                        pageSize: response.data.pageSize,
                        totalTickets: response.data.totalResultCount,
                    });
                    
                    const data = response.data.results;
                    const logsDownloadData =  [["Barcode", "Validity", "Show Id", "External Ticket Id", "Checkpoint name",  "result"]];
                    setTickets(ticketService.getVenueTickets(data) );

                    data.map(item => {
                        logsDownloadData.push([
                            item.barcode,
                            item.validity,
                            item.showId,
                            item.externalTicketId,
                            item.history ? item.history.checkpoint?.name : "",
                            item.history ? item.history.result : ""
                        ]);
                        return null;
                    });
                    setCsvData(logsDownloadData);
                }
            } else {
                setShowErrorDialog(true);
                setRrrorMessages(responseService.getErrorMessages(response.data));
            };
            setShowLoadingScreen(false);
        });
    };


    /**
     * Updates state and tickets list by api response according to search criteria.
     *
     * @param {Object} criteriaObject
     */
    const searchLogs = (criteriaObject) =>
    {
        let parameterObject = {
            Filter: criteriaObject.by + '@=*' + criteriaObject.criteria
        };

        if (!criteriaObject.criteria) {
            parameterObject = {
                Filter: 'barcode@=*'
            };
        }

        setShowLoadingScreen(true);

        venueService.getAllTickets(props.match.params.id, parameterObject).then(response => {
            if (response.status === 200) {
                if (response.data.results !== null) {
                    setTickets(ticketService.getVenueTickets(response.data.results));
                    setPaginationData({
                        currentPage: response.data.currentPage,
                        totalPageCount: response.data.totalPageCount,
                        pageSize: response.data.pageSize,
                        totalTickets: response.data.totalResultCount,
                    });
                }
            } else {                
                setShowErrorDialog(true);
                setRrrorMessages(responseService.getErrorMessages(response.data));
            }
            setShowLoadingScreen(false);
        })
    };

    /**
     * Updates venue's state value according to selected page.
     *
     * @param {Number} page
     */
    const changePageHandler = (page) =>
    {
        if (paginationData.currentPage !== page) {
            fetchTickets(page,paginationData.pageSize);
        }
    };

    return (
        <div className="Logs">
            
            <SceneTitle text={t('Venue Logs')} >
                <p>{props.venue?.name}</p>
            </SceneTitle>

            <LogSearch searchLogs={searchLogs}/>

            <div className="row my-4">
                <div className="col-6">
                    <h4 className="m-0 results-title">{t("Results")} <span className={paginationData.totalTickets > 0 ? 'total-tickets hasResults' : 'total-tickets'}>{paginationData.totalTickets}</span></h4>
                </div>

                {/* 
                    TO BE ADDED SOON IN THE FUTURE 
                    <div className="col-6 download text-end">        
                    {csvData
                        ?   <CSVLink data={csvData}>Download Logs</CSVLink>
                        :   <button className="disabled" disabled >Download Logs</button>
                    }
                </div> */}
            </div>

            <div className="col-12 logs-panel">
                { csvData &&
                    <div className="row LogPanel text-center m-0 p-0 list-header">
                        <div className="col-2 offset-1">{t("Bar Code")}</div>
                        <div className="col-2">{t("Personalised Name")}</div>
                        <div className="col-1"></div>
                        <div className="col-1"></div>
                        <div className="col-3">{t("External Ticket ID")}</div>
                        <div className="col-2">{t("Ticket Status")}</div>
                    </div>
                }
                {
                    Object.entries(tickets).map(ticket => <LogPanel key={ticket[0]} ticket={ticket} /> )
                }
            </div>

            <Pagination changePageHandler={changePageHandler} currentPage={paginationData.currentPage} totalPageCount={paginationData.totalPageCount}/>

            { showLoadingScreen && <LoadingScreen/> }
            
            <DialogError show={showErrorDialog} closeDialog={() => setShowErrorDialog(false) }>
                {
                    errorMessages.map((message,index) => <p key={index}>{message}</p> )
                }
            </DialogError>
        </div>
    );
};

export default Logs;
