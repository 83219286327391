import React       from 'react';
import PropTypes   from 'prop-types';
import IconEdit    from './images/ico-edit.svg';
import IconCancel  from './images/ico-cancel.svg';

import './Filter.css'

const SingleFilter = ({ filter, editClickHandler, deleteClickHandler }) => 
(
    <span data-testid="filter-wrapper" className="filter">
        <img src={IconEdit} data-testid="edit-filter" alt="edit" title="edit" onClick={editClickHandler}/>
        {filter}
        <img src={IconCancel} data-testid="delete-filter" alt="Cancel" title="Remove" onClick={deleteClickHandler}/>
    </span>
);

SingleFilter.propTypes = {
    editClickHandler   : PropTypes.func.isRequired,
    deleteClickHandler : PropTypes.func.isRequired,
    filter             : PropTypes.string.isRequired
};

export default SingleFilter;