import axios from 'axios/index';

/**
 * @class ./services/api/Venue/Venue
 */
class Venue
{

    /**
     * Returns paginates list of all venues.
     *
     * @param {Object} paramObject
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *    {
     *     "id": "string",
     *     "name": "string",
     *     "useTimeframe": true,
     *     "loadShowsInAdvance": 0,
     *     "keepShowsAfterEnd": 0,
     *     "shows": {},
     *     "checkpoints": [
     *       {
     *         "name": "string",
     *         "from": "string",
     *         "to": "string",
     *         "permissionCheckOnly": true
     *       }
     *     ],
     *     "zones": [
     *       {
     *         "name": "string",
     *         "rules": [
     *           [
     *             {
     *               "operator": "equal",
     *               "field": "string",
     *               "operand": "string"
     *             }
     *           ]
     *         ]
     *       }
     *     ],
     *     "actions": [
     *       {
     *         "actionId": "string",
     *         "confirmationRequired": true,
     *         "rules": [
     *           [
     *             {
     *               "operator": "equal",
     *               "field": "string",
     *               "operand": "string"
     *             }
     *           ]
     *         ]
     *       }
     *     ]
     *    }
     *  ],
     *   "resultCount": 0,
     *   "totalResultCount": 0,
     *   "pageSize": 0,
     *   "currentPage": 0,
     *   "totalPageCount": 0
     * }
     *
     */
    getAll = (paramObject) =>
    {
        return axios.get('/venues',
            {
                params: paramObject
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Creates one venue.
     *
     * @param {Object} venue
     *
     * @returns {Object}
     *
     * @example Request/Response:
     *    {
     *     "id": "string",
     *     "name": "string",
     *     "useTimeframe": true,
     *     "loadShowsInAdvance": 0,
     *     "keepShowsAfterEnd": 0,
     *     "shows": {},
     *     "checkpoints": [
     *       {
     *         "name": "string",
     *         "from": "string",
     *         "to": "string",
     *         "permissionCheckOnly": true
     *       }
     *     ],
     *     "zones": [
     *       {
     *         "name": "string",
     *         "rules": [
     *           [
     *             {
     *               "operator": "equal",
     *               "field": "string",
     *               "operand": "string"
     *             }
     *           ]
     *         ]
     *       }
     *     ],
     *     "actions": [
     *       {
     *         "actionId": "string",
     *         "confirmationRequired": true,
     *         "rules": [
     *           [
     *             {
     *               "operator": "equal",
     *               "field": "string",
     *               "operand": "string"
     *             }
     *           ]
     *         ]
     *       }
     *     ]
     *    }
     *  ]
     * }
     *
     */
    createOne = (venue) => {
        return axios.post('/venues',
            venue
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns one venue for the given venue Id.
     *
     * @param {String} id
     *
     * @returns {Object}
     *
     * @example Response:
     *    {
     *     "id": "string",
     *     "name": "string",
     *     "useTimeframe": true,
     *     "loadShowsInAdvance": 0,
     *     "keepShowsAfterEnd": 0,
     *     "shows": {},
     *     "checkpoints": [
     *       {
     *         "name": "string",
     *         "from": "string",
     *         "to": "string",
     *         "permissionCheckOnly": true
     *       }
     *     ],
     *     "zones": [
     *       {
     *         "name": "string",
     *         "rules": [
     *           [
     *             {
     *               "operator": "equal",
     *               "field": "string",
     *               "operand": "string"
     *             }
     *           ]
     *         ]
     *       }
     *     ],
     *     "actions": [
     *       {
     *         "actionId": "string",
     *         "confirmationRequired": true,
     *         "rules": [
     *           [
     *             {
     *               "operator": "equal",
     *               "field": "string",
     *               "operand": "string"
     *             }
     *           ]
     *         ]
     *       }
     *     ]
     *    }
     *  ]
     * }
     *
     */
    getOne = (id) =>
    {
        return axios.get('/venues/' + encodeURIComponent(id))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Updates one venue based on venue Id.
     *
     * @param {String} id
     * @param {Object} venue
     *
     * @returns {Object}
     *
     * @example Request:
     *    {
     *     "id": "string",
     *     "name": "string",
     *     "useTimeframe": true,
     *     "loadShowsInAdvance": 0,
     *     "keepShowsAfterEnd": 0,
     *     "shows": {},
     *     "checkpoints": [
     *       {
     *         "name": "string",
     *         "from": "string",
     *         "to": "string",
     *         "permissionCheckOnly": true
     *       }
     *     ],
     *     "zones": [
     *       {
     *         "name": "string",
     *         "rules": [
     *           [
     *             {
     *               "operator": "equal",
     *               "field": "string",
     *               "operand": "string"
     *             }
     *           ]
     *         ]
     *       }
     *     ],
     *     "actions": [
     *       {
     *         "actionId": "string",
     *         "confirmationRequired": true,
     *         "rules": [
     *           [
     *             {
     *               "operator": "equal",
     *               "field": "string",
     *               "operand": "string"
     *             }
     *           ]
     *         ]
     *       }
     *     ]
     *    }
     *  ]
     * }
     *
     */
    updateOne = (id, venue) =>
    {
        return axios.put('/venues/' + encodeURIComponent(id),
            venue
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
            return {
                status: error.response.status,
                data: error.response.data
            };
            }
        );
    };

    /**
     * Deletes one venue for the given venue Id.
     *
     * @param {String} id
     *
     * @returns {Object}
     *
     */
    deleteOne = (id) =>
    {
        return axios.delete('/venues/' + encodeURIComponent(id))
        .then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                    return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns paginates list of shows in given venue.
     *
     * @param {String} venueId
     * @param {Object} parameterObject
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *   {
     *    "id": "string",
     *    "externalId": "string",
     *    "name": "string",
     *    "begin": "2018-03-01T10:56:54.884Z",
     *    "end": "2018-03-01T10:56:54.884Z",
     *    "ticketUsageLimit": 0,
     *    "allowReentryAfter": "string",
     *   }
     *  ],
     *   "resultCount": 0,
     *   "totalResultCount": 0,
     *   "pageSize": 0,
     *   "currentPage": 0,
     *   "totalPageCount": 0
     * }
     *
     */
    getAllShows = (venueId, parameterObject) =>
    {
        return axios.get('/venues/' + encodeURIComponent(venueId) + '/shows',
            {
                params: parameterObject
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Returns paginates list of handhelds in given venue.
     *
     * @param {String} venueId
     * @param {Object} paramObject
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *    {
     *      "id": "string",
     *      "venueId": "string",
     *      "name": "string",
     *      "deviceUniqueIdentifier": "string",
     *      "deviceModel": "string",
     *      "stagingStatus": "destaged",
     *      "state": {
     *        "lastStatusReport": "2018-03-01T10:56:54.838Z",
     *        "batteryLevel": 0,
     *        "appVersion": "1.0.0",
     *        "wifiSignal": 0,
     *        "mobileSignal": 0,
     *        "volume": {
     *          "reportedValue": 0,
     *          "adminRequestedValue": 0,
     *          "latestAdminValueApplied": true
     *        },
     *        "brightness": {
     *         "reportedValue": 0,
     *          "adminRequestedValue": 0,
     *          "latestAdminValueApplied": true
     *        },
     *        "checkpoint": {
     *          "reportedValue": "string",
     *          "adminRequestedValue": "string",
     *          "latestAdminValueApplied": true
     *        },
     *        "shows": {
     *           "additionalProp1": {
     *               "reportedValue": true,
     *               "adminRequestedValue": true,
     *               "latestAdminValueApplied": true
     *           },
     *           "additionalProp2": {
     *               "reportedValue": true,
     *               "adminRequestedValue": true,
     *               "latestAdminValueApplied": true
     *           },
     *           "additionalProp3": {
     *               "reportedValue": true,
     *               "adminRequestedValue": true,
     *               "latestAdminValueApplied": true
     *           },
     *        }
     *      }
     *    }
     *  ],
     *  "resultCount": 0,
     *  "totalResultCount": 0,
     *  "pageSize": 0,
     *  "currentPage": 0,
     *  "totalPageCount": 0
     * }
     *
     */
    getAllHandhelds = (venueId, paramObject) =>
    {
        return axios.get('/venues/' + encodeURIComponent(venueId) + '/handhelds', {
            params: paramObject
        })
            .then(response => {
            return {
                status: response.status,
                data: response.data
            };
        })
            .catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns paginates list of tickets in given venue.
     *
     * @param {String} venueId
     * @param {Object} parameterObject
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *   {
     *    "history": [
     *     {
     *       "handheldId": "string",
     *       "venueId": "string",
     *       "checkpoint": {
     *         "name": "string",
     *         "from": "string",
     *         "to": "string",
     *         "permissionCheckOnly": true
     *       },
     *       "time": "2018-04-12T11:53:31Z",
     *       "result: "successPermissionCheck"
     *     }
     *    ],
     *    "barcode": "string",
     *    "validity": "valid",
     *    "externalTicketId": "string",
     *    "personalisedTicketSalutation": "string",
     *    "personalisedTicketFirstName": "string",
     *    "personalisedTicketLastName": "string",
     *    "customProperties": {
     *      "additionalProp1": "string",
     *      "additionalProp2": "string",
     *      "additionalProp3": "string"
     *    }
     *   }
     *  ],
     *  "resultCount": 0,
     *  "totalResultCount": 0,
     *  "pageSize": 0,
     *  "currentPage": 0,
     *  "totalPageCount": 0
     * }
     *
     */
    getAllTickets = (venueId, parameterObject) =>
    {
        return axios.get('/venues/' + encodeURIComponent(venueId) + '/tickets',
            {
                params: parameterObject
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Returns an array of all properties that can be used as "field" for ticket search.
     *
     * @param {String} venueId
     *
     * @returns {Object}
     *
     * @example Response:
     * [
     *  "string"
     * ]
     *
     */
    getAllTicketPropertyKeys = (venueId) =>
    {
        return axios.get('/venues/' + encodeURIComponent(venueId) + '/ticketpropertykeys')
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

}

export default Venue;