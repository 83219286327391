/**
 * @class src/scenes/ShowManagement/services/Select
 */
class Select
{

    /**
     * Shows all available barcode titles.
     *
     * @type {Array}
     */
    showBarcodeTitles = [
        'qr',
        'code128',
        'code39',
        'code93',
        'aztec',
        'code11',
        'code25',
        'dataMatrix',
        'ean13',
        'ean8',
        'pdf417',
        'upca',
        'upce'
    ];


    /**
     * Returns appropriate barcode title string valid for front end display.
     *
     * @param {String} title
     *
     * @returns {String}
     */
    convertTitleToAppropriateName = (title) =>
    {
        let transformedTitle = '';

        switch(title) {
            case 'qr':
                transformedTitle = 'QR';
                break;
            case 'code128':
                transformedTitle = 'Code 128';
                break;
            case 'code39':
                transformedTitle = 'Code 39';
                break;
            case 'code93':
                transformedTitle = 'Code 93';
                break;
            case 'aztec':
                transformedTitle = 'Aztec';
                break;
            case 'code11':
                transformedTitle = 'Code 11';
                break;
            case 'code25':
                transformedTitle = 'Code 25';
                break;
            case 'dataMatrix':
                transformedTitle = 'Data Matrix';
                break;
            case 'ean13':
                transformedTitle = 'EAN-13';
                break;
            case 'ean8':
                transformedTitle = 'EAN-8';
                break;
            case 'pdf417':
                transformedTitle = 'PDF417';
                break;
            case 'upca':
                transformedTitle = 'UPC-A';
                break;
            case 'upce':
                transformedTitle = 'UPC-E';
                break;
            default:
                return null;
        }

        return transformedTitle;
    }


    /**
     * Returns barcode title string valid for API calls.
     *
     * @param {String} appropriateTitle
     *
     * @returns {String}
     */
    convertAppropriateNameToTitle = (appropriateTitle) =>
    {
        let transformedTitle = '';

        switch(appropriateTitle) {
            case 'QR':
                transformedTitle = 'qr';
                break;
            case 'Code 128':
                transformedTitle = 'code128';
                break;
            case 'Code 39':
                transformedTitle = 'code39';
                break;
            case 'Code 93':
                transformedTitle = 'code93';
                break;
            case 'Aztec':
                transformedTitle = 'aztec';
                break;
            case 'Code 11':
                transformedTitle = 'code11';
                break;
            case 'Code 25':
                transformedTitle = 'code25';
                break;
            case 'Data Matrix':
                transformedTitle = 'dataMatrix';
                break;
            case 'EAN-13':
                transformedTitle = 'ean13';
                break;
            case 'EAN-8':
                transformedTitle = 'ean8';
                break;
            case 'PDF417':
                transformedTitle = 'pdf417';
                break;
            case 'UPC-A':
                transformedTitle = 'upca';
                break;
            case 'UPC-E':
                transformedTitle = 'upce';
                break;
                default:
                    return null;
        }

        return transformedTitle;
    }


    /**
     * Returns mapped barcode options array.
     *
     * @param {Array} responseData
     *
     * @returns {Array}
     */
    getBarcodeOptions = ( responseData=[] ) =>
    {
        let barcodeOptions = this.showBarcodeTitles.map((title, index) => {
            return {
                id: index + 1,
                title: this.convertTitleToAppropriateName(title),
                selected: responseData.includes(title)
            };
        });

        if(responseData.length === 0) {
            barcodeOptions = barcodeOptions.map(item => {
                if(item.title === 'QR' || item.title === 'Code 128') {
                    item.selected = true;
                }

                return item;
            });
        }

        return barcodeOptions;
    }

    /**
     * Returns mapped title array valid for API calls.
     * 
     * @param {Array} multiSelectData
     *
     * @returns {Array}
     */
    barcodeOptionsToTitleList = (multiSelectData) =>
    {
        let barcodeOptions = multiSelectData
            .filter(item => item.selected === true)
            .map(item => this.convertAppropriateNameToTitle(item.title));
        
        if (barcodeOptions.length === 0) {
            barcodeOptions = ["qr", "code128"];
        }

        return barcodeOptions;
    }

}

export default Select;