/**
 * @class scenes/VenueManagement/scenes/Shows/services/List
 */
class List
{

    /**
     * Venue shows list columns.
     *
     * @type {Object}
     */
    showColumns = {
        'Name'              : { 'subtitle' : null, 'sortable' : false },
        'Within time frame' : { 'subtitle' : null, 'sortable' : false },
        'In the future'     : { 'subtitle' : null, 'sortable' : false },
        'Total'             : { 'subtitle' : null, 'sortable' : false }
    };

    /**
     * Returns columns for venue shows list.
     *
     * @returns {Object}
     */
    getShowColumns = () =>
    {
        return this.showColumns;
    };

    /**
     * Returns adapted data for venue shows list.
     *
     * @param {Object} responseData
     *
     * @returns {Array}
     */
    getShowData = (responseData) =>
    {
        let showsData = [];

        responseData.map(show => {
            let showData = {};
            showData['name'] = 'name';
            showData['begin'] = '1212121';
            showData['end'] = '23233';
            showData['event'] = '5454545';
            showsData.push(showData);

            return true;
        });

        return showsData;
    };
}

export default List;