import axios from "axios/index";

export type ActionMessage = {
  id: string;
  text: string;
  sound: string;
  sort: number;
};

export default class ActionMessageService {
  static actionMessagesCached?: ActionMessage[];

  /**
   *
   * Gets all actionMessages available in the system.
   * The result is cached upon the first call and returned in subsequent usages.
   *
   * @returns {Promise<ActionMessage[]>}
   */
  async getActionMessages(): Promise<ActionMessage[]> {
    if (ActionMessageService.actionMessagesCached) {
      return ActionMessageService.actionMessagesCached;
    }

    var response = await axios.get<ActionMessage[]>("/actionMessages");
    ActionMessageService.actionMessagesCached = response.data;
    return ActionMessageService.actionMessagesCached;
  }
}
