import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Color, ColorService } from "../../../../../services/api/Color";
import {
  ActionMessage,
  ActionMessageService,
} from "../../../../../services/api/ActionMessage";
import { LoadingForTables } from "../../../../../components/Loading";
import { Action, ActionService } from "../../../../../services/api/Action";
import { t } from "@transifex/native";

const localesList = {
  "de-CH": "German (CH)",
  "de-DE": "German (DE)",
  "fr-FR": "French",
  "it-IT": "Italian",
  "en-GB": "English (GB)",
  "en-US": "English (US)",
};

const gendersList = {
  Female: t("Female"),
  Male: t("Male"),
};

class ActionDataSources {
  colors?: Color[];
  actionMessages?: ActionMessage[];
  actions?: Action[];
  locales = localesList;
  genders = gendersList;
  loaded = () => this.colors && this.actionMessages && this.actions && true;
}

const ActionDataSourcesContext = createContext<ActionDataSources>(
  new ActionDataSources()
);

export function useActionDataSources(): ActionDataSources {
  var context = useContext(ActionDataSourcesContext);
  return context;
}

export default function ActionDataSourcesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [colors, setColors] = useState<Color[] | undefined>(undefined);
  const [actionMessages, setActionMessages] = useState<
    ActionMessage[] | undefined
  >(undefined);
  const [actions, setActions] = useState<Action[] | undefined>(undefined);

  useEffect(() => {
    const getColors = async () => {
      var colorService = new ColorService();
      var colors = await colorService.getColors();
      setColors(colors);
    };

    const getActionMessages = async () => {
      var actionMessageService = new ActionMessageService();
      var actionMessages = await actionMessageService.getActionMessages();
      setActionMessages(actionMessages);
    };

    const getActions = async () => {
      var actionService = new ActionService();
      var actions = await actionService.getAll();
      setActions(actions);
    };

    getColors();
    getActionMessages();
    getActions();
  }, []);

  const actionDataSources = new ActionDataSources();
  actionDataSources.actionMessages = actionMessages;
  actionDataSources.colors = colors;
  actionDataSources.actions = actions;
  return (
    <ActionDataSourcesContext.Provider value={actionDataSources}>
      {actionDataSources.loaded() && children}
      {!actionDataSources.loaded() && <LoadingForTables />}
    </ActionDataSourcesContext.Provider>
  );
}
