import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { T } from "@transifex/react";
import { Button, ButtonStatus, ButtonType } from "../Form/components/Button";

import "./style.css";

/**
 * @class Dialog/Dialog
 */
class Dialog extends Component {
  state = {
    showModal: true,
  };

  /**
   * Changes state for closing modal.
   */
  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  /**
   * Override.
   */
  UNSAFE_componentWillReceiveProps() {
    this.props.showModal &&
      this.setState({
        showModal: this.props.showModal,
      });
  }

  /**
   * @returns {XML}
   */
  render() {
    return (
      <Modal className={this.props.className} show={this.state.showModal}>
        {this.props.title ? (
          <Modal.Header>
            <h1>
              {this.props.title}
              {this.props.additionalTitle && (
                <span className={"additional-title"}>
                  {["", this.props.additionalTitle].join(" ")}
                </span>
              )}
            </h1>
          </Modal.Header>
        ) : null}

        <Modal.Body>{this.props.children}</Modal.Body>
        <Modal.Footer>
          {!this.props.hideSecondaryButton && (
            <Button
              className="cancel-button"
              type={ButtonType.Cancel}
              onClickHandler={() =>
                this.props.closeModal
                  ? this.props.closeModal()
                  : this.closeModal()
              }
            >
              {this.props.secondaryButton}
            </Button>
          )}

          <Button
            className="create-button"
            status={this.props.mainButtonDisabled ? ButtonStatus.Disabled : ""}
            onClickHandler={() => this.props.action()}
          >
            {this.props.mainButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

Dialog.defaultProps = {
  className: "",
  mainButton: <T _str="create" />,
  secondaryButton: <T _str="cancel" />,
  hideSecondaryButton: false,
};

Dialog.propTypes = {
  action: PropTypes.func,
  className: PropTypes.string,
  additionalTitle: PropTypes.string,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  mainButtonDisabled: PropTypes.bool,
  hideSecondaryButton: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mainButton: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondaryButton: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Dialog;
